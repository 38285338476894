import { Button, Popover, Spacer } from '@eltoro-ui/components';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { ResetSettings } from '../EditBlock/components/ResetSettings';
import { HorizontalLabel } from '../HorizontalLabel';
import './MoreInfoContainer.scss';

export const MoreInfoContainer: React.FC<{
  label: string;
  showReset?: boolean;
  onReset?: () => void;
}> = ({ label, showReset = false, onReset, children }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="MoreInfoContainer">
      <HorizontalLabel label={label}>
        <ResetSettings showReset={showReset} onReset={() => onReset && onReset()}>
          <Popover
            align="end"
            position={['bottom']}
            content={
              <>
                <Spacer height="0.5rem" />
                <div className="MoreInfoContainer__body">{children}</div>
              </>
            }
            isOpen={open}
          >
            <div>
              <Button
                kind={open ? 'default' : 'text'}
                onClick={() => setOpen(!open)}
                iconLeft={<FontAwesomeIcon icon={faEdit} />}
              />
            </div>
          </Popover>
        </ResetSettings>
      </HorizontalLabel>
    </div>
  );
};
