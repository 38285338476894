import React from 'react';
import classNames from 'classnames';

type CreativesLibraryIconProps = {
  className?: string;
};

export default function CreativesLibraryIcon({ className }: CreativesLibraryIconProps) {
  return (
    <svg
      className={classNames(className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      role="img"
      aria-hidden="true"
    >
      <path
        d="M17 9C17.5523 9 18 8.55228 18 8C18 7.44772 17.5523 7 17 7L15 7C14.4477 7 14 7.44771 14 8C14 8.55228 14.4477 9 15 9L17 9Z"
        fill="currentColor"
      />
      <path
        d="M18 11C18 11.5523 17.5523 12 17 12H15C14.4477 12 14 11.5523 14 11C14 10.4477 14.4477 10 15 10L17 10C17.5523 10 18 10.4477 18 11Z"
        fill="currentColor"
      />
      <path
        d="M17 15C17.5523 15 18 14.5523 18 14C18 13.4477 17.5523 13 17 13H15C14.4477 13 14 13.4477 14 14C14 14.5523 14.4477 15 15 15H17Z"
        fill="currentColor"
      />
      <path
        d="M14 24C14 22.8954 14.8954 22 16 22C17.1046 22 18 22.8954 18 24C18 25.1046 17.1046 26 16 26C14.8954 26 14 25.1046 14 24Z"
        fill="currentColor"
      />
      <path
        d="M26 8C26 8.55228 25.5523 9 25 9L23 9C22.4477 9 22 8.55228 22 8C22 7.44771 22.4477 7 23 7L25 7C25.5523 7 26 7.44772 26 8Z"
        fill="currentColor"
      />
      <path
        d="M25 12C25.5523 12 26 11.5523 26 11C26 10.4477 25.5523 10 25 10L23 10C22.4477 10 22 10.4477 22 11C22 11.5523 22.4477 12 23 12H25Z"
        fill="currentColor"
      />
      <path
        d="M26 14C26 14.5523 25.5523 15 25 15H23C22.4477 15 22 14.5523 22 14C22 13.4477 22.4477 13 23 13H25C25.5523 13 26 13.4477 26 14Z"
        fill="currentColor"
      />
      <path
        d="M24 22C22.8954 22 22 22.8954 22 24C22 25.1046 22.8954 26 24 26C25.1046 26 26 25.1046 26 24C26 22.8954 25.1046 22 24 22Z"
        fill="currentColor"
      />
      <path
        d="M10 8C10 8.55228 9.55228 9 9 9L7 9C6.44772 9 6 8.55228 6 8C6 7.44771 6.44772 7 7 7L9 7C9.55228 7 10 7.44772 10 8Z"
        fill="currentColor"
      />
      <path
        d="M9 12C9.55228 12 10 11.5523 10 11C10 10.4477 9.55228 10 9 10L7 10C6.44772 10 6 10.4477 6 11C6 11.5523 6.44772 12 7 12H9Z"
        fill="currentColor"
      />
      <path
        d="M10 14C10 14.5523 9.55228 15 9 15H7C6.44772 15 6 14.5523 6 14C6 13.4477 6.44772 13 7 13H9C9.55228 13 10 13.4477 10 14Z"
        fill="currentColor"
      />
      <path
        d="M8 22C6.89543 22 6 22.8954 6 24C6 25.1046 6.89543 26 8 26C9.10457 26 10 25.1046 10 24C10 22.8954 9.10457 22 8 22Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5C3 3.34315 4.34315 2 6 2H10C10.7684 2 11.4692 2.28885 12 2.7639C12.5308 2.28885 13.2316 2 14 2H18C18.7684 2 19.4692 2.28885 20 2.7639C20.5308 2.28885 21.2316 2 22 2H26C27.6569 2 29 3.34315 29 5V27C29 28.6569 27.6569 30 26 30H22C21.2316 30 20.5308 29.7111 20 29.2361C19.4692 29.7111 18.7684 30 18 30H14C13.2316 30 12.5308 29.7111 12 29.2361C11.4692 29.7111 10.7684 30 10 30H6C4.34315 30 3 28.6569 3 27V5ZM19 27V5C19 4.44772 18.5523 4 18 4H14C13.4477 4 13 4.44772 13 5V27C13 27.5523 13.4477 28 14 28H18C18.5523 28 19 27.5523 19 27ZM21 5V27C21 27.5523 21.4477 28 22 28H26C26.5523 28 27 27.5523 27 27V5C27 4.44772 26.5523 4 26 4H22C21.4477 4 21 4.44772 21 5ZM10 4C10.5523 4 11 4.44772 11 5V27C11 27.5523 10.5523 28 10 28H6C5.44772 28 5 27.5523 5 27V5C5 4.44772 5.44772 4 6 4H10Z"
        fill="currentColor"
      />
    </svg>
  );
}
