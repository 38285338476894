import React from 'react';
import { NavLink, useParams } from 'react-router-dom';

import classNames from 'classnames';

import { Steps } from 'primereact/steps';
import type { MenuItem } from 'primereact/menuitem';

import './ProgressStepper.scss';

export const ProgressStepper = ({
  activeStep,
  tagNames,
  pathset,
}: {
  activeStep: number;
  pathset?: any;
  tagNames?: string[];
}) => {
  const { campaignId } = useParams<{ campaignId: string }>();

  const items: MenuItem[] = tagNames
    ? tagNames.map((label, index) => {
        let url: string = '';
        if (label === 'Audience') url = `/create-campaign/${campaignId}/setup`;
        if (label === 'Creative') url = `/create-campaign/${campaignId}/creatives`;
        return {
          label,
          url: (index === 0 || index === 1) && index < activeStep - 1 ? url : undefined,
          className: classNames(index < activeStep - 1 && 'p-highlight'),
          template: (item, options) => {
            if (!item.url) {
              // @ts-ignore
              return <span {...options.element.props} />;
            }
            // @ts-ignore
            return <NavLink {...options.element.props} exact to={item.url} />;
          },
        };
      })
    : [];

  return (
    <Steps
      className="campaign-creation-progress"
      model={items}
      readOnly={false}
      activeIndex={activeStep - 1}
    />
  );

  // return (
  //   <div className="ProgressStepperBar">
  //     <div className="ProgressStepperBar__steps">
  //       {tagNames?.map((tagName, index) => (
  //         <Fragment key={index}>
  //           {index > 0 && (
  //             <div
  //               className={classNames(
  //                 'ProgressStepperBar__line',
  //                 activeStep > index && 'ProgressStepperBar__line--is-filled'
  //               )}
  //             />
  //           )}
  //           <div>
  //             <button
  //               onClick={pathsetp(index)}
  //               style={{ cursor: pathsetp(index) ? 'pointer' : '' }}
  //               className="ProgressStepperBar__stepbutton"
  //               type="button"
  //             >
  //               <div
  //                 className={classNames(
  //                   'ProgressStepperBar__step',
  //                   activeStep > index && 'ProgressStepperBar__step--is-filled'
  //                 )}
  //                 style={{
  //                   color: activeStep > index ? 'var(--color-secondary-300)' : '',
  //                   textAlign: 'center',
  //                   paddingTop: activeStep > index ? '2px' : '3px',
  //                   border: activeStep > index ? '3px solid var(--color-secondary-300)' : '',
  //                 }}
  //               >
  //                 {index + 1}
  //               </div>
  //             </button>
  //             <button
  //               onClick={pathsetp(index)}
  //               style={{ cursor: pathsetp(index) ? 'pointer' : '' }}
  //               className="ProgressStepperBar__stepbutton_text"
  //               type="button"
  //             >
  //               <div
  //                 style={{
  //                   color: activeStep > index ? 'var(--color-secondary-300)' : '',
  //                   textAlign: 'center',
  //                   letterSpacing: '1px',
  //                   paddingTop: '4px',
  //                   fontWeight: activeStep > index ? 600 : 500,
  //                 }}
  //                 className="progressTag"
  //               >
  //                 {tagName}
  //               </div>
  //             </button>
  //           </div>
  //         </Fragment>
  //       ))}
  //     </div>
  //   </div>
  // );
};
