import React from 'react';
import { RecomendationContactEnum } from 'enums';
import { SaveAudienceWizard } from 'Pages/ProspectActivity/components';
import { FlexBox, Text } from '@eltoro-ui/components';
import sellerImgSrc from 'assets/Images/sellers.svg';
import buyerImgSrc from 'assets/Images/buyers.svg';
import './styles.scss';

interface Props {
  kind: RecomendationContactEnum;
  count: number;
}

const RecomendationTile = ({ kind, count = 0 }: Props) => {
  return (
    <div className="RecomendationTile">
      <FlexBox alignItems="center" justifyContent="flex-start" gap="10px">
        <img
          src={kind === RecomendationContactEnum.SELLER ? sellerImgSrc : buyerImgSrc}
          alt={kind === RecomendationContactEnum.SELLER ? 'seller' : 'buyer'}
        />
        <FlexBox flexDirection="column" alignItems="start" justifyContent="center" gap="10px">
          <Text on="white" weight="bold" UNSAFE_className="title">
            {kind === RecomendationContactEnum.SELLER
              ? 'Households likely to sell'
              : 'Households likely to buy'}
          </Text>
          <Text on="white" UNSAFE_className="desc">
            {kind === RecomendationContactEnum.SELLER
              ? `${count} of your contact households who are potential sellers are displaying in-market activity over the last 90 days.`
              : `${count} of your contact households who are potential buyers are displaying in-market activity over the last 90 days.`}
          </Text>
        </FlexBox>
      </FlexBox>
      <SaveAudienceWizard
        contactType={kind === RecomendationContactEnum.SELLER ? 'seller' : 'buyer'}
        onSaveAndClose={() => {}}
        saveText="Save Audience"
        type="contacts"
      />
    </div>
  );
};

export default RecomendationTile;
