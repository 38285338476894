import React from 'react';
import { Group, Rect } from 'react-konva';
import { useRecoilValue } from 'recoil';
import {
  blockBorderRadiusAtom,
  blockStrokeColorAtom,
  blockStrokeEnabledAtom,
  blockStrokeWidthAtom,
} from '../../../../../State';
import { BlockConfigType } from '../../../../../Typings';

export const BorderBox: React.FC<{
  width: number;
  height: number;
  clippingFunc: (ctx: CanvasRenderingContext2D) => void;
  config: BlockConfigType;
}> = ({ width, height, clippingFunc, config }) => {
  const borderStroke = useRecoilValue(blockStrokeEnabledAtom(config));
  const borderStrokeColor = useRecoilValue(blockStrokeColorAtom(config));
  const borderStrokeWidth = useRecoilValue(blockStrokeWidthAtom(config));
  const borderRadius = useRecoilValue(blockBorderRadiusAtom(config));
  return (
    <Group clipFunc={clippingFunc}>
      <Rect
        width={width}
        height={height}
        cornerRadius={borderRadius}
        strokeEnabled={borderStroke}
        stroke={borderStrokeColor}
        strokeWidth={borderStrokeWidth}
      />
    </Group>
  );
};
