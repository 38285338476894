import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { Button, FlexBox, Text } from '@eltoro-ui/components';

export const StepOne = ({ onClose }: { onClose: (e?: boolean) => void }) => {
  return (
    <FlexBox flexDirection="column" gap="8px">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Text on="white" size="l" weight={600} UNSAFE_style={{ color: '#FFAB03' }}>
            STEP 1
          </Text>
        </div>
        <div>
          <FontAwesomeIcon
            className="cross_icon"
            icon={faTimes}
            onClick={() => onClose()}
            cursor="pointer"
          />
        </div>
      </div>
      <Text on="white" size="xxl" weight="normal">
        Make sure your CSV is formatted correctly
      </Text>
      <Text on="grey-500" size="s">
        Refer to the template below to correctly format your contacts. Your file must be an Excel or
        CSV file ( save as CSV UTT-8 ( Comma delimited)(*.csv))
      </Text>
      <a
        style={{ textDecoration: 'none', width: 'fit-content' }}
        download="contacts"
        href="data:text/csv;base64,Zmlyc3QgbmFtZSxsYXN0IG5hbWUsYWRkcmVzcyx6aXAscGhvbmUgbnVtYmVyLGVtYWlsLGNpdHksc3RhdGU="
      >
        <Button type="button" kind="default" size="s" UNSAFE_className="uploadcsvModal__example">
          Download Template
        </Button>
      </a>
    </FlexBox>
  );
};
