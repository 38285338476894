import React, { useState } from 'react';
import { APIFeatureType } from 'types';
import { GridBox, Text } from '@eltoro-ui/components';
import './MapPopup.scss';
import { getAbbreviatedNumber, getIconBuzzColor, getBuzz, hexToRgb, luminance } from 'Helpers';
// import { Bar } from 'react-chartjs-2'
// import { icon } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
// import { getListingHistogram } from 'Requests'
// import { useSelector, connect } from 'react-redux'
// import {
//   savedAudiences,
//   finishedCampaigns,
//   verticalData,
//   multiData,
// } from '../../Pages/CampaignDashboard/fakeData'
// import {
//   multiOverlappingOptions,
//   verticalStackingOptions,
// } from '../../Pages/CampaignDashboard/barOptions'

// @ts-ignore
const MarkerWrap = React.createRef(null);

export const MapPopup: React.FC<{ feature: APIFeatureType }> = ({ feature }) => {
  const getTextHeaderColor = () => {
    // trim 'var(' & ')' from returned string
    const trimmedColor = getIconBuzzColor(feature).slice(4, -1);
    // get css var property and remove '#' from beginning
    const hexColor = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue(trimmedColor)
      .replace(/[#]/g, '');
    // convert hex to rgb and get luminance
    // useEffect(() => {
    // })

    const lumins = luminance(hexToRgb(hexColor));
    if (lumins > 0.6) {
      return 'black';
    }
    return 'white';
  };
  // @ts-ignore
  const [selectedVal, setSelectedVal] = useState(false);
  // @ts-ignore

  function days(index: number) {
    switch (index) {
      case 0:
        return 'S';
      case 1:
        return 'M';
      case 2:
        return 'T';
      case 3:
        return 'W';
      case 4:
        return 'T';
      case 5:
        return 'F';
      case 6:
        return 'S';
      default:
        return '';
    }
  }

  // const chartWeekLabels = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

  function markerHis() {
    const mls = localStorage.getItem('Seleceted_Marker');
    if (mls === feature.mls_id) {
      setSelectedVal(true);
    }
  }
  setInterval(markerHis, 500);

  const currentDay: number = new Date().getDay();
  const max = (feature?.buzz_history && Math.max(...feature.buzz_history)) ?? 0;
  const color = getIconBuzzColor(feature);
  const buzz = getBuzz(feature);

  // const data = {
  //   labels: chartWeekLabels,
  //   datasets: [
  //     {
  //       label: '',
  //       data: feature.buzz_history,
  //       borderWidth: 1,
  //     },
  //   ],
  // }

  return (
    <div className="MapPopup">
      <GridBox gridTemplateColumns="repeat(2, 1fr)" UNSAFE_style={{ padding: '0 0 1rem' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div className="MapPopup__header">
            <b>Weekly Buzz</b>
            <br />
            <Text on="white">{feature.street}</Text>
          </div>
          <GridBox gridTemplateColumns="max-content" gap=".5rem" UNSAFE_className="MapPopup__body">
            <Text on="white">
              <span>
                <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />
              </span>{' '}
              Beds - {feature.beds}
            </Text>
            <Text on="white">
              <span>
                <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />
              </span>{' '}
              Baths - {feature.baths}
            </Text>
            <Text on="white">
              <span>
                <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />
              </span>{' '}
              Listed Price - $
              {feature.listing_price && getAbbreviatedNumber(feature.listing_price.toString())}
            </Text>

            {/* <div id={feature.mls_id}>It loads</div>
        {selectedVal ? '<div>' : 'Not Showing'} */}

            {/* <Text on="white">
          Listed on <b>{new Date(feature.created).toLocaleDateString()}</b>
        </Text> */}
          </GridBox>
        </div>
        <div
          style={{
            marginLeft: '15px',
            display: 'flex',
            height: '100%',
          }}
        >
          {feature?.buzz_history?.map((item: number, index: number) => {
            return (
              <div className="feature-buzz_history" key={index}>
                <div
                  className="feature-buzz_history-line"
                  style={{
                    backgroundColor: currentDay === index ? '#F66233' : 'grey',
                    height: `${(item / max) * 7}rem`,
                  }}
                />
                <p
                  className="feature-buzz_history-text"
                  style={{
                    color: currentDay === index ? '#F66233' : '',
                    fontWeight: currentDay === index ? 'bold' : '400',
                  }}
                >
                  {days(index)}
                </p>
              </div>
            );
          })}
        </div>
      </GridBox>
      {feature.buzz_score && (
        <div className="MapPopup__buzzStatus" style={{ backgroundColor: color }}>
          {buzz.toUpperCase()}
        </div>
      )}
    </div>
  );
};
