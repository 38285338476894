import React from 'react';
import './HeaderLogo.scss';
import { NavLink } from 'react-router-dom';

export const HeaderLogo = ({
  logo,
  to,
  onClick,
}: {
  logo: string;
  to: string;
  onClick?: () => void;
}) => {
  return (
    <NavLink to={to} onClick={onClick} className="HeaderLogo">
      <img className="HeaderLogo__image" src={logo} alt="logo" />
    </NavLink>
  );
};
