import React from 'react';
import { MapLocationType } from 'types';
import './LocationLabels.scss';
import { LocationLabel } from '..';

export const LocationLabels = ({
  locations,
  onRemove,
  loading,
}: {
  locations: any;
  onRemove: (id: string) => void;
  loading?: boolean;
}) => {
  return (
    <div className="LocationLabels">
      {locations.map((selectedLocation: { value: React.Key | null | undefined }) => (
        <LocationLabel
          key={selectedLocation.value}
          location={selectedLocation}
          onRemove={onRemove}
          loading={loading}
        />
      ))}
    </div>
  );
};
