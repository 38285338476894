import React from 'react';
import { Rect } from 'react-konva';
import { v4 as uuid } from 'uuid';
import { useRecoilValue } from 'recoil';
import {
  filterAngleAtom,
  filterOpacityAtom,
  filterStartColorAtom,
  filterStartSpaceAtom,
  filterStopColorAtom,
  filterStopSpaceAtom,
  filterVisibleAtom,
} from '../../../State';

export const FilterRect: React.FC<{
  width: number;
  height: number;
}> = ({ width, height }) => {
  const angle = useRecoilValue(filterAngleAtom);
  const opacity = useRecoilValue(filterOpacityAtom);
  const start = useRecoilValue(filterStartColorAtom);
  const stop = useRecoilValue(filterStopColorAtom);
  const startSpace = useRecoilValue(filterStartSpaceAtom);
  const stopSpace = useRecoilValue(filterStopSpaceAtom);
  const visible = useRecoilValue(filterVisibleAtom);

  const handleGradientStartStop = () => {
    const centerPoint = {
      x: width / 2,
      y: height / 2,
    };
    const radius = height > width ? height : width;
    const radian = ((angle - 90) * Math.PI) / 180;
    const startPos = {
      x: -radius * Math.cos(radian) + centerPoint.x,
      y: -radius * Math.sin(radian) + centerPoint.y,
    };
    const stopPos = {
      x: radius * Math.cos(radian) + centerPoint.x,
      y: radius * Math.sin(radian) + centerPoint.y,
    };
    return {
      start: startPos,
      stop: stopPos,
    };
  };

  return (
    <Rect
      x={0}
      y={0}
      width={width}
      height={height}
      id={uuid()}
      fillLinearGradientStartPoint={handleGradientStartStop().start}
      fillLinearGradientEndPoint={handleGradientStartStop().stop}
      fillLinearGradientColorStops={[startSpace, start, stopSpace, stop]}
      opacity={opacity}
      listening={false}
      visible={visible}
    />
  );
};
