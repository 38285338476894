import React from 'react';
import { Link } from 'react-router-dom';

import { Modal, Button } from '@eltoro-ui/components';

import cross from 'assets/Images/cross.png';

import './UpgradeModalForLenses.scss';

type UpgradeModalForLensesProps = {
  title: string;
  description: string;
  onCancel: () => void;
};

const UpgradeModalForLenses = ({ title, description, onCancel }: UpgradeModalForLensesProps) => {
  return (
    <Modal className="lens-upgrade-modal">
      <img
        src={cross}
        onClick={onCancel}
        alt="cross"
        className="PersonalInfoForm__cross"
        role="presentation"
      />
      <div className="lens-upgrade-modal-content">
        <div className="lens-upgrade-modal-content-inner">
          <div>
            <span className="title">{title}</span>
            <span className="description">{description}</span>
          </div>
        </div>
        <div className="lens-upgrade-modal-content-footer">
          <Button size="l" weight="bold" onClick={onCancel}>
            Cancel
          </Button>
          <Link to="/profile/billing">
            <Button size="l" kind="primary" weight="bold">
              Upgrade
            </Button>
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default UpgradeModalForLenses;
