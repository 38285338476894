import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Modal, Button } from '@eltoro-ui/components';
import { PersonalInfoForm } from 'Components';
import ConfirmPayment from 'Components/ConfirmPayment';
import type { TCommonSubscription, TRootState, UserInfoType } from 'types';

import SubscriptionPlanChoose from 'Components/SubscriptionPlanChoose';
import { getChargebeePackages } from 'Requests';

import { setUser } from 'Redux/actions';

import Congratulation from 'assets/Images/congratulation.svg';
import Leftgroup from 'assets/Images/leftgroup.svg';
import Rightgroup from 'assets/Images/rightgroup.svg';

import './OnboardingModal.scss';
import { GuideEnum } from 'enums';

const emptyUser = {
  personalInfo: {
    brokerage: '',
    email: '',
    mls_id: '',
    mls_registered_name: '',
    phone_number: '',
    primary_zip_code: '',
    first_name: '',
    last_name: '',
    street: '',
    state: '',
    billing_zip_code: '',
    city: '',
  },
};

export const OnboardingModal = ({ setModalOpen }: { setModalOpen: (value: boolean) => void }) => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state: TRootState) => state.userReducer);
  const [selectedSubscription, setSelectedSubscription] = useState<TCommonSubscription | null>(
    null
  );

  const [currentUser, setCurrentUser] = useState<UserInfoType>(emptyUser);
  const [modalStep, setModalStep] = useState<number>(0);
  const [subscriptions, setSubscriptions] = useState<TCommonSubscription[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    getChargebeePackages().then(res => {
      setSubscriptions(res.data?.subscriptions.reverse() || []);
      setLoading(false);
    });

    return () => {
      setSubscriptions([]);
    };
  }, [currentUser]);

  const history = useHistory();

  useEffect(() => {
    const currentModalStep = () => {
      if (userData?.is_comfirmed === 0) setModalStep(1);
      if (userData?.is_comfirmed === 1 && userData?.is_compelete === 0) setModalStep(2);
    };
    setTimeout(currentModalStep, 0);
  }, []);

  const populatePersonalInfo = (info: UserInfoType['personalInfo']) => {
    const tempData = {
      ...userData,
      ...info,
    };

    dispatch(setUser(tempData));
    setCurrentUser({
      ...currentUser,
      personalInfo: { ...info },
    });
  };

  const onContinue = () => {
    setModalStep(3);
  };

  const onPreviousStep = () => {
    setModalStep(step => step - 1);
  };

  const handleClick = () => {
    setModalOpen(false);
    sessionStorage.setItem(
      'guideBot',
      JSON.stringify({
        isActive: true,
        type: GuideEnum.CONTACTS,
        msgType: '',
        count: 0,
      })
    );
    window.location.reload();
    history.push('/dashboard');
  };

  return (
    <Modal style={{ maxWidth: 'calc(100vw - 10rem)' }}>
      {modalStep === 1 && (
        <PersonalInfoForm
          user={currentUser}
          onSubmit={value => {
            populatePersonalInfo(value);
            setModalStep(2);
          }}
        />
      )}
      {modalStep === 2 && (
        <SubscriptionPlanChoose
          loading={loading}
          onContinue={onContinue}
          subscriptions={subscriptions}
          selectedSubscription={selectedSubscription}
          setSelectedSubscription={setSelectedSubscription}
        />
      )}
      {modalStep === 3 && (
        <ConfirmPayment
          subscription={selectedSubscription}
          onPreviousStep={onPreviousStep}
          setModalStep={setModalStep}
        />
      )}
      {modalStep === 4 && (
        <div className="OnboardingModal-welcome">
          <img className="Leftgroup" src={Leftgroup} alt="Leftgroup" />
          <div className="OnboardingModal-welcomeHeader">
            <div className="beewo-header-congrats">
              <img src={Congratulation} alt="congrats" />
              <p>Congratulations!</p>
            </div>
            <div className="beewo-header-welcome">
              <p>Welcome to Beewo</p>
            </div>
            <div className="description">
              <p>
                You&apos;ve successfully created your profile. Click below <br />
                to start accessing the platform.
              </p>
            </div>
          </div>
          <div className="btn-container">
            <Button
              UNSAFE_style={{
                borderRadius: '10px',
                padding: '26px 20px',
                color: '#2B1F0A',
              }}
              kind="primary"
              size="l"
              weight="bold"
              width="30%"
              onClick={handleClick}
            >
              Get Started
            </Button>
          </div>
          <img className="Rightgroup" src={Rightgroup} alt="Rightgroup" />
        </div>
      )}
    </Modal>
  );
};
