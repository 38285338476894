import React, { FC } from 'react';

export const stateToAbbv = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District of Columbia': 'DC',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};

export const stateAbbr: string[] = Object.values(stateToAbbv).sort();

type StateSelectType = {
  defaultValue?: string;
  onChange: (value: string) => void;
};

export const StateSelect: FC<StateSelectType> = ({ defaultValue, onChange }) => {
  const currentValue = defaultValue || '';
  return (
    <select
      required
      autoComplete="address-level1"
      defaultValue={currentValue}
      onChange={e => onChange(e.target.value)}
      data-testid="state-select"
    >
      {defaultValue === '' && (
        <option value="" disabled>
          Select state
        </option>
      )}
      {stateAbbr.map((state: string) => (
        <option key={state} value={state} data-testid={`option-${state}`}>
          {state}
        </option>
      ))}
    </select>
  );
};
