import React, { useMemo } from 'react';
import { Group, Rect, Text } from 'react-konva';
import Konva from 'konva';
import { useRecoilValue } from 'recoil';
import { BackgroundImage } from '..';
import {
  artboardBlocksSelector,
  artboardLabelSelector,
  backgroundLocalPhotoAtom,
  backgroundPhotoAtom,
  creativeTypeAtom,
  directMailSizeAtom,
} from '../../../State';
import { ArtboardType } from '../../../Typings';
import { FilterRect } from '../FilterRect';
import { Block } from '../blocks';
import { DirectMailStamp } from '../DirectMailStamp';
import { DirectMailAddress } from '../DirectMailAddress';

const bufferSize = 20;

// an artboard contains a single ad along with its label/size
export const Artboard: React.FC<{
  artboard: ArtboardType;
  adRef: (el: Konva.Group) => void;
  isEditing: boolean;
  onSelectBlock: (selected: boolean) => void;
}> = ({ artboard, adRef, isEditing, onSelectBlock }) => {
  const { x, y, width, height, id } = artboard;
  const backgroundPhoto = useRecoilValue(backgroundPhotoAtom);
  const backgroundLocalPhoto = useRecoilValue(backgroundLocalPhotoAtom);
  const artboardLabel = useRecoilValue(artboardLabelSelector(id));
  const blocks = useRecoilValue(artboardBlocksSelector(id));
  const creativeType = useRecoilValue(creativeTypeAtom);
  const directMailSize = useRecoilValue(directMailSizeAtom);

  const isDirectMailBack =
    creativeType === 'directMail' && directMailSize && artboard.side === 'back';

  const photo = useMemo(
    () => backgroundLocalPhoto ?? backgroundPhoto,
    [backgroundLocalPhoto, backgroundPhoto]
  );

  return (
    <Group x={x} y={y} width={width + bufferSize} height={height + bufferSize * 2}>
      <Text x={bufferSize / 2} y={0} text={artboardLabel} />
      {/* Rect added for dropshadow around the visible artboard ad */}
      <Rect
        x={bufferSize / 2}
        y={bufferSize}
        width={width}
        height={height}
        shadowBlur={10}
        shadowOpacity={0.25}
        shadowOffsetY={5}
        fill="white"
      />
      {/* Group contains clipping mask so elements within cannot bleed outside */}
      <Group
        id={id}
        x={bufferSize / 2}
        y={bufferSize}
        width={width}
        height={height}
        clipFunc={(ctx: CanvasRenderingContext2D) => {
          ctx.rect(0, 0, width, height);
        }}
        ref={adRef}
      >
        {/* default white background */}
        <Rect id={`${id}-background`} x={0} y={0} width={width} height={height} fill="white" />
        {photo && <BackgroundImage image={photo} artboardName={id} />}
        <FilterRect width={width} height={height} />
        {blocks?.map(block => (
          <Block
            key={block.id}
            blockConfig={{
              id: block.id,
              kind: block.kind,
              artboardName: id,
            }}
            isEditing={isEditing}
            onSelect={onSelectBlock}
            isOnMobile={id === 'mobile'}
          />
        ))}
        {isDirectMailBack && (
          <>
            <DirectMailStamp />
            <DirectMailAddress />
          </>
        )}
      </Group>
    </Group>
  );
};
