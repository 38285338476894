import { Text } from '@eltoro-ui/components';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './Impressionvalidator.scss';

type validatorType = {
  title?: string;
  icon?: JSX.Element;
  UNSAFE_CLASS_icon?: string | number | undefined;
  days?: number | undefined;
};

export const ImpressionValidator: React.FC<validatorType> = ({
  title,
  days,
  UNSAFE_CLASS_icon,
  icon,
}) => {
  return (
    <div style={{ marginTop: '-8px' }} className="ImpressionValidator">
      <FontAwesomeIcon icon={faExclamationCircle} style={{ color: 'red', marginRight: '5px' }} />
      <Text on="grey-500" weight="normal" size="l">
        {/* You need to select additional {days} days. */}
        You need to select additional {days} {Number(days) <= 1 ? 'day' : 'days'}.
      </Text>
    </div>
  );
};
