import React from 'react';
import { useSelector } from 'react-redux';
import { TRootState } from 'types';
import { Link } from 'react-router-dom';

interface Props {
  recomendationsCount: number;
  msg?: string;
}

const Recomendations = ({ recomendationsCount, msg }: Props) => {
  const { userData } = useSelector((state: TRootState) => state.userReducer);

  return (
    <>
      <h4>🎉 Hey {userData.first_name}, we have good news!</h4>
      <p>
        {recomendationsCount > 1
          ? `We found ${recomendationsCount} recommendations tailored to your needs, try to make the most of them!`
          : msg}
      </p>
      <Link to="/recomendations" className="link">
        {recomendationsCount > 1 ? 'See recommendations' : 'Tell me more'}
      </Link>
    </>
  );
};

export default Recomendations;
