import React, { useRef } from 'react';
import type { SyntheticEvent } from 'react';

import classNames from 'classnames';

import { Menu } from 'primereact/menu';
import type { MenuItem } from 'primereact/menuitem';

import { Button } from '@eltoro-ui/components';

import CustomIcons from 'assets/icons';

import './CreativeTypeBadge.scss';

type CreativeTypeBadgeProps = {
  onClick: () => void;
  onClickGenerateCreative?: () => void;
  onClickChooseFromCreativeLibrary?: () => void;
  text: string;
  bannerIcon?: string;
  typeBadge?: 'banner' | 'video';
  disabledDirectMail?: boolean;
  UNSAFE_className?: string;
};

export const CreativeTypeBadge = ({
  onClick,
  onClickGenerateCreative,
  onClickChooseFromCreativeLibrary,
  text,
  bannerIcon,
  typeBadge,
  disabledDirectMail,
  UNSAFE_className = '',
}: CreativeTypeBadgeProps) => {
  const menuRef = useRef<Menu>(null);

  const generateCreative = [
    {
      icon: <CustomIcons name="grid" fontSize={16} />,
      label: 'Use Ad Builder',
      command: () => {
        if (onClickGenerateCreative) onClickGenerateCreative();
      },
    },
  ];

  const items: MenuItem[] = [
    {
      icon: <CustomIcons name="upload" fontSize={16} />,
      label: 'Upload Your Own',
      command: onClick,
    },
    ...(typeBadge === 'banner' ? generateCreative : []),
    {
      icon: <CustomIcons name="books" fontSize={16} />,
      label: 'Choose from Library',
      command: onClickChooseFromCreativeLibrary,
    },
  ];

  return (
    <div className={classNames('CampaignSetup__box', UNSAFE_className)}>
      <div style={{ marginBlock: '1rem' }}>
        <img
          className="CampaignSetup__box__icon"
          src={bannerIcon}
          alt="creative-type"
          style={{ marginBottom: '8px', marginTop: '12px' }}
        />
      </div>
      <div className="creative-action-popup-container">
        <Menu
          model={items}
          popup
          ref={menuRef}
          id="popup_menu_right"
          className="select-creative-action-popup"
          appendTo="self"
        />
        <Button
          type="button"
          kind="primary"
          size="l"
          weight="bold"
          onClick={event => menuRef?.current?.toggle(event as SyntheticEvent)}
          aria-controls="popup_menu_left"
          aria-haspopup
          UNSAFE_className="CreativeTypeBadge__btn"
          UNSAFE_TEXTSTYLE="CampaignSetup__boxTextButton"
          disabled={disabledDirectMail}
        >
          {text}
        </Button>
      </div>
    </div>
  );
};
