import { ResponseType, TUnReadTotalCounts, TUserNotifications } from 'types';
import requests from '../http';

export const getUserNotifications = async (
  page: number,
  size: number,
  source?: string
): Promise<ResponseType<TUserNotifications>> => {
  const res = await requests.get(
    source
      ? `/notifications?page=${page}&size=${size}&source=${source}`
      : `/notifications?page=${page}&size=${size}`
  );
  return res;
};

export const getUnReadNotificationsCounts = async (): Promise<
  ResponseType<TUnReadTotalCounts[]>
> => {
  const res = await requests.get('/notifications/counts');
  return res;
};

export const markAsReadAllNotifications = async (
  read: number
): Promise<ResponseType<{ msg: string }>> =>
  requests.put(`/notifications/update_by_user?read=${read}`, {});

export const markAsReadSingleNotification = async (
  body: [
    {
      id: string;
      read: number;
    }
  ]
): Promise<ResponseType<{ msg: string }>> => requests.put(`/notifications/update_by_ids`, body);

export const markAsReadSpecificTypeNotifications = async (
  source: string,
  read: number
): Promise<ResponseType<{ msg: string }>> =>
  requests.put(`/notifications/update_by_source?source=${source}&read=${read}`);

export const subscribeTopic = async (
  device_token: string,
  subscribe: number
): Promise<ResponseType<{ msg: string }>> =>
  requests.post(`/notifications/fcm_topic`, { device_token, subscribe });
