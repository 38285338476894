import React, { FC, useEffect, useState } from 'react';

import { userRole } from 'Helpers';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from 'Redux/actions';
import { chargebeeSubscription, getChargebeePackages, userDetail } from 'Requests';
import type { TCommonSubscription, TCurrentSubscription, TRootState } from 'types';
import { SubscriptionCard } from '..';
import './SubscriptionSection.scss';
import {
  cancelUpcomingSubscription,
  updateSubscription,
} from '../../../../Requests/Request_Methods/chargebeeMethods';

export const SubscriptionSection: FC = () => {
  const [subscriptions, setSubscriptions] = useState<TCommonSubscription[]>([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { userData } = useSelector((state: TRootState) => state.userReducer);

  useEffect(() => {
    getChargebeePackages().then(res => {
      setSubscriptions(res.data?.subscriptions.reverse() || []);
    });

    return () => {
      setSubscriptions([]);
    };
  }, []);

  const onSubscribe = (body: { subscription_id: TCommonSubscription['id'] }) => () => {
    setLoading(true);
    chargebeeSubscription(body)
      .then(() => {
        const token = localStorage.getItem('beewo_token') || undefined;
        userDetail(token).then(res => {
          const user = {
            user_role: userRole(res.data?.is_super),
            ...res.data,
          };
          dispatch(setUser(user));
          setLoading(false);
        });
        toast.success('Your subscription has been activated successfully.');
      })
      .catch(() => {
        setLoading(false);
        toast.error('Oops, we were not able to activate your subscription.');
      });
  };

  const onUpdateSubscription =
    (body: { id: TCurrentSubscription['sub_id']; plan_id: TCommonSubscription['id'] }) => () => {
      setLoading(true);
      updateSubscription(body)
        .then(() => {
          const token = localStorage.getItem('beewo_token') || undefined;
          userDetail(token).then(res => {
            const user = {
              user_role: userRole(res.data?.is_super),
              ...res.data,
            };
            dispatch(setUser(user));
            setLoading(false);
          });
          const toastMessage = body.plan_id.includes('premium')
            ? 'You have successfully upgraded your plan.'
            : 'You have successfully downgraded your plan.';
          toast.success(toastMessage);
        })
        .catch(() => {
          setLoading(false);
          const toastMessage = body.plan_id.includes('premium')
            ? 'Oops, your plan upgrade has failed.'
            : 'Oops, your plan downgrade has failed.';
          toast.error(toastMessage);
        });
    };

  const onCancelUpcoming =
    ({ sub_id }: Pick<TCurrentSubscription, 'sub_id'>) =>
    () => {
      setLoading(true);
      cancelUpcomingSubscription({ sub_id })
        .then(() => {
          const token = localStorage.getItem('beewo_token') || undefined;
          userDetail(token).then(res => {
            const user = {
              user_role: userRole(res.data?.is_super),
              ...res.data,
            };
            dispatch(setUser(user));
            setLoading(false);
          });
          toast.success('Your upcoming subscription has been canceled successfully.');
        })
        .catch(() => {
          setLoading(false);
          toast.error('Your upcoming subscription cancellation has been failed.');
        });
    };

  return (
    <div className="Subscription__container">
      <SubscriptionCard
        data={subscriptions}
        userData={userData}
        onSubscribe={onSubscribe}
        onUpdateSubscription={onUpdateSubscription}
        onCancelUpcoming={onCancelUpcoming}
        loading={loading}
      />
    </div>
  );
};
