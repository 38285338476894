import { addBleed } from '../Helpers';
import { ArtboardType } from '../Typings';
import { directMailInches } from './directMail';

const directMailPixelWidth = 600;
export const directMailArtboardGap = 10;

const getDirectMailPixelHeight = (pixelWidth: number, directMailId: string) => {
  const { width, height } = directMailInches[directMailId];
  return (pixelWidth * addBleed(height)) / addBleed(width);
};

export const defaultArtboards: ArtboardType[] = [
  {
    x: 0,
    y: 0,
    width: 728,
    height: 90,
    id: 'wide',
    type: 'banner',
  },
  {
    x: 0,
    y: 130,
    width: 300,
    height: 600,
    id: 'tall',
    type: 'banner',
  },
  {
    x: 320,
    y: 130,
    width: 160,
    height: 600,
    id: 'thin',
    type: 'banner',
  },
  {
    x: 500,
    y: 130,
    width: 320,
    height: 50,
    id: 'mobile',
    type: 'banner',
  },
  {
    x: 500,
    y: 220,
    width: 300,
    height: 250,
    id: 'squarish',
    type: 'banner',
  },
  {
    x: 0,
    y: 0,
    width: directMailPixelWidth,
    height: getDirectMailPixelHeight(directMailPixelWidth, 'small'),
    id: 'small-front',
    type: 'directMail',
    side: 'front',
    size: 'small',
  },
  {
    x: directMailPixelWidth + directMailArtboardGap,
    y: 0,
    width: directMailPixelWidth,
    height: getDirectMailPixelHeight(directMailPixelWidth, 'small'),
    id: 'small-back',
    type: 'directMail',
    side: 'back',
    size: 'small',
  },
  {
    x: 0,
    y: 0,
    width: directMailPixelWidth,
    height: getDirectMailPixelHeight(directMailPixelWidth, 'medium'),
    id: 'medium-front',
    type: 'directMail',
    side: 'front',
    size: 'medium',
  },
  {
    x: directMailPixelWidth + directMailArtboardGap,
    y: 0,
    width: directMailPixelWidth,
    height: getDirectMailPixelHeight(directMailPixelWidth, 'medium'),
    id: 'medium-back',
    type: 'directMail',
    side: 'back',
    size: 'medium',
  },
  {
    x: 0,
    y: 0,
    width: directMailPixelWidth,
    height: getDirectMailPixelHeight(directMailPixelWidth, 'large'),
    id: 'large-front',
    type: 'directMail',
    side: 'front',
    size: 'large',
  },
  {
    x: directMailPixelWidth + directMailArtboardGap,
    y: 0,
    width: directMailPixelWidth,
    height: getDirectMailPixelHeight(directMailPixelWidth, 'large'),
    id: 'large-back',
    type: 'directMail',
    side: 'back',
    size: 'large',
  },
  {
    x: 0,
    y: 0,
    width: directMailPixelWidth,
    height: getDirectMailPixelHeight(directMailPixelWidth, 'extra-large'),
    id: 'extra-large-front',
    type: 'directMail',
    side: 'front',
    size: 'extra-large',
  },
  {
    x: directMailPixelWidth + directMailArtboardGap,
    y: 0,
    width: directMailPixelWidth,
    height: getDirectMailPixelHeight(directMailPixelWidth, 'extra-large'),
    id: 'extra-large-back',
    type: 'directMail',
    side: 'back',
    size: 'extra-large',
  },
];
