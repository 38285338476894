export const globalFilters: undefined | any = (state = { globalFilters: '' }, action: any) => {
  switch (action.type) {
    case 'GALOBAL_FILTERS':
      return { globalFilters: action.payload };
    default:
      return { ...state };
  }
};

export const setGlobalFilters: undefined | any = (
  state = { setGlobalFilters: '' },
  action: any
) => {
  switch (action.type) {
    case 'SET_GALOBAL_FILTERS':
      return { setGlobalFilters: action.payload };
    default:
      return { ...state };
  }
};
