import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Button, Text } from '@eltoro-ui/components';
import { PaginationForRealtorPropsType } from 'types';
import './PaginationForRealtor.scss';

export const PaginationForRealtor: FC<PaginationForRealtorPropsType> = ({
  currentPage = 1,
  totalPages = 0,
  onPageChange,
}) => {
  return (
    <div className="pagination_for_realtor">
      <Button
        onClick={() => onPageChange(currentPage - 1)}
        iconLeft={<FontAwesomeIcon icon={faAngleLeft} />}
        disabled={currentPage === 1}
        UNSAFE_className="_button"
      />
      <Text UNSAFE_className="_text" on="grey-050" kind="subdued">
        <b>{currentPage}</b> ... {totalPages}
      </Text>
      <Button
        data-testid="forwardClick"
        onClick={() => onPageChange(currentPage + 1)}
        iconLeft={<FontAwesomeIcon icon={faAngleRight} />}
        disabled={currentPage === totalPages}
        UNSAFE_className="_button"
      />
    </div>
  );
};
