import React, { useCallback, useEffect, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';

import classNames from 'classnames';
import moment from 'moment';

import toast from 'react-hot-toast';

import type { SidebarProps } from 'primereact/sidebar';
import type { MenuItem } from 'primereact/menuitem';
import type { MultiSelectChangeEvent, MultiSelectProps } from 'primereact/multiselect';
import { MultiSelect } from 'primereact/multiselect';

import type { Column } from 'react-table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faCheck } from '@fortawesome/free-solid-svg-icons';

import { FlexBox } from '@eltoro-ui/components';
import type { TableProps } from 'Components';
import { EmptyStatus, ReactTable } from 'Components';
import ConfirmModal from 'Pages/MyProfile/components/BillingSection/components/ConfirmModal';

import CreativeDetailsDrawer from 'Pages/CreativesLibrary/components/CreativeDetailsDrawer';
import CreativeActionAccessor from 'Pages/CreativesLibrary/components/CreativesList/elements/CreativeActionAccessor';
import RenameCreativeModal from 'Pages/CreativesLibrary/components/CreativesList/elements/RenameCreativeModal';
import CreativesFilterTag from 'Pages/CreativesLibrary/components/CreativesList/elements/CreativesFilterTag';

import type { CreativeFilter, CreativeStatusItem, CreativeTypeItem } from 'Pages/CreativesLibrary';

import CustomIcons from 'assets/icons';

import type { TCreative, TResPagination } from 'types';
import { CreativeStatusEnum, CreativeTypeEnum } from 'enums';

import { downloadCreative, formatBytes } from 'Utils/helpers';

import { deleteCreative } from 'Requests/Request_Methods/creativesLibraryMethods';

import './CreativesList.scss';

type CreativeActionTypes = 'delete' | 'view' | 'rename';

const creativesStatusItems: CreativeStatusItem[] = [
  { name: CreativeStatusEnum.UPLOADING },
  { name: CreativeStatusEnum.ACTIVE },
  { name: CreativeStatusEnum.ERROR },
];

const creativesTypeItems: CreativeTypeItem[] = [
  { name: CreativeTypeEnum.BANNER },
  { name: CreativeTypeEnum.VIDEO },
];

export type CreativesListProps = {
  creatives: TCreative[];
  loading: boolean;
  page: [number, Dispatch<SetStateAction<number>>];
  creative_filter: [CreativeFilter, Dispatch<SetStateAction<CreativeFilter>>];
  pagination: [TResPagination, Dispatch<SetStateAction<TResPagination>>];
  updateCreativesListAfterDeletion: () => Promise<void>;
  updateCreativeAfterEditCreativeName: (
    creative: Pick<TCreative, 'name' | 'creative_uuid'>
  ) => void;
};

const CreativesList = ({
  creatives,
  loading,
  updateCreativesListAfterDeletion,
  updateCreativeAfterEditCreativeName,
  page: [page, setPage],
  pagination: [pagination],
  creative_filter: [creative_filter, setCreativeFilter],
}: CreativesListProps) => {
  const [creativeAction, setCreativeAction] = useState<CreativeActionTypes | null>(null);
  const [selectedCreative, setSelectedCreative] = useState<TCreative | null>(null);
  const [deleteCreativeLoading, setDeleteCreativeLoading] = useState<boolean>(false);

  const [creativeDetailsView, setCreativeDetailsView] = useState<boolean>(false);

  const [creativeStatuses, setCreativeStatuses] = useState<CreativeStatusItem[]>([]);
  const [creativeTypes, setCreativeTypes] = useState<CreativeTypeItem[]>([]);

  useEffect(() => {
    setCreativeStatuses(creative_filter.status_type);
    setCreativeTypes(creative_filter.creative_type);
  }, [creative_filter]);

  const onHideCreativeDetailsView: SidebarProps['onHide'] = () => {
    setSelectedCreative(null);
    setCreativeAction(null);
    setCreativeDetailsView(false);
  };

  const onRenameCreativeAction =
    (creative: TCreative): MenuItem['command'] =>
    () => {
      setSelectedCreative(creative);
      setCreativeAction('rename');
    };

  const onDeleteCreativeAction =
    (creative: TCreative): MenuItem['command'] =>
    () => {
      setSelectedCreative(creative);
      setCreativeAction('delete');
    };

  const onViewDetailsAction =
    (creative: TCreative): MenuItem['command'] =>
    () => {
      setSelectedCreative(creative);
      setCreativeAction('view');
      setCreativeDetailsView(true);
    };

  const onDownloadCreativeAction =
    (creative: TCreative): MenuItem['command'] =>
    () =>
      downloadCreative(creative);

  const columns: Column<TCreative>[] = [
    {
      Header: ' ',
      columns: [
        {
          Header: '',
          id: 'preview',
          accessor: ({
            presigned_url,
            thumb_presigned_url,
            creative_type,
            name,
            file_type,
            status,
          }) =>
            presigned_url && status !== CreativeStatusEnum.UPLOADING ? (
              <div className="creative-preview">
                {creative_type === CreativeTypeEnum.BANNER ||
                (creative_type === CreativeTypeEnum.VIDEO && thumb_presigned_url) ? (
                  <img src={thumb_presigned_url || presigned_url} alt={name} />
                ) : (
                  <video>
                    <source src={presigned_url} type={`video/${file_type}`} />
                    <track src="captions_en.vtt" kind="captions" label="english_captions" />
                  </video>
                )}
                {creative_type === CreativeTypeEnum.VIDEO && (
                  <CustomIcons
                    className="creative-video-icon"
                    name="video_play"
                    color="#FFAB03"
                    fontSize={12}
                  />
                )}
              </div>
            ) : (
              <CustomIcons name="beewo_logo_creatives" fontSize={36} />
            ),
        },
        {
          Header: 'Creative Name',
          accessor: 'name',
        },
        {
          Header: 'File Type',
          accessor: 'file_type',
          Cell: ({ row: { original: creative } }) => <span>.{creative.file_type}</span>,
        },
        {
          Header: 'Creative Type',
          accessor: 'creative_type',
          Cell: ({ row: { original: creative } }) => (
            <span style={{ textTransform: 'capitalize' }}>{creative.creative_type}</span>
          ),
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: ({
            row: {
              original: { status },
            },
          }) => (
            <div className={classNames('creative-status', status)}>
              {status === CreativeStatusEnum.UPLOADING ? `${status}...` : status}
            </div>
          ),
        },
        {
          Header: 'File Size',
          accessor: ({ file_size }) => formatBytes(file_size),
        },
        {
          Header: 'Specs',
          accessor: 'file_specs',
        },
        {
          Header: 'Order Lines',
          accessor: ({ orderline_count }) => orderline_count || '-',
        },
        {
          Header: 'Creative ID',
          accessor: ({ eltoro_creative_id }) => eltoro_creative_id ?? '-',
        },
        {
          Header: 'Date Created',
          accessor: ({ created_at }) => moment(created_at).format('MM/DD/YYYY'),
        },
        {
          Header: '',
          id: 'actions',
          accessor: creative => (
            <CreativeActionAccessor
              creative={creative}
              onRenameCreativeAction={onRenameCreativeAction}
              onDeleteCreativeAction={onDeleteCreativeAction}
              onViewDetailsAction={onViewDetailsAction}
              onDownloadCreativeAction={onDownloadCreativeAction}
            />
          ),
        },
      ],
    },
  ];

  const onChangeCreativeStatuses = ({ value }: MultiSelectChangeEvent) =>
    setCreativeStatuses(value);

  const onChangeCreativeTypes = ({ value }: MultiSelectChangeEvent) => setCreativeTypes(value);

  const onCreativeStatusDelete = (name: CreativeStatusEnum | CreativeTypeEnum) => () =>
    setCreativeFilter(creativeFilters => ({
      ...creativeFilters,
      status_type: creativeFilters.status_type.filter(status => status.name !== name),
    }));

  const onCreativeTypeDelete = (name: CreativeStatusEnum | CreativeTypeEnum) => () =>
    setCreativeFilter(creativeFilters => ({
      ...creativeFilters,
      creative_type: creativeFilters.creative_type.filter(status => status.name !== name),
    }));

  const handleApplyFilters = (name: string) => {
    if (name === 'type')
      setCreativeFilter(creativeFilters => ({
        ...creativeFilters,
        creative_type: creativeTypes,
      }));

    if (name === 'status')
      setCreativeFilter(creativeFilters => ({
        ...creativeFilters,
        status_type: creativeStatuses,
      }));

    setPage(1);
  };

  const onHide =
    (name: string): MultiSelectProps['onHide'] =>
    () => {
      if (
        (name === 'type' &&
          (creativeTypes.length !== creative_filter.creative_type.length ||
            creativeTypes.some(
              ({ name }) => !creative_filter.creative_type.find(type => type.name === name)
            ))) ||
        (name === 'status' &&
          (creativeStatuses.length !== creative_filter.status_type.length ||
            creativeStatuses.some(
              ({ name }) => !creative_filter.status_type.find(status => status.name === name)
            )))
      )
        handleApplyFilters(name);
    };

  const PanelFooterTemplate: MultiSelectProps['panelFooterTemplate'] = (_, hide) => {
    return (
      <FlexBox
        UNSAFE_className="creative-filter-panel-footer"
        alignItems="center"
        justifyContent="flex-end"
      >
        <button className="creative-filter-panel-footer-apply-btn" onClick={hide}>
          Apply
        </button>
      </FlexBox>
    );
  };

  const onClear = () => setCreativeFilter({ creative_type: [], status_type: [] });

  const header: TableProps['header'] = () => {
    return (
      <FlexBox flexDirection="column" gap="20px">
        <FlexBox alignItems="center" gap="20px">
          <MultiSelect
            name="type"
            className="creative-filter-select"
            itemClassName="creative-filter-item"
            panelClassName="creative-filter-panel"
            options={creativesTypeItems}
            optionLabel="name"
            value={creativeTypes}
            fixedPlaceholder
            placeholder="Type"
            onChange={onChangeCreativeTypes}
            appendTo="self"
            checkboxIcon={
              <FontAwesomeIcon icon={faCheck} color="#FFAB03" style={{ fontSize: 12 }} />
            }
            dropdownIcon={
              <FontAwesomeIcon icon={faAngleDown} color="#757575" style={{ fontSize: 18 }} />
            }
            panelHeaderTemplate={() => null}
            panelFooterTemplate={PanelFooterTemplate}
            onHide={onHide('type')}
            style={{ width: 140 }}
          />
          <MultiSelect
            name="status"
            className="creative-filter-select"
            itemClassName="creative-filter-item"
            panelClassName="creative-filter-panel"
            options={creativesStatusItems}
            optionLabel="name"
            value={creativeStatuses}
            fixedPlaceholder
            placeholder="Status"
            onChange={onChangeCreativeStatuses}
            appendTo="self"
            panelHeaderTemplate={() => null}
            checkboxIcon={
              <FontAwesomeIcon icon={faCheck} color="#FFAB03" style={{ fontSize: 12 }} />
            }
            dropdownIcon={
              <FontAwesomeIcon icon={faAngleDown} color="#757575" style={{ fontSize: 18 }} />
            }
            panelFooterTemplate={PanelFooterTemplate}
            onHide={onHide('status')}
            style={{ width: 140 }}
          />
        </FlexBox>
        {(!!creative_filter.status_type.length || !!creative_filter.creative_type.length) && (
          <FlexBox alignItems="center" gap="12px">
            {creative_filter.creative_type.map(({ name }) => (
              <CreativesFilterTag key={name} name={name} onDelete={onCreativeTypeDelete} />
            ))}
            {creative_filter.status_type.map(({ name }) => (
              <CreativesFilterTag key={name} name={name} onDelete={onCreativeStatusDelete} />
            ))}
            <button className="clear-filters" onClick={onClear}>
              Clear
            </button>
          </FlexBox>
        )}
      </FlexBox>
    );
  };

  const onCancelDelete = () => {
    setSelectedCreative(null);
    setCreativeAction(null);
  };

  const onDeleteCreative = useCallback(async () => {
    if (selectedCreative) {
      try {
        setDeleteCreativeLoading(true);
        await deleteCreative(selectedCreative.creative_uuid);

        toast.success('Creative deleted successfully!');
        updateCreativesListAfterDeletion();
      } catch (e) {
        if ('detail' in e && typeof e.detail === 'string') toast.error(e.detail);
        else toast.error('Something went wrong!');
      } finally {
        setDeleteCreativeLoading(false);
        onCancelDelete();
      }
    }
  }, [selectedCreative]);

  return (
    <>
      <ReactTable<TCreative>
        className="creatives-library-table"
        data={creatives}
        columns={columns}
        currentPage={page}
        rowDisabled={creative => creative.status === CreativeStatusEnum.UPLOADING}
        pageCount={pagination.total_pages}
        totalCount={pagination.total_count}
        pageSize={10}
        header={header}
        setPage={setPage}
        loading={loading}
        emptyText={
          <EmptyStatus
            heading="Your creatives library is empty."
            subHeading="Start uploading assets in your library so you can use them for your campaigns later."
          />
        }
        selectFeature={false}
      />
      {selectedCreative && creativeAction === 'delete' && (
        <ConfirmModal
          type="delete"
          title="Delete Creative?"
          content={
            <span>
              Are you sure you want to delete{' '}
              <b>
                {selectedCreative.name}.{selectedCreative.file_type}
              </b>
              ?
            </span>
          }
          loading={deleteCreativeLoading}
          onOk={onDeleteCreative}
          okText="Yes, Delete"
          onCancel={onCancelDelete}
        />
      )}
      {selectedCreative && creativeAction === 'rename' && (
        <RenameCreativeModal
          creative={selectedCreative}
          onCancel={onCancelDelete}
          updateCreativeAfterEditCreativeName={updateCreativeAfterEditCreativeName}
        />
      )}
      <CreativeDetailsDrawer
        creative_uuid={selectedCreative?.creative_uuid}
        visible={creativeDetailsView}
        onHide={onHideCreativeDetailsView}
        updateCreativeAfterEditCreativeName={updateCreativeAfterEditCreativeName}
      />
    </>
  );
};

export default CreativesList;
