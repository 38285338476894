import React from 'react';
import {
  Button,
  GridBox,
  Spacer,
  Select,
  Text,
  SelectOptionType,
  FlexBox,
} from '@eltoro-ui/components';
import './DirectMailSizeSelector.scss';
import { useRecoilState } from 'recoil';
import { directMailSizeAtom } from '../../../State';
import { directMailInches } from '../../../Data';
import { capitalize } from '../../../Helpers';

const directMailSizes = Object.keys(directMailInches).map(key => key);

export const DirectMailSizeSelector = () => {
  const [directMailSize, setDirectMailSize] = useRecoilState(directMailSizeAtom);

  const handleSelect = (option?: SelectOptionType) => {
    setDirectMailSize(option?.label.toLowerCase());
  };

  return (
    <div
      className={`DirectMailSizeSelector ${
        directMailSize ? 'DirectMailSizeSelector--is-selected' : ''
      }`}
    >
      {directMailSize ? (
        <FlexBox alignItems="center">
          <Text weight="bold" on="white">
            Direct mail size
          </Text>
          <Spacer />
          <Select
            placeholder="Select a direct mail size"
            options={directMailSizes.map(size => ({
              label: capitalize(size),
            }))}
            selectedOption={{ label: capitalize(directMailSize) }}
            onChange={handleSelect}
            maxHeight={300}
          />
          <Spacer />
        </FlexBox>
      ) : (
        <>
          <Text weight="bold" on="white">
            Select your direct mail size
          </Text>
          <Spacer />
          <GridBox gridTemplateColumns="repeat(4, auto)" gap="0.5rem">
            {directMailSizes.map(size => (
              <Button key={size} size="xl" onClick={() => setDirectMailSize(size)}>{`${
                directMailInches[size].width
              }"x${directMailInches[size].height}" (${capitalize(size)})`}</Button>
            ))}
          </GridBox>
        </>
      )}
    </div>
  );
};
