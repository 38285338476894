import React from 'react';
import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { Text } from '@eltoro-ui/components';

import { PieChart } from 'react-minimal-pie-chart';

import bellicon from 'assets/Images/prospects_lg_6.png';

import type { AllOrderlinesStats } from 'types';

import './CampaignProgress.scss';

interface CampaignProgressStats extends Pick<AllOrderlinesStats, 'Active' | 'Completed'> {
  inReview: AllOrderlinesStats['In Review'];
}

type CampaignProgressProps = {
  data: CampaignProgressStats;
  textTotal?: string;
  textShow: string;
};

export const CampaignProgress: FC<CampaignProgressProps> = ({
  data,
  textTotal = 'SAVED OUT',
  textShow,
}) => {
  const campaignProgressSchema = [
    { value: data.Active, color: '#FFAB03' },
    { value: data.Completed, color: '#3DC4F5' },
    { value: data.inReview, color: '#E3E0E0' },
  ];
  const getChart = () => {
    return (
      <div>
        {data.Active === 0 && data.Completed === 0 && data.inReview === 0 ? (
          <img src={bellicon} width="100%" alt="No Data" />
        ) : (
          <PieChart
            data={campaignProgressSchema}
            lineWidth={30}
            labelPosition={0}
            className="save_audiences_container__pie_className"
          />
        )}
      </div>
    );
  };

  return (
    <div className="campaignProgress__container">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column-reverse',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Link to="/campaigns" className="campaignProgress__linkAnchore">
            See all
          </Link>
        </div>
        <div className="campaignProgress__right" style={{ fontWeight: 600 }}>
          <div className="campaignProgress__status">
            <span className="campaignProgress__status__color draft" />
            <span className="campaignProgress__status__text">In Review ({data.inReview})</span>
          </div>
          <div className="campaignProgress__status">
            <span className="campaignProgress__status__color active" />
            <span className="campaignProgress__status__text">Active ({data.Active})</span>
          </div>
          <div className="campaignProgress__status">
            <span className="campaignProgress__status__color complete" />
            <span className="campaignProgress__status__text">Completed ({data.Completed})</span>
          </div>
        </div>
        <div className="campaignProgress__innerContainer">{getChart()}</div>
        <Text UNSAFE_className="campaignProgress__title" weight="bold" on="grey-100" size="xxl">
          {textShow}
        </Text>
      </div>
    </div>
  );
};
