import React from 'react';
import { Text } from '@eltoro-ui/components';
import { APIFeatureType } from 'types';

export const MapToolTip: React.FC<{ feature: { street: string } }> = ({ feature }) => {
  return (
    <div className="MapToolTip">
      <Text on="white">{feature.street}</Text>
    </div>
  );
};
