import React, { useState, useRef } from 'react';
import { TextInput } from '@eltoro-ui/components';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import searchicon from '../../assets/Images/searchicon.png';
import './SearchBar.scss';

type TextInputType = {
  value: string;
  onChange: (e: { target: { value: string } }) => void;
  UNSAFE_CLASSNAME?: string;
};

export const SearchBar: React.FC<TextInputType> = ({ value, onChange, UNSAFE_CLASSNAME }) => {
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const searchFieldInActive = () => {
    if (inputRef.current) {
      setSearchBarOpen(true);
      inputRef.current.focus();
    }
  };

  const onBlur = () => {
    if (!value) {
      setSearchBarOpen(false);
    }
  };

  return (
    <TextInput
      className="search_input_field"
      inputRef={inputRef}
      // className={`${classnames({
      //   close_search_bar: searchBarOpen,
      //   open_search_bar: searchBarOpen,
      // })} ${searchBarOpen && UNSAFE_CLASSNAME} `}
      // textFieldClassName={`${classnames({
      //   hide_search_bar: searchBarOpen,
      // })}`}
      UNSAFE_CLASS_text="search_input_text"
      placeholder="Search"
      value={value}
      color="#2B1F0A"
      // size="l"
      onChange={onChange}
      // onClick={searchFieldInActive}
      // iconStyle={{ paddingLeft: 10 }}
      // iconButtonClassName={classnames({
      //   button_before: searchBarOpen,
      //   button_after: searchBarOpen,
      // })}

      iconStyle={{ position: 'absolute', right: '8px', top: '13px' }}
      icon={
        <>
          <img src={searchicon} alt="searchicon" />
        </>
      }
      onBlur={onBlur}
    />
  );
};
