import React, { useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { toast } from 'react-hot-toast';

import { PageNav, PageRoutes } from '@eltoro-ui/components';
import { Loading, Layout } from 'Components';
import { ResetPassword } from 'Pages/ResetPassword';
import {
  TabWrapper,
  BillingSection,
  SubscriptionSection,
  PersonalInfoSection,
  Transaction,
  Feedback,
} from 'Pages/MyProfile/components';
import NotificationSetting from 'Pages/MyProfile/components/NotificationSetting/NotificationSetting';
// import CancelSubscription from 'Pages/MyProfile/components/CancelSubscription';
import TermsPolicies from 'Pages/MyProfile/components/TermsPolicies';

import type { TRootState } from 'types';

import { createChargebeeUser, getMlS } from 'Requests';

import './MyProfilePage.scss';

export const MyProfilePage: FC = () => {
  const [options, setOptions] = useState('');
  const [profilePages, setprofilePages] = useState<any>([]);
  const { userData } = useSelector((state: TRootState) => state.userReducer);

  useEffect(() => {
    if (options === '') {
      getMlS()
        .then(res => {
          res.data.listings.map((i: { name: any }) => setOptions(i.name));
        })
        .catch(err => toast.error(err.data));
    }
  }, []);
  function setupUserProfile() {
    if (userData && userData?.is_compelete !== 1) {
      const schema = {
        id: userData?.id,
        mls_registered_name: userData?.mls_registered_name,
        email: userData?.email,
      };
      createChargebeeUser(schema).catch(err => {
        toast.error(err.detail);
      });
    }
  }
  useEffect(() => {
    if (userData) {
      setupUserProfile();
    }
  }, [userData]);

  useEffect(() => {
    setprofilePages([
      {
        path: '/profile',
        title: 'General Info',
        exactPath: true,
        component: () => (
          <TabWrapper>
            {!userData ? <Loading /> : <PersonalInfoSection user={userData} />}
          </TabWrapper>
        ),
      },
      {
        path: '/profile/billing',
        title: 'Billing Info',
        exactPath: false,
        component: () => (
          <TabWrapper>
            <SubscriptionSection />
            <Transaction />
            <BillingSection />
            {/* <CancelSubscription user={userData} /> */}
          </TabWrapper>
        ),
      },

      {
        path: '/profile/notifications',
        title: 'Feedback',
        exactPath: false,
        component: () => (
          <TabWrapper>
            <Feedback />
          </TabWrapper>
        ),
      },
      {
        path: '/profile/notification-settings',
        title: 'Notification Settings',
        exactPath: false,
        component: () => (
          <TabWrapper>
            <NotificationSetting />
          </TabWrapper>
        ),
      },
      {
        path: '/profile/terms-policies',
        title: 'Terms and Policies',
        exactPath: false,
        component: () => (
          <TabWrapper>
            <TermsPolicies />
          </TabWrapper>
        ),
      },
    ]);
  }, [userData]);

  const profilePageRoutes = useMemo(() => {
    return [
      ...profilePages,
      {
        path: '/profile/password-reset',
        title: 'Reset Password',
        exactPath: false,
        component: () => (
          <TabWrapper>
            <ResetPassword />
          </TabWrapper>
        ),
      },
    ];
  }, [profilePages]);

  return (
    <Layout>
      <div className="MyProfilePage">
        <BrowserRouter>
          <nav className="MyProfilePage__nav">
            <h1 className="MyProfilePage__header">Account Settings</h1>
            <PageNav pages={profilePages} userData={userData} />
          </nav>

          <main className="MyProfilePage__main">
            <PageRoutes pages={profilePageRoutes} />
          </main>
        </BrowserRouter>
      </div>
    </Layout>
  );
};
