import React from 'react';
import { Spacer, Text, TextInput, MaxHeightContainer, Box } from '@eltoro-ui/components';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'rc-tooltip';
import classNames from 'classnames';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { Uploader } from 'Components/Uploader';
import { FileUploadType } from 'types';
import './BackgroundImageTab.scss';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { backgroundLocalPhotoAtom, backgroundPhotoAtom, unsplashPhotosAtom } from '../../../State';
import { PhotoButton } from './components';

export const BackgroundImageTab: React.FC<{
  search: string;
  onChange: (s: string) => void;
}> = ({ search, onChange }) => {
  const photos = useRecoilValue(unsplashPhotosAtom);
  const setBackgroundPhoto = useSetRecoilState(backgroundPhotoAtom);
  const setBackgroundLocalPhoto = useSetRecoilState(backgroundLocalPhotoAtom);

  const onSubmit = (files: FileUploadType) => {
    if (files.acceptedFiles.length) {
      const file = files.acceptedFiles[files.acceptedFiles.length - 1];
      const [width, height] = file.file_specs?.split('x');
      setBackgroundPhoto(null);
      setBackgroundLocalPhoto({
        id: file.id as string,
        url: URL.createObjectURL(file),
        width: Number(width),
        height: Number(height),
      });
    }
  };

  return (
    <div className="BackgroundImageTab">
      <MaxHeightContainer
        UNSAFE_CLASSNAME="BackgroundImageTab__overflow"
        header={
          <Box padding="1rem" paddingBottom="0.5rem">
            <Text
              on="white"
              weight="bold"
              UNSAFE_style={{ display: 'flex', alignItems: 'center', gap: 4 }}
            >
              Background Images{' '}
              <Tooltip
                placement="top"
                trigger="hover"
                overlayClassName={classNames('audience-tooltip', 'save-audience-info')}
                showArrow
                align={{
                  offset: [-15, -3],
                  targetOffset: [-6, 0],
                }}
                overlay={
                  <span>
                    Images provided by Unsplash. To learn more visit{' '}
                    <a
                      href="https://unsplash.com/license"
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: 'inherit' }}
                    >
                      License | Unsplash
                    </a>
                  </span>
                }
                getTooltipContainer={() => document.body}
              >
                <span style={{ cursor: 'pointer', display: 'flex' }}>
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    color="#AAB2B5"
                    style={{ fontSize: 20 }}
                  />
                </span>
              </Tooltip>
            </Text>
            <Spacer />
            <TextInput
              value={search}
              onChange={e => onChange(e.target.value)}
              icon={<FontAwesomeIcon icon={faSearch} />}
            />
          </Box>
        }
      >
        <div className="BackgroundImageTab__photo-container">
          <Uploader
            onDropFile={onSubmit}
            type="banner"
            validate={false}
            showThumbs={false}
            multiple={false}
          >
            <div className="BackgroundImageTab__temp-uploader">
              <span>Upload your Own</span>
            </div>
          </Uploader>
          <Spacer />
          {photos.map(photo => (
            <React.Fragment key={photo.id}>
              <PhotoButton
                photo={photo}
                onClick={() => {
                  setBackgroundLocalPhoto(null);
                  setBackgroundPhoto(photo);
                }}
              />
              <Spacer />
            </React.Fragment>
          ))}
        </div>
      </MaxHeightContainer>
    </div>
  );
};
