import Konva from 'konva';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { RecoilText } from '../..';
import { blockFontSizeAtom } from '../../../../State';
import { BlockConfigType } from '../../../../Typings';
import { BorderBox } from './components';

export const SquareLogo: React.FC<{
  id: string;
  width: number;
  height: number;
  isOnMobile?: boolean;
}> = ({ id, width, height, isOnMobile = false }) => {
  // shape configs
  const outsideBorderConfig: BlockConfigType = {
    id,
    kind: 'squareLogo',
    key: 'outsideBorder',
  };
  // const iconConfig: BlockConfigType = {
  //   id,
  //   kind: 'squareLogo',
  //   key: 'icon',
  // }

  // text configs
  const centerTextConfig: BlockConfigType = {
    id,
    kind: 'squareLogo',
    key: 'mainTitle',
  };
  const yearTextConfig: BlockConfigType = {
    id,
    kind: 'squareLogo',
    key: 'subtitle',
  };

  const yearTextRef = useRef<Konva.Text>(null);
  const [yearTextWidth, setYearTextWidth] = useState(yearTextRef.current?.getTextWidth() || 40);
  // state values
  const centerFontSize = useRecoilValue(blockFontSizeAtom(centerTextConfig));
  const yearFontSize = useRecoilValue(blockFontSizeAtom(yearTextConfig));

  useEffect(() => {
    setYearTextWidth(yearTextRef.current?.getTextWidth() || 40);
  }, [yearTextRef.current, yearFontSize]);

  const centerTextY = height / 2 - centerFontSize / 2;

  return (
    <>
      <BorderBox
        width={width}
        height={height - yearFontSize / 2}
        clippingFunc={(ctx: CanvasRenderingContext2D) => {
          const padding = 5;
          const leftOfYearText = width / 2 - yearTextWidth / 2 - padding;
          const rightOfYearText = width / 2 + yearTextWidth / 2 + padding;
          ctx.beginPath();
          ctx.moveTo(-padding, -padding);
          ctx.lineTo(width + padding, -padding);
          ctx.lineTo(width + padding, height);
          ctx.lineTo(rightOfYearText, height);
          ctx.lineTo(rightOfYearText, height - yearFontSize);
          ctx.lineTo(leftOfYearText, height - yearFontSize);
          ctx.lineTo(leftOfYearText, height);
          ctx.lineTo(-padding, height);
          ctx.closePath();
        }}
        config={outsideBorderConfig}
      />
      <RecoilText
        config={centerTextConfig}
        x={0}
        y={centerTextY}
        width={width}
        isOnMobile={isOnMobile}
      />
      <RecoilText
        textRef={yearTextRef}
        config={yearTextConfig}
        x={0}
        y={height - yearFontSize}
        width={width}
        isOnMobile={isOnMobile}
      />
    </>
  );
};
