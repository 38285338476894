import React from 'react';

import { Modal } from '@eltoro-ui/components';
import type { ModalProps } from '@eltoro-ui/components';

import { TCreative } from 'types';

import { formatBytes } from 'Utils/helpers';

import cross from 'assets/Images/cross.png';

import './ImagePreviewModal.scss';

interface ImagePreviewModalProps
  extends Pick<TCreative, 'presigned_url' | 'name' | 'file_size'>,
    Pick<ModalProps, 'offClick'> {}

const ImagePreviewModal = ({
  presigned_url,
  name,
  file_size,
  offClick,
}: ImagePreviewModalProps) => {
  return (
    <Modal className="creative-image-preview">
      <img
        src={cross}
        onClick={offClick}
        alt="cross"
        className="PersonalInfoForm__cross"
        role="presentation"
      />
      <div className="header">
        <span className="name">{name}</span>
        <div className="circle" />
        <span className="size">{formatBytes(file_size)}</span>
      </div>
      <div className="preview-modal-container">
        <img src={presigned_url as string} alt={name} />
      </div>
    </Modal>
  );
};

export default ImagePreviewModal;
