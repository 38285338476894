import React, { useState } from 'react';
import type { ReactNode } from 'react';

import toast from 'react-hot-toast';

import { Button, GridBox, Text } from '@eltoro-ui/components';
import { Uploader } from 'Components/Uploader';

import {
  UploadAreaLayout,
  UploadTypes,
} from 'Pages/CampaignCreation/components/CampaignCreatives/_components/UploadAreaLayout';

import { CreativeTypeEnum } from 'enums';
import type { FileUploadType } from 'types';

import cross from 'assets/Images/cross.png';

import './UploadVideoModal.scss';

type UploadVideoModalProps = {
  onClickOutside: () => void;
  onSubmit: (files: FileUploadType, typeModal?: CreativeTypeEnum) => void;
  typeModal: CreativeTypeEnum.VIDEO;
  loading?: boolean;
  okText?: string | ReactNode;
};

export const UploadVideoModal = ({
  onClickOutside,
  onSubmit,
  typeModal,
  loading,
  okText = 'Confirm',
}: UploadVideoModalProps) => {
  const [frontSubmittedFile, setfrontSubmittedFile] = useState<any>();
  return (
    <div className="UploadVideoModal">
      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <img
          src={cross}
          onClick={onClickOutside}
          alt="cross"
          className="PersonalInfoForm__cross"
          role="presentation"
        />
      </div>
      <GridBox justifyItems="flex-start" gap="0.5rem">
        <Text tag="div" on="white" size="xxl" UNSAFE_className="UploadMailerModal__step-subheading">
          Upload Your Video
        </Text>
      </GridBox>
      <div className="UploadVideoModal__hl" />
      <div>
        <Uploader type="video" onDropFile={files => setfrontSubmittedFile(files)}>
          <UploadAreaLayout uploadType={UploadTypes.video} />
        </Uploader>
      </div>
      <div className="UploadVideoModal__footer">
        <Button kind="default" size="l" weight="bold" onClick={onClickOutside}>
          Cancel
        </Button>
        <Button
          kind="primary"
          size="l"
          weight="bold"
          disabled={loading || !frontSubmittedFile?.acceptedFiles?.length}
          loading={loading}
          onClick={async () => {
            if (frontSubmittedFile?.acceptedFiles?.length > 0) {
              await onSubmit(frontSubmittedFile, typeModal);
              await onClickOutside();
            } else {
              toast.error('Please add a video creative');
            }
          }}
        >
          {okText}
        </Button>
      </div>
    </div>
  );
};
