/* eslint-disable */
import { faTable } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useRef } from 'react';
import './LabelRichTextBox.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';

type TextInputType = {
  title?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  type?: string;
  isError?: Boolean;
  icon?: IconProp | JSX.Element;
  placeHolder?: string | undefined;
  value?: string | number | readonly string[] | undefined;
  wdt?: string | number | undefined;
  border?: string | number | undefined;
  fontSize?: string | number | undefined;
  color?: string | undefined;
  padding?: string | number | undefined;
  EyePassIcon?: JSX.Element;
  requireTage?: string;
  iconLeft?: any;
  iconRight?: any;
  disabled?: boolean;
  style?: any;
  placeholder: string;
  lableStyle?: any;
  imgIconStyle?: any;
  UNSAFE_ClassName?: string;
  defaultValue?: string;
};

export const LabelRichTextBox: React.FC<TextInputType> = ({
  title,
  requireTage,
  onChange,
  placeholder,
  EyePassIcon,
  defaultValue,
  disabled,
  value,
  isError,
  iconLeft,
  iconRight,
  style,
  lableStyle,
  type = 'textarea',
  imgIconStyle,
  icon,
  wdt,
  border,
  fontSize,
  color,
  padding,
  UNSAFE_ClassName,
}) => {
  const [errir, seterrir] = useState('');
  return (
    <>
      <div className="label-rich-text-box-container">
        <div
          className={classNames(
            isError ? 'did-floating-label-content did-error-input' : 'did-floating-label-content'
          )}
          style={{ position: 'relative' }}
        >
          <div className="input_icon_start">{iconLeft}</div>
          <div className="input_icon_end">{iconRight}</div>
          <textarea
            disabled={disabled}
            id="sale"
            value={value}
            style={style}
            className={classNames(
              'did-floating-input1',
              !iconLeft && 'did-floating-input__padding'
            )}
            placeholder=" "
            onFocus={e => (e.target.placeholder = placeholder)}
            onBlur={e => (e.target.placeholder = ' ')}
            onChange={onChange}
            defaultValue={defaultValue}
          />
          <label
            style={lableStyle}
            htmlFor="sale"
            className={classNames('did-floating-label', !iconLeft && 'did-floating-label__padding')}
          >
            {title}
            <span style={{ color: '#BD0000' }}>{requireTage}</span>
          </label>
          <div className="inputRightIcon">{EyePassIcon}</div>
        </div>
      </div>
    </>
  );
};
