import React, { useMemo } from 'react';
import paligonSrc from 'assets/Images/paligon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { GuideEnum, RecomendationTypeEnum, RecomendationListingEnum } from 'enums';
import Contacts from './components/Contacts';
import Recomendations from './components/Recomendations';
import './styles.scss';

type LocationType = {
  state?: string;
  city?: string;
  street?: string;
  zip?: string;
};

interface Props {
  isActive: boolean;
  type: GuideEnum;
  collapseToggle: boolean;
  handleClose: () => void;
  recomendationsCount?: number;
  recomendationType?: RecomendationTypeEnum;
  recomendationListingName?: RecomendationListingEnum;
  locationData?: LocationType;
  sellersCount?: number;
  buyersCount?: number;
}

const GuidePopoverSelector = ({
  type,
  isActive,
  collapseToggle,
  handleClose,
  recomendationsCount,
  recomendationType,
  recomendationListingName,
  locationData,
  sellersCount,
  buyersCount,
}: Props) => {
  const recomendationMSGbyType = useMemo(() => {
    switch (recomendationType) {
      case RecomendationTypeEnum.AGENT:
        if (sellersCount && buyersCount === 0) {
          return `${sellersCount} of your contact households who are potential sellers are displaying in-market activity over the last 90 days.`;
        }

        if (buyersCount && sellersCount === 0) {
          return `${buyersCount} of your contact households who are potential buyers are displaying in-market activity over the last 90 days`;
        }

        return `We found 2 recommendations tailored to your needs, try to make the most of them!`;
      case RecomendationTypeEnum.LISTING:
        return recomendationListingName === RecomendationListingEnum.PROMOTION
          ? `Congrats on your new listing at ${locationData?.street ?? ''}, ${
              locationData?.city ?? ''
            }, ${locationData?.state ?? ''}, ${
              locationData?.zip ?? ''
            }! Engage with potential buyers seen at comparable properties.`
          : `Congrats on closing the deal at ${locationData?.street ?? ''}, ${
              locationData?.city ?? ''
            }, ${locationData?.state ?? ''}, ${
              locationData?.zip ?? ''
            }! Engage with potential sellers residing around your listing area.`;
      default:
        return '';
    }
  }, [recomendationType, locationData, sellersCount, buyersCount]);

  const content = {
    [GuideEnum.CONTACTS]: <Contacts />,
    [GuideEnum.RECOMENDATIONS]: (
      <Recomendations
        recomendationsCount={Number(recomendationsCount)}
        msg={recomendationsCount === 1 ? recomendationMSGbyType : ''}
      />
    ),
  };

  const position =
    type === GuideEnum.CONTACTS
      ? {
          top: 3.5,
          left: collapseToggle ? 18 : 5,
        }
      : {
          top: 11,
          left: collapseToggle ? 18 : 5,
        };

  return isActive ? (
    <div
      className="overlay_container"
      style={{ left: `${position.left}rem`, top: `${position.top}rem` }}
    >
      <FontAwesomeIcon
        icon={faTimes}
        color="#767676"
        size="lg"
        onClick={handleClose}
        style={{ position: 'absolute', right: '5%', top: '12%', cursor: 'pointer' }}
      />
      <img src={paligonSrc} className="paligon" alt="paligon" />
      {content[type]}
    </div>
  ) : null;
};

export default GuidePopoverSelector;
