import React, { useCallback, useEffect, useRef, useState } from 'react';

import Tippy from '@tippyjs/react';

import { useResizeEffect } from 'Hooks';

import { isEllipsisActive } from 'Utils/helpers';

import type { CampaignOrderlineType } from 'types';

interface OrderlineNameAccessorProps extends Pick<CampaignOrderlineType, 'name'> {}

const OrderlineNameAccessor = ({ name }: OrderlineNameAccessorProps) => {
  const ordelineNameRef = useRef<HTMLSpanElement>(null);
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (ordelineNameRef.current)
      setDisabled(isEllipsisActive(ordelineNameRef.current.parentNode as HTMLElement));
  }, [ordelineNameRef.current]);

  const setDisabledOnResize = useCallback(() => {
    if (!ordelineNameRef.current) return;
    setDisabled(isEllipsisActive(ordelineNameRef.current.parentNode as HTMLElement));
  }, [ordelineNameRef.current]);

  // @ts-ignore
  useResizeEffect(setDisabledOnResize, [ordelineNameRef]);

  return (
    <Tippy placement="bottom-start" content={name} disabled={disabled}>
      <span ref={ordelineNameRef}>{name}</span>
    </Tippy>
  );
};

export default OrderlineNameAccessor;
