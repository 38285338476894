import React from 'react';
import type { ReactNode } from 'react';

import { Modal } from '@eltoro-ui/components';
import {
  UploadBannerModal,
  UploadVideoModal,
  UploadCSVModal,
} from 'Pages/CampaignCreation/components/CampaignCreatives/_components';

import { CreativeTypeEnum } from 'enums';
import type { FileUploadType } from 'types';

import './CreativeCampaignModal.scss';

export type CreativeCampaignModalProps = {
  loading?: boolean;
  type: CreativeTypeEnum | 'csv';
  onClickOutside: () => void;
  onSubmit: (files: FileUploadType, typeModal?: CreativeTypeEnum) => void;
  campaignId?: any;
  okText?: string | ReactNode;
};

export const CreativeCampaignModal = ({
  campaignId,
  type,
  onClickOutside,
  onSubmit,
  loading,
  okText = 'Confirm',
}: CreativeCampaignModalProps) => {
  const displayModal = () => {
    if (type === CreativeTypeEnum.BANNER) {
      return (
        <UploadBannerModal
          typeModal={type}
          onClickOutside={onClickOutside}
          onSubmit={onSubmit}
          loading={loading}
          okText={okText}
        />
      );
    }
    if (type === 'csv') {
      return (
        <UploadCSVModal
          onClickOutside={onClickOutside}
          onSubmit={onSubmit}
          campaignId={campaignId}
          okText={okText}
        />
      );
    }
    return (
      <UploadVideoModal
        okText={okText}
        typeModal={type}
        loading={loading}
        onClickOutside={onClickOutside}
        onSubmit={onSubmit}
      />
    );
  };
  return <Modal offClick={onClickOutside}>{displayModal()}</Modal>;
};
