import React, { useState } from 'react';

import { FlexBox, Text } from '@eltoro-ui/components';
import { CampaignStatusBadge, LabelValuePair, ProgressIndicator } from 'Components';

import bannerImg from 'assets/Images/Group339.png';
import videoImg from 'assets/Images/Subtract.png';
import draft from 'assets/Images/draft.png';
import inreview from 'assets/Images/inreview.png';
import activeimg from 'assets/Images/activeimg.png';

import { getStatus } from 'Pages';

import type { CampaignDetailsType } from 'types';

import './CampaignDetails.scss';

export const statusBadgeIconClass = (status: string) => {
  if (status === 'processed' || status === 'Open') return 'CampaignBadge_inReview';
  if (status === 'pending' || status === 'processing') return 'CampaignBadge_draft';
  if (status === 'Serving') return 'CampaignBadge_active';
  return 'CampaignBadge_draft';
};
export const getStatusBadgeImage = (status: string) => {
  if (status === 'processed' || status === 'Open') return inreview;
  if (status === 'pending' || status === 'processing') return draft;
  if (status === 'Ready' || status === 'Serving') return activeimg;
  return '#AAB2B5';
};

type CampaignDetailsProps = {
  campaign: CampaignDetailsType;
};

export const CampaignDetails = ({ campaign }: CampaignDetailsProps) => {
  const [startDate, setStartDate] = useState(
    new Date(
      parseInt(campaign?.start_day.split('T')[0].split('-')[0], 10),
      parseInt(campaign?.start_day.split('T')[0].split('-')[1], 10) - 1,
      parseInt(campaign?.start_day.split('T')[0].split('-')[2], 10)
    )
  );

  const [endDate, setEndDate] = useState(
    new Date(
      parseInt(campaign?.stop_day.split('T')[0].split('-')[0], 10),
      parseInt(campaign?.stop_day.split('T')[0].split('-')[1], 10) - 1,
      parseInt(campaign?.stop_day.split('T')[0].split('-')[2], 10)
    )
  );
  const getCurrentDate = (date: string) => {
    const dates = new Date(date);
    return `${dates.toLocaleString('en-us', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    })} at ${dates.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })}`;
  };
  function getDays(start: string | number | Date, last: string | number | Date) {
    const date1 = new Date(start);
    const date2 = new Date(last);

    // calculation for converting a day into milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // calculation for the time difference between start and last
    const diffTime = date2.getTime() - date1.getTime();

    // calculation for the days between start and last
    // return number of days
    return Math.round(diffTime / oneDay);
  }
  // const getCurrentOutOf = () => {
  //   if (
  //     new Date() >= new Date(campaign?.start_date) &&
  //     new Date() <= new Date(campaign?.end_date)
  //   ) {
  //     return getDays(new Date(), campaign?.end_date)
  //   }
  //   return 0
  // }

  // const getCurrentOutOfEnd = () => {
  //   return campaign?.days
  // }
  const getData = (type?: string) => {
    if (!type) {
      const listOfName = campaign?.orderlines
        .map(orderline => orderline.creative_type)
        .filter((url, i, array) => array.indexOf(url) === i);

      return listOfName?.join(', ');
    }
    return campaign.orderlines
      .map(
        orderline =>
          orderline?.clickThroughUrl && `${orderline.clickThroughUrl} (${orderline.creative_type})`
      )
      .filter((url, i, array) => array.indexOf(url) === i);
  };
  const linkImg = (creative_type: string) => {
    if (creative_type === 'Video') {
      return videoImg;
    }
    return bannerImg;
  };

  return (
    <>
      <Text
        on="grey-050"
        UNSAFE_style={{ marginBottom: '.7rem' }}
        UNSAFE_className="campaign-title-audience-heading"
      >
        {campaign?.name}
      </Text>

      <FlexBox alignItems="center" justifyContent="space-between">
        <LabelValuePair
          UNSAFE_className="text-campaign-details"
          UNSAFE_className_Value="text-campaign-details-value"
          label="CREATED"
          value={getCurrentDate(campaign.created)}
        />
      </FlexBox>
      <hr className="divider" />
      <FlexBox alignItems="center" justifyContent="space-between">
        <LabelValuePair
          UNSAFE_className="text-campaign-details flight_date"
          label="FLIGHT DATES"
          UNSAFE_className_Value="text-campaign-details-value"
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          value="1/03/20 - 3/23/20"
        />

        <FlexBox
          flexDirection="column"
          justifyContent="space-between"
          UNSAFE_className="creative_right_column"
        >
          <LabelValuePair
            UNSAFE_className_Value="text-campaign-details-value creative_value"
            UNSAFE_className="text-campaign-details"
            label="CREATIVE"
            value={getData()}
          />

          <LabelValuePair
            UNSAFE_className_Value="text-campaign-details-value creative_value valueWidth"
            UNSAFE_className="text-campaign-details"
            label="CLICK THROUGH LINK"
            value={campaign?.orderlines.map(orderline => (
              <span key={orderline.eltoro_id} style={{ display: 'flex' }}>
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {orderline.clickThroughUrl}
                </span>
              </span>
            ))}
          />
          <div>
            <Text
              UNSAFE_className="text-campaign-details text-campaign-details"
              on="white"
              size="m"
              weight="bold"
            >
              DAYS
            </Text>
            <ProgressIndicator
              current={campaign?.served_days}
              end={campaign?.total_days}
              clasName="campaign_details_progressbar"
            />
            <FlexBox UNSAFE_className="flight-shedule" justifyContent="space-between">
              <Text UNSAFE_className="flight-date-time" on="white">
                Flight Date Start
              </Text>
              <Text UNSAFE_className="flight-date-time" on="white">
                Flight Date End
              </Text>
            </FlexBox>
          </div>
        </FlexBox>
      </FlexBox>
      {/* </GridBox> */}

      {/* Old Component */}
      {/* <div className="CampaignDetails">
        <Section
          UNSAFE_className_text="section-campaign-details"
          title="Campaign Details"
          noBackground
        >
          <div className="CampaignDetails__body">
            <FlexBox
              flexDirection="row"
              justifyContent="space-between"
              flexWrap="wrap"
              UNSAFE_className="flexbox_conainer"
            >
              <GridBox UNSAFE_className="grid_box" gap="2rem">
                <LabelValuePair
                  UNSAFE_className="text-campaign-details"
                  UNSAFE_className_Value="text-campaign-details-value"
                  label="CREATED"
                  value={getCurrentDate(campaign.created)}
                  // value="09/22/19 at 10:31AM"
                />
                <LabelValuePair
                  UNSAFE_className="text-campaign-details"
                  label="FLIGHT DATES"
                  UNSAFE_className_Value="text-campaign-details-value"
                  startDate={startDate}
                  endDate={endDate}
                  onStartDateChange={setStartDate}
                  onEndDateChange={setEndDate}
                  value="1/03/20 - 3/23/20"
                />
                <div>
                  <Text
                    UNSAFE_className="text-campaign-details text-campaign-details-value"
                    on="white"
                    size="m"
                    weight="bold"
                  >
                    DAYS
                  </Text>
                  <ProgressIndicator
                    current={campaign?.served_days}
                    end={campaign?.total_days}
                  />
                </div>
              </GridBox>

              <GridBox gap="2rem">
                <LabelValuePair
                  UNSAFE_className_Value="text-campaign-details-value"
                  UNSAFE_className="text-campaign-details"
                  label="CREATIVE"
                  // value={"Direct Mailer, Digital Banner"}
                  value={getData()}
                />
                <LabelValuePair
                  UNSAFE_className_Value="text-campaign-details-value valueWidth"
                  UNSAFE_className="text-campaign-details"
                  label="CLICK THROUGH LINK"
                  value={campaign?.orderlines.map(
                    (
                      creative: {
                        clickThroughUrl: string
                        creative_type: string
                      },
                      index: React.Key | null | undefined,
                    ) => (
                      <span key={index} style={{ display: 'flex' }}>
                        <span>{creative.clickThroughUrl}</span>{' '}
                        <img
                          src={linkImg(creative.creative_type)}
                          alt="link Img"
                          className="link-img"
                        />
                      </span>
                    ),
                  )}
                  // value="https://myrealestatewebsite.com"
                />
                <LabelValuePair
                  UNSAFE_className_Value="text-campaign-details-value"
                  UNSAFE_className="text-campaign-details"
                  label="STATUS"
                  value={
                    <div style={{ width: '50%' }}>
                      <CampaignStatusBadge
                        status={getStatus(campaign.status)}
                        color={getStatusColor(campaign.status)}
                      />
                    </div>
                  }
                />
                <LabelValuePair
                  UNSAFE_className_Value="text-campaign-details-value"
                  UNSAFE_className="text-campaign-details"
                  label="# OF IMPRESSIONS"
                  value={`${campaign?.impressions_perday || '4'}x`}
                />
              </GridBox>
            </FlexBox>
          </div>
        </Section>
      </div> */}
    </>
  );
};
