import React, { useRef, useState, useEffect } from 'react';
import type { ChangeEventHandler } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faCheck, faPlus, faSearch } from '@fortawesome/pro-regular-svg-icons';

import { useDebouncedCallback } from '@eltoro-ui/hooks';
import { Modal } from '@eltoro-ui/components';

import type { TSuggestedListing, APIFeatureType } from 'types';

import { searchListingById, setIntoMyListings } from 'Requests';

import './ListingSearch.scss';

interface ListingSearchProps {
  onAdd: (listing: APIFeatureType) => void;
}

interface ListingSearchModalProps extends ListingSearchProps {
  onClose: () => void;
}

export const ListingSearch = ({ onAdd }: ListingSearchProps) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="ListingSearch">
      <button
        type="button"
        className="ListingSearch__button"
        onClick={() => setIsActive(active => !active)}
      >
        <FontAwesomeIcon icon={faSearch} />
        <span className="content">Search by listing ID</span>
      </button>

      {isActive && <ListingSearchModal onAdd={onAdd} onClose={() => setIsActive(false)} />}
    </div>
  );
};

export const ListingSearchModal = ({ onAdd, onClose }: ListingSearchModalProps) => {
  const [searchedText, setSearchedText] = useState('');

  const [suggestions, setSuggestions] = useState<TSuggestedListing[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [addListingLoading, setAddListingLoading] = useState<number>(0);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => inputRef.current?.focus(), []);

  const onAddIntoMyListings = (listing: TSuggestedListing) => async () => {
    try {
      setAddListingLoading(listing.listingid);
      const res = await setIntoMyListings(listing.listingid);

      if (res.data) {
        onAdd(res.data);
        setSuggestions(suggestions =>
          suggestions.map(suggestion =>
            res.data?.listingid && suggestion.listingid === res.data.listingid
              ? { ...suggestion, added: true }
              : suggestion
          )
        );
      }
      setAddListingLoading(0);
    } catch (e) {
      setAddListingLoading(0);
    }
  };

  const onChangeSearch: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) =>
    setSearchedText(value);

  const getLocationsDebounced = useDebouncedCallback(async (query: string) => {
    try {
      setLoading(true);
      const res = await searchListingById(query);

      if (res.data) {
        setSuggestions(res.data.listings);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, 800);

  useEffect(() => {
    if (searchedText.length) {
      getLocationsDebounced(searchedText);
    } else {
      setSuggestions([]);
      setLoading(false);
    }
    return () => {
      setSuggestions([]);
      setLoading(false);
    };
  }, [searchedText]);

  return (
    <Modal className="LocationSearchModal" offClick={onClose}>
      <div className="ListingSearch__model-content">
        <div className="ListingSearch__search">
          <FontAwesomeIcon icon={faSearch} className="ListingSearch__icon" />
          <input
            placeholder="Search by listing ID"
            ref={inputRef}
            className="ListingSearch__input"
            type="text"
            value={searchedText}
            onChange={onChangeSearch}
            maxLength={50}
          />
        </div>
        {loading ? (
          <div
            style={{
              textAlign: 'center',
              margin: '2rem',
            }}
          >
            <div className="before-spinner" style={{ position: 'unset' }} />
          </div>
        ) : (
          suggestions.length > 0 && (
            <ul className="ListingSearch__suggestions">
              {suggestions.map(listing => (
                <li key={listing.listingid}>
                  <div className="ListingSearch__suggestion-button">
                    <div className="ListingSearch__suggestion-info">
                      <span className="ListingSearch__suggestion-info-name">
                        <b>{listing.street}</b> {listing.city} {listing.state}, {listing.zip}
                      </span>
                      <span className="ListingSearch__suggestion-info-id">
                        Listing ID: {listing.mls_id}
                      </span>
                    </div>
                    {listing.added ? (
                      <FontAwesomeIcon icon={faCheck} color="#FFAB03" />
                    ) : (
                      <button
                        className="ListingSearch__suggestion-add-into-listings"
                        onClick={onAddIntoMyListings(listing)}
                      >
                        {addListingLoading === listing.listingid ? (
                          <FontAwesomeIcon
                            icon={faSpinnerThird}
                            style={{
                              animation: 'spinner-border 0.75s linear infinite',
                            }}
                          />
                        ) : (
                          <FontAwesomeIcon icon={faPlus} color="#FFAB03" />
                        )}
                        <span>Add to my listings</span>
                      </button>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          )
        )}
      </div>
    </Modal>
  );
};
