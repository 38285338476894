import { UserType } from 'types';

export const getSessionUser = () => {
  const userString = localStorage.getItem('beewo_user');
  if (!userString) return { user: {} };
  if (userString === 'undefined') return { user: {} };

  const user = JSON.parse(userString || '{}');

  return { user };
};
export const setSessionUser = (user: UserType) => {
  localStorage.setItem('beewo_user', JSON.stringify(user || '{}'));
};

export const clearSession = () => {
  localStorage.removeItem('beewo_token');
  localStorage.removeItem('beewo_user');
  sessionStorage.removeItem('guideBot');
};

export const setAuthHeader = (token: string) => {
  localStorage.setItem('beewo_token', token);
};

export const getAuthHeader = (upload?: Boolean) => {
  const token = localStorage.getItem('beewo_token');
  return {
    Authorization: `Bearer ${token}`,
    'Content-Type': upload ? 'multipart/form-data' : 'application/json',
  };
};
