import React from 'react';
import './index.scss';

export const LinearLoader = () => {
  return (
    <div className="linear-activity">
      <div className="indeterminate" />
    </div>
  );
};
