/* eslint-disable */
import React, { useState } from 'react';
import type { ReactNode } from 'react';

import toast from 'react-hot-toast';

import { Button, GridBox } from '@eltoro-ui/components';
import { Uploader } from 'Components/Uploader';
import { UploadAreaLayout, UploadTypes } from '../UploadAreaLayout';

import cross from 'assets/Images/cross.png';

import { uploadCSV } from 'Requests/Request_Methods/campaignMethods';
import type { FileUploadType } from 'types';

import './uploadcsvModal.scss';

type UploadCsvModalProps = {
  onClickOutside: () => void;
  onSubmit: (files: FileUploadType) => void;
  campaignId: any;
  okText?: string | ReactNode;
};

export const UploadCSVModal = ({
  onClickOutside,
  campaignId,
  okText = 'Confirm',
}: UploadCsvModalProps) => {
  const [file, setfile] = useState<File[]>([]);
  const [id, setId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const Form = new FormData();

  const onSubmitFile = (e: any) => {
    setfile(e.acceptedFiles);
    setId(campaignId.id);
  };
  const postCSV = () => {
    setIsLoading(true);
    file.forEach(csv => {
      Form.append('files', csv);
    });
    Form.append('campaign_id', id);
    uploadCSV(Form)
      .then(res => {
        if (res.detail) {
          toast.error('Invalid csv');
        }
        setIsLoading(false);
        setTimeout(() => onClickOutside(), 200);
      })
      .catch(() => {
        toast.error('Invalid csv');
        setIsLoading(false);
      });
  };
  return (
    <div className="UploadBannerModal uploadcsvModal">
      <img className="uploadcsvModal__cross" onClick={onClickOutside} src={cross} alt="cross" />

      <p className="uploadcsvModal__StepOne_heading">STEP 1</p>

      <b className="uploadcsvModal__StepOne_sub_heading">
        Make sure your CSV is formatted correctly
      </b>

      <i className="uploadcsvModal__StepOne_description">
        Notice: Our system will only process addresses and zip codes. All other columns are ignored
        by our system.
      </i>
      <div className="uploadcsvModal__points">
        <ol type="a">
          <li>Address Column with address as column header </li>
          <li>
            Zip Code Column with zip as column header (Zip needs to be broken down into a 5-digit
            zip and must preserve 0 in zip codes beginning with the number 0)
          </li>
          <li>Must be a text or CSV file (save as a CSV UTF-8(comma delimited)(*.csv))</li>
          <li>No commas or special characters</li>
          <li>Be sure to provide the proper target list for your campaign</li>
        </ol>
      </div>
      <a
        style={{ textDecoration: 'none', width: 'fit-content' }}
        download="prospects"
        href="data:text/csv;base64,YWRkcmVzcyx6aXAK"
      >
        <Button type="button" kind="default" size="s" UNSAFE_className="uploadcsvModal__example">
          Download Template
        </Button>
      </a>
      <div className="UploadBannerModal__hl" />
      <GridBox justifyItems="flex-start" gap="0.5rem">
        <p className="uploadcsvModal__StepOne_heading">STEP 2</p>

        <b className="uploadcsvModal__StepOne_sub_heading">Upload Your List</b>
      </GridBox>
      <div>
        <Uploader type="csv" onDropFile={(files: FileUploadType) => onSubmitFile(files)}>
          <UploadAreaLayout uploadType={UploadTypes.csv} />
        </Uploader>
      </div>
      <div className="UploadBannerModal__footer">
        <Button kind="default" size="l" weight="bold" onClick={onClickOutside}>
          Cancel
        </Button>
        <Button
          kind="primary"
          size="l"
          weight="bold"
          onClick={postCSV}
          disabled={!file.length || isLoading}
          loading={isLoading}
        >
          {okText}
        </Button>
      </div>
    </div>
  );
};
