import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import FlagProvider from '@unleash/proxy-client-react';
import type { IConfig } from 'unleash-proxy-client';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { ErrorBoundary, Spinner } from 'Components';

import { store } from 'Redux/store';

import reportWebVitals from 'reportWebVitals';

import './index.css';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_KEY,
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const App = React.lazy(() => import('./App'));

const config = {
  url: process.env.REACT_APP_UNLEASH_URL,
  clientKey: process.env.REACT_APP_UNLEASH_PROXY_CLIENT_KEYS,
  appName: process.env.REACT_APP_UNLEASH_APP_NAME,
  environment: process.env.REACT_APP_UNLEASH_ENV,
  refreshInterval: 1800,
  disableMetrics: true,
} as IConfig;

ReactDOM.render(
  <React.StrictMode>
    <FlagProvider config={config}>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <Provider store={store}>
            <App />
          </Provider>
        </Suspense>
      </ErrorBoundary>
    </FlagProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
