import React, { useEffect, useMemo, useState } from 'react';

import toast from 'react-hot-toast';
import { AxiosError } from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOff } from '@fortawesome/pro-duotone-svg-icons';
import { faToggleOn } from '@fortawesome/pro-regular-svg-icons';

import { FlexBox } from '@eltoro-ui/components';
import { Loading } from 'Components';

import { getNotificationSettings, pushNotificationSetting, subscribeTopic } from 'Requests';

import { NotificationSettingType, UserDeviceEnum } from 'enums';
import type { NotificationSetting } from 'types';

import './NotificationSetting.scss';

const NotificationSettingsTitles = {
  [NotificationSettingType.ACCOUNT]: 'Account notifications',
  [NotificationSettingType.CAMPAIGN]: 'Campaign notifications',
  [NotificationSettingType.CONTACTS]: 'Contact notifications',
};

const NotificationSettings = () => {
  const [notificationSetting, setNotificationSetting] = useState<NotificationSetting[]>([]);

  useEffect(() => {
    getNotificationSettings().then(res => {
      if (res.data) {
        if (res.data.notifications.length > 0) {
          setNotificationSetting(res.data.notifications);
        }
      }
    });
  }, []);

  async function toggleTurnOffOnNotification(isAllOn: boolean) {
    setNotificationSetting(settings =>
      settings.map(setting => ({ ...setting, status: +!isAllOn }))
    );
    try {
      const settings = notificationSetting.map(({ type }) => ({ type, status: +!isAllOn }));

      await pushNotificationSetting({
        settings,
        device: UserDeviceEnum.WEB,
      });
      toast.success('Saved Successfully!');
      const user = localStorage.getItem('firebase_notification');
      if (user) {
        await subscribeTopic(user, +!isAllOn);
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        setNotificationSetting(settings =>
          settings.map(setting => ({ ...setting, status: +!setting.status }))
        );
      }
    }
  }

  const callNotificationToggle = (setting: NotificationSetting, value: number) => async () => {
    setNotificationSetting(settings =>
      settings.map(({ type, status, ...prevSetting }) => {
        if (type === setting.type) return { type, status: value, ...prevSetting };
        return { type, status, ...prevSetting };
      })
    );
    try {
      const settings = notificationSetting.map(({ type, status }) => {
        if (type === setting.type) return { type, status: value };
        return { type, status };
      });
      await pushNotificationSetting({
        settings,
        device: UserDeviceEnum.WEB,
      });
      toast.success('Saved Successfully!');
      const user = localStorage.getItem('firebase_notification');
      if (user) {
        await subscribeTopic(user, +settings.some(setting => setting.status));
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        setNotificationSetting(settings =>
          settings.map(({ type, status, ...prevSetting }) => {
            if (type === setting.type) return { type, status: +!status, ...prevSetting };
            return { type, status, ...prevSetting };
          })
        );
      }
    }
  };

  const isAllOn = useMemo(
    () => notificationSetting.every(({ status }) => !!status),
    [notificationSetting]
  );

  return (
    <div className="notificationSetting">
      {notificationSetting.length ? (
        <div className="notificationSetting__container">
          <div className="notificationSetting__containerItem">
            <span className="notification-header-title">Notification Settings</span>
            <FlexBox
              alignItems="center"
              justifyContent="center"
              UNSAFE_className="notificationSetting__containerItemIconContainer"
            >
              <span>Push</span>
            </FlexBox>
          </div>
          {notificationSetting.map(setting => (
            <div key={setting.type} className="notificationSetting__containerItem">
              <span className="notificationSetting__containerItemContent">
                {NotificationSettingsTitles[setting.type]}
              </span>
              <FlexBox
                alignItems="center"
                justifyContent="center"
                UNSAFE_className="notificationSetting__containerItemIconContainer"
              >
                <FontAwesomeIcon
                  className="notificationSetting__containerItemIcon"
                  size="1x"
                  color={setting.status === 1 ? '#FFB708' : 'lightgray'}
                  icon={setting.status === 1 ? faToggleOn : faToggleOff}
                  onClick={callNotificationToggle(setting, +!setting.status)}
                />
              </FlexBox>
            </div>
          ))}

          <div className="notificationSetting__containerBottom">
            <button
              className="notificationSetting__button"
              type="button"
              onClick={() => toggleTurnOffOnNotification(isAllOn)}
            >
              Turn {isAllOn ? 'OFF' : 'ON'} all notifications
            </button>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default NotificationSettings;
