import React, { useMemo } from 'react';
import { FlexBox } from '@eltoro-ui/components';
import { RecomendationContactEnum } from 'enums';
import EmptyState from './EmptyState';
import RecomendationTile from './RecomendationTile';

interface Props {
  sellersCount?: number;
  buyersCount?: number;
}

const RecomendedForYou = ({ sellersCount, buyersCount }: Props) => {
  const content = useMemo(() => {
    if (sellersCount && buyersCount) {
      return [
        <RecomendationTile
          key={RecomendationContactEnum.SELLER}
          kind={RecomendationContactEnum.SELLER}
          count={sellersCount}
        />,
        <RecomendationTile
          key={RecomendationContactEnum.BUYER}
          kind={RecomendationContactEnum.BUYER}
          count={buyersCount}
        />,
      ];
    }

    if (sellersCount && !buyersCount) {
      return <RecomendationTile kind={RecomendationContactEnum.SELLER} count={sellersCount} />;
    }

    if (buyersCount && !sellersCount) {
      return <RecomendationTile kind={RecomendationContactEnum.BUYER} count={buyersCount} />;
    }

    return <EmptyState />;
  }, [sellersCount, buyersCount]);

  return (
    <FlexBox flexDirection="column" gap="24px">
      {content}
    </FlexBox>
  );
};

export default RecomendedForYou;
