import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelectedListingMarker } from 'Pages/ProspectActivity/lenses/components/LensSelectedMarker/LensSelectedMarker';
import React from 'react';
import { useSelector } from 'react-redux';
import './MapMarker.scss';

export const MapMarker: React.FC<{
  color?: string;
  isSelected?: any;
  f?: any;
  searchClicked?: any;
}> = ({ color = 'red', isSelected, f, searchClicked }) => {
  const isTrue = false;

  return (
    <div className="MapMarker">
      <svg
        className={`MapMarker__svg-icon ${
          searchClicked && f?.selected && 'MapMarker__svg-icon-large'
        }`}
        viewBox="0 0 424 552"
      >
        <path
          fill={color}
          className={isTrue ? 'MapMarker__svg-marker-path-selected' : 'MapMarker__svg-marker-path'}
          d="M192.27,521.67C46.97,311.03,20,289.41,20,212c0-106.04,85.96-192,192-192s192,85.96,192,192 c0,77.41-26.97,99.03-172.27,309.67C222.2,535.44,201.8,535.44,192.27,521.67L192.27,521.67z"
        />
        <div
          className={`MapMarker__starIcon ${
            searchClicked && f?.selected && 'MapMarker__starIconBig '
          }`}
        >
          <FontAwesomeIcon
            icon={faStar}
            style={{
              color: '#fff',
              fontSize: searchClicked && f?.selected ? '23px' : '12px',
            }}
          />
        </div>
      </svg>
    </div>
  );
};
