import { CreativeType, ResponseType, getDataType } from 'types';
import requests from '../http';

export const getCreatives = async (
  skip?: number | null,
  limit?: number | null
): Promise<ResponseType<getDataType<CreativeType[]>>> => requests.get(`/creatives`);

export const getCreativeById = async (creative_id: string): Promise<ResponseType<CreativeType>> => {
  return requests.get(`/creative/${creative_id}`);
};

export const updateCreative = async (
  creative_id: string,
  body: Partial<CreativeType>
): Promise<ResponseType<CreativeType>> => {
  return requests.patch(`/creative/${creative_id}`, body);
};

export const createCreative = async (
  body: Partial<CreativeType>
): Promise<ResponseType<CreativeType>> => requests.post(`/creative`, body);
