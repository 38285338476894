import type { ResponseType, TCreative, TCreativeDetails, TCreatives } from 'types';
import { CreativeStatusEnum, CreativeTypeEnum } from 'enums';

import requests from '../http';

export const getAllCreatives = async (
  page: number = 1,
  pageSize: number = 10,
  body: { creative_type: CreativeTypeEnum[]; status: CreativeStatusEnum[] }
): Promise<ResponseType<TCreatives>> =>
  requests.post(`/creative?page=${page}&size=${pageSize}`, body);

export const uploadCreatives = async (data: FormData): Promise<ResponseType<{ message: string }>> =>
  requests.post('/creative/upload', data, true);

export const getCreativeDetails = async (
  creative_uuid: TCreative['creative_uuid']
): Promise<ResponseType<any>> => requests.get(`/creative/${creative_uuid}`);

export const editCreativeName = async (
  creative_uuid: TCreative['creative_uuid'],
  body: Pick<TCreativeDetails, 'name'>
): Promise<ResponseType<{ msg: string }>> => requests.patch(`/creative/${creative_uuid}`, body);

export const deleteCreative = async (
  creative_uuid: TCreative['creative_uuid']
): Promise<ResponseType<{ message: string }>> => requests.delete(`/creative/${creative_uuid}`);
