import { Spacer, Text, TextInput } from '@eltoro-ui/components';
import React from 'react';
import { useRecoilState } from 'recoil';
import { blockTextValueSelector } from '../../../../../State';
import { AvailableBlocksType } from '../../../../../Typings';

export const EditTextValues: React.FC<{
  label: string;
  config: {
    ids: string[];
    kind: AvailableBlocksType;
    key: string;
  };
}> = ({ label, config }) => {
  const [textValue, setTextValue] = useRecoilState(blockTextValueSelector(config));
  const { value, error } = textValue;

  return (
    <>
      <Text on="white" size="s" kind="subdued">
        {label}
      </Text>
      <Spacer height="0.25rem" />
      <TextInput
        placeholder={error ? 'Update your text here...' : 'Your text here...'}
        value={error ? '' : value}
        onChange={e => setTextValue({ value: e.target.value, error: false })}
      />
      <Spacer />
    </>
  );
};
