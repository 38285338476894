// convert hex colors to rgb
export const hexToRgb = (hex: string): [number, number, number] => [
  +`0x${hex[1]}${hex[2]}`,
  +`0x${hex[3]}${hex[4]}`,
  +`0x${hex[5]}${hex[6]}`,
];

// convert rgb color to hex
export const rgbToHex = (rgb: [number, number, number]) =>
  `#${rgb
    .map(c => {
      if (c > 255 || c < 0) {
        throw new Error(
          'Each channel in the RGB array should be a ' +
            'floating point number between 0 and 255, inclusive'
        );
      }
      const _c = c.toString(16);
      return _c.length === 1 ? `0${_c}` : _c;
    })
    .join('')}`;

// returns 0-1 luminance of any rgb value
export const luminance = (rgb: [number, number, number]) => {
  const a = rgb.map(v => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : (v + 0.055) / 1.055 ** 2.4;
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};
