import React from 'react';
import classNames from 'classnames';
import './CalendarInputDay.scss';

export const CalendarInputDay = ({
  day,
  isInMonth,
  isSubdued,
  isDisabled,
  isStart,
  isEnd,
  isInRange,
  isHovered,
  isHoveredStart,
  isHoveredEnd,
  isInHoveredRange,
  onClick,
  onHoverChange,
  editableDates = true,
}: {
  day: Date;
  isInMonth: boolean;
  isSubdued: boolean;
  isDisabled: boolean;
  isStart: boolean;
  isEnd: boolean;
  isInRange: boolean;
  isHovered: boolean;
  isHoveredStart: boolean;
  isHoveredEnd: boolean;
  isInHoveredRange: boolean;
  editableDates?: boolean;
  onClick: (clickedDay: Date) => void;
  onHoverChange: (hovered: boolean, targetDay: Date) => void;
}) => {
  return (
    <button
      type="button"
      className={classNames('CalendarInputDay', {
        'CalendarInputDay--is-in-month': isInMonth,
        'CalendarInputDay--is-hovered': isHovered,
        'CalendarInputDay--is-subdued': isSubdued,
        'CalendarInputDay--is-disabled': isDisabled,
      })}
      onClick={() => editableDates && onClick(day)}
      onMouseEnter={() => onHoverChange(true, day)}
      onMouseLeave={() => onHoverChange(false, day)}
      onFocus={() => onHoverChange(true, day)}
      onBlur={() => onHoverChange(false, day)}
      disabled={isDisabled}
    >
      <div className="CalendarInputDay__aspect-ratio" aria-hidden />

      {isInRange && (
        <div
          className={classNames('CalendarInputDay__range-bg', {
            'CalendarInputDay__range-bg--start': isStart,
            'CalendarInputDay__range-bg--end': isEnd,
          })}
          aria-hidden
        />
      )}

      {isInHoveredRange && (
        <div
          className={classNames('CalendarInputDay__hovered-range-bg', {
            'CalendarInputDay__hovered-range-bg--start': isHoveredStart,
            'CalendarInputDay__hovered-range-bg--end': isHoveredEnd,
          })}
          aria-hidden
        />
      )}

      <div className="CalendarInputDay__label" style={{ color: isInRange ? 'white' : '#293235' }}>
        {day.getDate()}
      </div>
    </button>
  );
};
