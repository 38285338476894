import { authRoles } from 'routes';
import { TFileEvent } from 'types';
import moment from 'moment';

export const removeKeyFromObject = (key: string, parentObject: { [key: string]: any }) => {
  const filteredObject = Object.keys(parentObject)
    .filter((parentKey: string) => parentKey !== key)
    .reduce((acc: { [key: string]: any }, curr: string) => {
      return {
        ...acc,
        [curr]: parentObject[curr],
      };
    }, {});
  return filteredObject;
};

// returns an abbreviated string without zeros
// Ex: basicAbbreviation(1, '1230000') returns 1.23
// useful for formatting larger numbers from user inputs
export const basicAbbreviation = (sliceAt: number, input: string) => {
  const startingNumber = input.slice(0, sliceAt);
  const remainder = input.slice(-1 * (input.length - startingNumber.length));
  return parseFloat(`${startingNumber}.${remainder}`.replace(/^0+/g, ''));
};

// returns large numbers into more readable strings
export const getAbbreviatedNumber = (input: string) => {
  const parsedNumber = parseInt(input, 10);
  // if greater than 1 mill, create mill abbreviation
  if (parsedNumber >= 1000000) {
    return `${basicAbbreviation(-6, input).toFixed(2)} mil`;
  }
  // if greater than 1k, create thousands abbreviation
  if (parsedNumber >= 1000) {
    return `${basicAbbreviation(-3, input)}k`;
  }
  return input;
};

export const getFormData = (file: any, name = 'files') => {
  const formData = new FormData();
  // formData.append(name, file.target.files[0])
  formData.append(name, file);
  return formData;
};

const createImage = (_url: any) =>
  new Promise((resolve, reject) => {
    const _image = new Image();
    _image.addEventListener('load', () => resolve(_image));
    _image.addEventListener('error', error => reject(error));
    _image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    _image.src = _url;
  });

function getRadianAngle(degreeValue: any) {
  return (degreeValue * Math.PI) / 180;
}

export const getCroppedImg = async (imageSrc: any, pixelCrop: any, rotation = 0) => {
  const _image: any = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx: any = canvas.getContext('2d');
  const maxSize = Math.max(_image.width, _image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.fillStyle = '#DCDCDC';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(_image, safeArea / 2 - _image.width * 0.5, safeArea / 2 - _image.height * 0.5);
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + _image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + _image.height * 0.5 - pixelCrop.y)
  );
  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise(resolve => {
    canvas.toBlob(file => {
      resolve(file);
    }, 'image/png');
  });
};

export const getBase64 = (event: TFileEvent, callback: (base64: any) => void) => {
  // eslint-disable-next-line prefer-destructuring
  const file = event.target.files[0];
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    callback(reader.result);
  };
  reader.onerror = error => {
    // eslint-disable-next-line no-console
    console.log('create base64 error', error);
  };
};

export const getLocalUser = () => {
  const user = localStorage.getItem('user');
  return user ? JSON.parse(atob(user)) : null;
};

export const userRole = (value: number | undefined) => {
  if (value === 1 && localStorage.getItem('beewo_token') && !localStorage.getItem('user_view')) {
    return 'super_admin';
  }
  if (value === 0 && localStorage.getItem('beewo_token') && localStorage.getItem('user_view')) {
    return authRoles.ultraAdmin;
  }
  if (value === 0 && localStorage.getItem('beewo_token') && !localStorage.getItem('user_view')) {
    return 'real_estate';
  }
  return '';
};

export const removeTimeFromDate = (datetime: Date | string) => {
  return moment(datetime).format('MM/DD/YYYY');
};

// function for url validation
export const validateURL = (url: string): boolean => {
  /* eslint-disable */
  const regex = new RegExp(
    /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i
  );
  return regex.test(url);
};

// export const url_validation = (url: string) => {
//   // const regex = /^(ftp|http|https):\/\/[^ "]+$/
//   const regex = /\.([A-Za-z0-9_-]*?)\./
//   const isValid = regex.test(url)
//   return isValid
// }

export const get_email_validation = (url: string) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isValid = regex.test(url);
  return isValid;
};

export const getFileName = (name: string) => {
  const newName: string[] = name.split('__');
  return newName[newName.length - 1];
};

export const capitalized = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const getCamelCase = (name: string) => {
  const cityname: string = name.toLowerCase().replace(/[^a-zA-Z1-9](.)/g, (m, chr) => {
    return chr.toUpperCase();
  });
  return capitalized(cityname);
};
