import React, { CSSProperties } from 'react';
import './ErrorMsg.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type ErrorMsgType = {
  title?: string;
  icon?: JSX.Element;
  iconStyle?: CSSProperties;
};
export const ErrorMsg: React.FC<ErrorMsgType> = ({ title, icon, iconStyle }) => {
  return (
    <div style={iconStyle} className="setPasswordError">
      <div className="setPasswordErrorIcon">{icon}</div>
      <div>
        <p className="setPasswordErrorMsg">{title}</p>
      </div>
    </div>
  );
};

export default ErrorMsg;
