import React from 'react';
import type { FC } from 'react';

import { PieChart } from 'react-minimal-pie-chart';

import { Text } from '@eltoro-ui/components';
import { Section } from 'Components';

import nodata from 'assets/Images/nodata.png';

import type { AllOrderlinesStats } from 'types';

import './ProgressCampaign.scss';

interface ProgressCampaignDataType extends Pick<AllOrderlinesStats, 'In Review' | 'Active'> {}

export const ProgressCampaign: FC<{ data: ProgressCampaignDataType }> = ({ data }) => {
  const compaignTypeDataSchema = {
    pieData: [
      { title: 'In Review', value: data['In Review'] || 0, color: '#E3E0E0' },
      { title: 'Active', value: data.Active || 0, color: '#20C412' },
    ],
    context: [
      {
        value: data['In Review'] || 0,
        label: 'In Review',
        backgroundColor: '#E3E0E0',
      },
      {
        value: data.Active || 0,
        label: 'Active',
        backgroundColor: '#20C412',
      },
    ],
  };
  const isCompaignTypeDataSchema = data;

  // const verticalData = {
  //   datasets: [
  //     {
  //       label: 'Pending',
  //       data: [data?.pending || 0],
  //       backgroundColor: '#44C2E6',
  //       hoverBackgroundColor: '#44C2E6',
  //     },
  //     {
  //       label: 'Processed',
  //       data: [data?.processed || 0],
  //       backgroundColor: '#007B94',
  //       hoverBackgroundColor: '#007B94 ',
  //     },
  //   ],
  // }

  return (
    <Section
      title="In-Progress Ads"
      UNSAFE_className_container="CampaignDashboard"
      UNSAFE_className_text="CampaignDashboard__title"
    >
      <div className="CampaignDashboard__in-progress-campaign-body">
        <div className="CampaignDashboard__in-progress-campaign-bar">
          {isCompaignTypeDataSchema['In Review'] === 0 && isCompaignTypeDataSchema.Active === 0 ? (
            <div style={{ flexDirection: 'column' }} className="nodata-inprogress">
              <img style={{ width: '100px' }} src={nodata} alt="nodata" />
              <Text on="tertiary-300" UNSAFE_className="noDataText">
                No Data Found
              </Text>
            </div>
          ) : (
            <PieChart
              className="CampaignDashboard__inProgressPieChart"
              lineWidth={25}
              labelPosition={0}
              data={compaignTypeDataSchema.pieData}
            />
          )}
        </div>
        <div className="CampaignDashboard__in-progress-campaign-titles">
          {compaignTypeDataSchema.context.map((value, index) => {
            return (
              <div key={index} className="progress_btn">
                <div key={value.label} className="campaignType__context_container">
                  <div
                    className="CampaignDashboard__in-progress-campaign-circle"
                    style={{
                      backgroundColor: value.backgroundColor,
                    }}
                  />
                  <Text UNSAFE_className="campaignType__label" size="m" on="white">
                    <span className="campaignType__value"> {Math.round(value.value)}</span> -{' '}
                    {value.label}
                  </Text>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Section>
  );
};
