import { DimensionsType } from '../Typings';

// these are final printed measurements. Bleeds are to be added when...
// ...calculating final size as seen in `direct-mail-helpers.ts`
export const directMailInches: { [key: string]: DimensionsType } = {
  small: {
    width: 6,
    height: 4.25,
  },
  medium: {
    width: 9,
    height: 6,
  },
  large: {
    width: 11,
    height: 6,
  },
  'extra-large': {
    width: 11,
    height: 8.5,
  },
};

// dimensions from https://www.uniquelitho.com/page/postcard-design-specifications
export const stampSizeInches = {
  width: 1.125,
  height: 1.25,
};

export const postalAreaInches = {
  width: 3.25,
  height: 1.5,
};

export const postalAreaSpacerInches = {
  width: 0.5,
  height: 0.625,
};
