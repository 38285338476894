import React, { useState, useRef, useEffect } from 'react';
import type { ReactNode } from 'react';

import classNames from 'classnames';
import Tippy from '@tippyjs/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { Checkbox } from '@eltoro-ui/components/Checkbox';
import { TableCell } from '@eltoro-ui/components/TableCell';
import { RadioButtonCustom } from '@eltoro-ui/components/RadioButtonCustom';

import type { TableColumnType } from '@eltoro-ui/components/Table';

import type { AnyObject } from 'types';

import './TableRow.scss';

type TableRowType<RecordType> = {
  row: RecordType;
  columns: TableColumnType<RecordType>[];
  altBg?: boolean;
  ExpandableRow?: (row: RecordType) => ReactNode;
  selected?: any;
  handleSelectRow?: (row: RecordType, checked: boolean) => void;
  getColspan: () => number;
  striped?: boolean;
  tableRowClass?: string;
  checkBorder?: string | undefined;
  checkBoxActive?: string | undefined;
  noCheckbox?: boolean;
  radioButton?: boolean;
  disabledClassName?: string;
};

export const TableRow = <RecordType extends AnyObject = AnyObject>({
  row,
  columns,
  altBg,
  ExpandableRow,
  selected,
  handleSelectRow,
  getColspan,
  striped,
  tableRowClass,
  checkBorder,
  checkBoxActive,
  noCheckbox,
  radioButton,
  disabledClassName,
}: TableRowType<RecordType>) => {
  const [expanded, setExpanded] = useState(false);
  const [rowWidth, setRowWidth] = useState<number>();

  const colWidth = rowWidth ? `${rowWidth / columns.length}px` : undefined;

  const _handleExpand = () => {
    if (ExpandableRow) {
      setExpanded(!expanded);
    }
  };

  const widthRef = useRef<HTMLTableRowElement>(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setRowWidth(widthRef.current?.offsetWidth);
    });
    if (widthRef.current) {
      resizeObserver.observe(widthRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <>
      <Tippy
        className="audience-pending-tooltip"
        placement="top"
        hideOnClick={false}
        disabled={!disabledClassName}
        content="Your audience creation is in process. Please refresh after a few minutes."
      >
        <tr
          className={classNames(
            'TableRow',
            altBg && 'TableRow--alternate',
            selected && 'TableRow--selected',
            disabledClassName
          )}
          ref={widthRef}
        >
          {handleSelectRow && (
            <td className="TableRow__checkbox">
              {!noCheckbox && (
                <Checkbox
                  disabled={!!disabledClassName}
                  checkBorder={checkBorder}
                  checkBoxActive={checkBoxActive}
                  checked={selected || false}
                  onChange={checked => handleSelectRow(row, checked)}
                />
              )}
              {radioButton && (
                <RadioButtonCustom
                  isCircular
                  checkBorder={checkBorder}
                  RadioButtonCustomActive={checkBoxActive}
                  checked={selected || false}
                  onChange={checked => handleSelectRow(row, checked)}
                />
              )}
            </td>
          )}
          {ExpandableRow && (
            <td className="TableRow__expand" aria-label="td">
              <button
                aria-label="expand"
                className="TableRow__caret"
                type="button"
                onClick={() => _handleExpand()}
              >
                <FontAwesomeIcon icon={expanded ? faAngleDown : faAngleRight} />
              </button>
            </td>
          )}
          {columns.map((col, index) => {
            return (
              <TableCell
                key={JSON.stringify(row) + index}
                column={col}
                row={row}
                colWidth={colWidth}
                tableRowClass={tableRowClass}
              />
            );
          })}
        </tr>
      </Tippy>
      {expanded ? (
        <tr className="TableRow__expansion">
          <td colSpan={getColspan()} className="TableExpandedRow__column">
            {ExpandableRow && ExpandableRow(row)}
          </td>
        </tr>
      ) : (
        !striped && <tr className="TableRow__spacer" />
      )}
    </>
  );
};
