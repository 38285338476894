import React from 'react';
import { FlexBox, Text } from '@eltoro-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faStar } from '@fortawesome/free-solid-svg-icons';

export const SummaryTotal: React.FC<{
  total: number;
  isNegative?: Boolean;
  discount?: number;
}> = ({ total, isNegative, discount }) => {
  return (
    <FlexBox alignItems="center" justifyContent="space-between">
      <Text size="xl" on="white" weight="bold">
        <span style={{ color: '#2B1F0A', fontWeight: 700 }}>TOTAL</span>
      </Text>
      <Text size="xl" on="white" weight="bold" tag="div">
        <FontAwesomeIcon icon={faDollarSign} color="#FFAB03" style={{ width: '20px' }} />
        <span
          style={{
            color: isNegative ? '#FFAB03' : '#FFAB03',
            fontWeight: 700,
          }}
        >
          {isNegative ? ' -' : ''} {total.toLocaleString()}
        </span>
      </Text>
    </FlexBox>
  );
};
