import React, { FC } from 'react';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Pagination.scss';

type PagenationPropsType = {
  loading: boolean;
  totalItems: number;
  currentPage: number;
  totalPages: number;
  title?: string;
  onClickPrev: () => void;
  onClickNext: () => void;
  rangeOfItems?: number | string;
};

export const Pagination: FC<PagenationPropsType> = ({
  loading,
  totalItems,
  currentPage,
  totalPages,
  title,
  onClickPrev,
  onClickNext,
  rangeOfItems = 20,
}) => {
  const getCurrentItemsCount = () => {
    let start;
    let limit;

    if (rangeOfItems) {
      start = currentPage * +rangeOfItems - (+rangeOfItems - 1);
      limit = currentPage === totalPages ? totalItems : currentPage * +rangeOfItems;
    } else {
      start = currentPage * 10 - 9;
      limit = currentPage === totalPages ? totalItems : currentPage * 10;
    }

    return `${start < 10 ? `0${start}` : start} - ${limit < 10 ? `0${limit}` : limit}`;
  };

  return (
    <div className="pagination_container">
      <div>
        <button
          aria-label="back"
          type="button"
          disabled={currentPage === 1 || loading}
          className="button"
          data-testid="backClick"
          onClick={onClickPrev}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <span>{getCurrentItemsCount()}</span>
        <button
          aria-label="forward"
          type="button"
          className="button"
          data-testid="forwardClick"
          disabled={currentPage === totalPages || loading}
          onClick={onClickNext}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>

      {/* <p className="title">{`${
        totalItems < 10 ? `0${totalItems}` : totalItems
      } ${title}`}</p> */}
      <p className="title" style={{ marginTop: '3px' }}>
        page <b>{currentPage}</b> of <b>{totalPages}</b>
      </p>
    </div>
  );
};
