import { Checkbox, Spacer } from '@eltoro-ui/components';
import React from 'react';
import { AlphaPicker } from 'react-color';
import { useRecoilState } from 'recoil';
import {
  blockShadowBlurSelector,
  blockShadowColorSelector,
  blockShadowVisibleSelector,
  blockShadowOpacitySelector,
} from '../../../State';
import { BlocksConfigType } from '../../../Typings';
import { ColorSelect } from '../ColorSelect';
import { HorizontalLabel } from '../HorizontalLabel';
import { DropShadowOffset } from './components';
import './DropShadowSettings.scss';

export const DropShadowSettings: React.FC<{ config: BlocksConfigType }> = ({ config }) => {
  const [enabled, setEnabled] = useRecoilState(blockShadowVisibleSelector(config));
  const [color, setColor] = useRecoilState(blockShadowColorSelector(config));
  const [blur, setBlur] = useRecoilState(blockShadowBlurSelector(config));
  const [opacity, setOpacity] = useRecoilState(blockShadowOpacitySelector(config));

  return (
    <div className="DropShadowSettings">
      <Checkbox
        label="Enable"
        checked={enabled.error ? false : enabled.value}
        onChange={checked => setEnabled({ value: checked, error: false })}
      />
      <Spacer />
      <HorizontalLabel label="Color">
        <ColorSelect color={color.value} onChange={c => setColor({ value: c.hex, error: false })} />
      </HorizontalLabel>
      <Spacer />
      <HorizontalLabel label="Blur">
        <input
          className="DropShadowSettings__number-input"
          type="number"
          value={blur.value}
          onChange={e => setBlur({ value: parseInt(e.target.value, 10), error: false })}
          min="0"
        />
      </HorizontalLabel>
      <Spacer />
      <HorizontalLabel label="Opacity">
        <Spacer />
        <AlphaPicker
          color={`rgba(0,0,0,${opacity.error ? 0.5 : opacity.value})`}
          onChange={c => setOpacity({ value: c.hsl.a || 0.5, error: false })}
        />
      </HorizontalLabel>
      <HorizontalLabel label="Position">
        <DropShadowOffset config={config} />
      </HorizontalLabel>
    </div>
  );
};
