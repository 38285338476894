import React, { useState } from 'react';
import type { ReactNode } from 'react';

import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import './LensSidebarHeader.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { setCenterLens } from 'Redux/actions';
import ListingToggle from './elements/ListingToggle';

export type LensSidebarHeaderProps = {
  heading?: string;
  subHeading?: string | ReactNode;
  toggle?: boolean;
  isToggleOpen?: boolean;
  toggleText?: string | ReactNode;
  isToggle?: (val: boolean) => void;
};

export const LensSidebarHeader = ({
  heading,
  subHeading,
  toggle,
  toggleText = 'Show also inactive (sold) listings',
  isToggleOpen,
  isToggle,
}: LensSidebarHeaderProps) => {
  const dispatch = useDispatch();

  return (
    <div className="LensSidebarHeader">
      <Link
        className="LensSidebarHeader__back-button"
        onClick={() => dispatch(setCenterLens(false))}
        to="/prospects"
      >
        <FontAwesomeIcon icon={faLongArrowLeft} /> Go Back
      </Link>
      <div className="LensSidebarHeader__heading">{heading}</div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginBlockEnd: '8px' }}>
        {subHeading && <div className="LensSidebarHeader__sub-heading">{subHeading}</div>}
        {toggle && (
          <ListingToggle isToggle={isToggle} isToggleOpen={isToggleOpen} toggleText={toggleText} />
        )}
      </div>
    </div>
  );
};
