import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import moment from 'moment';
import toast from 'react-hot-toast';

import type { InputTextProps } from 'primereact/inputtext';

import { PhoneNumberUtil } from 'google-libphonenumber';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

import { Button, Modal, Form } from '@eltoro-ui/components';
import { useForm, useValidatedState } from '@eltoro-ui/hooks';
import { Loading } from 'Components';
import { VotingModal } from 'Components/VotingModal';

import UserCity from 'assets/Images/userCity.png';
import Usericon from 'assets/Images/usericon.png';
import Usernumber from 'assets/Images/usernumber.png';
import UserState from 'assets/Images/state.png';
import Usermail from 'assets/Images/usermail.png';
import UserZipcode from 'assets/Images/userzipcode.png';
import Useraddress from 'assets/Images/useraddress.png';
import ContactInfoIcon from 'assets/Images/contactinformationedit.png';

import { ContactInformation } from 'Pages/MyContacts/components/ContactInformation/ContactInformation';

import type { TContact, TContactAddress, TContactAddressKeys } from 'types';

import { getContactDetailById } from 'Requests';
import { editContact } from 'Requests/Request_Methods/contactsMethods';

import './ContactDetail.scss';

export const ContactDetail = () => {
  const phoneUtil = PhoneNumberUtil.getInstance();

  const { contactId } = useParams<{ contactId: string }>();
  const [contactInfo, setContactInfo] = useState<TContact | null>(null);
  const [editContactLoading, setEditContactLoading] = useState<boolean>(false);
  const [editContactModal, setEditContactModal] = useState(false);
  const history = useHistory();

  // form state
  const [readOnly, setReadOnly] = useState<boolean>(true);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState<boolean>(true);

  const [firstName, setFistName] = useState<TContact['first_name']>(contactInfo?.first_name ?? '');
  const [lastName, setLastName] = useState<TContact['last_name']>(contactInfo?.last_name ?? '');

  const [email, setEmail, isValidEmail] = useValidatedState(contactInfo?.email ?? '', 'email');

  const [addresses, setAddresses] = useState<TContact['addresses']>(contactInfo?.addresses ?? []);

  const [phoneNumber, setPhoneNumber] = useState<TContact['phone']>(contactInfo?.phone ?? '');

  const { isValidForm, touched } = useForm({
    firstName,
    lastName,
    email,
    addresses,
    phoneNumber,
  });

  const { validForm } = isValidForm([isValidEmail, isValidPhoneNumber]);

  const onSubmit = async () => {
    try {
      setEditContactLoading(true);
      const data = {
        ...contactInfo,
        first_name: firstName,
        last_name: lastName,
        email,
        addresses,
        phone: phoneNumber,
      };
      await editContact(data, +contactId);
      await getContact();
      setReadOnly(true);
    } catch (e) {
      toast.error(e);
    } finally {
      setEditContactLoading(false);
    }
  };

  const getContact = () => {
    getContactDetailById(contactId).then(({ data }) => {
      if (data) {
        const {
          first_name,
          last_name,
          email: contact_email,
          addresses: contact_addresses,
          phone,
        } = data;

        setContactInfo(data);
        setFistName(first_name);
        setLastName(last_name);
        setEmail(contact_email);
        setAddresses(contact_addresses);
        setPhoneNumber(phone);

        if (phone) {
          const number = phoneUtil.parseAndKeepRawInput(phone, 'US');
          setIsValidPhoneNumber(phoneUtil.isValidNumberForRegion(number, 'US'));
        }
      }
    });
  };

  useEffect(() => {
    getContact();
  }, []);

  const onChangeFirstName: InputTextProps['onChange'] = e => setFistName(e.target.value);
  const onChangeLastName: InputTextProps['onChange'] = e => setLastName(e.target.value);
  const onChangeEmail: InputTextProps['onChange'] = e => setEmail(e.target.value);

  const onChangePhoneNumber: InputTextProps['onChange'] = e => {
    let value = e.target.value.replace(/[^0-9() -]+/g, '');
    setPhoneNumber(value);

    if (value) {
      try {
        const number = phoneUtil.parseAndKeepRawInput(value, 'US');
        setIsValidPhoneNumber(phoneUtil.isValidNumberForRegion(number, 'US'));
      } catch (e) {
        setIsValidPhoneNumber(false);
      }
    } else setIsValidPhoneNumber(true);
  };

  const onChangeAddress =
    (
      contact_address_uuid: TContactAddress['contact_address_uuid'],
      key: TContactAddressKeys
    ): InputTextProps['onChange'] =>
    e => {
      setAddresses(contactAddresses => {
        return contactAddresses.map(contactAddress => {
          if (contactAddress.contact_address_uuid === contact_address_uuid) {
            contactAddress[key] = e.target.value;
            return contactAddress;
          }
          return contactAddress;
        });
      });
    };

  const disabled =
    !firstName &&
    !lastName &&
    !email &&
    !phoneNumber &&
    addresses.every(
      ({ street, state, city, postal_code }) => !street && !state && !city && !postal_code
    );

  const onClickEditContact = () => setReadOnly(false);

  return (
    <Form touched={touched}>
      <div className="screen-inline">
        <div className="contactDetail__head">
          <div className="contactDetail__headIcon">
            <Button
              UNSAFE_className="back_icon"
              type="button"
              iconLeft={<FontAwesomeIcon icon={faAngleLeft} color="#2B1F0A" />}
              onClick={() => {
                history.goBack();
              }}
            />
            <h3 className="edit_information_title">Contact Details</h3>
          </div>
          {contactInfo && !contactInfo?.enrichment_data && (
            <Button
              kind="primary"
              size="l"
              weight="bold"
              iconLeft={
                editContactLoading ? (
                  <FontAwesomeIcon
                    icon={faSpinnerThird}
                    style={{ fontSize: 20, animation: 'spinner-border 0.75s linear infinite' }}
                  />
                ) : null
              }
              disabled={(!readOnly && !validForm) || (!readOnly && disabled) || editContactLoading}
              onClick={readOnly ? onClickEditContact : onSubmit}
            >
              {readOnly ? 'Edit Contact' : 'Update'}
            </Button>
          )}
        </div>
        {editContactModal && (
          <Modal offClick={() => setEditContactModal(false)}>
            <VotingModal
              handleModel={() => setEditContactModal(false)}
              icons={ContactInfoIcon}
              title="Contact Information Edit"
              subTitle="Change contact information as needed to keep your notifications up to date."
            />
          </Modal>
        )}

        {contactInfo ? (
          <>
            <div className="contactDetail__content">
              <div className="contactDetail__content__row">
                <h4 className="contactDetail__content__row__title">Personal Information</h4>
                <div className="contactDetail__content__row__inputs">
                  <ContactInformation
                    readOnly={readOnly}
                    icon={Usericon}
                    title="First Name"
                    content={firstName}
                    onChange={onChangeFirstName}
                  />

                  <ContactInformation
                    readOnly={readOnly}
                    icon={Usericon}
                    title="Last Name"
                    content={lastName}
                    onChange={onChangeLastName}
                  />

                  <ContactInformation
                    readOnly={readOnly}
                    icon={Usermail}
                    title="Email Address"
                    content={email}
                    onChange={onChangeEmail}
                    error={!isValidEmail ? 'Invalid email address' : ''}
                  />

                  <ContactInformation
                    readOnly={readOnly}
                    icon={Usernumber}
                    title="Phone Number"
                    content={phoneNumber}
                    onChange={onChangePhoneNumber}
                    error={!isValidPhoneNumber ? 'Invalid phone number!' : ''}
                  />
                </div>
              </div>

              {contactInfo?.addresses.map(
                ({ street, state, city, postal_code, contact_address_uuid }, index) => {
                  return (
                    <div key={contact_address_uuid} className="contactDetail__content__row">
                      <h4 className="contactDetail__content__row__title">
                        Address Line {index + 1}
                      </h4>
                      <div className="contactDetail__content__row__inputs">
                        <ContactInformation
                          readOnly={readOnly}
                          icon={Useraddress}
                          title="Address"
                          content={street}
                          onChange={onChangeAddress(contact_address_uuid, 'street')}
                        />

                        <ContactInformation
                          readOnly={readOnly}
                          icon={UserZipcode}
                          title="Zip Code"
                          content={postal_code}
                          onChange={onChangeAddress(contact_address_uuid, 'postal_code')}
                        />

                        <ContactInformation
                          readOnly={readOnly}
                          icon={UserCity}
                          title="City"
                          content={city}
                          onChange={onChangeAddress(contact_address_uuid, 'city')}
                        />

                        <ContactInformation
                          readOnly={readOnly}
                          icon={UserState}
                          title="State"
                          content={state}
                          onChange={onChangeAddress(contact_address_uuid, 'state')}
                        />
                      </div>
                    </div>
                  );
                }
              )}
            </div>
            <div className="contactDetail__info">
              <div className="contactDetail__info__container">
                <span className="contactDetail__info_title">Date Uploaded:</span>
                <span className="contactDetail__info_value">
                  {moment(contactInfo.date_uploaded).format('MM/DD/YYYY')}
                </span>
              </div>
              <div className="contactDetail__info__container">
                <span className="contactDetail__info_title">Upload Source:</span>
                <span className="contactDetail__info_value">
                  {contactInfo.device.charAt(0).toUpperCase() + contactInfo.device.slice(1)}
                </span>
              </div>
            </div>
          </>
        ) : (
          <div>
            <Loading />
          </div>
        )}
      </div>
    </Form>
  );
};
