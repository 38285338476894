import React, { forwardRef } from 'react';
import type { ForwardedRef, ReactNode, MouseEvent, Ref, MutableRefObject } from 'react';
import { Link } from 'react-router-dom';
import type { LinkProps } from 'react-router-dom';

import classNames from 'classnames';
import * as CSS from 'csstype';

import { SpinnerLoader } from 'Components/SpinnerLoader';

import './Button.scss';

type ButtonProps = {
  children?: string | ReactNode;
  iconLeft?: ReactNode | null;
  iconRight?: ReactNode | null;
  content?: ReactNode;
  UNSAFE_className?: string;
  rounded?: boolean;
  type?: 'button' | 'submit' | 'file';
  kind?: 'default' | 'primary' | 'danger' | 'text';
  size?: 's' | 'm' | 'l' | 'xl';
  UNSAFE_TEXTSTYLE?: string;
  width?: string;
  UNSAFE_style?: CSS.Properties;
  fonts?: undefined | string;
  weight?: CSS.Property.FontWeight | undefined;
  disabled?: any;
  onMouseOver?: (event?: MouseEvent) => void;
  onMouseOut?: (event?: MouseEvent) => void;
  name?: undefined | string;
  data_testid?: string;
  loading?: boolean;
  Text?: string;
} & (
  | { onClick?: (event?: MouseEvent) => void; to?: undefined; replace?: undefined }
  | { onClick?: undefined; to?: LinkProps['to']; replace?: LinkProps['replace'] }
);
type RefProps =
  | {
      buttonRef?: Ref<HTMLButtonElement> | MutableRefObject<HTMLButtonElement>;
      linkRef?: undefined;
    }
  | {
      linkRef?: Ref<HTMLAnchorElement> | MutableRefObject<HTMLAnchorElement>;
      buttonRef?: undefined;
    };

export const Button = forwardRef(
  (
    {
      children,
      iconLeft,
      iconRight,
      content,
      onClick,
      weight,
      UNSAFE_className,
      rounded = false,
      type,
      kind = 'default',
      size = 'm',
      width,
      UNSAFE_style,
      fonts,
      disabled = false,
      onMouseOver,
      onMouseOut,
      to,
      replace,
      buttonRef,
      linkRef,
      name,
      UNSAFE_TEXTSTYLE,
      data_testid,
      loading,
      ...props
    }: ButtonProps & RefProps,
    ref: ForwardedRef<any>
  ) => {
    const sizeClass = ` Button--${size}-size`;

    const wrapperClasses = classNames(
      'Button',
      sizeClass,
      {
        'Button--primary': kind === 'primary',
        'Button--danger': kind === 'danger',
        'Button--text': kind === 'text',
        'Button--disabled': disabled,
        'Button--rounded': rounded,
        'Button--has-right-icon': !!iconRight,
        'Button--has-left-icon': !!iconLeft,
      },
      UNSAFE_className
    );

    const handleMouseOver = (event: React.MouseEvent) => {
      if (onMouseOver) {
        onMouseOver(event);
      }
    };
    const handleMouseOut = (event: React.MouseEvent) => {
      if (onMouseOut) {
        onMouseOut(event);
      }
    };

    const handleClick = (event: React.MouseEvent) => {
      if (onClick && !disabled) {
        onClick(event);
      }
    };

    const customStyle: CSS.Properties = { width, ...UNSAFE_style };

    const RenderIcon = ({ icon }: { icon: ReactNode }) => <>{icon}</>;

    const buttonContent = (
      <>
        {iconLeft ? (
          <div className="Button__icon-wrapper">
            <RenderIcon icon={iconLeft} />
          </div>
        ) : null}
        {children ? (
          <div>
            <span
              className={`Button__text ${UNSAFE_TEXTSTYLE}`}
              style={{ font: fonts, fontWeight: weight }}
            >
              {children}
              {content}
            </span>
          </div>
        ) : null}
        {iconRight ? (
          <div className="Button__icon-wrapper">
            <RenderIcon icon={iconRight} />
          </div>
        ) : null}
      </>
    );

    const commonProps = {
      onMouseOver: handleMouseOver,
      onMouseOut: handleMouseOut,
      style: customStyle,
      onFocus: () => false,
      onBlur: () => false,
    };

    const linkProps = {
      ...commonProps,
      ref: ref || linkRef,
      className: `${wrapperClasses} Button__link`,
    };

    const buttonProps = {
      ...commonProps,
      onClick: handleClick,
      disabled,
      type: type || 'button',
      className: wrapperClasses,
      ref: ref || buttonRef,
      width,
    };

    const renderButton = (passedType: 'button' | 'submit' | 'file' | undefined) => {
      if (passedType === 'submit') {
        return (
          <button {...buttonProps} {...props} style={{ ...customStyle }} type="submit">
            {loading ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <SpinnerLoader className="button_loading" />
                {buttonContent}
              </div>
            ) : (
              buttonContent
            )}
          </button>
        );
      }

      return (
        <button
          data-testid={data_testid}
          {...buttonProps}
          {...props}
          type="button"
          style={{
            ...customStyle,
            display: 'flex',
            justifyContent: 'center',
          }}
          name={name}
        >
          {loading ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SpinnerLoader className="button_loading" />
              {buttonContent}
            </div>
          ) : (
            buttonContent
          )}
        </button>
      );
    };

    return to && !disabled ? (
      <Link {...linkProps} {...props} to={to} replace={replace}>
        {buttonContent}
      </Link>
    ) : (
      renderButton(type)
    );
  }
);
