import { Button, Select, Text } from '@eltoro-ui/components';
import React, { useEffect, useState } from 'react';
import './FindYourProspectFilters.scss';

export const SquareFootFilter = ({
  modalOpen,
  setIsModalOpen,
  setSquareFeet,
  currentFilters,
  postedFilters,
  setPostedFilter,
  setIsClicked,
  isClicked,
}: {
  modalOpen: any;
  setIsModalOpen: any;
  setSquareFeet: any;
  currentFilters: any;
  postedFilters: any;
  setPostedFilter: any;
  setIsClicked?: any;
  isClicked?: any;
}) => {
  const [areaRange, setAreaRange] = useState<any>({
    value: currentFilters.squareFoot,
  });
  const [postedRange, setPostedRange] = useState<any>({
    value: currentFilters.squareFoot?.replaceAll(' Sqft', ''),
  });
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (areaRange.value || postedRange.value) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [areaRange, postedRange]);

  const onClickCancelOrClear = (type: string) => {
    setIsModalOpen({ ...modalOpen, squareFoot: false });
    if (type === 'Clear') {
      setAreaRange({ ...areaRange, value: null });
      setPostedRange({
        ...postedRange,
        value: null,
      });
      setSquareFeet({
        ...currentFilters,
        squareFoot: null,
      });
      setPostedFilter({
        ...postedFilters,
        squareFoot: null,
      });
      if (areaRange?.value || currentFilters?.squareFoot) {
        setIsClicked({ ...isClicked, isLoaded: false });
      }
    }
    // show prospects box on cancel
    // else {
    //   setIsClicked({ ...isClicked, isLoaded: true })
    // }
  };

  const onSaveChanges = () => {
    setIsModalOpen({ ...modalOpen, squareFoot: false });
    setIsClicked({ ...isClicked, isLoaded: false });

    if (areaRange.value) {
      setSquareFeet({
        ...currentFilters,
        squareFoot: areaRange.value,
      });
      setPostedFilter({
        ...postedFilters,
        squareFoot: postedRange.value,
      });
    } else {
      setSquareFeet(currentFilters);
      setPostedFilter(postedFilters);
    }
  };

  return (
    <div className="FindYourProspectFilters__priceFilterConatiner">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Text size="m" tag="div" on="grey-500" UNSAFE_className="title">
          Square Foot
        </Text>
        <div className="title-divider" />
      </div>
      <br />
      <div className="FindYourProspectFilters__priceFilter" style={{ marginBottom: '20px' }}>
        <Select
          width="315px"
          Height="38px"
          radius="10px"
          size="18px"
          borderStyle="1px solid #E3E4E5"
          noCross
          isCenter
          placeholder={areaRange?.value || currentFilters?.squareFoot}
          options={[
            { label: `Any` },
            { label: `0 - 999 Sqft` },
            { label: `1000 Sqft - 1499 Sqft` },
            { label: '1500 Sqft - 1999 Sqft' },
            { label: '2000 Sqft - 2499 Sqft' },
            { label: '2500 Sqft - 2999 Sqft' },
            { label: '3000 Sqft - 3499 Sqft' },
            { label: '3500 Sqft - 3999 Sqft' },
            { label: '4000 Sqft - 4900 Sqft' },
          ]}
          selectedOption={areaRange?.value}
          onChange={(val: any) => {
            setAreaRange({ ...areaRange, value: val.label });
            setPostedRange({
              ...postedRange,
              value: val?.label.replaceAll(' Sqft', ''),
            });
          }}
          maxHeight={300}
        />
      </div>
      <hr />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button
          kind="default"
          size="l"
          UNSAFE_className="SellYourListingFaster__modalCancel"
          weight="bold"
          onClick={() => onClickCancelOrClear('Cancel')}
        >
          Cancel
        </Button>
        <Button
          kind="default"
          UNSAFE_className="SellYourListingFaster__modalSave"
          size="l"
          weight="bold"
          disabled={isDisabled}
          onClick={onSaveChanges}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
};
