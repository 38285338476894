import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const OAuthGoogle = () => {
  const location = useLocation();
  useEffect(() => {
    const proxyHost = 'https://api.cloudsponge.com/auth';

    function appendSearch() {
      let { search } = location;
      if (search && search[0] === '?') {
        search += '&';
      } else {
        search += '?';
      }
      search += 'state=_csAuth%3D1%26import_id%3Dproxy-test';
      return search;
    }

    // serializes an object into a query-string
    const serializeParams = (params: { [x: string]: string | number | boolean }) => {
      const results: string[] = [];
      Object.keys(params).forEach(k => {
        results.push(`${k}=${encodeURIComponent(params[k])}`);
      });
      return results.join('&');
    };

    // converts the URL string into an object
    const parseParams = (url: string) => {
      const obj = {};
      url.replace(/([^?=&]+)(=([^&]*))?/g, ($0, $1, $2, $3) => {
        // @ts-ignore
        return (obj[$1] = decodeURIComponent($3));
      });
      return obj;
    };

    // parse the query string

    const queryParams: any = parseParams(location.search);

    // selects just the OAuth-related params out of the query
    const oauthParams: any = {};
    Object.keys(queryParams).forEach(key => {
      if (
        key === 'code' ||
        key === 'state' ||
        key === 'error' ||
        key === 'error_code' ||
        key === 'forward'
      ) {
        // @ts-ignore
        oauthParams[key] = queryParams[key];
      }
    });

    // checks to see if this page actually the necessary received OAuth params
    // and then flings the state and code up to CloudSponge to do the heavy lifting work

    if (oauthParams.state && (oauthParams.code || oauthParams.error || oauthParams.error_code)) {
      // @ts-ignore
      window.location = `${proxyHost}?${serializeParams(oauthParams)}`;
    }

    // add the redirect endpoint to any .cs-proxy links on the page
    window.addEventListener(
      'load',
      () => {
        let i;
        const links = document.getElementsByClassName('cs-force');
        for (i = 0; i < links.length; i += 1) {
          // @ts-ignore
          links[i].href = proxyHost + appendSearch();
        }
      },
      false
    );
  }, [location]);

  return <div />;
};

export default OAuthGoogle;
