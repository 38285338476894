import { KonvaEventObject } from 'konva/types/Node';
import React from 'react';
import { Circle, Layer, Stage } from 'react-konva';
import { useRecoilState, useRecoilValue } from 'recoil';
import { withinMinMax } from '../../../../Helpers';
import {
  blockShadowColorSelector,
  blockShadowOffsetXSelector,
  blockShadowOffsetYSelector,
} from '../../../../State';
import { BlocksConfigType } from '../../../../Typings';

export const DropShadowOffset: React.FC<{ config: BlocksConfigType }> = ({ config }) => {
  const [offsetX, setOffsetX] = useRecoilState(blockShadowOffsetXSelector(config));
  const [offsetY, setOffsetY] = useRecoilState(blockShadowOffsetYSelector(config));
  const color = useRecoilValue(blockShadowColorSelector(config));

  const handleDragMove = (e: KonvaEventObject<DragEvent>) => {
    const circle = e.target;
    const layer = circle.getLayer();
    if (!(circle && layer)) return;
    const maxX = layer.width() - circle.attrs.radius;
    const maxY = layer.height() - circle.attrs.radius;
    // prevent circle from leaving layer
    const x = withinMinMax(circle.x(), [25, maxX]);
    const y = withinMinMax(circle.y(), [25, maxY]);
    circle.position({ x, y });
  };

  const shadowPos = () => ({
    x: (offsetX.error ? 0 : offsetX.value) + 50,
    y: (offsetX.error ? 0 : offsetY.value) + 50,
  });

  const handleDragEnd = (e: KonvaEventObject<DragEvent>) => {
    const shadow = e.target;
    if (!shadow) return;
    setOffsetX({ value: shadow.x() - 50, error: false });
    setOffsetY({ value: shadow.y() - 50, error: false });
  };

  return (
    <Stage width={100} height={100}>
      <Layer>
        <Circle
          radius={25}
          x={shadowPos().x}
          y={shadowPos().y}
          fill={color.value}
          draggable
          onMouseEnter={e => {
            const stage = e.currentTarget.getStage();
            if (!stage) return;
            stage.container().style.cursor = 'grab';
          }}
          onMouseLeave={e => {
            const stage = e.currentTarget.getStage();
            if (!stage) return;
            stage.container().style.cursor = 'default';
          }}
          onDragMove={handleDragMove}
          onDragEnd={handleDragEnd}
        />
        <Circle radius={25} x={50} y={50} fill="white" stroke="black" listening={false} />
      </Layer>
    </Stage>
  );
};
