import React, { ReactNode, ChangeEvent } from 'react';
import './Checkbox.scss';
import classNames from 'classnames';

export const Checkbox: React.FunctionComponent<{
  isCircular?: boolean;
  checked: boolean;
  onChange: (checked: boolean, event: ChangeEvent<HTMLInputElement>) => void;
  label?: ReactNode;
  id?: string;
  size?: 's' | 'm' | 'l' | 'xl';
  fontOverWrite?: boolean | undefined;
  checkBorder?: string | undefined;
  checkBoxActive?: string | undefined;
  background?: string | undefined;
  backgroundColor?: string | undefined;
  disabled?: boolean | undefined;
}> = ({
  checked,
  onChange,
  label,
  id,
  size = 'm',
  isCircular,
  fontOverWrite,
  checkBorder,
  checkBoxActive,
  background,
  backgroundColor,
  disabled,
}) => {
  const sizeClass = ` Checkbox--${size}-size`;
  const modifierClasses = classNames(' ', sizeClass, {
    'Checkbox--checked': checked,
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.currentTarget.checked, event);
    }
  };

  return (
    <div className={`Checkbox${modifierClasses} ${checked ? checkBoxActive : ''}`}>
      <label htmlFor={id} className="Checkbox__wrapper-label">
        <div
          className={`Checkbox__input-wrapper${
            disabled ? ' Checkbox__input-wrapper__disabled' : ''
          }`}
          style={{
            borderRadius: isCircular ? '100rem' : '',
            backgroundColor: !checked ? background : backgroundColor,
          }}
        >
          <input
            disabled={disabled}
            className="Checkbox__input"
            type="checkbox"
            onChange={handleChange}
            id={id}
            checked={checked}
          />
          <div className="Checkbox__checkmark" />
        </div>
        {label && (
          <span className={`Checkbox__label ${fontOverWrite && 'fontOverwrite'}`}>{label}</span>
        )}
      </label>
    </div>
  );
};
