import React from 'react';
import { useLocation } from 'react-router-dom';

import './LoginFooter.scss';

type InputTypes = {
  color?: Boolean;
  borderTop?: string | number | undefined;
  classname?: string;
};
export const LoginFooter: React.FC<InputTypes> = ({ color, borderTop, classname }) => {
  const Location = useLocation();

  const isMarginRight = () => {
    return ['/login', '/signup', '/verify-email', '/email-verify', '/reset-password'].includes(
      Location.pathname
    );
  };
  return (
    <div
      className={`loginFooter ${classname}`}
      style={{
        backgroundColor: color ? '#fff' : '#F8F8F8',
        borderTop,
        margin: isMarginRight() ? '' : '2rem 0 0 65px',
      }}
    >
      <div>
        <span>© {new Date().getFullYear()} Beewo. All rights reserved.</span>
      </div>
      {/* <div> */}
      {/*  <span> */}
      {/*    Opt Out / Support / Terms & Conditions /{' '} */}
      {/*    <a href="https://beewo.com/privacy" target="_blank" rel="noreferrer"> */}
      {/*      Privacy Policy */}
      {/*    </a> */}
      {/*  </span> */}
      {/*  <ul> */}
      {/*    <li className="loginFooterRightList"> */}
      {/*      Opt Out / Support / Terms & Conditions / Privacy Policy */}
      {/*    </li> */}
      {/*    <li className="loginFooterRightList">Opt Out / </li> */}
      {/*    <li className="loginFooterRightList"> Support / </li> */}
      {/*    <li className="loginFooterRightList">Terms & Conditions /</li> */}
      {/*    <li className="loginFooterRightList">Privacy Policy </li> */}
      {/*  </ul> */}
      {/* </div> */}
    </div>
  );
};

LoginFooter.defaultProps = {
  color: false,
};
