import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { Dispatch, SetStateAction, ChangeEventHandler } from 'react';

import debounce from 'lodash.debounce';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons';

import { Loading } from 'Components';
import CustomIcons from 'assets/icons';

import { getLocationOnSearch, getPlaceGeoJSON } from 'Requests/Request_Methods/prospectMethods';

import type { ContactZipCode, TLocation } from 'types';

import './ZipCodesList.scss';

type ZipCodesListProps = {
  loading?: boolean;
  polygonsLoading?: boolean;
  zips: ContactZipCode[];
  selectedZips: ContactZipCode[];
  onSelectZip: (zips: ContactZipCode[]) => void;
  setPolygonLocations: Dispatch<SetStateAction<TLocation[]>>;
  setPolygonsLoading: Dispatch<SetStateAction<boolean>>;
};

const ZipCodesList = ({
  loading,
  zips,
  selectedZips,
  onSelectZip,
  setPolygonLocations,
  polygonsLoading,
  setPolygonsLoading,
}: ZipCodesListProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState<string>('');

  const [selected, setSelected] = useState<ContactZipCode[]>([]);

  const debouncedSearch = useRef(
    debounce(async (zips: ContactZipCode[]) => {
      await setZipPolygons(zips.slice(0, 5));
    }, 500)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const getZipPolygon = async (zip: ContactZipCode) => {
    try {
      const locations = await getLocationOnSearch(zip.zip);
      if (locations.length) {
        const polygons = await getPlaceGeoJSON(
          locations[0].originalFeature.properties.id,
          locations[0].originalFeature.properties.layer
        );
        return polygons[0];
      }
    } catch (e) {
      console.warn(e);
    }
    return null;
  };

  const setZipPolygons = async (zips: ContactZipCode[]): Promise<void> => {
    try {
      setPolygonsLoading(true);
      const polygons = await Promise.all(zips.map(zip => getZipPolygon(zip)));
      setPolygonLocations(polygons.filter(polygon => !!polygon) as TLocation[]);
    } catch (e) {
      console.warn(e);
    } finally {
      setPolygonsLoading(false);
    }
  };

  useEffect(() => {
    setSelected(zips);
    debouncedSearch(zips);
  }, [zips]);

  const handleSelectZip = (zip: ContactZipCode) => () => {
    setSelected(prevState => {
      const matchedZip = prevState.find(contactZip => contactZip.zip === zip.zip);
      if (matchedZip) return prevState.filter(contactZip => contactZip.zip !== zip.zip);
      return [...prevState, zip];
    });
  };

  const filteredZips = useMemo(() => {
    return zips.filter(({ zip }) => zip.toLowerCase().includes(searchValue.toLowerCase()));
  }, [searchValue, zips]);

  const isAllSelected = useMemo(() => {
    const selectedZips = filteredZips.filter(
      ({ zip }) => !!selected.find(({ zip: sZip }) => zip === sZip)
    );
    return selectedZips.length === filteredZips.length;
  }, [selected, filteredZips]);

  const onSelectAll = useCallback(() => {
    if (isAllSelected)
      setSelected(prevState =>
        prevState.filter(contactZip => !filteredZips.find(({ zip }) => contactZip.zip === zip))
      );
    else
      setSelected(prevState => [
        ...prevState.filter(contactZip => !filteredZips.find(({ zip }) => contactZip.zip === zip)),
        ...filteredZips,
      ]);
  }, [isAllSelected, filteredZips]);

  useEffect(() => {
    onSelectZip(selected);
  }, [selected]);

  const onChangeSearch: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
    setSearchValue(value);
    debouncedSearch(zips.filter(({ zip }) => zip.toLowerCase().includes(value.toLowerCase())));
  };

  const onClear = () => {
    setSearchValue('');
    debouncedSearch(zips);
    inputRef.current?.focus();
  };

  if (loading) return <Loading />;

  if (!zips.length)
    return (
      <div className="ListingsList">
        <div className="ListingsList__empty">
          <CustomIcons name="folder" fontSize={64} />
          <div className="ListingsList__empty__content">
            <span className="title">You still do not have contacts to analyze</span>
            <span className="description">Start by uploading or syncing your contacts list.</span>
          </div>
        </div>
      </div>
    );

  return (
    <div className={classNames('ListingsList', 'grow-your-sphere')}>
      <div
        className={classNames(
          'ListingsList__listings ListingsList__listingScroll',
          'GrowYourSphere__listings_content'
        )}
      >
        <div className="ListingsList__header">
          <div className="ListingsList__count">
            <span className="count">
              Your Contacts ({zips.reduce((contactCount, zip) => contactCount + zip.count, 0)})
            </span>
          </div>
        </div>
        <div className="ListingsList__header">
          <div className="listing-search">
            <FontAwesomeIcon icon={faSearch} color="#6D6D6D" style={{ fontSize: 22 }} />
            <input
              ref={inputRef}
              onChange={onChangeSearch}
              value={searchValue}
              placeholder="Search by ZIP code"
              className="listing-search-input"
              type="text"
            />
            {searchValue && (
              <button aria-label="clear" type="button" className="clear-btn" onClick={onClear}>
                <FontAwesomeIcon color="#FFAB03" icon={faTimes} style={{ fontSize: 15 }} />
              </button>
            )}
          </div>
        </div>
        {!filteredZips.length ? (
          <span className="not-found-zips">No matching ZIP codes found for “{searchValue}”</span>
        ) : (
          <div
            className={classNames('zips-loading-container', polygonsLoading && 'polygon-loading')}
          >
            {polygonsLoading && (
              <div>
                <div className="zip-loading-indicator">
                  <FontAwesomeIcon
                    icon={faSpinnerThird}
                    color="#FFAB03"
                    style={{ fontSize: 30, animation: 'spinner-border 0.75s linear infinite' }}
                  />
                  <span className="zip-loading-indicator-text">Analyzing your contacts</span>
                </div>
              </div>
            )}
            <div className={classNames('zips-content', polygonsLoading && 'zips-content-blur')}>
              <div className="ListingsList__header">
                <div className="ListingsList__count">
                  <button
                    className={classNames('select-all-contacts-zips-checkbox', {
                      selected: isAllSelected,
                    })}
                    disabled={polygonsLoading}
                    onClick={onSelectAll}
                  >
                    {isAllSelected && <FontAwesomeIcon icon={faCheck} />}
                  </button>
                  <span className="select-title">Select all</span>
                </div>
                {!!selectedZips.length && (
                  <span className="selected_listings_count">{selectedZips.length} Selected</span>
                )}
              </div>
              <div className="zips-content-titles">
                <span>Zip Code</span>
                <span>Contact Count</span>
              </div>
              <div className="GrowYourSphere__listings">
                {filteredZips.map(({ zip, count }) => {
                  const isSelected = !!selectedZips.find(contactZip => contactZip.zip === zip);
                  return (
                    <button
                      key={zip}
                      type="button"
                      className={classNames('ListingLabel', {
                        'ListingLabel--selected': isSelected,
                      })}
                      disabled={polygonsLoading}
                      onClick={handleSelectZip({ zip, count })}
                    >
                      <div className="ListingLabel__wrapper">
                        <div className="ListingLabel__icon-circle">
                          {isSelected && <FontAwesomeIcon icon={faCheck} />}
                        </div>
                        <div className="ListingLabel__labels">
                          <div className="ListingLabel__labels__info">
                            <div className="ListingLabel__label">{zip}</div>
                            <div className="ListingLabel__sub-label">
                              {count} contact{count > 1 ? 's' : ''}
                            </div>
                          </div>
                        </div>
                      </div>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ZipCodesList;
