import React from 'react';

import { Button, FlexBox, Modal, Text } from '@eltoro-ui/components';

import CustomIcons from 'assets/icons';

import cross from 'assets/Images/cross.png';

import './ContactEnrichmentInformationModal.scss';

type ContactEnrichmentInformationModalProps = {
  onClose: () => void;
};

export default function ContactEnrichmentInformationModal({
  onClose,
}: ContactEnrichmentInformationModalProps) {
  return (
    <Modal className="ContactEnrichmentInformationModal">
      <FlexBox flexDirection="column" alignItems="center" gap="46px">
        <FlexBox flexDirection="column" gap="16px">
          <CustomIcons name="contact_enrichment_info" fontSize={95} />
          <Text on="white" textAlign="center" UNSAFE_style={{ color: '#2B1F0A', fontSize: 28 }}>
            What is contact enrichment?
          </Text>
          <Text
            on="white"
            textAlign="center"
            UNSAFE_style={{ color: '#6D6D6D', fontSize: 16, maxWidth: 700, lineHeight: 1.5 }}
          >
            Beewo&apos;s Contact Enrichment feature (premium membership only) helps to identify
            missing or incomplete address information for your contacts. The process will run
            automatically upon upload for contact entries that have an email address and/or phone
            number but are also missing the address and/or ZIP code. Any information found will not
            be shared back, however you will see an &apos;enrichment&apos; icon next to the contacts
            name. This will aid in more accurate digital advertising and contact notifications of
            in-market activity.
          </Text>
        </FlexBox>
        <Button kind="primary" size="l" weight="bold" onClick={onClose}>
          Dismiss
        </Button>
      </FlexBox>
    </Modal>
  );
}
