import React, { useEffect, CSSProperties, FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { StandardLonghandProperties } from 'csstype';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../Button';
import { FlexBox } from '../Box';
import './Modal.scss';

export type ModalProps = {
  offClick?: () => void;
  closable?: boolean;
  noBackground?: boolean;
  positionFromTop?: StandardLonghandProperties['marginTop'];
  className?: string;
  style?: CSSProperties;
  header?: ReactNode;
};

export const Modal: FC<ModalProps> = ({
  offClick,
  closable = true,
  children,
  noBackground = false,
  positionFromTop,
  className,
  style = {},
  header,
}) => {
  useEffect(() => {
    window.document.body.style.overflow = 'hidden';
    return () => {
      window.document.body.style.overflow = 'auto';
    };
  }, []);

  return createPortal(
    <div className="Modal">
      <div
        role="presentation"
        className="Modal__shade"
        onClick={closable ? offClick : () => undefined}
      />
      <div
        role="dialog"
        className={classNames('Modal__content', className, {
          'Modal__content--has-no-background': noBackground,
        })}
        style={{
          alignSelf: positionFromTop ? 'start' : undefined,
          marginTop: positionFromTop,
          ...style,
        }}
      >
        {header && (
          <FlexBox
            flexGrow={1}
            justifyContent={header ? 'space-between' : 'flex-end'}
            UNSAFE_className="Modal__header"
          >
            {header && header}
            <Button
              onClick={offClick}
              iconLeft={<FontAwesomeIcon icon={faTimes} />}
              UNSAFE_className="Modal__close"
            />
          </FlexBox>
        )}
        <div
          className="Modal__scrollable-content"
          style={{
            maxHeight: positionFromTop
              ? `calc(100vh - 5rem - ${positionFromTop})`
              : 'calc(100vh - 5rem)',
          }}
        >
          {children}
        </div>
      </div>
    </div>,
    document.getElementById('root') as HTMLElement
  );
};
