import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ColorSelect, HorizontalLabel } from '../../..';
import { blockMainColorSelector, selectedKindAtom } from '../../../../../State';

export const EditMainFeatures = () => {
  const kind = useRecoilValue(selectedKindAtom);
  if (!kind) return null;
  const [mainColor, setMainColor] = useRecoilState(blockMainColorSelector(kind));
  return (
    <div className="EditMainFeatures">
      <HorizontalLabel label="Block Color">
        <ColorSelect
          color={mainColor.value}
          onChange={c => setMainColor({ value: c.hex, error: false })}
          error={mainColor.error}
        />
      </HorizontalLabel>
    </div>
  );
};
