import React, { useEffect, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import toast from 'react-hot-toast';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { Button, FlexBox, Modal, Text } from '@eltoro-ui/components';
import { LabelInputField } from 'Components/LabelInputField';

import { saveAudience } from 'Requests';

import {
  setIsAudienceSaved,
  setLaunchProspect,
  //  setOpenTagModal,
  //  setTargetAudience,
} from 'Redux/actions';

import type { AudienceTagType, TRootState } from 'types';

import {
  ContactsGrowYourSphereAudienceBody,
  saveContactsAudience,
  saveContactsGrowYourSphereAudience,
} from 'Requests/Request_Methods/contactsMethods';
import type { ContactsAudienceBody } from 'Requests/Request_Methods/contactsMethods';

import './SaveAudienceModal.scss';

export type AudienceDetailsType = { name: string; tags?: string[] };

export type SaveAudienceModalType = {
  contactType?: 'seller' | 'buyer' | null;
  onClickOutside: () => void;
  onSave?: (audienceDetails: AudienceDetailsType) => void;
  selectedTags: AudienceTagType[];
  selectedZips?: string[];
  setSelectedTags: Dispatch<SetStateAction<AudienceTagType[]>>;
  setTagsModalOpen: Dispatch<SetStateAction<boolean>>;
  listing?: any;
  totalProspects?: any;
  dataObj?: any;
  type?: any;
  targetAudience: string;
  setTargetAudience: Dispatch<SetStateAction<string>>;
  seen_in_market?: boolean;
  radiusListing?: number[];
};

export const SaveAudienceModal: React.FC<SaveAudienceModalType> = ({
  onClickOutside,
  onSave,
  selectedTags,
  selectedZips,
  listing,
  totalProspects,
  setSelectedTags,
  dataObj,
  type,
  setTagsModalOpen,
  radiusListing,
  targetAudience,
  setTargetAudience,
  seen_in_market = false,
  contactType = null,
}) => {
  const [name] = useState(type);
  const isLaunchCampaign = useSelector((state: TRootState) => state.isCampaignLaunchReducer);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  //  const targetAudience = useSelector((state: TRootState) => state.targetAudienceReducer);

  const listingsId: any = [];
  if (type === 'my listings' || type === 'Virtual Just Listed/Just Sold') {
    listing?.forEach((element: any) => {
      listingsId.push(element.listingid);
    });
  }
  const [btnDisabled, setbtnDisabled] = useState(false);
  const [loader1, setLoader1] = useState<boolean>(false);
  const [loader2, setLoader2] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setbtnDisabled(false);
      setLoader1(false);
      setLoader2(false);
    };
  }, []);

  const afterSaveAudience = (body: Partial<any>, audience_id: number, isSelected?: boolean) => {
    onClickOutside();
    setTargetAudience('');
    setSelectedTags([]);
    if (onSave) onSave({ name });
    toast.success('Audience creation is in progress.');
    dispatch(setIsAudienceSaved(true));

    if (isSelected && audience_id) {
      history.replace({
        pathname: location.pathname,
        search: '?action=create-campaign',
        state: body.source !== 'Virtual Just Listed/Just Sold' ? { isSelected, audience_id } : {},
      });
    }
    if (isLaunchCampaign?.LaunchProspectOpen && audience_id)
      if (isLaunchCampaign?.campaignId?.id) {
        history.push({
          pathname: `/create-campaign/${isLaunchCampaign?.campaignId.id}/setup`,
          state:
            body.source !== 'Virtual Just Listed/Just Sold'
              ? { isSelected: true, audience_id }
              : {},
        });
        dispatch(setLaunchProspect(false));
      } else {
        history.replace('?action=create-campaign');
        dispatch(setLaunchProspect(false));
      }
  };

  const postInfo = (isSelected?: boolean) => {
    setbtnDisabled(true);

    if (isSelected) {
      setLoader1(true);
    } else {
      setLoader2(true);
    }

    if (type === 'contacts') {
      const body = {
        tags: selectedTags.map(tag => tag.value),
        name: targetAudience,
        seen_in_market,
      };

      saveContactAudienceAPI(body, isSelected);
    } else if (type === 'Grow Your Sphere') {
      const bodyWithFilters = {
        name: targetAudience,
        source: type,
        tags: selectedTags.map(tag => tag.value),
        zip: selectedZips ?? [],
      };

      selectedTags = [];
      saveContactsGrowYourSphereAudienceAPI(bodyWithFilters, isSelected);
    } else if (type !== 'My Listings' && type !== 'Virtual Just Listed/Just Sold') {
      const bodyWithFilters = {
        name: targetAudience,
        source: type,
        consider_body: true,
        body: dataObj,
        tags: selectedTags.map(tag => tag.value),
        prospects_counts: totalProspects?.toString(),
        listings: [],
      };

      selectedTags = [];
      saveAudienceAPI(bodyWithFilters, isSelected);
    } else {
      const sourceType =
        type === 'Virtual Just Listed/Just Sold' ? 'Virtual Just Listed/Just Sold' : 'My Listings';
      const bodyWithArray = {
        name: targetAudience,
        source: sourceType,
        prospects_counts: totalProspects.toString(),
        // listings:
        //   radiusListing && type === 'Virtual Just Listed/Just Sold'
        //     ? Array.from(new Set([...listingsId, ...radiusListing]))
        //     : listingsId,
        listings: listingsId,
        tags: selectedTags.map(tag => tag.value),
      };
      selectedTags = [];
      saveAudienceAPI(bodyWithArray, isSelected);
    }
  };

  const saveAudienceAPI = (body: Partial<any>, isSelected?: boolean) => {
    saveAudience({ ...body, contact_type: contactType })
      .then(res => {
        if (res.data) afterSaveAudience(body, res.data.audience_id, isSelected);
      })
      .finally(() => {
        setbtnDisabled(false);
        setLoader1(false);
        setLoader2(false);
      });
  };

  const saveContactsGrowYourSphereAudienceAPI = (
    body: ContactsGrowYourSphereAudienceBody,
    isSelected?: boolean
  ) => {
    saveContactsGrowYourSphereAudience(body)
      .then(res => {
        if (res.data) afterSaveAudience(body, res.data.audience_id, isSelected);
      })
      .finally(() => {
        setbtnDisabled(false);
        setLoader1(false);
        setLoader2(false);
      });
  };

  const saveContactAudienceAPI = (body: ContactsAudienceBody, isSelected?: boolean) => {
    saveContactsAudience({ ...body, contact_type: contactType })
      .then(res => {
        if (res.data) afterSaveAudience(body, res.data.audience_id, isSelected);
      })
      .finally(() => {
        setbtnDisabled(false);
        setLoader1(false);
        setLoader2(false);
      });
  };

  const openTagModel = () => {
    setTagsModalOpen(true);
  };
  // const [isHover, setIsHover] = useState(false)
  const offClickOutside = () => {
    onClickOutside();
    setTargetAudience('');
    setSelectedTags([]);
  };
  return (
    <Modal
      // header="Name and Tag your Target Audience"
      offClick={offClickOutside}
      className="SaveAudienceModal"
    >
      <div className="OnboardingModal__welcome ListingModalBg SaveAudiencePosition">
        <div className="OnboardingModal__welcomeHeader ListingAudienceModal">
          <div className="SaveAudienceModal__cancel">
            <FontAwesomeIcon icon={faTimes} onClick={offClickOutside} color="#6d6d6d" />
          </div>
          <div style={{ textAlign: 'center', width: '100%' }}>
            <Text
              on="white"
              weight="bold"
              textAlign="center"
              UNSAFE_style={{ fontSize: '36px', lineHeight: '45px' }}
            >
              Save Audience
            </Text>
          </div>
          <span className="audience-size-inform-text">
            Save observed prospects counts and automatically convert to digital audiences for online
            ads. The audience group will be on your Saved Audience page.
          </span>
          <br />
          <div
            style={{
              margin: '0 30px',
              textAlign: 'center',
            }}
          >
            <FlexBox flexDirection="column">
              <LabelInputField
                placeholder="Target Audience Name"
                title="Target Audience"
                type="text"
                style={{ paddingLeft: '20px' }}
                padding={10}
                value={targetAudience}
                onChange={e => {
                  setTargetAudience(e.target.value);
                }}
              />
              <div style={{ display: 'flex', flexWrap: 'wrap', padding: '0.5rem 0 1rem 0' }}>
                <Button
                  kind="default"
                  UNSAFE_className="targetAudience__btn"
                  onClick={openTagModel}
                  disabled={selectedTags.length > 5}
                >
                  + Tag
                </Button>
                {selectedTags.map(tag => {
                  return (
                    <Button
                      key={tag.uuid}
                      UNSAFE_className="targetAudience__tags"
                      kind="default"
                      iconRight={
                        <FontAwesomeIcon
                          className="targetAudience__tagsCross"
                          icon={faTimes}
                          onClick={() => {
                            setSelectedTags(tags => [
                              ...tags.filter(({ uuid }) => uuid !== tag.uuid),
                            ]);
                          }}
                        />
                      }
                    >
                      {tag.value}
                    </Button>
                  );
                })}
              </div>
              <FlexBox flexDirection="column" alignItems="center">
                <Button
                  kind="primary"
                  size="xl"
                  weight="bolder"
                  width="335px"
                  UNSAFE_className="save-and-launch-audience-btn"
                  onClick={() => postInfo(true)}
                  disabled={!targetAudience || btnDisabled}
                  loading={loader1}
                >
                  Save &#38; launch new ad campaign
                </Button>
                <Button
                  loading={loader2}
                  kind="primary"
                  size="xl"
                  weight="bolder"
                  width="335px"
                  UNSAFE_className="save-audience-btn"
                  onClick={() => postInfo(false)}
                  disabled={!targetAudience || btnDisabled}
                >
                  Save audience
                </Button>
              </FlexBox>
              <span className="audience-size-inform-text note">
                There may be a difference between prospect observations and target audience counts.
              </span>
            </FlexBox>
          </div>
        </div>
      </div>
    </Modal>
  );
};
