import React, { ReactNode } from 'react';

import diamond from 'assets/Images/diamond_icon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagic } from '@fortawesome/pro-solid-svg-icons';

import { Button, FlexBox, Text } from '../../../../@eltoro-ui/components';

import './upgradeModal.scss';

interface UpgradeModalProps {
  title: string | ReactNode;
  description?: string | ReactNode;
  onCancel?: () => void;
}

const UpgradeModal = ({ title, description, onCancel }: UpgradeModalProps) => {
  return (
    <FlexBox UNSAFE_className="upgrade-modal" flexDirection="column" alignItems="center">
      <img src={diamond} alt="icon" style={{ width: '140px' }} />
      <Text on="white" UNSAFE_className="upgrade-modal-title">
        {title}
      </Text>
      {description && (
        <Text on="white" UNSAFE_className="upgrade-modal-description">
          {description}
        </Text>
      )}
      <FlexBox justifyContent="space-evenly" UNSAFE_style={{ gap: '15px' }}>
        <Button kind="default" size="l" UNSAFE_className="upgrade-modal-action" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          to="/profile/billing"
          kind="primary"
          size="l"
          UNSAFE_className="upgrade-modal-action"
          iconLeft={<FontAwesomeIcon icon={faMagic} style={{ fontSize: 18 }} />}
        >
          Upgrade to Premium
        </Button>
      </FlexBox>
    </FlexBox>
  );
};

export default UpgradeModal;
