import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import toast from 'react-hot-toast';
import Tooltip from 'rc-tooltip';
import Tippy from '@tippyjs/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { faArrowFromBottom, faBadgeCheck, faMagic, faSync } from '@fortawesome/pro-solid-svg-icons';

import { Button, FlexBox, Text } from '@eltoro-ui/components';
import { PageHeader, ReactTableSearchBar } from 'Components';
import { TalkToSphereList } from 'Pages/ProspectActivity/lenses/TalkToSphereLens/TalkToSphereList';
import { UploadCSVModal } from 'Pages/MyContacts/components';
import { SaveAudienceWizard } from 'Pages/ProspectActivity/components';
import CloudSpongeWidget from 'Pages/Dashboard/_Components/contactsGetter/CloudSpongeWidget';
import ContactEnrichmentInformationModal from 'Pages/ProspectActivity/lenses/TalkToSphereLens/ContactEnrichmentInformationModal';

import { uploadContactsSync } from 'Requests/Request_Methods/contactsMethods';

import type { TContact, TRootState, TResPagination } from 'types';

import { getContacts } from 'Requests';

import './TalkToSphereLens.scss';

export const TalkToSphereLens = () => {
  const history = useHistory();
  const tableRef = useRef<any>(null);

  const { setGlobalFilters } = useSelector((state: TRootState) => state.setGlobalFilters);
  const { globalFilters } = useSelector((state: TRootState) => state.globalFilters);
  const { userData } = useSelector((state: TRootState) => state.userReducer);

  const [contactEnrichmentInformationModalOpen, setContactEnrichmentInformationModalOpen] =
    useState(false);

  const [fetchDataIsLoading, setFetchDataIsLoading] = useState(false);
  const [contacts, setContacts] = useState<TContact[]>([]);

  const [syncIsLoading, setSyncIsLoading] = useState(false);
  const [uploadCSVModalIsOpen, setUploadCSVModalIsOpen] = useState(false);

  const [page, setPage] = useState<number>(1);
  const [pagination, setPagination] = useState<TResPagination>({ total_count: 0, total_pages: 0 });
  const [enrichState, setEnrichState] = useState({
    enrichment_percent: 0,
    pending_contacts_count: 0,
  });

  const _getContacts = async () => {
    setFetchDataIsLoading(true);
    try {
      const res = await getContacts(page, 100, true);
      if (res.data?.contacts) {
        const {
          total_count,
          total_pages,
          enrichment_percent,
          pending_contacts_count,
          contacts: resContacts,
        } = res.data;
        setContacts(resContacts);
        setPagination({ total_count, total_pages });
        setEnrichState({ enrichment_percent, pending_contacts_count });
      }
      setFetchDataIsLoading(false);
    } catch (err: any) {
      toast.error(err.detail);
      setFetchDataIsLoading(false);
    }
  };

  useEffect(() => {
    _getContacts();
  }, [page]);

  const [buttonActive, setButtonActive] = useState(true);
  const [loadFirst, setloadFirst] = useState(true);

  const afterSubmitContacts = async (cloudContacts: any) => {
    setSyncIsLoading(true);
    try {
      const contactsBody = cloudContacts.map((contact: any) => ({
        first_name: contact.first_name,
        last_name: contact.last_name,
        phone: contact.phone.map((phone: any) => ({
          number: phone.number,
          primary: phone?.primary,
          selected: phone?.selected,
        })),
        email: contact.email.map((email: any) => ({
          address: email.address,
          primary: email?.primary,
          selected: email?.selected,
        })),
        address: contact.address.map((address: any) => ({
          street: address.street,
          city: address.city,
          region: address.region,
          postal_code: address.postal_code,
        })),
      }));
      await uploadContactsSync(contactsBody);
      setSyncIsLoading(false);
      _getContacts();
    } catch (e) {
      setSyncIsLoading(false);
      toast.error('Oops, there was an issue with importing your contacts.');
    }
  };

  const launchCloudSponge = () => {
    // @ts-ignore
    if (window.cloudsponge) {
      // @ts-ignore
      window.cloudsponge.launch();
    }
  };

  const handleContactEnrichmentInformationModal = (open: boolean) => () => {
    setContactEnrichmentInformationModalOpen(open);
  };

  return (
    <div className="talkToSphere">
      {contactEnrichmentInformationModalOpen && (
        <ContactEnrichmentInformationModal
          onClose={handleContactEnrichmentInformationModal(false)}
        />
      )}
      <FlexBox flexDirection="column" UNSAFE_style={{ gap: '16px' }}>
        {!!contacts?.length &&
          userData?.sub_name !== 'premium' &&
          !!enrichState.enrichment_percent && (
            <PageHeader
              UNSAFE_CLASSNAME="MyContacts__PageHeader upgrade"
              subTitle={
                <FlexBox alignItems="center" UNSAFE_style={{ gap: '15px' }}>
                  <FontAwesomeIcon icon={faBadgeCheck} color="#FCD917" className="enriched-badge" />
                  <span className="enrich-text">
                    Enrich <b>{enrichState.enrichment_percent}%</b> of your potential leads to run
                    more effective campaigns.
                  </span>
                </FlexBox>
              }
              actions={[
                <Button
                  key="upgrade"
                  UNSAFE_className="upgrade-button"
                  size="l"
                  weight="bold"
                  onClick={() => history.push('/profile/billing')}
                  iconLeft={<FontAwesomeIcon icon={faMagic} />}
                >
                  Upgrade
                </Button>,
              ]}
            />
          )}
        <PageHeader
          UNSAFE_CLASSNAME="MyContacts__PageHeader"
          subTitle={
            <FlexBox alignItems="center" UNSAFE_style={{ gap: '15px' }}>
              <span>
                {enrichState.pending_contacts_count ? (
                  <b>
                    {userData?.sub_name === 'premium'
                      ? 'Enriching Your Contacts...'
                      : 'Importing Your Contacts...'}
                  </b>
                ) : (
                  <FlexBox flexDirection="column" gap="8px">
                    <span>
                      You have <b>{pagination.total_count} contacts</b>
                    </span>
                    <FlexBox alignItems="center" gap="4px">
                      <span style={{ color: '#FFAB03', fontSize: 14 }}>Contact Enrichment</span>
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        onClick={handleContactEnrichmentInformationModal(true)}
                        style={{ color: '#FFAB03', fontSize: 16, cursor: 'pointer' }}
                      />
                    </FlexBox>
                  </FlexBox>
                )}
              </span>
              {!!contacts?.length &&
                !!enrichState.enrichment_percent &&
                userData?.sub_name === 'premium' && (
                  <div className="enrich-tag">
                    <FontAwesomeIcon icon={faBadgeCheck} color="#FCD917" style={{ fontSize: 20 }} />
                    <span className="enrich-text">{enrichState.enrichment_percent}% enriched</span>
                  </div>
                )}
            </FlexBox>
          }
          actions={[
            <ReactTableSearchBar
              key="campaign-searchbar"
              globalFilter={globalFilters}
              setGlobalFilter={setGlobalFilters}
              placeholder="Search by first name, last name"
            />,
            <Tooltip
              key="save-audience"
              placement="top"
              trigger="hover"
              overlayClassName="audience-tooltip"
              showArrow
              overlay={
                !pagination.total_count ? (
                  <>
                    Import your contacts <br /> to create an audience
                  </>
                ) : (
                  ''
                )
              }
              {...(!pagination.total_count ? {} : { visible: false })}
            >
              <SaveAudienceWizard
                type="contacts"
                disabled={!pagination.total_count}
                onSaveAndClose={() => {}}
              />
            </Tooltip>,
            ...(contacts?.length
              ? [
                  <Tippy
                    key="sync-email-accounts"
                    placement="top"
                    className="audience-pending-tooltip"
                    trigger="mouseenter"
                    content="Sync email accounts"
                  >
                    <Button
                      kind="primary"
                      size="l"
                      onClick={launchCloudSponge}
                      disabled={
                        !!enrichState.pending_contacts_count || buttonActive || syncIsLoading
                      }
                      iconLeft={
                        buttonActive || syncIsLoading ? (
                          <FontAwesomeIcon
                            icon={faSpinnerThird}
                            style={{
                              fontSize: 20,
                              animation: 'spinner-border 0.75s linear infinite',
                            }}
                          />
                        ) : (
                          <FontAwesomeIcon icon={faSync} style={{ fontSize: 20 }} />
                        )
                      }
                    />
                  </Tippy>,
                  <Tippy
                    key="upload-contacts"
                    placement="top-end"
                    className="audience-pending-tooltip"
                    trigger="mouseenter"
                    content="Upload contacts"
                  >
                    <Button
                      kind="primary"
                      size="l"
                      disabled={!!enrichState.pending_contacts_count}
                      onClick={() => setUploadCSVModalIsOpen(true)}
                      iconLeft={
                        <FontAwesomeIcon icon={faArrowFromBottom} style={{ fontSize: 20 }} />
                      }
                    />
                  </Tippy>,
                ]
              : []),
          ]}
        />
        {!!enrichState.pending_contacts_count && !!contacts?.length && (
          <PageHeader
            title=""
            style={{ height: '60px', background: 'var(--color-white)', padding: '0 1rem' }}
            subTitle={
              <FlexBox alignItems="center" UNSAFE_style={{ gap: '15px' }}>
                <FontAwesomeIcon
                  icon={faSpinnerThird}
                  color="#007B94"
                  style={{
                    animation: 'spinner-border 0.75s linear infinite',
                    fontSize: 24,
                  }}
                />
                <FlexBox flexDirection="column">
                  <Text
                    on="white"
                    UNSAFE_style={{ fontSize: '14px' }}
                    UNSAFE_className="processing-title"
                  >
                    {userData?.sub_name === 'premium'
                      ? 'We are enriching your contacts!'
                      : 'We are importing your contacts!'}
                  </Text>
                  <Text
                    on="white"
                    UNSAFE_style={{ fontSize: '12px' }}
                    UNSAFE_className="processing-description"
                  >
                    Please refresh after a while to see uploaded list.
                  </Text>
                </FlexBox>
              </FlexBox>
            }
            actions={[
              <Button
                key="refresh"
                size="l"
                kind="text"
                UNSAFE_className="refresh-contacts"
                onClick={() => {
                  history.go(0);
                }}
              >
                Refresh
              </Button>,
            ]}
          />
        )}
      </FlexBox>

      <TalkToSphereList
        tableRef={tableRef}
        buttonActive={buttonActive}
        fetchDataIsLoading={fetchDataIsLoading}
        contacts={contacts}
        enrichState={enrichState}
        pagination={pagination}
        setPage={setPage}
        page={page}
        setUploadCSVModalIsOpen={setUploadCSVModalIsOpen}
        launchCloudSponge={launchCloudSponge}
      />

      {uploadCSVModalIsOpen && (
        <UploadCSVModal
          onClose={(e?: boolean) => {
            if (e) {
              _getContacts();
            }
            setUploadCSVModalIsOpen(false);
          }}
        />
      )}
      <CloudSpongeWidget
        afterSubmitContacts={afterSubmitContacts}
        setButtonActive={setButtonActive}
        loadFirst={loadFirst}
        buttonActive={buttonActive}
        onCloseTypea={() => {
          setloadFirst(false);
        }}
      />
    </div>
  );
};
