import { Button, Checkbox, Popover, Spacer, Text } from '@eltoro-ui/components';
import { useOnClickOutside } from '@eltoro-ui/hooks';
import { faChevronCircleDown, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { defaultArtboards } from '../../../../../Data';
import {
  allBlocksOfKindSelector,
  artboardLabelSelector,
  selectedBlockIdsAtom,
  selectedKindAtom,
} from '../../../../../State';
import { ArtboardBlocksType } from '../../../../../Typings';
import './BlockSelectPopover.scss';

export const BlockSelectPopover = () => {
  const [showBlocks, setShowBlocks] = useState(false);
  const setSelectedKind = useSetRecoilState(selectedKindAtom);
  const allBlocksOfKind = useRecoilValue(allBlocksOfKindSelector);
  const [selectedBlockIds, setSelectedBlockIds] = useRecoilState(selectedBlockIdsAtom);
  const selectRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  useOnClickOutside([selectRef, buttonRef], () => setShowBlocks(false));

  const handleSelectedChange = (checked: boolean, selectedId: string) => {
    if (!checked) {
      setSelectedBlockIds(selectedBlockIds.filter(a => a !== selectedId));
      if (selectedBlockIds.length === 1) setSelectedKind(undefined);
    } else {
      setSelectedBlockIds([...selectedBlockIds, selectedId]);
    }
  };

  return (
    <Popover
      isOpen={showBlocks}
      position={['bottom']}
      align="end"
      content={
        <div ref={selectRef} className="BlockSelectPopover">
          <Text on="white" kind="subdued" size="s">
            Apply to selected blocks
          </Text>
          <Spacer height="0.25rem" />
          {allBlocksOfKind?.map(matchingBlock => {
            const [currentArtboard] = defaultArtboards.filter(
              artboard => artboard.id === matchingBlock.artboardName
            );
            const isSelected = selectedBlockIds.some(a => a === matchingBlock.id);
            return (
              <SelectBlockCheckBox
                key={matchingBlock.id}
                isSelected={isSelected}
                onChange={handleSelectedChange}
                block={matchingBlock}
                artboardName={currentArtboard.id}
              />
            );
          })}
        </div>
      }
    >
      <div>
        <Button
          buttonRef={buttonRef}
          kind="text"
          iconLeft={
            <FontAwesomeIcon icon={!showBlocks ? faChevronCircleRight : faChevronCircleDown} />
          }
          onClick={() => setShowBlocks(!showBlocks)}
        />
      </div>
    </Popover>
  );
};

const SelectBlockCheckBox: React.FC<{
  block: ArtboardBlocksType;
  onChange: (checked: boolean, id: string) => void;
  isSelected: boolean;
  artboardName: string;
}> = ({ block, onChange, isSelected, artboardName }) => {
  const artboardLabel = useRecoilValue(artboardLabelSelector(artboardName));
  return (
    <Checkbox
      key={block.id}
      id={block.id}
      label={artboardLabel}
      checked={isSelected}
      onChange={checked => onChange(checked, block.id)}
    />
  );
};
