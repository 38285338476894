import React from 'react';
import type { FC, ReactNode } from 'react';

import { Text } from '@eltoro-ui/components';
import { PaginationForRealtor } from 'Components';

import './Realtor.scss';

type RealtorPropsType = {
  title: string;
  subTitle?: string;
  children: ReactNode;
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  isDataFound: boolean;
};

export const Realtor: FC<RealtorPropsType> = ({
  title,
  subTitle,
  children,
  onPageChange,
  currentPage,
  totalPages,
  isDataFound,
}) => {
  return (
    <div className="realtor" style={{ height: isDataFound ? 'inherit' : 'auto' }}>
      <div className="realtor__header_section">
        <Text UNSAFE_className="realtor__title" on="white" weight="bold" textTransform="capitalize">
          {title}
        </Text>
        <Text UNSAFE_className="realtor__sub_title" on="white">
          {subTitle}
        </Text>
      </div>
      <div>{children}</div>
      {isDataFound && (
        <PaginationForRealtor
          onPageChange={onPageChange}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      )}
    </div>
  );
};
