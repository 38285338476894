import { FilterSetType, NotificationSettingsResponse, NotificationType, ResponseType } from 'types';
import { NotificationSettingType, UserDeviceEnum } from 'enums';

import requests from '../http';
import { setAuthHeader } from '../../Tools';

export const getAuth = async (
  email: string,
  password: string
): Promise<{ data: { _id: string; token: string } }> => {
  const auth = await requests.post('/token', {
    email,
    password,
  });
  setAuthHeader(auth?.data?.token);

  return auth;
};

export const getNotifications = async (): Promise<{
  data: {
    notifications: NotificationType[];
  };
}> => requests.get(`/notifications`);

// export const testNotifications = aysnc (body: object) : Promise<ResponseType<any>> =>{
// const req ='/notifications/test_notifications'
// return requests.post(req,body)
// }

export const getTestingNotications = async (body: any): Promise<ResponseType<any>> => {
  const req = '/notifications/test_notifications';
  return requests.post(req, body);
};

export const getNotificationSettings = async (): Promise<
  ResponseType<NotificationSettingsResponse>
> => {
  const req = '/notifications/settings?device=web';
  return requests.get(req);
};

export const pushNotificationSetting = async (body: {
  settings: { type: NotificationSettingType; status: number }[];
  device: UserDeviceEnum;
}): Promise<ResponseType<any>> => {
  return requests.post('/notifications', body);
};

export const getFilters = async (): Promise<{
  data: { filters: FilterSetType };
}> => requests.get('/filters');

export const getMlS = async () => requests.get('/listings/regions');
