import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import toast from 'react-hot-toast';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { useLocations } from 'Hooks';
import { Button, MaxHeightContainer, Spacer } from '@eltoro-ui/components';
import { Map, MapPopup, MapSearch, MapToolTip, mapLocationResolver } from 'Components';
import {
  AudienceResultsCard,
  LocationLabels,
  MapMarker,
  SaveAudienceWizard,
} from 'Pages/ProspectActivity/components';
import {
  LensSidebar,
  LensSidebarFooter,
  LensSidebarHeader,
} from 'Pages/ProspectActivity/lenses/components';

import { setSidebarLens } from 'Redux/actions';
import { getPlaceGeoJSON } from 'Requests/Request_Methods/prospectMethods';
import { getMapListings, postFYP } from 'Requests';
import { getDecryptedData, getIconBuzzColor } from 'Helpers';

import type { APIFeatureType, TLocation } from 'types';

import warning_icon from 'assets/Images/warning_icon.svg';

import './LikelySellers.scss';

export const LikelySellers = () => {
  const { locations, addLocation, hasLocations, clearLocation } = useLocations();
  const [polygonLocation, setPolygonLocation] = useState<TLocation | null>(null);
  const [features, setFeatures] = useState<APIFeatureType[]>([]);
  const dispatch = useDispatch();

  const showProspectsModal = () => {
    return locations.length >= 1 && isClicked.isLoaded;
  };
  const [listingArea, setlistingArea] = useState<any[]>([]);
  const [dataObj, setdataObj] = useState<any>();
  const [loading, setLoading] = useState(false);

  const resetStates = () => {
    setIsClicked({ isCancel: false, isLoaded: false });
    clearLocation();
    setlistingArea([]);
    setPolygonLocation(null);
  };

  const totalProspects = listingArea.reduce(
    (count, currFeature) => count + currFeature.unique_visitor_count,
    0
  );
  const searchProspect = useCallback((polygon: TLocation) => {
    setLoading(true);
    postFYP({
      bounding_box: polygon.bounding_box,
    })
      .then(async (res: any) => {
        const lisitingsData = [];
        if (res?.data) {
          const decryptedData = await getDecryptedData(res?.data?.response);

          lisitingsData.push(...decryptedData.listings);

          if (!decryptedData.listings.length) {
            toast.error('No listings found matching your search criteria.', {
              icon: <img src={warning_icon} alt="warn" />,
            });
          }
          if (res.data) {
            setIsClicked({ ...isClicked, isLoaded: true });
            setlistingArea(lisitingsData);
            setdataObj({
              ids: decryptedData.listings.map(
                (listing: { listingid: number }) => listing.listingid
              ),
              bounding_box: polygon.bounding_box,
            });
          }
        } else {
          setlistingArea([]);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
        if (!polygon) dispatch(setSidebarLens(true));
      });
  }, []);

  useEffect(() => {
    if (hasLocations) {
      getMapListings(locations.map(location => mapLocationResolver(location))).then(res => {
        if (res.data) {
          setFeatures(res.data.listings);
        }
      });
    } else {
      setFeatures([]);
    }
  }, [hasLocations, locations]);

  const [isClicked, setIsClicked] = useState({
    isCancel: false,
    isLoaded: false,
  });

  const getPolygon = async (): Promise<TLocation | null> => {
    try {
      setLoading(true);
      if (polygonLocation) setPolygonLocation(null);
      const data = await getPlaceGeoJSON(
        locations[0].originalFeature.properties.id,
        locations[0].originalFeature.properties.layer
      );
      if (data.length) {
        setPolygonLocation(data[0]);
        return data[0];
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }

    return null;
  };

  const onFindSellers = async () => {
    const polygon = await getPolygon();
    if (polygon) await searchProspect(polygon);
  };

  return (
    <div className="LikelySellers">
      <LensSidebar>
        <MaxHeightContainer
          fullHeight
          header={
            <LensSidebarHeader
              heading="Likely Sellers"
              subHeading={
                <span>
                  Engage with <b>potential sellers</b> and advertise your real estate services
                </span>
              }
            />
          }
          footer={<></>}
        >
          <div className="LikelySellers__search_location">Search a Location</div>
          <div className="LensSidebarHeader__sub-heading LensSidebarHeader__sub-heading__sub">
            e.g. City, Zip Code
          </div>
          <div className="LikelySellers__sidebar-content">
            {locations.length < 1 ? <MapSearch onSelect={addLocation} /> : ''}
            {hasLocations && (
              <>
                {locations.length < 1 ? <Spacer height="2rem" /> : ''}
                <LocationLabels loading={loading} locations={locations} onRemove={resetStates} />
                {locations.length < 1 ? <Spacer height="2rem" /> : ''}
                <div className="LikelySellers__sidebar-content__observation-filters">
                  <div className="LikelySellers__sidebar-content__observation-filters__content">
                    <div className="LikelySellers__search_location">Observation Filters:</div>
                    <div className="LikelySellers__sidebar-content__observation-filters__content__filter-inner">
                      <div className="filter">
                        <FontAwesomeIcon icon={faCheck} color="#FFAB03" />
                        <span>Visits active listings </span>
                      </div>
                      <div className="filter">
                        <FontAwesomeIcon icon={faCheck} color="#FFAB03" />
                        <span>Current residence is not active listing</span>
                      </div>
                    </div>
                  </div>
                  <div className="divider" />
                  <Button
                    UNSAFE_className="MyListingsLens__FooterBtn"
                    kind="primary"
                    weight="bold"
                    size="l"
                    width="100%"
                    disabled={loading}
                    loading={loading}
                    onClick={onFindSellers}
                  >
                    Find Sellers
                  </Button>
                </div>
              </>
            )}
          </div>
        </MaxHeightContainer>
      </LensSidebar>
      {showProspectsModal() && !!totalProspects && (
        <LensSidebarFooter>
          <AudienceResultsCard>
            <div className="MyListingsLens__score">
              <div className="MyListingsLens__scoreLeft">
                <p className="prospects_count">~{totalProspects.toLocaleString()}</p>
              </div>
              <div className="MyListingsLens__scoreRight">
                <b className="prospects_observed">Prospects observed</b>
              </div>
            </div>
            {totalProspects === 0 ? (
              <div>
                <p className="no_footfall">No footfall traffic has been seen in this area.</p>
              </div>
            ) : null}
            <SaveAudienceWizard
              type="Likely Sellers"
              dataObj={dataObj}
              onSaveAndClose={() => {
                resetStates();
              }}
              totalProspects={totalProspects}
              listing={features}
            />
          </AudienceResultsCard>
        </LensSidebarFooter>
      )}

      <div className="LikelySellers__map">
        <Map
          type="Likely Sellers"
          features={listingArea}
          locations={[]}
          polygon={polygonLocation}
          marker={f => <MapMarker color={getIconBuzzColor(f)} />}
          popup={f => <MapPopup feature={f} />}
          tooltip={f => <MapToolTip feature={f} />}
        />
      </div>
    </div>
  );
};
