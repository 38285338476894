import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';

import { Button, Modal, Text } from '@eltoro-ui/components';
import { LabelInputField } from 'Components/LabelInputField';
import { SaveAudienceModal } from 'Pages/ProspectActivity/components';
import type { AudienceDetailsType } from 'Pages/ProspectActivity/components';
import debounce from 'lodash.debounce';

import { v4 as uuidv4 } from 'uuid';

import type { AudienceTagType, TRootState } from 'types';

// import { setOpenTagModal } from 'Redux/actions';
import './SaveAudienceWizard.scss';

type SaveAudienceWizardProps = {
  onSaveAndClose: (audienceDetails: AudienceDetailsType) => void;
  totalProspects?: number;
  selectedZips?: string[];
  listing?: any;
  type?: string;
  dataObj?: any;
  radiusListing?: number[];
  disabled?: boolean;
  loading?: boolean;
  saveText?: string;
  seen_in_market?: boolean;
  handleOutsideClick?: (callback: () => void) => void;
  contactType?: 'seller' | 'buyer' | null;
};

export const SaveAudienceWizard = ({
  handleOutsideClick,
  onSaveAndClose,
  selectedZips,
  totalProspects,
  listing,
  type,
  dataObj,
  radiusListing,
  disabled,
  loading,
  seen_in_market,
  contactType = null,
  saveText = 'Save Target Audience',
}: SaveAudienceWizardProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    return () => {
      setModalOpen(false);
    };
  }, []);

  const handleSaveAudience = (audienceDetails: AudienceDetailsType) => {
    onSaveAndClose(audienceDetails);
    // TODO: Call to save target
    setModalOpen(false);
  };
  const [targetAudience, setTargetAudience] = useState('');
  const [val, setVal] = useState('');
  const dispatch = useDispatch();
  const [tagModal, setOpenTagModal] = useState(false);
  //  const { tagModal } = useSelector((state: TRootState) => state.openTagModalReducer);
  const [tags, setTags] = useState<AudienceTagType[]>([]);

  const debouncedVal = useRef(debounce(async (value: string) => setVal(value), 300)).current;

  useEffect(() => {
    return () => {
      debouncedVal.cancel();
    };
  }, [debouncedVal]);

  const addTag = () => {
    setTags(ps => [
      ...ps,
      {
        uuid: uuidv4(),
        value: val,
      },
    ]);
    setVal('');
    setOpenTagModal(false);
  };

  const saveAudienceDisabled = useMemo(() => {
    if (
      type === 'Virtual Just Listed/Just Sold' ||
      type === 'Grow Your Sphere' ||
      type === 'contacts'
    ) {
      return disabled;
    }

    return disabled || !totalProspects;
  }, [disabled, totalProspects, type]);

  const handleClick = () => {
    if (handleOutsideClick) {
      handleOutsideClick(() => setModalOpen(true));
    } else {
      setModalOpen(true);
    }
  };

  return (
    <div className="SaveAudienceWizard">
      <Button
        size="l"
        kind="primary"
        weight="bold"
        UNSAFE_className="SaveAudienceWizard__btn"
        onClick={handleClick}
        disabled={saveAudienceDisabled || loading}
        loading={loading}
      >
        {saveText}
      </Button>
      {!tagModal && modalOpen && (
        <SaveAudienceModal
          onClickOutside={() => setModalOpen(false)}
          selectedZips={selectedZips || []}
          onSave={handleSaveAudience}
          selectedTags={tags}
          listing={listing}
          totalProspects={totalProspects}
          setSelectedTags={setTags}
          dataObj={dataObj}
          radiusListing={radiusListing}
          type={type}
          seen_in_market={seen_in_market}
          contactType={contactType}
          setTagsModalOpen={setOpenTagModal}
          targetAudience={targetAudience}
          setTargetAudience={setTargetAudience}
        />
      )}
      {tagModal && modalOpen ? (
        <Modal offClick={() => setOpenTagModal(false)} className="SaveAudienceModal">
          <div className="OnboardingModal__welcome ListingModalTagBg SaveAudiencePosition">
            <div className="icon">
              <FontAwesomeIcon icon={faLongArrowAltLeft} onClick={() => setOpenTagModal(false)} />
            </div>
            <div className="OnboardingModal__welcomeHeader ListingAudienceModal">
              <div style={{ textAlign: 'center' }}>
                <Text on="white" weight="normal" size="xxxl" textAlign="center">
                  Add Tag
                </Text>
              </div>
              <br />
              <div
                style={{
                  // width: '280px',
                  margin: '0 30px',
                  textAlign: 'center',
                }}
              >
                <div style={{ textAlign: 'left' }}>
                  {/* <Text
                    on="grey-500"
                    size="m"
                    UNSAFE_style={{ marginBottom: '0.5rem' }}
                  >
                    Tag Name
                  </Text> */}

                  {/* <InputBox
                    placeHolder="Enter Tag"
                    border="1px solid "
                    wdt="100%"
                    paddingLeft="10px"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setVal(e.target.value)
                    }}
                  /> */}
                  <LabelInputField
                    placeholder="Tag"
                    title="Enter Tag"
                    type="text"
                    style={{ paddingLeft: '20px' }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      debouncedVal(e.target.value)
                    }
                  />
                  <Button
                    kind="primary"
                    size="xl"
                    width="100%"
                    weight="bolder"
                    UNSAFE_style={{ marginTop: '1rem' }}
                    onClick={addTag}
                    disabled={!val}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        ''
      )}
    </div>
  );
};
