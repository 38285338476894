import React from 'react';
import type { ReactNode } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';

import './CampaignErrorInfo.scss';

interface CampaignErrorInfoProps {
  title: ReactNode | string;
  subTitle: ReactNode | string;
}

const CampaignErrorInfo = ({ title, subTitle }: CampaignErrorInfoProps) => {
  return (
    <div className="campaign-error-message">
      <div className="campaign-error-message-info">
        <span className="icon">
          <FontAwesomeIcon icon={faExclamationCircle} color="#FF0000" style={{ fontSize: 31 }} />
        </span>
        <div className="campaign-error-message-info-details">
          <div className="campaign-error-message-info-details-title">{title}</div>
          <div className="campaign-error-message-info-details-content">{subTitle}</div>
        </div>
      </div>
    </div>
  );
};

export default CampaignErrorInfo;
