import { Button, Popover } from '@eltoro-ui/components';
import { useOnClickOutside } from '@eltoro-ui/hooks';
import { faChevronCircleDown, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { appliedBlockKindsSelector, selectedKindAtom } from '../../../../../State';
import './BlockQuickSelect.scss';

export const BlockQuickSelect: React.FC<{ label: string }> = ({ label }) => {
  const [showBlockKinds, setShowBlockKinds] = useState(false);
  const [selectedKind, setSelectedKind] = useRecoilState(selectedKindAtom);
  const blockKinds = useRecoilValue(appliedBlockKindsSelector);
  const selectRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  useOnClickOutside([selectRef, buttonRef], () => setShowBlockKinds(false));

  return (
    <Popover
      align="end"
      position={['bottom']}
      content={
        <div ref={selectRef} className="BlockQuickSelect">
          {blockKinds.map(blockKind => (
            <Button
              key={blockKind.kind}
              kind={selectedKind === blockKind.kind ? 'primary' : 'text'}
              onClick={() => setSelectedKind(blockKind.kind)}
            >
              {blockKind.label}
            </Button>
          ))}
        </div>
      }
      isOpen={showBlockKinds}
    >
      <div>
        <Button
          buttonRef={buttonRef}
          kind="text"
          onClick={() => blockKinds.length > 1 && setShowBlockKinds(!showBlockKinds)}
          iconRight={
            <FontAwesomeIcon icon={showBlockKinds ? faChevronCircleDown : faChevronCircleRight} />
          }
        />
      </div>
    </Popover>
  );
};
