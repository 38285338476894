import React from 'react';
import './Layout.scss';

type LayoutPropsType = {
  container_className?: string;
};

export const Layout: React.FC<LayoutPropsType> = ({ children, container_className }) => {
  return (
    <div className={`layout_container ${container_className}`}>
      <div className="layout">{children}</div>
    </div>
  );
};
