import React from 'react';
import './SummaryEditButton.scss';

export const SummaryEditButton: React.FC<{ onClick: () => void }> = ({ onClick, children }) => {
  return (
    <button className="SummaryEditButton" type="button" onClick={onClick}>
      {children}
    </button>
  );
};
