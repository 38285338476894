import React, { FC } from 'react';
import { Text } from '@eltoro-ui/components';
import './ProgressIndicator.scss';

type ProgressIndicatorType = {
  current: number;
  end: number;
  prefix?: string;
  styleXL?: 'xxxl' | 'xxl' | 'xl' | 'l' | 'm' | 's' | 'xs' | undefined;
  clasName?: string;
};

export const ProgressIndicator: FC<ProgressIndicatorType> = ({
  current,
  end,
  prefix = '',
  styleXL = 'm',
  clasName,
}) => {
  const progressPercent = () => {
    if ((current / end) * 100 > 100) return 100;
    if ((current / end) * 100 < 0) return 0;
    return (current / end) * 100 || 0;
  };
  const displayText = () => {
    // if (!current || !end) return '---'
    return (
      <Text
        on="white"
        UNSAFE_className="ProgressIndicator__daysOfTotal"
        size={styleXL}
        UNSAFE_style={{ color: '#2B1F0A' }}
        kind="subdued"
      >
        <span className="ProgressIndicator__daysOfTotalBold">{`${prefix}${current.toLocaleString()}`}</span>{' '}
        of {`${prefix}${end.toLocaleString()}`}
      </Text>
    );
  };

  return (
    <div className={`ProgressIndicator ${clasName}`}>
      <div className="ProgressIndicator__text">{displayText()}</div>
      <div className="ProgressIndicator__meter">
        <div className="ProgressIndicator__progress" style={{ width: `${progressPercent()}%` }} />
      </div>
    </div>
  );
};
