import React, { ReactNode } from 'react';
import './AudienceResultsCard.scss';

export const AudienceResultsCard = ({ children }: { children?: ReactNode }) => {
  return (
    <div className="AudienceResultsCard">
      {/* <div className="AudienceResultsCard__title"></div> */}
      {children}
    </div>
  );
};
