import React from 'react';
import { useSelector } from 'react-redux';
import { TRootState } from 'types';

const Contacts = () => {
  const { userData } = useSelector((state: TRootState) => state.userReducer);

  return (
    <>
      <h4>👋 Hey {userData.first_name}!</h4>
      <p>
        Import some of your contacts. This will allow us to send you daily notifications about their
        household&#39;s in market activity.
      </p>
    </>
  );
};

export default Contacts;
