import React from 'react';

import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { CreativeTypeEnum } from 'enums';
import type { TCreative } from 'types';

import './CreativeCard.scss';

type CreativeCardProps = {
  creative: TCreative;
  selected: boolean;
  used: boolean;
  onSelectCreative: () => void;
};

const CreativeCard = ({ creative, selected, used, onSelectCreative }: CreativeCardProps) => {
  return (
    <button
      className={classNames('creative-card', { selected, used })}
      onClick={onSelectCreative}
      disabled={used}
    >
      {(used || selected) && (
        <div className="selected-icon">
          <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: 25 }} />
        </div>
      )}
      <div className="creative-card-thumb-container">
        {creative?.creative_type === CreativeTypeEnum.BANNER ||
        (creative?.creative_type === CreativeTypeEnum.VIDEO && creative?.thumb_presigned_url) ? (
          <img
            src={(creative.thumb_presigned_url || creative.presigned_url) as string}
            alt={creative.name}
            className="creative-thumb"
          />
        ) : (
          <video className="creative-thumb">
            <source src={creative.presigned_url as string} type={`video/${creative.file_type}`} />
            <track src="captions_en.vtt" kind="captions" label="english_captions" />
          </video>
        )}
        {creative.creative_type === CreativeTypeEnum.VIDEO && (
          <span className="preview-btn-video">
            <FontAwesomeIcon
              icon={faPlayCircle}
              color="#FFFFFF"
              style={{
                fontSize: 24,
              }}
            />
          </span>
        )}
      </div>
      <div className="creative-card-info">
        <span className="creative-card-info-name">
          {creative.name}.{creative.file_type}
        </span>
        <span className="creative-card-info-type_specs">
          {creative.creative_type} - {creative.file_specs}
        </span>
      </div>
    </button>
  );
};

export default CreativeCard;
