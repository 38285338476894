import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import toast from 'react-hot-toast';

import moment from 'moment';
import classNames from 'classnames';

import type { SidebarProps } from 'primereact/sidebar';
import { Sidebar } from 'primereact/sidebar';
import { InputText, InputTextProps } from 'primereact/inputtext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faArrowsAltH, faEdit } from '@fortawesome/pro-regular-svg-icons';

import { FlexBox } from '@eltoro-ui/components';
import CreativeDetailSkeleton from 'Pages/CreativesLibrary/components/CreativeDetailsDrawer/elements/CreativeDetailSkeleton';
import ImagePreviewModal from 'Pages/CreativesLibrary/components/CreativeDetailsDrawer/elements/ImagePreviewModal';

import CustomIcons from 'assets/icons';

import type { CreativesListProps } from 'Pages/CreativesLibrary/components/CreativesList';

import { CreativeTypeEnum } from 'enums';
import type { TCreative, TCreativeDetails } from 'types';

import { downloadCreative, formatBytes } from 'Utils/helpers';

import {
  editCreativeName,
  getCreativeDetails,
} from 'Requests/Request_Methods/creativesLibraryMethods';

import './CreativeDetailsDrawer.scss';

export interface CreativeDetailsDrawerProps
  extends Pick<SidebarProps, 'onHide' | 'visible'>,
    Pick<CreativesListProps, 'updateCreativeAfterEditCreativeName'> {
  creative_uuid: TCreative['creative_uuid'] | undefined;
}

const CreativeDetailsDrawer = ({
  visible,
  onHide,
  creative_uuid,
  updateCreativeAfterEditCreativeName,
}: CreativeDetailsDrawerProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [creative, setCreative] = useState<TCreativeDetails | null>(null);
  const [creativeLoading, setCreativeLoading] = useState<boolean>(false);
  const [editCreativeNameLoading, setEditCreativeLoading] = useState<boolean>(false);

  const [creativeName, setCreativeName] = useState<string>(creative?.name || '');
  const [editInput, setEditInput] = useState<boolean>(false);

  const [previewModalOpen, setPreviewModalOpen] = useState<boolean>(false);

  const isValidName = useMemo(() => !!creativeName.trim(), [creativeName]);

  const getCreative = async (creative_uuid: TCreative['creative_uuid']) => {
    try {
      setCreativeLoading(true);

      const { data } = await getCreativeDetails(creative_uuid);

      setCreative(data);
      setCreativeName(data.name);
    } catch (e) {
      if ('detail' in e && typeof e.detail === 'string') toast.error(e.detail);
      else toast.error('Something went wrong!');
    } finally {
      setCreativeLoading(false);
    }
  };

  useEffect(() => {
    if (creative_uuid && visible) getCreative(creative_uuid);
  }, [creative_uuid, visible]);

  const header = <span className="creative-details-drawer-header-text">Creative Details</span>;

  const onHideDrawer = () => {
    onHide();
    setCreative(null);
  };

  const toggleEditInput = () => {
    setEditInput(true);
  };

  useEffect(() => {
    if (editInput && inputRef && inputRef.current) inputRef.current.focus();
  }, [editInput]);

  const onChange: InputTextProps['onChange'] = ({ target: { value } }) => setCreativeName(value);

  const onEditCreativeName = useCallback(
    async (value: Pick<TCreativeDetails, 'name'>) => {
      if (creative?.creative_uuid) {
        try {
          setEditCreativeLoading(true);

          const { data } = await editCreativeName(creative.creative_uuid, value);
          if (data) toast.success(data.msg);
          await updateCreativeAfterEditCreativeName({
            creative_uuid: creative.creative_uuid,
            ...value,
          });

          const updatedCreative = { ...creative, ...value };

          setCreative(updatedCreative);
        } catch (e) {
          if ('detail' in e && typeof e.detail === 'string') toast.error(e.detail);
        } finally {
          setEditCreativeLoading(false);
          setEditInput(false);
        }
      }
    },
    [creative?.creative_uuid]
  );

  const onBlur = async () => {
    if (inputRef && inputRef.current && creative?.name) {
      if (inputRef.current.value.trim()) {
        if (inputRef.current.value !== creative.name)
          await onEditCreativeName({ name: inputRef.current.value });
        else setEditInput(false);
      } else {
        setCreativeName(creative.name);
        setEditInput(false);
      }
    }
  };

  const onDownload = useCallback(() => {
    if (creative) {
      downloadCreative(creative);
    }
  }, [creative]);

  const onClosePreviewModal = () => setPreviewModalOpen(false);

  const onOpenPreviewModal = () => {
    if (creative?.creative_type === CreativeTypeEnum.BANNER) setPreviewModalOpen(true);
  };

  return (
    <>
      <Sidebar
        className="creative-details-drawer"
        position="right"
        header={header}
        visible={visible}
        onHide={editInput ? () => undefined : onHideDrawer}
        closeIcon={<FontAwesomeIcon icon={faTimes} style={{ fontSize: 22 }} />}
        appendTo={document.getElementById('root')}
      >
        {creativeLoading && <CreativeDetailSkeleton />}
        {creative && !creativeLoading && (
          <FlexBox flexDirection="column">
            <FlexBox flexDirection="column" gap="16px" UNSAFE_className="creative-info">
              <div className="creative-thumb-container">
                <div className="creative-thumb-container-filter">
                  {creative?.creative_type === CreativeTypeEnum.BANNER ||
                  (creative?.creative_type === CreativeTypeEnum.VIDEO &&
                    creative?.thumb_presigned_url) ? (
                    <img
                      src={(creative.thumb_presigned_url || creative.presigned_url) as string}
                      alt={creative.name}
                      className="creative-thumb"
                    />
                  ) : (
                    <video className="creative-thumb">
                      <source
                        src={creative.presigned_url as string}
                        type={`video/${creative.file_type}`}
                      />
                      <track src="captions_en.vtt" kind="captions" label="english_captions" />
                    </video>
                  )}
                </div>
                <button
                  aria-label="preview"
                  className={classNames(
                    'preview-btn',
                    creative?.creative_type === CreativeTypeEnum.VIDEO && 'video'
                  )}
                  onClick={onOpenPreviewModal}
                >
                  <FontAwesomeIcon
                    icon={
                      creative?.creative_type === CreativeTypeEnum.VIDEO
                        ? faPlayCircle
                        : faArrowsAltH
                    }
                    color="#FFFFFF"
                    style={{
                      fontSize: 24,
                    }}
                  />
                </button>
              </div>
              <FlexBox flexDirection="column" gap="12px">
                <div className="creative-info-section">
                  <span className="creative-info-section-title">Creative Name</span>
                  <FlexBox alignItems="center" justifyContent="space-between" gap="20px">
                    <span className="p-input-icon-right">
                      {editCreativeNameLoading && (
                        <i className="pi pi-spin pi-spinner" style={{ color: '#FFAB03' }} />
                      )}
                      <InputText
                        id="creative-name"
                        ref={inputRef}
                        className={classNames('creative-name-input', !isValidName && 'p-invalid')}
                        value={creativeName}
                        placeholder="Enter Creative Name"
                        onChange={onChange}
                        disabled={!editInput}
                        readOnly={!editInput}
                        onBlur={onBlur}
                      />
                    </span>
                    {!editInput && (
                      <button
                        aria-label="edit-name"
                        className="edit-name-action-btn"
                        onClick={toggleEditInput}
                      >
                        <FontAwesomeIcon icon={faEdit} color="#FFAB03" style={{ fontSize: 20 }} />
                      </button>
                    )}
                  </FlexBox>
                </div>
                <div className="creative-info-section">
                  <span className="creative-info-section-title">File Type</span>
                  <span className="creative-info-section-value">.{creative.file_type}</span>
                </div>
                <div className="creative-info-section">
                  <span className="creative-info-section-title">Creative Type</span>
                  <span className="creative-info-section-value type">{creative.creative_type}</span>
                </div>
                <div className="creative-info-section">
                  <span className="creative-info-section-title">File Status</span>
                  <span className="creative-info-section-value type">{creative.status}</span>
                </div>
                <div className="creative-info-section">
                  <span className="creative-info-section-title">File Size</span>
                  <span className="creative-info-section-value">
                    {formatBytes(creative.file_size)}
                  </span>
                </div>
                <div className="creative-info-section">
                  <span className="creative-info-section-title">File Specs</span>
                  <span className="creative-info-section-value">{creative.file_specs}</span>
                </div>
                <div className="creative-info-section">
                  <span className="creative-info-section-title">Order Lines</span>
                  <span className="creative-info-section-value">{creative.orderline_count}</span>
                </div>
              </FlexBox>
              <div className="creative-info-section">
                <span className="creative-info-section-title">Date Created</span>
                <span className="creative-info-section-value">
                  {moment(creative.created_at).format('MM/DD/YYYY')}
                </span>
              </div>
              <div className="creative-info-section">
                <span className="creative-info-section-title">Creative ID</span>
                <span className="creative-info-section-value">{creative.eltoro_creative_id}</span>
              </div>
            </FlexBox>
            <button className="download-creative-btn" onClick={onDownload}>
              <CustomIcons name="download" color="#FFAB03" fontSize={27} />
              <span>Download Creative</span>
            </button>
          </FlexBox>
        )}
      </Sidebar>
      {creative && previewModalOpen && (
        <ImagePreviewModal
          file_size={creative.file_size}
          name={creative.name}
          presigned_url={creative.presigned_url}
          offClick={onClosePreviewModal}
        />
      )}
    </>
  );
};

export default CreativeDetailsDrawer;
