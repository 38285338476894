import React, { memo } from 'react';
import { FlexBox, Text } from '@eltoro-ui/components';
import { SaveAudienceWizard } from 'Pages/ProspectActivity/components';
import notActiveImgSrc from 'assets/Images/not-active-contacts.svg';
import sellerImgSrc from 'assets/Images/sellers.svg';
import buyerImgSrc from 'assets/Images/buyers.svg';
import { RecomendationContactEnum } from 'enums';
import './styles.scss';

interface Props {
  hasActiveContacts: boolean;
  kind: RecomendationContactEnum;
}

const ActivityByKind = ({ hasActiveContacts, kind }: Props) => {
  const imgSrc = hasActiveContacts
    ? kind === RecomendationContactEnum.SELLER
      ? sellerImgSrc
      : buyerImgSrc
    : notActiveImgSrc;

  const desc = hasActiveContacts
    ? `Some of the potential ${
        kind === RecomendationContactEnum.SELLER ? 'seller' : 'buyer'
      } households in your contacts were seen in market in `
    : `None of your ${
        kind === RecomendationContactEnum.SELLER ? 'seller' : 'buyer'
      } contacts were seen in market in `;

  const btnText =
    kind === RecomendationContactEnum.SELLER ? 'Talk to likely sellers' : 'Talk to likely buyers';

  return (
    <div className="activity_container">
      <FlexBox flexDirection="column" alignItems="center">
        <img src={imgSrc} alt="not active contacts" />
        <Text on="white" UNSAFE_className="desc" UNSAFE_style={{ width: '300px' }}>
          {desc}
        </Text>
        <Text on="white" UNSAFE_className="desc" UNSAFE_style={{ marginBottom: '1rem' }}>
          last 90 days
        </Text>
        <SaveAudienceWizard
          onSaveAndClose={() => {}}
          saveText={btnText}
          disabled={!hasActiveContacts}
          type="contacts"
          contactType={kind === RecomendationContactEnum.SELLER ? 'seller' : 'buyer'}
        />
      </FlexBox>
    </div>
  );
};

export default memo(ActivityByKind);
