import React from 'react';
import { Spacer, Text } from '@eltoro-ui/components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWalking } from '@fortawesome/pro-solid-svg-icons';

import uploadCSV from 'assets/Images/Group286.png';
import target from 'assets/Images/target.png';
import user from 'assets/Images/user.png';

import { TargetsTypes } from 'Utils/constants';

import type { CampaignTargetType } from 'types';

import './CompactSavedAudiences.scss';

function audienceIcon(audience: string, targetIcon?: any) {
  if (targetIcon) {
    return targetIcon;
  }

  if (audience === 'AUDIENCE_TYPE_B2C') {
    return uploadCSV;
  }
  if (audience === 'AUDIENCE_TYPE_DC' || audience === 'AUDIENCE_TYPE_DEVICE') {
    return target;
  }
  return user;
}

export const CompactSavedAudiences = ({
  audiences,
  targetIcon,
  userIcon,
}: {
  audiences: CampaignTargetType[];
  targetIcon?: any;
  userIcon?: any;
}) => {
  return (
    <div className="CompactSavedAudiences">
      {audiences
        .filter(audience => TargetsTypes.includes(audience.type))
        .map(audience => (
          <div key={audience.id}>
            <div className="CompactSavedAudiences__audience-container">
              <div className="CompactSavedAudiences__image">
                <img src={audienceIcon(audience.type, targetIcon)} alt="" />
              </div>
              <div className="CompactSavedAudiences__body">
                <Text on="white" UNSAFE_className="CompactSavedAudiences__audience_headings">
                  {audience.beewo.name}
                </Text>
              </div>
              <div className="CompactSavedAudiences__description" />
              <div className="CompactSavedAudiences__border" />
              <div className="CompactSavedAudiences__prospects">
                <div className="CompactSavedAudiences__icon">
                  <FontAwesomeIcon icon={faWalking} style={{ color: '#f7a500' }} />
                </div>
                <div className="CompactSavedAudiences__prospects-text">
                  <div className="CompactSavedAudiences__text-display">
                    <span className="CompactSavedAudiences__span">
                      {audience.beewo.prospects_counts}
                    </span>

                    <div className="CompactSavedAudiences__prospect-name">prospects</div>
                  </div>
                </div>
              </div>
            </div>
            <Spacer />
          </div>
        ))}
    </div>
  );
};
