import React from 'react';
import { Button } from '@eltoro-ui/components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userDetail } from 'Requests/Request_Methods/authUserMethods';
import { authRoles } from 'routes';
import { TRootState } from 'types';
import { setUser } from 'Redux/actions';
import logo from '../../Images/logo.png';
import '../Loading/Loading.scss';
import './LoginHeader.scss';
import lockImg from '../../Images/lock.png';

export const LoginHeader = () => {
  const { userData } = useSelector((state: TRootState) => state.userReducer);
  const history = useHistory();
  const dispatch = useDispatch();
  const backToSuperAdmin = () => {
    const preToken = localStorage.getItem('user_view');
    if (typeof preToken === 'string') {
      localStorage.setItem('beewo_token', atob(preToken));
      userDetail(atob(preToken))
        .then(resp => {
          const tempData = {
            ...resp?.data,
            user_role: authRoles?.superAdmin,
          };
          dispatch(setUser(tempData));
          localStorage.setItem('user', btoa(JSON.stringify(tempData)));
          localStorage.removeItem('user_view');

          history.push('/dashboard');
        })
        .catch(err => {});
    }
  };
  return (
    <div>
      <div className="loginHeader">
        <img src={logo} alt="" className="loginHeader_logo" />
        {userData?.user_role === authRoles?.ultraAdmin && (
          <span className="admin-temp-btn" style={{ marginTop: '0px' }}>
            <Button
              UNSAFE_TEXTSTYLE="go-back-admin-text"
              UNSAFE_className="go-back-admin"
              onClick={() => {
                backToSuperAdmin();
              }}
              iconLeft={<img src={lockImg} alt="lock" />}
              // <Button
              // onClick={offClick}
              // UNSAFE_className="Modal__close"
            >
              Super Admin
            </Button>
          </span>
        )}
      </div>

      {/* <div className="loginHeader_hr"></div> */}
      <hr className="loginHeader_hr" />
    </div>
  );
};
