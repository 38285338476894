import React from 'react';
import type { Dispatch, SetStateAction, RefObject } from 'react';

import SignatureCanvas from 'react-signature-canvas';
import { Button as PrimeButton } from 'primereact/button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';

import { Button, FlexBox, Modal } from '@eltoro-ui/components';

import cross from 'assets/Images/cross.png';

import './SignatureModal.scss';

type SignatureModalProps = {
  signEmpty: [isSignEmpty: boolean, setIsSignEmpty: Dispatch<SetStateAction<boolean>>];
  signatureCanvasRef: RefObject<SignatureCanvas>;
  signLoading: boolean;
  onConfirmSign: () => void;
  onClose: () => void;
};

const SignatureModal = ({
  signEmpty: [isSignEmpty, setIsSignEmpty],
  onConfirmSign,
  signLoading,
  signatureCanvasRef,
  onClose,
}: SignatureModalProps) => {
  const onEnd = async () => {
    if (!signatureCanvasRef.current) return;
    setIsSignEmpty(signatureCanvasRef.current.isEmpty());
  };

  const onClear = () => {
    if (!signatureCanvasRef.current) return;
    signatureCanvasRef.current.clear();
    setIsSignEmpty(signatureCanvasRef.current.isEmpty());
  };

  return (
    <Modal className="SignatureModal">
      <button onClick={onClose} className="close-btn">
        <img src={cross} alt="cross" className="SignatureModal__cross" />
      </button>
      <FlexBox flexDirection="column" alignItems="center" gap="20px">
        <FlexBox flexDirection="column" alignItems="center" gap="4px">
          <span className="SignatureModal__title">
            Please review and sign the{' '}
            <a href="/terms-of-use.pdf" target="_blank" rel="noreferrer">
              Beewo Terms of Service
            </a>
          </span>
          <span className="SignatureModal__description">
            <span>
              By signing below, you acknowledge that you have read and agree to be bound by the
              Terms of Service. When you click “Confirm” after signing, your signature will be
              affixed to the Terms of Service.
            </span>
          </span>
        </FlexBox>
        <SignatureCanvas
          ref={signatureCanvasRef}
          penColor="green"
          canvasProps={{
            className: 'signature-container',
          }}
          onEnd={onEnd}
        />
        <FlexBox
          justifyContent="space-between"
          alignItems="center"
          UNSAFE_style={{ width: '100%' }}
        >
          <PrimeButton
            className="clear-signature-btn"
            icon={<FontAwesomeIcon icon={faTrash} />}
            label="Clear"
            severity="danger"
            text
            onClick={onClear}
          />
          <Button
            kind="primary"
            size="l"
            disabled={isSignEmpty}
            onClick={onConfirmSign}
            loading={signLoading}
            weight="bold"
          >
            Confirm
          </Button>
        </FlexBox>
      </FlexBox>
    </Modal>
  );
};

export default SignatureModal;
