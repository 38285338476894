import React from 'react';
import { Text as TextType } from 'konva/types/shapes/Text';
import { Text } from 'react-konva';
import { useRecoilValue } from 'recoil';
import {
  blockFontFamilyAtom,
  blockFontSizeAtom,
  blockFontStyleAtom,
  blockTextAlignAtom,
  blockTextColorAtom,
  blockTextDecorationAtom,
  blockTextValueAtom,
} from '../../../State';
import { BlockConfigType } from '../../../Typings';

// wraps Konva's Text with recoil related values to user can supply only a block config if needed
export const RecoilText: React.FC<{
  config: BlockConfigType;
  textRef?: React.RefObject<TextType>;
  width?: number;
  height?: number;
  x?: number;
  y?: number;
  name?: string;
  isOnMobile?: boolean;
}> = ({ config, textRef, width, height, x, y, name, isOnMobile = false }) => {
  const color = useRecoilValue(blockTextColorAtom(config));
  const fontSize = useRecoilValue(blockFontSizeAtom(config));
  const align = useRecoilValue(blockTextAlignAtom(config));
  const fontStyle = useRecoilValue(blockFontStyleAtom(config));
  const textDecoration = useRecoilValue(blockTextDecorationAtom(config));
  const fontFamily = useRecoilValue(blockFontFamilyAtom(config));
  const textValue = useRecoilValue(blockTextValueAtom(config));

  return (
    <Text
      name={name}
      ref={textRef}
      fill={color}
      x={x}
      y={y}
      height={height}
      width={width}
      align={align}
      text={textValue}
      fontSize={isOnMobile ? fontSize * 0.75 : fontSize}
      fontStyle={fontStyle}
      textDecoration={textDecoration}
      fontFamily={fontFamily}
      listening={false}
    />
  );
};
