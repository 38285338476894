import React, { useRef } from 'react';

import { Menu } from 'primereact/menu';
import type { MenuItem } from 'primereact/menuitem';
import type { ButtonProps } from 'primereact/button';
import { Button as PrimeButton } from 'primereact/button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTrash } from '@fortawesome/pro-light-svg-icons';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';

import CustomIcons from 'assets/icons';

import { CreativeStatusEnum } from 'enums';
import type { TCreative } from 'types';

interface CreativeActionAccessorProps {
  creative: TCreative;
  onRenameCreativeAction: (creative: TCreative) => MenuItem['command'];
  onDeleteCreativeAction: (creative: TCreative) => MenuItem['command'];
  onViewDetailsAction: (creative: TCreative) => MenuItem['command'];
  onDownloadCreativeAction: (creative: TCreative) => MenuItem['command'];
}

const CreativeActionAccessor = ({
  creative,
  onRenameCreativeAction,
  onDeleteCreativeAction,
  onViewDetailsAction,
  onDownloadCreativeAction,
}: CreativeActionAccessorProps) => {
  const creativeActionRef = useRef<Menu>(null);
  const menuRootWrapperRef = useRef<HTMLDivElement>(null);

  const toggleCreativeActionSelect: ButtonProps['onClick'] = event => {
    if (creativeActionRef && creativeActionRef.current) {
      creativeActionRef.current.toggle(event);
    }
  };

  const deleteItem =
    creative.status === CreativeStatusEnum.ACTIVE && creative.orderline_count
      ? []
      : [
          {
            label: 'Delete',
            className: 'delete',
            icon: <FontAwesomeIcon icon={faTrash} color="#ED6762" style={{ fontSize: 12 }} />,
            command: onDeleteCreativeAction(creative),
          },
        ];

  const items: MenuItem[] = [
    {
      label: 'Rename',
      icon: <CustomIcons name="edit" color="#222124" fontSize={12} />,
      command: onRenameCreativeAction(creative),
    },
    {
      label: 'View details',
      icon: <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: 12 }} />,
      command: onViewDetailsAction(creative),
    },
    {
      label: 'Download',
      icon: <CustomIcons name="download" fontSize={12} />,
      command: onDownloadCreativeAction(creative),
    },
    ...deleteItem,
  ];

  return (
    <div ref={menuRootWrapperRef} className="creative-actions-wrapper">
      <Menu
        className="creatives-actions-menu"
        ref={creativeActionRef}
        popup
        id="popup_menu_right"
        popupAlignment="right"
        model={items}
      />
      <PrimeButton
        className="creative-action-btn"
        disabled={creative.status === CreativeStatusEnum.UPLOADING}
        icon={
          <FontAwesomeIcon
            icon={faEllipsisV}
            color={creative.status === CreativeStatusEnum.UPLOADING ? '#D7D7D7' : '#757575'}
            style={{ fontSize: 20 }}
          />
        }
        onClick={toggleCreativeActionSelect}
        aria-controls="popup_menu_right"
        aria-haspopup
      />
    </div>
  );
};

export default CreativeActionAccessor;
