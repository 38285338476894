import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import ReactGA from 'react-ga4';
import toast from 'react-hot-toast';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faLockAlt } from '@fortawesome/pro-solid-svg-icons';

import { Button, Text, Modal } from '@eltoro-ui/components';
import { LoginHeader, LoginFooter, OnboardingModal } from 'Components';
import { LabelInputField } from 'Components/LabelInputField';
import { ErrorMsg } from 'Pages/PasswordSetup/components/ErrorMsg';

import { userRole } from 'Helpers';
import { setUser, setUserMail } from 'Redux/actions';

import { userDetail } from 'Requests/Request_Methods/authUserMethods';
import { tokenValidate, createNewUser, forgotPassword, userLogin } from 'Requests';

import leftGroup from 'assets/Images/leftgroup.svg';
import rightGroup from 'assets/Images/rightgroup.svg';
import logo from 'Images/Icon_color@2x.png';
import Warning from 'Images/warning.png';

import './PasswordSetup.scss';

export const PasswordSetup = () => {
  const dispatch = useDispatch();
  const Location = useLocation();

  const [modalOpen, setModalOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [pass, setPass] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [wrongPassword, setWrongPassword] = useState(Boolean);
  const [retypePassword, setRetypePassword] = useState(true);
  const [openOnboardingModal, setOpenOnboardingModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPass, setshowPass] = useState(false);
  const [showPass1, setshowPass1] = useState(false);
  const [email, setEmail] = useState('');
  const history = useHistory();
  const focusInput = useRef(null);
  const query = new URLSearchParams(Location.search).get('token')?.toString();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: Location.pathname + Location.search,
    });
  }, []);

  useEffect(() => {
    if (query && Location.pathname === '/verify-email') {
      tokenValidate(JSON.stringify(query))
        .then((response: any) => {
          if (response) {
            setIsValid(true);
            setEmail(response.data?.email);
          }
        })
        .catch((err: any) => {
          setIsValid(false);
          toast.error(`Token has been expired. Please try again with a new email.`);
          // alert('invalid token')
        });
    } else {
      setIsValid(true);
    }
  }, []);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();

    if (!(retypePassword || wrongPassword)) {
      if (Location.pathname === '/verify-email') {
        setIsValid(false);
        setLoading(true);
        const body = { email, password, token: query };

        createNewUser(body)
          .then(res => {
            if (res?.data?.sub) {
              dispatch(setUserMail(res.data.email));
              localStorage.setItem('userMail', res.data.email);
              setModalOpen(true);
              setLoading(false);
            } else {
              setIsValid(true);
              if (typeof res?.detail === 'string') {
                toast.error(res?.detail);
              }
            }
          })
          .catch(err => {
            toast.error(err.detail);
          });
      } else {
        setIsValid(false);
        const param = { token: query, new_password: password };
        forgotPassword(param).then(res => {
          if (res.detail) {
            setIsValid(true);
            toast.error('Inactive user');
            // alert('Inactive user')
          } else {
            setModalOpen(true);
          }
          // setResetModal(true)
        });
      }
      setLoading(false);
    }
  };
  const meApicall = (tkn: string) => {
    // if (tkn) {
    userDetail(tkn)
      .then(res => {
        if (res?.data?.email) {
          const user = {
            user_role: userRole(res.data.is_super),
            ...res.data,
          };
          dispatch(setUser(user));
          setModalOpen(false);
          // setModalStep(1)
          history.push('/dashboard');
        }
      })
      .catch((err: any) => {
        // setIsLoading(false)
      });
    // }
  };
  const Login = () => {
    const obj = { username: email, password };
    userLogin(obj).then((res: any) => {
      if (res?.data?.token) {
        localStorage.setItem('beewo_token', res?.data?.token);
        meApicall(res.data.token);
        // setOpenOnboardingModal(true)
      } else {
        toast.error('Session Expired for Reset Password');
      }
    });
    setModalOpen(false);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setLoading(false);
    if (
      e.target.value.match(
        /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[#$%=@!{},`~&*()'<>?. :;_|^/+\t\r\n\\\[\]"-])[a-zA-Z0-9#$%=@!{},`~&*()'<>?. :;_|^/+\t\r\n\\\[\]"-]{8,18}$/
      )
    ) {
      setWrongPassword(false);
      if (e.target.value !== pass) {
        setRetypePassword(true);
      } else {
        setRetypePassword(false);
      }
    } else {
      setWrongPassword(true);
    }
  };
  const repeatPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPass(e.target.value);
    setLoading(false);
    if (password === e.target.value) {
      setRetypePassword(false);
    } else {
      setRetypePassword(true);
    }
  };

  return (
    <div className="loginContainer">
      <LoginHeader />
      {openOnboardingModal && <OnboardingModal setModalOpen={setOpenOnboardingModal} />}
      <div className="mainLogin">
        <div className="password_setup_content">
          <div className="mainLogin__logo">
            <img src={logo} alt="beewo-logo" className="public-beewo-logo" />
          </div>

          <Text weight="bold" size="xl" on="white" UNSAFE_className="creat_acc_heading">
            Create New Password
          </Text>
          <div>
            <p className="Create_account_description">
              Set a password to complete your sign-up process.
            </p>
          </div>
          <br />
          <div className="Login__form">
            <form
              onSubmit={e => {
                onSubmit(e);
              }}
            >
              <div style={{ position: 'relative' }}>
                <LabelInputField
                  title="New Password"
                  placeholder="New Password"
                  id="enterPassword"
                  isError={wrongPassword && password.length > 0}
                  type={showPass === false ? 'password' : 'text'}
                  autoComplete="new-password"
                  iconLeft={<FontAwesomeIcon icon={faLockAlt} style={{ color: '#b3b0b0' }} />}
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                  EyePassIcon={
                    showPass ? (
                      <FontAwesomeIcon
                        icon={faEye}
                        style={{
                          color: '#b3b0b0',
                        }}
                        onClick={event => {
                          event.stopPropagation();
                          event.preventDefault();
                          if (focusInput.current) {
                            // @ts-ignore
                            focusInput.current.focus();
                          }
                          setshowPass(false);
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faEyeSlash}
                        style={{
                          color: '#b3b0b0',
                        }}
                        onClick={event => {
                          event.stopPropagation();
                          event.preventDefault();
                          document.getElementById('enterPassword')?.focus();
                          if (focusInput.current) {
                            //  @ts-ignore
                            focusInput.current.focus();
                          }
                          setshowPass(true);
                        }}
                      />
                    )
                  }
                />
                {wrongPassword && password.length > 0 ? (
                  <ErrorMsg
                    title="Password must contain min 8, max 18 characters including numbers, special characters, upper and lowercase letters."
                    iconStyle={{ alignItems: 'flex-start' }}
                    icon={
                      <img
                        className="start_icon"
                        style={{ marginTop: '-3px', width: '24px' }}
                        src={Warning}
                        alt="icon"
                      />
                    }
                  />
                ) : (
                  ''
                )}
              </div>
              <br />
              <LabelInputField
                title="Re-Enter New Password"
                id="reEnterPassword"
                placeholder="Re-Enter New Password"
                isError={retypePassword && pass.length > 0}
                type={showPass1 === false ? 'password' : 'text'}
                autoComplete="new-password"
                iconLeft={<FontAwesomeIcon icon={faLockAlt} style={{ color: '#b3b0b0' }} />}
                onChange={(e: any) => {
                  repeatPassword(e);
                }}
                EyePassIcon={
                  showPass1 ? (
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{
                        color: '#b3b0b0',
                      }}
                      onClick={event => {
                        event.stopPropagation();
                        event.preventDefault();
                        if (focusInput.current) {
                          // @ts-ignore
                          focusInput.current.focus();
                        }
                        setshowPass1(false);
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faEyeSlash}
                      style={{
                        color: '#b3b0b0',
                      }}
                      onClick={event => {
                        event.stopPropagation();
                        event.preventDefault();
                        document.getElementById('reEnterPassword')?.focus();
                        if (focusInput.current) {
                          //  @ts-ignore
                          focusInput.current.focus();
                        }
                        setshowPass1(true);
                      }}
                    />
                  )
                }
              />
              {retypePassword && pass.length > 0 ? (
                <ErrorMsg
                  title="You enter invaild password"
                  iconStyle={{ alignItems: 'flex-start' }}
                  icon={
                    <img
                      className="start_icon"
                      style={{ marginTop: '-3px', width: '24px' }}
                      src={Warning}
                      alt="icon"
                    />
                  }
                />
              ) : (
                ''
              )}
              <br />
              <Button
                UNSAFE_style={{
                  justifyContent: 'center',
                }}
                type="submit"
                kind="primary"
                size="xl"
                width="100%"
                fonts="16px"
                disabled={retypePassword || wrongPassword || !isValid}
                loading={loading}
                weight="bold"
              >
                Create Password
              </Button>
            </form>
          </div>
        </div>
      </div>
      {/* End login */}
      {modalOpen && (
        <Modal>
          <div
            style={{
              width: '30rem',
              padding: '3rem 2rem',
              position: 'relative',
            }}
            className="OnboardingModal__welcome"
          >
            <img
              src={leftGroup}
              alt="leftcardicon"
              style={{
                position: 'absolute',
                top: '0px',
                left: '0px',
                width: '23%',
                zIndex: '-1',
              }}
            />
            <div style={{ padding: '0px 2rem' }} className="OnboardingModal__welcomeHeader">
              <div style={{ textAlign: 'center' }}>
                <Text
                  UNSAFE_style={{ fontWeight: 800 }}
                  on="white"
                  weight="bold"
                  size="xxl"
                  textAlign="center"
                >
                  Password Set Successfully
                </Text>
              </div>
              <br />
              <div
                style={{
                  // width: '280px',
                  margin: '0 30px',
                  textAlign: 'center',
                }}
              >
                <p className="newpassword_model">
                  You can now use your new password to sign in to your account.
                </p>
              </div>
            </div>

            <Button
              UNSAFE_style={{
                borderRadius: '10px',
                padding: '26px',
                width: '100%',
              }}
              kind="primary"
              size="xl"
              weight="bold"
              UNSAFE_className="passwordReset__login"
              onClick={() => {
                if (Location.pathname === '/reset-password') {
                  if (localStorage.getItem('beewo_token')) {
                    history.push('/profile');
                  } else {
                    history.push('/login');
                  }
                } else {
                  Login();
                }
              }}
            >
              {localStorage.getItem('beewo_token') ? 'Done' : 'Sign in'}
            </Button>
            <img
              style={{
                position: 'absolute',
                bottom: '0px',
                right: '0px',
                width: '24%',
                zIndex: '-1',
              }}
              src={rightGroup}
              alt="righcardicon"
            />
          </div>
        </Modal>
      )}
      <LoginFooter />
    </div>
  );
};
