import React, { useEffect, useState } from 'react';

const CloudSpongeWidget = props => {
  useEffect(() => {
    addJavascript(
      `https://api.cloudsponge.com/widget/${process.env.REACT_APP_CLOUDSPONGE_KEY}.js`,
      '__cloudsponge_widget_script',
      () => {
        if (window.cloudsponge) {
          window.cloudsponge.init({
            sources: [
              'gmail',
              'yahoo',
              'outlookcom',
              'aol',
              'icloud',
              'office365',
              'outlook',
              'addressbook',
              'mailcom',
            ],
            afterClosing: () => {
              props.onCloseTypea();
            },
            afterInit: () => {
              props.setButtonActive(false);
            },
            // receive the contacts into your javascript and do what you like with them.
            afterSubmitContacts: contacts => {
              props.afterSubmitContacts(contacts);
            },
            browserContactCacheMin: 0,
          });
        }
      }
    );
  }, []);

  const addJavascript = (src, id, callback) => {
    if (id && document.getElementById(id)) {
      // the script is already loaded so just invoke the callback and return
      if (callback) callback();
      return;
    }

    // create and add the scrpt tag
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.async = true;
    if (id) {
      scriptTag.id = id;
    }

    // set the script to invoke a callback after it loads
    if (callback) {
      if (scriptTag.readyState) {
        // IE7+
        scriptTag.onreadystatechange = () => {
          if (scriptTag.readyState === 'loaded' || scriptTag.readyState === 'complete') {
            // clear the callback so it only ever executes once
            scriptTag.onreadystatechange = null;
            callback();
          }
        };
      } else {
        scriptTag.onload = () => {
          // Other browsers support the onload attribute \o/
          callback();
        };
      }
    }

    // assign the src attribute
    scriptTag.src = src;
    // add the script to the page
    document.body.appendChild(scriptTag);
  };
  return <>{props.children}</>;
};

export default CloudSpongeWidget;
