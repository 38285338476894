import React, { useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import toast from 'react-hot-toast';

import { Button, MaxHeightContainer, Spacer, Checkbox, Text, Table } from '@eltoro-ui/components';
import { useLocations } from 'Hooks';
import {
  AudienceResultsCard,
  BedroomNBathFilter,
  LocationLabels,
  MapMarker,
  PriceFilter,
  SaveAudienceWizard,
} from 'Pages/ProspectActivity/components';
import { buzzFilters } from 'Pages/ProspectActivity/configs';
import { SquareFootFilter } from 'Pages/ProspectActivity/components/FindProspectFilters/SquareFootFilter';
import {
  BuzzScoreFilters,
  Map,
  MapPopup,
  MapSearch,
  MapToolTip,
  Realtor,
  mapLocationResolver,
} from 'Components';
import type { BuzzFilterType } from 'Components';
import {
  LensFilterContainer,
  LensSidebar,
  LensSidebarFooter,
  LensSidebarHeader,
} from 'Pages/ProspectActivity/lenses/components';

import { getMapListings, postFYP, getRealtorActivity } from 'Requests';
import { getIconBuzzColor, removeKeyFromObject, getDecryptedData } from 'Helpers';

import { setSidebarLens } from 'Redux/actions';

import type { APIFeatureType, FilterDataType, FilterType, MinMaxType, TRealtorType } from 'types';

import warning_icon from 'assets/Images/warning_icon.svg';

import './SellYourListingFaster.scss';

const defaults = {
  listing_price: {
    id: 'listing_price',
    label: 'Listing price',
    options: { min: 0, max: 5000000 },
  },
  beds: {
    id: 'beds',
    label: 'Minimum number of bedrooms',
    options: [1, 2, 3, 4, 5],
  },
  baths: {
    id: 'baths',
    label: 'Minimum number of bathrooms',
    options: [1, 2, 3, 4, 5],
  },
  status: {
    id: 'status',
    label: 'Availibility Status',
    options: ['Active', 'Contingent', 'Under Contract', 'Sold'],
  },
  year_built: {
    id: 'year_built',
    label: 'Year Built',
    options: { min: 0, max: 2021 },
  },
};

type RealtorFilterType = {
  page: number;
  sort: string;
  order: 'desc' | 'asc';
};

export const SellYourListingFaster = () => {
  const { locations, addLocation, removeLocation, hasLocations, clearLocation } = useLocations();
  const [features, setFeatures] = useState<APIFeatureType[]>([]);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState<{ [key: string]: FilterType }>({});
  const dispatch = useDispatch();
  const [activeFilters, setActiveFilters] = useState<{ [key: string]: FilterType }>(defaults);
  const [activeBuzzFilters, setActiveBuzzFilters] = useState(buzzFilters);

  const [isClicked, setIsClicked] = useState({
    isCancel: false,
    isLoaded: false,
  });
  const [activeToggleFeature, setActiveToggleFeature] = useState<string>('');
  // Filters dropdown positioning
  const [searchRect, setSearchRect] = useState<DOMRect>();
  const measuredFiltersRef = useCallback(
    (node: HTMLButtonElement) => {
      if (node !== null) {
        setSearchRect(node.getBoundingClientRect());
      }
    },
    [showFilters]
  );
  // prospectFilters={beds:}
  // used for onChange in active Filter component - this needs to be debounced for server side filtering
  const updateActiveFilters = (filter: { [key: string]: FilterType }) => {
    setActiveFilters(prev => ({
      ...prev,
      ...filter,
    }));
  };

  useEffect(() => {
    if (!locations.length) {
      setActiveToggleFeature('');
      setRealtorData(null);
      setRealtorFilters({
        page: 1,
        sort: 'active_listings',
        order: 'desc',
      });
      setActiveBuzzFilters(buzzFilters);
    }
  }, [locations]);

  const [realtorFilters, setRealtorFilters] = useState<RealtorFilterType>({
    page: 1,
    sort: 'active_listings',
    order: 'desc',
  });

  const [realtorData, setRealtorData] = useState<TRealtorType | null>(null);

  useEffect(() => {
    if (locations.length) {
      getRealtorActivity({ ...realtorFilters, size: 5, bounding_box: locations[0].bounding_box })
        .then(res => {
          if (res?.data) {
            setRealtorData(res?.data);
          }
        })
        .catch((err: any) => {
          if ('detail' in err && typeof err.detail === 'string') toast.error(err.detail);
        });
    }
  }, [locations, realtorFilters]);

  const onPageChange = (page: number) => {
    setRealtorFilters({ ...realtorFilters, page });
  };
  const onSort = () => {
    const order = realtorFilters.order === 'desc' ? 'asc' : 'desc';
    setRealtorFilters({ ...realtorFilters, order, page: 1 });
  };

  // add or remove filter object
  const handleFilterActivation = (key: string, checked: boolean) => {
    if (checked) {
      setActiveFilters(prev => ({
        ...prev,
        [key]: filters[key],
      }));
    } else {
      setActiveFilters(prev => removeKeyFromObject(key, prev));
    }
  };

  const isActiveFilter = (k: string) =>
    Object.keys(activeFilters).some((filter: string) => filter === k);

  const filterSelections = (filterSet: { [key: string]: FilterType }) => {
    return Object.entries(filterSet).reduce((acc, filter) => {
      if (filter[1].data) {
        if ((filter[1].data as { label: string }).label) {
          return {
            ...acc,
            [filter[0]]: parseInt((filter[1].data as { label: string }).label, 10),
          };
        }
        return {
          ...acc,
          [filter[0]]: filter[1].data,
        };
      }
      return acc;
    }, {});
  };

  // this filtering will most likely be done on the server, but some of the filtering might be more
  // perfomant with the client-side filtering of returned meta data of smaller API responses.
  // TLDR; this filtering should probably be done server-side

  const filterFeatures = (
    feats: APIFeatureType[],
    selectedFilters: { [key: string]: FilterDataType }
  ) => {
    return Object.entries(selectedFilters).reduce((filteredFeatures: APIFeatureType[], flt) => {
      return filteredFeatures.reduce((matchingFeatures: APIFeatureType[], feature) => {
        const matchingData = Object.entries(feature).reduce((acc: any[], dataEntry) => {
          if (flt[0] === dataEntry[0]) {
            if (dataEntry[1] && typeof flt[1] === 'object') {
              if ((flt[1] as MinMaxType).max >= 0 && (flt[1] as MinMaxType).min >= 0) {
                const range = flt[1] as MinMaxType;
                if (dataEntry[1] >= range.min && dataEntry[1] <= range.max)
                  return [...acc, dataEntry];
              }
            }
            if (dataEntry[1] && typeof flt[1] === 'number') {
              if (dataEntry[1] >= flt[1]) return [...acc, dataEntry];
            }
            if (dataEntry[1] && Array.isArray(flt[1])) {
              if (flt[1].some((value: string | number) => dataEntry[1] === value))
                return [...acc, dataEntry];
            }
            // data currently does not have a boolean meta data
            /* if (dataEntry[1] && typeof flt[1] === 'boolean') {
             *   if (dataEntry[1] === flt[1]) acc.concat(dataEntry)
             * } */
            return acc;
          }
          return acc;
        }, []);
        if (matchingData.length) return [...matchingFeatures, feature];
        return matchingFeatures;
      }, []);
    }, feats);
  };
  const [listingArea, setlistingArea] = useState<any>([]);
  const [dataObj, setdataObj] = useState<any>();
  const [loading, setLoading] = useState(false);

  const resetStates = () => {
    clearLocation();
    setlistingArea([]);
  };
  const totalProspects = listingArea.reduce(
    (count: any, currFeature: any) => count + currFeature.unique_visitor_count,
    0
  );
  const [range, setRange] = useState({ price: ['0', '1'], area: [1, 2] });
  const [filteres, setFilteres] = useState<any>([]);

  const bedFilter = () =>
    postedFilter.bed &&
    filteres.push({
      key: 'beds',
      value: [postedFilter.bed],
      type: 'greater',
    });
  const bathFilter = () =>
    postedFilter.bath &&
    filteres.push({
      key: 'baths',
      value: [postedFilter.bath],
      type: 'greater',
    });
  const isPriceSelectedAny = (price: string) => price === 'Any';
  const priceFilter = (price: any) =>
    postedFilter.price &&
    filteres.push({
      key: 'listing_price',
      value: isPriceSelectedAny(price.price[0]) ? ['0'] : [price?.price[0], price?.price[1]],
      type: isPriceSelectedAny(price.price[0]) ? 'greater' : 'between',
    });

  const isAreaSelectedAny = (area: string) => area === 'Any';
  const areaFilter = (area: any) =>
    postedFilter.squareFoot &&
    filteres.push({
      key: 'size',
      value: isAreaSelectedAny(area?.area[0]) ? ['0'] : [area?.area[0], area?.area[1]],
      type: isAreaSelectedAny(area?.area[0]) ? 'greater' : 'between',
    });
  const searchProspect = useCallback(
    async (BuzzFilters?: any) => {
      if (modalOpen) setIsModalOpen(initialModal);

      const Buzz = ['High buzz', 'Warm buzz', 'Medium buzz', 'Low buzz'];
      const buzzExcluded = Buzz.filter((v: any) => {
        const buzzFills = BuzzFilters ?? activeBuzzFilters;
        return !buzzFills.find((buzz: any) => buzz.label === v);
      });

      const localPriceNArea = {
        price: postedFilter?.price?.replaceAll(' ', '').split('-') || range.price,
        area: postedFilter?.squareFoot?.replaceAll(' ', '').split('-') || range.area,
      };
      setRange({
        price: postedFilter?.price?.replaceAll(' ', '').split('-') || range.price,
        area: postedFilter?.squareFoot?.replaceAll(' ', '').split('-') || range.area,
      });

      bedFilter();
      bathFilter();
      areaFilter(localPriceNArea);
      priceFilter(localPriceNArea);

      setLoading(true);

      postFYP({
        exclude: buzzExcluded,
        filters: filteres,
        bounding_box: locations[0].bounding_box,
      })
        .then(async (res: any) => {
          setFilteres([]);
          const lisitingsData = [];
          if (res?.data) {
            const decryptedData = await getDecryptedData(res?.data?.response);

            lisitingsData.push(...decryptedData.listings);

            if (!decryptedData.listings.length) {
              toast.error('No listings found matching your search criteria.', {
                icon: <img src={warning_icon} alt="warn" />,
              });
            }
            if (res.data) {
              setdataObj({
                exclude: buzzExcluded,
                ids: decryptedData.listings.map(
                  (listing: { listingid: number }) => listing.listingid
                ),
                bounding_box: locations?.[0]?.bounding_box,
                filters: filteres,
              });

              setIsClicked({ ...isClicked, isLoaded: true });
              setlistingArea(lisitingsData);
            }
          } else {
            setFilteres([]);
            setlistingArea([]);
          }
        })
        .catch((err: any) => {
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
          if (!locations.length) dispatch(setSidebarLens(true));
        });
    },
    [activeBuzzFilters, locations, isClicked, filteres]
  );

  useEffect(() => {
    if (locations.length < 1) {
      setCurrentFilters({
        bed: null,
        bath: null,
        price: null,
        squareFoot: null,
      });
      setPostedFilter({
        bed: null,
        bath: null,
        price: null,
        squareFoot: null,
      });
      setIsClicked({ ...isClicked, isCancel: false, isLoaded: false });
      setlistingArea([]);
    }
  }, [locations]);

  useEffect(() => {
    if (hasLocations) {
      getMapListings(locations.map(location => mapLocationResolver(location))).then(res => {
        if (res.data) {
          setFeatures(res.data.listings);
        }
      });
    } else {
      setFeatures([]);
    }
  }, [hasLocations, locations]);
  const filteredFeatures = Object.keys(filterSelections(activeFilters)).length
    ? filterFeatures(features, filterSelections(activeFilters))
    : features;

  const initialModal = {
    bedNBath: false,
    price: false,
    squareFoot: false,
  };

  const [modalOpen, setIsModalOpen] = useState(initialModal);

  const [currentFilters, setCurrentFilters] = useState({
    bed: null,
    bath: null,
    price: null,
    squareFoot: null,
  });
  const [postedFilter, setPostedFilter] = useState<any>({
    bed: null,
    bath: null,
    price: null,
    squareFoot: null,
  });

  const onChangeBuzzScoreFilters = async (BuzzFilters: BuzzFilterType[]) => {
    await setActiveBuzzFilters(BuzzFilters);
    await searchProspect(BuzzFilters);
  };
  const getFiletersBoxBody = () => {
    let component = null;

    if (activeToggleFeature === 'realtor_filter') {
      component = (
        <div className="realtor_container">
          {realtorData && (
            <Realtor
              subTitle="Top brokerages"
              title={locations[0]?.value || ''}
              onPageChange={onPageChange}
              currentPage={realtorData.current_page}
              totalPages={realtorData.total_pages}
              isDataFound={!!realtorData?.data?.length}
            >
              {!realtorData.data.length ? (
                <div className="not-found-realtor" style={{ textAlign: 'center' }}>
                  <Text on="grey-050" kind="subdued">
                    No Data Found of{' '}
                    <span
                      style={{
                        textTransform: 'capitalize',
                      }}
                    >
                      {locations[0]?.value}
                    </span>
                  </Text>
                </div>
              ) : (
                <Table
                  rows={realtorData.data}
                  fixedCellSize
                  columns={[
                    {
                      path: 'brokrage',
                      label: 'BROKERAGE',
                      alignment: 'left',
                      RowCell: listing => <b>{listing.brokrage}</b>,
                    },
                    {
                      path: 'active_listings',
                      label: 'ACTIVE LISTINGS',
                      alignment: 'center',
                      onSort,
                    },
                  ]}
                />
              )}
            </Realtor>
          )}
        </div>
      );
    } else if (activeToggleFeature === 'buzz_filter') {
      component = (
        <LensFilterContainer>
          <BuzzScoreFilters
            filters={buzzFilters}
            activeFilters={activeBuzzFilters}
            onChange={onChangeBuzzScoreFilters}
          />
        </LensFilterContainer>
      );
    }

    return component;
  };

  const activeFilterOnClick = (filterType: string) => {
    return activeToggleFeature === filterType
      ? setActiveToggleFeature('')
      : setActiveToggleFeature(filterType);
  };

  return (
    <div className="SellYourListingFaster">
      {modalOpen.bedNBath && (
        <div className="SellYourListingFaster__sideFilter bedrooms">
          <BedroomNBathFilter
            currentFilters={currentFilters}
            setBed={setCurrentFilters}
            setBath={setCurrentFilters}
            modalOpen={modalOpen}
            setIsModalOpen={setIsModalOpen}
            postedFilters={postedFilter}
            setPostedFilter={setPostedFilter}
            setIsClicked={setIsClicked}
            isClicked={isClicked}
          />
        </div>
      )}

      {modalOpen.price && (
        <div className="SellYourListingFaster__sideFilter price">
          <PriceFilter
            currentFilters={currentFilters}
            setPrice={setCurrentFilters}
            modalOpen={modalOpen}
            setIsModalOpen={setIsModalOpen}
            postedFilters={postedFilter}
            setPostedFilter={setPostedFilter}
            setIsClicked={setIsClicked}
            isClicked={isClicked}
          />
        </div>
      )}
      {modalOpen.squareFoot && (
        <div className="SellYourListingFaster__sideFilter sqft">
          <SquareFootFilter
            currentFilters={currentFilters}
            postedFilters={postedFilter}
            setPostedFilter={setPostedFilter}
            setSquareFeet={setCurrentFilters}
            modalOpen={modalOpen}
            setIsModalOpen={setIsModalOpen}
            setIsClicked={setIsClicked}
            isClicked={isClicked}
          />
        </div>
      )}
      <LensSidebar>
        <MaxHeightContainer
          fullHeight
          header={
            <LensSidebarHeader
              heading="Sell Your Listing Faster"
              subHeading={
                <>
                  Engage with <b>potential buyers</b> seen at listings that are similar to yours and
                  advertise your listing
                </>
              }
            />
          }
          footer={<></>}
        >
          <div className="SellYourListingFaster__search_location">Search a Location</div>
          <div className="search-location">
            <div className="SellYourListingFaster__city_zipcode">e.g. City, Zip Code</div>
          </div>
          <div className="SellYourListingFaster__sidebar-content">
            {locations.length < 1 ? <MapSearch onSelect={addLocation} /> : ''}
            {hasLocations && (
              <>
                {locations.length < 1 ? <Spacer height="2rem" /> : ''}
                <LocationLabels
                  loading={loading}
                  locations={locations}
                  onRemove={(rest: any) => {
                    removeLocation(rest);
                    setIsModalOpen({
                      bedNBath: false,
                      price: false,
                      squareFoot: false,
                    });
                  }}
                />
                {locations.length < 1 ? <Spacer height="2rem" /> : ''}
                <Text on="white" size="m" weight="bold">
                  <span className="filters_prospects">Filters:</span>
                </Text>

                <div>
                  <Button
                    kind="default"
                    UNSAFE_className={`first_filter_button ${
                      currentFilters.bed && currentFilters.bath
                        ? 'SellYourListingFaster__sidebarFiltersButton'
                        : 'SellYourListingFaster__sidebarFiltersUnselectedButton'
                    }`}
                    size="l"
                    width="100%"
                    onClick={() => {
                      setIsModalOpen({ ...initialModal, bedNBath: true });
                    }}
                  >
                    {currentFilters.bed && currentFilters.bath
                      ? `${currentFilters.bed} Bed/${currentFilters.bath} Bath`
                      : 'Bed/Bath'}
                  </Button>
                  <Button
                    kind="default"
                    UNSAFE_className={
                      currentFilters.price
                        ? 'SellYourListingFaster__sidebarFiltersButton'
                        : 'SellYourListingFaster__sidebarFiltersUnselectedButton'
                    }
                    onClick={() => {
                      setIsModalOpen({ ...initialModal, price: true });
                    }}
                    size="l"
                    width="100%"
                  >
                    {currentFilters.price ? `${currentFilters.price}` : 'Price'}
                  </Button>
                  <Button
                    kind="default"
                    UNSAFE_className={
                      currentFilters.squareFoot
                        ? 'SellYourListingFaster__sidebarFiltersButton'
                        : 'SellYourListingFaster__sidebarFiltersUnselectedButton'
                    }
                    size="l"
                    width="100%"
                    onClick={() => {
                      setIsModalOpen({ ...initialModal, squareFoot: true });
                    }}
                  >
                    {currentFilters.squareFoot ? `${currentFilters.squareFoot}` : 'Squarefoot'}
                  </Button>
                  <Button
                    UNSAFE_className="MyListingsLens__FooterBtn"
                    kind="primary"
                    weight="bold"
                    size="l"
                    width="100%"
                    onClick={() => {
                      searchProspect();
                    }}
                    disabled={loading}
                    loading={loading}
                  >
                    Search
                  </Button>
                </div>
              </>
            )}
          </div>
        </MaxHeightContainer>
      </LensSidebar>
      {locations.length >= 1 && isClicked.isLoaded && (
        <div>
          <div className="SellYourListingFaster__rightTop" style={{ display: 'flex' }}>
            <Button
              UNSAFE_className="GrowYourSphere__rightTopButton"
              UNSAFE_style={{
                background: activeToggleFeature === 'buzz_filter' ? '#FFB708' : '',
              }}
              onClick={() => activeFilterOnClick('buzz_filter')}
            >
              Buzz Filter
            </Button>
            <Button
              UNSAFE_className="GrowYourSphere__rightTopButton"
              UNSAFE_style={{
                background: activeToggleFeature === 'realtor_filter' ? '#FFB708' : '',
              }}
              onClick={() => activeFilterOnClick('realtor_filter')}
            >
              Brokerages
            </Button>
          </div>
          <div className="MyListingsLens__filter">{getFiletersBoxBody()}</div>
        </div>
      )}
      {!!listingArea.length && (
        <LensSidebarFooter>
          <AudienceResultsCard>
            <div className="MyListingsLens__score">
              <div className="MyListingsLens__scoreLeft">
                <p className="prospects_count">
                  {totalProspects ? '~' : ''}
                  {totalProspects.toLocaleString()}
                </p>
              </div>
              <div className="MyListingsLens__scoreRight">
                <b className="prospects_observed">Prospects observed</b>
              </div>
            </div>
            {totalProspects === 0 ? (
              <div>
                <p className="no_footfall">No footfall traffic has been seen in this area.</p>
              </div>
            ) : null}
            <SaveAudienceWizard
              type="Sell Your Listing Faster"
              dataObj={dataObj}
              onSaveAndClose={() => {
                resetStates();
              }}
              totalProspects={totalProspects}
              listing={filteredFeatures}
            />
          </AudienceResultsCard>
        </LensSidebarFooter>
      )}

      <div
        className="SellYourListingFaster__map"
        aria-hidden="true"
        onClick={() => {
          setIsModalOpen(initialModal);
        }}
      >
        <Map
          type="Sell Your Listing Faster"
          features={listingArea}
          locations={[]}
          marker={f => <MapMarker color={getIconBuzzColor(f)} />}
          popup={f => <MapPopup feature={f} />}
          tooltip={f => <MapToolTip feature={f} />}
        />
      </div>
      {showFilters &&
        ReactDOM.createPortal(
          <div
            className="SellYourListingFaster__filter-selection"
            style={{
              top: `${searchRect?.bottom}px`,
              left: `${searchRect?.left}px`,
            }}
          >
            {Object.keys(filters).map((key: string) => (
              <Checkbox
                key={filters[key].label}
                checked={isActiveFilter(key)}
                onChange={checked => handleFilterActivation(key, checked)}
                label={filters[key].label}
              />
            ))}
          </div>,
          document.getElementById('root') as HTMLElement
        )}
    </div>
  );
};
