import React, { useCallback, useEffect, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';

import classNames from 'classnames';

import { InputText } from 'primereact/inputtext';
import type { InputTextProps } from 'primereact/inputtext';

import { isEllipsisActive } from 'Utils/helpers';
import { useResizeEffect } from 'Hooks';

import './ContactInformation.scss';

export const ContactInformation = ({
  icon,
  title,
  content,
  readOnly = false,
  onChange,
  error,
  UNSAFE_CLASSNAME,
}: {
  icon?: any;
  title: string;
  content?: string;
  UNSAFE_CLASSNAME?: string;
  readOnly?: boolean;
  error?: string;
  onChange?: InputTextProps['onChange'];
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [tooltipDisabled, setTooltipDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (inputRef.current) setTooltipDisabled(isEllipsisActive(inputRef.current as HTMLElement));
  }, [inputRef.current, content]);

  const setTooltipDisabledOnResize = useCallback(() => {
    if (!inputRef.current) return;
    setTooltipDisabled(isEllipsisActive(inputRef.current as HTMLElement));
  }, [inputRef.current]);

  // @ts-ignore
  useResizeEffect(setTooltipDisabledOnResize, [inputRef]);

  const onBlur = () => setTooltipDisabledOnResize();

  return (
    <div className={classNames('contactDetail__contentBox', UNSAFE_CLASSNAME)}>
      <img src={icon} alt={title} className="contactDetail__contentBox__icon" />
      <div className="ContactInformation__title">
        <label htmlFor={title}>{title}</label>
        <Tippy placement="top" disabled={tooltipDisabled} content={content}>
          <div>
            <InputText
              id={title}
              ref={inputRef}
              className={classNames('contact-input', error && 'p-invalid')}
              value={readOnly && !content ? '-' : content ?? ''}
              placeholder={`Enter ${title}`}
              onChange={onChange}
              disabled={readOnly}
              readOnly={readOnly}
              onBlur={onBlur}
            />
          </div>
        </Tippy>
        {!readOnly && error && (
          <span className={classNames('contact-input-error', 'p-error')}>{error}</span>
        )}
      </div>
    </div>
  );
};
