import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import type { Column, ColumnGroup } from 'react-table';

import { EmptyStatus, ReactTable } from 'Components';
import { Button, FlexBox } from '@eltoro-ui/components';
import type { TContact, TRootState } from 'types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faBadgeCheck, faSync } from '@fortawesome/pro-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import contactsImporting from 'assets/Images/contacts-loading.gif';

import { removeTimeFromDate } from 'Helpers';
import AddressesAccessor from 'Pages/ProspectActivity/lenses/TalkToSphereLens/TalkToSphereList/AddressesAccessor';

import './TalkToSphereList.scss';

type TalkToSphereListProps = {
  buttonActive: boolean;
  fetchDataIsLoading: boolean;
  contacts: TContact[];
  pagination: { total_pages: number; total_count: number };
  page: number;
  setPage: (page: number) => void;
  setUploadCSVModalIsOpen: (state: boolean) => void;
  tableRef?: any;
  enrichState: { enrichment_percent: number; pending_contacts_count: number };
  launchCloudSponge: () => void;
};

export const TalkToSphereList = ({
  buttonActive,
  fetchDataIsLoading,
  contacts,
  pagination,
  setPage,
  page,
  setUploadCSVModalIsOpen,
  tableRef,
  enrichState,
  launchCloudSponge,
}: TalkToSphereListProps) => {
  const history = useHistory();
  const { userData } = useSelector<TRootState, TRootState['userReducer']>(
    state => state.userReducer
  );
  const onViewDetail: Column<TContact>['accessor'] = contact => {
    return (
      <div className="">
        <FontAwesomeIcon
          onClick={() =>
            history.push({
              pathname: `/my-sphere/${contact.id}/contact-details`,
            })
          }
          icon={faChevronRight}
          size="1x"
          color="gray"
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            cursor: 'pointer',
          }}
        />
      </div>
    );
  };

  const columns: ColumnGroup<TContact>[] = [
    {
      Header: ' ',
      columns: [
        {
          Header: 'First Name',
          accessor: 'first_name',
          Cell: ({ row: { original: contact } }) =>
            contact.first_name ? (
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                {contact.enrichment_data && (
                  <FontAwesomeIcon icon={faBadgeCheck} color="#FCD917" style={{ fontSize: 15 }} />
                )}
                <span>{contact.first_name}</span>
              </div>
            ) : (
              '-'
            ),
        },
        {
          Header: 'Last Name',
          accessor: 'last_name',
          Cell: ({ row: { original: contact } }) => contact.last_name || '-',
        },
        {
          Header: 'Email Address',
          accessor: 'email',
          Cell: ({ row: { original: contact } }) => contact.email || '-',
        },
        {
          Header: 'Address',
          accessor: 'addresses',
          Cell: ({ row: { original: contact } }) => (
            <AddressesAccessor addresses={contact.addresses} />
          ),
        },
        {
          Header: 'Date Updated',
          accessor: contact => (contact.updated_at ? removeTimeFromDate(contact.updated_at) : '-'),
        },
        {
          Header: '',
          id: 'link',
          accessor: onViewDetail,
        },
      ],
    },
  ];

  const emptyText = useMemo(() => {
    const heading = enrichState.pending_contacts_count
      ? userData?.sub_name === 'premium'
        ? 'We are enriching your contacts!'
        : 'We are importing your contacts!'
      : 'You still do not have any contacts here';

    const subHeading = enrichState.pending_contacts_count
      ? 'Please refresh after a while to see your customers’ list.'
      : 'Start by uploading or syncing your contacts list.';

    return (
      <EmptyStatus
        icon={
          !!enrichState.pending_contacts_count && (
            <img src={contactsImporting} alt="loading..." style={{ width: '116px' }} />
          )
        }
        heading={heading}
        subHeading={subHeading}
      >
        <FlexBox
          flexDirection="column"
          alignItems="center"
          UNSAFE_style={{ gap: '10px', marginBlockStart: '20px' }}
        >
          {/* for proccesing state */}
          {enrichState.pending_contacts_count ? (
            <Button
              size="l"
              kind="text"
              UNSAFE_className="refresh-contacts"
              onClick={() => {
                history.go(0);
              }}
            >
              Refresh
            </Button>
          ) : (
            <>
              <Button
                kind="primary"
                size="l"
                onClick={launchCloudSponge}
                iconLeft={
                  buttonActive ? (
                    <FontAwesomeIcon
                      icon={faSpinnerThird}
                      style={{ fontSize: 20, animation: 'spinner-border 0.75s linear infinite' }}
                    />
                  ) : (
                    <FontAwesomeIcon icon={faSync} style={{ fontSize: 20 }} />
                  )
                }
              >
                Sync Email Accounts
              </Button>
              <Button kind="text" size="l" onClick={() => setUploadCSVModalIsOpen(true)}>
                Upload Contacts
              </Button>
            </>
          )}
        </FlexBox>
      </EmptyStatus>
    );
  }, [enrichState, userData, buttonActive]);

  return (
    <ReactTable<TContact>
      ref={tableRef}
      loading={fetchDataIsLoading}
      emptyText={emptyText}
      title="contact"
      data={contacts}
      columns={columns}
      deletable={false}
      selectAllFeature={false}
      selectFeature={false}
      pageSize={100}
      currentPage={page}
      pageCount={pagination.total_pages}
      totalCount={pagination.total_count}
      setPage={setPage}
    />
  );
};
