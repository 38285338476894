export const notificationsReducer = (
  state = { isUnReadNotification: true, userNotifications: null, unreadCount: 0 },
  action: any
) => {
  switch (action.type) {
    case 'SET_UN_READ_NOTIFICATION_COUNT':
      return { ...state, unreadCount: action.payload };
    case 'SET_UN_READ_NOTIFICATION':
      return { ...state, isUnReadNotification: action.payload };
    case 'SET_USER_NOTIFICATION':
      return { ...state, userNotifications: action.payload };
    default:
      return { ...state };
  }
};
