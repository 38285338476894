import React from 'react';
import type { CSSProperties } from 'react';
import classNames from 'classnames';

export const SpinnerLoader: React.FC<{
  className?: string;
  style?: CSSProperties;
}> = ({ style, className }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div className={classNames('before-spinner', className)} style={style} />
    </div>
  );
};
