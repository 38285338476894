/* eslint-disable */

import React, { useState, useEffect, useMemo, ChangeEventHandler } from 'react';
import { Button, Modal, Spacer, Text } from '@eltoro-ui/components';
import { Section, Loading } from 'Components';
import ConfirmModal from './components/ConfirmModal';
import { getPaymentCards, removeCard } from 'Requests';
import { EditCard } from './components';
import toast from 'react-hot-toast';
import './BillingSection.scss';
import addcardbill from '../../../../assets/Images/addcardbill.png';
import plusIcon from '../../../../assets/Images/addcard.png';
import visacard from '../../../../assets/Images/visacard.png';
import americanexpress from '../../../../assets/Images/americanexpress.png';
import discover from '../../../../assets/Images/discover.png';
import mastercard from '../../../../assets/Images/mastercard.png';
import dinearclub from '../../../../assets/Images/dinearclub.png';
import Jcb from '../../../../assets/Images/Jcb.png';
import deletecard from '../../../../assets/Images/deletecard.png';
import editcard from '../../../../assets/Images/editcard.png';
import cross from '../../../../assets/Images/cross.png';
import logoutleftcomb from '../../../../assets/Images/logoutleftcomb.png';
import logoutrightcomb from '../../assets/Images/logoutrightcomb.png';
import AddCard from './components/AddCard/AddCard';
import { useDispatch } from 'react-redux';
import { ConfrimModal } from 'Components/ConfrimModal';

import leftGroup from '../../../../assets/Images/leftgroup.svg';
import rightGroup from '../../../../assets/Images/rightgroup.svg';
import { TCard, TGetCardDetails } from '../../../../types';
import { makePrimary } from '../../../../Requests/Request_Methods/chargebeeMethods';
import Tippy from '@tippyjs/react';
import moment from 'moment';

export const BillingSection = () => {
  const [card, setCard] = useState<TGetCardDetails[]>([]);
  const [isAddCard, setIsAddCard] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [cardTitle, setCardTitle] = useState('Add Card');
  const [editId, setEditId] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [carddata, setCardData] = useState<TCard>();
  const [toggle, setToggle] = useState(false);
  const [loader, setloader] = useState(false);

  const [cardForDelete, setCardForDelete] = useState<null | TGetCardDetails>(null);
  const [cardForChangePrimary, setCardForChangePrimary] = useState<null | TGetCardDetails>(null);

  const getCardDetail = () => {
    getPaymentCards().then(res => {
      if (res.data?.details) setCard(res.data.details);
      setLoading(false);
    });
  };
  const onDeleteCard = () => {
    setloader(true);
    removeCard({ card_id: cardForDelete?.id })
      .then(() => {
        toast.success('Your credit card has been deleted successfully.');
        setLoading(true);
        setloader(false);
        setCardForDelete(null);

        getPaymentCards().then(res => {
          if (res.data?.details) setCard(res.data.details);
          setLoading(false);
        });
      })
      .catch(() =>
        toast.error('An error occurred while deleting your card... Please try again later.')
      );
  };

  useEffect(() => {
    getCardDetail();
  }, [toggle]);

  const brandIcon = {
    discover,
    mastercard,
    jcb: Jcb,
    visa: visacard,
    diners_club: dinearclub,
    american_express: americanexpress,
  };

  const onCancelDeleting = () => void setCardForDelete(null);

  const onChangePrimary =
    (card: TGetCardDetails): ChangeEventHandler<HTMLInputElement> =>
    event => {
      if (event.target.value === 'on') setCardForChangePrimary(card);
    };

  const onConfirmChangePrimary = async () => {
    try {
      setloader(true);
      cardForChangePrimary && (await makePrimary(cardForChangePrimary.id));
      await getCardDetail();
      setloader(false);
      setCardForChangePrimary(null);
      toast.success('Your primary card has been updated successfully.');
    } catch (e) {
      toast.error('An error occurred while updating your card... Please try again later.');
    }
  };

  const onCancelChangingPrimary = () => setCardForChangePrimary(null);

  const getLoading = () => {
    let component;
    if (loading) {
      component = <Loading />;
    } else if (!card.length) {
      component = (
        <div className="BillingSection__main">
          <img className="BillingSection__billingIcon" src={addcardbill} alt="addcardbill" />
          <Button
            type="button"
            kind="primary"
            size="m"
            weight="bold"
            UNSAFE_className="BillingSection__billingButton"
            onClick={() => {
              setIsAddCard(true);
              setCardTitle('Add Card');
            }}
          >
            Add Card
          </Button>
        </div>
      );
    } else {
      component = (
        <div className="BillingSection__allCardlist">
          <div className="BillingSection__allCardlist_heading">
            <div className="BillingSection__heading_section">
              <Text on="white" UNSAFE_className="BillingSection__heading_text">
                Card Detail
              </Text>
            </div>
            <div className="BillingSection__heading_section_expiry">
              <Text on="white" UNSAFE_className="BillingSection__heading_text">
                Expiry Date
              </Text>
            </div>
          </div>
          {card.map(item => (
            <div key={item.id} className="BillingSection__detailsCard">
              <div className="BillingSection__billing_card">
                <div className="BillingSection__primary">
                  <input
                    type="radio"
                    checked={!!item?.primary}
                    onChange={onChangePrimary(item)}
                    style={{ width: '20px', height: '20px' }}
                  />
                </div>
                <img src={brandIcon[item.card.brand]} alt="brand" />
                <div>
                  <Text on="white" UNSAFE_className="BillingSection__card_detail_text">
                    {item.card.masked_number}
                  </Text>
                  <Text on="white" UNSAFE_className="BillingSection__card_detail_text">
                    {`${item.card.first_name} ${item.card.last_name}`}
                  </Text>
                </div>
              </div>
              <div className="">
                <Text on="white" UNSAFE_className="BillingSection__heading_text_detail">
                  {moment()
                    .month(item.card.expiry_month - 1)
                    .year(item.card.expiry_year)
                    .format('MM/YY')}
                </Text>
              </div>
              <div className="BillingSection__edit_delete">
                <img
                  className="BillingSection__ctn"
                  onClick={() => {
                    setIsAddCard(true);
                    setCardTitle('Edit Card');
                    setCardNumber(item.card.masked_number);
                    setEditId(item.id);
                    setCardData(item.card);
                  }}
                  src={editcard}
                  alt="editcard"
                />
                <Tippy
                  placement="top-end"
                  content={
                    item.primary ? (
                      <span>
                        You can’t delete <br /> your primary card
                      </span>
                    ) : null
                  }
                  disabled={!item.primary}
                >
                  <img
                    className="BillingSection__ctn"
                    style={item.primary ? { opacity: 0.2, cursor: 'not-allowed' } : {}}
                    {...(!item.primary
                      ? {
                          onClick: () => void setCardForDelete(item),
                        }
                      : {})}
                    src={deletecard}
                    alt="editcard"
                  />
                </Tippy>
              </div>
            </div>
          ))}
        </div>
      );
    }
    return component;
  };
  return (
    <div className="BillingSection">
      <Section
        UNSAFE_className_text="BillingSection__title"
        UNSAFE_className_container="BillingSection__card"
      >
        {cardForDelete && (
          <ConfirmModal
            type="delete"
            loading={loader}
            title="Delete Credit Card"
            content={
              <>
                Are you sure you want to delete your{' '}
                <b>
                  <span style={{ textTransform: 'capitalize' }}>{cardForDelete.card.brand}</span>{' '}
                  {cardForDelete.card.masked_number}
                </b>
                ?
              </>
            }
            onCancel={onCancelDeleting}
            onOk={onDeleteCard}
            okText="Yes, Delete"
          />
        )}
        {cardForChangePrimary && (
          <ConfirmModal
            loading={loader}
            title="Change Primary Credit Card"
            okText="Yes, Change"
            content={
              <>
                Are you sure you want to set{' '}
                <b>
                  <span style={{ textTransform: 'capitalize' }}>
                    {cardForChangePrimary.card.brand}
                  </span>{' '}
                  {cardForChangePrimary.card.masked_number}
                </b>{' '}
                as your primary card.
              </>
            }
            onCancel={onCancelChangingPrimary}
            onOk={onConfirmChangePrimary}
          />
        )}
        <div className="BillingSection__billing_topbar">
          <Text on="white" UNSAFE_className="BillingSection__heading">
            Billing
          </Text>
          {card.length >= 1 && (
            <button
              className="BillingSection__headingButton"
              type="button"
              onClick={() => {
                setIsAddCard(true);
                setCardTitle('Add Card');
              }}
            >
              <img src={plusIcon} alt="uploadicon" style={{ marginRight: '10px' }} /> Add Card
            </button>
          )}
        </div>
        <div className="BillingSection__borderline" />

        {/* *****************Card List******** */}
        {getLoading()}
        {/* *****************No Card List******** */}

        {isAddCard && (
          <Modal style={{ position: 'relative' }}>
            <div className="BillingSection__AddCardModal">
              <img
                className="BillingSection__cross"
                onClick={() => setIsAddCard(false)}
                src={cross}
                alt="cross"
              />
              <img
                src={leftGroup}
                alt="leftcardicon"
                style={{
                  position: 'absolute',
                  top: '0px',
                  left: '0px',
                  width: '30%',
                  zIndex: '-1',
                }}
              />
              <AddCard
                // @ts-ignore
                editId={editId}
                setToggle={setToggle}
                setLoadings={setLoading}
                setIsAddCard={setIsAddCard}
                setCardNumber={cardNumber}
                title={cardTitle}
                cardData={carddata}
                getCardDetail={getCardDetail}
              />
              <img
                style={{
                  position: 'absolute',
                  bottom: '0px',
                  right: '0px',
                  width: '32%',
                  zIndex: '-1',
                }}
                src={rightGroup}
                alt="righcardicon"
              />
            </div>
          </Modal>
        )}
      </Section>
    </div>
  );
};
