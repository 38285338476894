import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';

import { FlexBox } from '@eltoro-ui/components';

import { privacyPolicies } from 'Utils/constants';

import './TermsPolicies.scss';

export default function TermsPolicies() {
  return (
    <FlexBox flexDirection="column" gap="24px">
      {privacyPolicies.map(policy => (
        <FlexBox
          key={policy.link}
          flexDirection="column"
          gap="12px"
          UNSAFE_className="policy-container"
        >
          <a className="policy-link" href={policy.link} target="_blank" rel="noreferrer">
            <span className="policy-title">{policy.title}</span>
            <FontAwesomeIcon icon={faExternalLink} />
          </a>
        </FlexBox>
      ))}
    </FlexBox>
  );
}
