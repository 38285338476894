import React from 'react';
import './DateTargetToggle.scss';

export const DateTargetToggle = ({
  startDate,
  endDate,
  inputTarget,
  onInputTargetChange,
}: {
  startDate: Date;
  endDate: Date;
  inputTarget: 'start' | 'end';
  onInputTargetChange: (newInputTarget: 'start' | 'end') => void;
}) => {
  const formatDate = (date: Date) =>
    date.toLocaleDateString('default', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });

  return (
    <div className="DateTargetToggle">
      <button
        type="button"
        className={`DateTargetToggle__button${
          inputTarget === 'start' ? ' DateTargetToggle__button--active' : ''
        }`}
        onClick={() => onInputTargetChange('start')}
      >
        <div className="DateTargetToggle__label">Start date</div>
        <div className="DateTargetToggle__date">{formatDate(startDate)}</div>
      </button>
      <button
        type="button"
        className={`DateTargetToggle__button${
          inputTarget === 'end' ? ' DateTargetToggle__button--active' : ''
        }`}
        onClick={() => onInputTargetChange('end')}
      >
        <div className="DateTargetToggle__label">End date</div>
        <div className="DateTargetToggle__date">{formatDate(endDate)}</div>
      </button>
    </div>
  );
};
