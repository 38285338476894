import React from 'react';
import { FlexBox, Text } from '@eltoro-ui/components';
import notActiveImgSrc from 'assets/Images/not-active-contacts.svg';
import './styles.scss';

const NotActive = () => {
  return (
    <div className="activity_container">
      <FlexBox flexDirection="column" alignItems="center">
        <img src={notActiveImgSrc} alt="not active contacts" />
        <Text on="white" UNSAFE_className="desc">
          None of your contacts were seen in
        </Text>
        <Text on="white" UNSAFE_className="desc">
          market in last 90 days.
        </Text>
      </FlexBox>
    </div>
  );
};

export default NotActive;
