import { atomFamily, DefaultValue, selectorFamily } from 'recoil';
import user_img from 'assets/Images/user.png';
import {
  AvailableBlocksType,
  BlockConfigType,
  BlocksConfigType,
  ValueWithErrorType,
} from '../Typings';
import {
  blockSelectorGetter,
  blockSelectorSetter,
  getDefaultShapeValue,
  mainColorHelper,
} from '../Helpers';
import { blockTextColorAtom } from './blockTextState';
import { blockStrokeColorAtom } from './blockStrokeState';
import { blockShadowColorAtom } from './blockShadowState';
import { userDetail } from '../../../Requests/Request_Methods/authUserMethods';

// each atom in this file has a selector that corresponds to it
// The atom keeps track of the specific piece of the Block's state...
// ...the selector is able to update all atoms of selected blocks at the same time.

export const blockBorderRadiusAtom = atomFamily<number, BlockConfigType>({
  key: 'blockBorderRadius',
  default: config => getDefaultShapeValue(config.kind, config.key, 'borderRadius'),
});

export const blockBorderRadiusSelector = selectorFamily<
  ValueWithErrorType<number>,
  BlocksConfigType
>({
  key: 'blockBorderRadiusSelector',
  get:
    config =>
    ({ get }) =>
      blockSelectorGetter(get, config, blockBorderRadiusAtom),
  set:
    config =>
    ({ set }, newRadius) => {
      blockSelectorSetter(set, config, blockBorderRadiusAtom, newRadius);
    },
});

export const blockShapeColorAtom = atomFamily<string, BlockConfigType>({
  key: 'blockShapeColor',
  default: config => getDefaultShapeValue(config.kind, config.key, 'color'),
});

export const blockShapeColorSelector = selectorFamily<ValueWithErrorType<string>, BlocksConfigType>(
  {
    key: 'blockShapeColorSelector',
    get:
      config =>
      ({ get }) =>
        blockSelectorGetter(get, config, blockShapeColorAtom),
    set:
      config =>
      ({ set }, newColor) => {
        blockSelectorSetter(set, config, blockShapeColorAtom, newColor);
      },
  }
);

export const blockShapeTypeAtom = atomFamily<'circle' | 'square' | '', BlockConfigType>({
  key: 'blockShapeType',
  default: config => getDefaultShapeValue(config.kind, config.key, 'shape'),
});

export const blockShapeTypeSelector = selectorFamily<
  ValueWithErrorType<'circle' | 'square' | ''>,
  BlocksConfigType
>({
  key: 'blockShapeypeSelector',
  get:
    config =>
    ({ get }) =>
      blockSelectorGetter(get, config, blockShapeTypeAtom),
  set:
    config =>
    ({ set }, newShape) => {
      blockSelectorSetter(set, config, blockShapeTypeAtom, newShape);
    },
});

const getMainColor = (attrName: string, config: BlockConfigType) => {
  const getters = {
    stroke: (blockConfig: BlockConfigType) => blockStrokeColorAtom(blockConfig),
    shadow: (blockConfig: BlockConfigType) => blockShadowColorAtom(blockConfig),
    text: (blockConfig: BlockConfigType) => blockTextColorAtom(blockConfig),
  } as const;
  return Object.keys(getters).includes(attrName)
    ? getters[attrName as keyof typeof getters](config)
    : blockShapeColorAtom(config);
};

export const blockMainColorSelector = selectorFamily<
  ValueWithErrorType<string>,
  AvailableBlocksType
>({
  key: 'blockMainColorSelector',
  get:
    kind =>
    ({ get }) => {
      const colorAtomConfigs = mainColorHelper(get, kind);
      const colors = colorAtomConfigs!.reduce((acc: string[], colorAtomConfig) => {
        const { atomKind } = colorAtomConfig;
        return [...acc, get(getMainColor(atomKind, colorAtomConfig.config))];
      }, []);
      const sameColors = colors.every(color => color === colors[0]);

      const returnValue = () => {
        if (colors?.length && colorAtomConfigs?.length) {
          const [primeShapeConfig] = colorAtomConfigs;
          const { atomKind } = primeShapeConfig;
          return get(getMainColor(atomKind, primeShapeConfig.config));
        }
        return '';
      };

      return { value: returnValue(), error: !sameColors };
    },
  set:
    kind =>
    ({ get, set }, newColor) => {
      const colorAtomConfigs = mainColorHelper(get, kind);
      const usedColor = newColor instanceof DefaultValue ? newColor : newColor.value;
      colorAtomConfigs?.forEach(colorAtomConfig => {
        const { atomKind } = colorAtomConfig;
        set(getMainColor(atomKind, colorAtomConfig.config), usedColor);
      });
    },
});

export const headShotImageAtom = atomFamily({
  key: 'headShotImage',
  default: async () => {
    const { data: user } = await userDetail(localStorage.getItem('beewo_token') as string);
    if (user && user.profile_pic) {
      const response = await fetch(user.profile_pic);
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    }
    return user_img;
  },
});

export const headShotImageSelector = selectorFamily<string, string[]>({
  key: 'headShotImageSelector',
  get:
    ids =>
    ({ get }) => {
      return get(headShotImageAtom(ids[0]));
    },
  set:
    ids =>
    ({ set }, newImage) => {
      ids.forEach(id => {
        set(headShotImageAtom(id), newImage);
      });
    },
});
