import React from 'react';
import { Text } from '@eltoro-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { CreditBundleType } from 'types';
import star from '../../../assets/Images/Star-2.png';
import './CreditBundleCard.scss';

export const CreditBundleCard: React.FC<{
  bundle: CreditBundleType | any;
  pointsNeeded?: number;
  onClick?: () => void;
}> = ({ bundle, onClick, pointsNeeded }) => {
  // const getStars = () => {
  //   const stars = []
  //   for (let i = 0; i < bundle.stars; i += 1) {
  //     stars.push(
  //       <FontAwesomeIcon
  //         key={bundle.name + bundle.stars + i}
  //         icon={faStar}
  //         className="CreditBundleCard__star"
  //         size="2x"
  //       />,
  //     )
  //   }
  //   return stars
  // }
  return (
    <div className="CreditBundleCard">
      {/* <img src={star} alt="" /> */}
      <div className="CreditBundleCard__title">
        <Text on="white" kind="subdued" weight="bold">
          {bundle.name}
          {/* {pointsNeeded} */}
        </Text>
      </div>

      <button
        type="button"
        onClick={onClick}
        className="CreditBundleCard__button"
        style={onClick ? { cursor: 'pointer' } : {}}
      >
        {/* <div className="CreditBundleCard__star-container" /> */}
        <div className="CreditBundleCard__buttonContent">
          <Text on="grey-100" tag="div" weight="normal" size="xxxl">
            {`${bundle?.metadata?.coins} `}
          </Text>
          <Text on="grey-500" weight="normal">
            {`credits = $${bundle?.metadata?.price}`}
          </Text>
          <Text on="grey-100" size="xl">
            {bundle?.metadata?.subText}
          </Text>
        </div>
      </button>
    </div>
  );
};
