export const chargebeePackageReducer: undefined | any = (
  state = { chargebeePackage: null },
  action: any
) => {
  switch (action.type) {
    case 'SET_CHARGEBEE_PACKAGE':
      return { chargebeePackage: action.payload };

    default:
      return { ...state };
  }
};
