import Konva from 'konva';
import React, { useEffect, useRef, useState } from 'react';
import { Group, Image as KonvaImage } from 'react-konva';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useImage from 'use-image';
import { RecoilCircle, RecoilRect } from '../..';
import { placeImage, rectWithBorderRadiusClip } from '../../../../Helpers';
import {
  blockBorderRadiusAtom,
  blockShapeTypeAtom,
  headShotImageAtom,
  mainDimensionsBlockAtom,
} from '../../../../State';
import { BlockConfigType, DimensionsType } from '../../../../Typings';

export const ImageFrame: React.FC<{
  id: string;
  width: number;
  height: number;
  isOnMobile?: boolean;
}> = ({ id, width, height, isOnMobile = false }) => {
  const setMainDimensions = useSetRecoilState(mainDimensionsBlockAtom(id));
  // this state may not be needed once proper uploader is in place
  const [imageDimensions, setImageDimensions] = useState<DimensionsType>();

  const frameConfig: BlockConfigType = {
    id,
    kind: 'imageFrame',
    key: 'frame',
  };
  const borderRadius = useRecoilValue(blockBorderRadiusAtom(frameConfig));
  const shapeType = useRecoilValue(blockShapeTypeAtom(frameConfig));
  const image = useRecoilValue(headShotImageAtom(id));

  const imageRef = useRef<Konva.Image>(null);
  const [img] = useImage(image, 'anonymous');

  // useEffect and contents may be removed once proper uploader is in place
  useEffect(() => {
    const createImageDimensions = () => {
      const newImage = new Image();
      newImage.onload = () => {
        setImageDimensions({
          height: newImage.height,
          width: newImage.width,
        });
      };
      newImage.src = image;
    };
    createImageDimensions();
  }, [image]);

  const diameter = width < height ? width : height;
  useEffect(() => {
    setMainDimensions({
      width: diameter,
      height: diameter,
    });
  }, [width, height]);

  const radius = diameter / 2;

  const imageDetails = imageDimensions && placeImage(imageRef, imageDimensions);

  if (!imageDetails) return null;

  return (
    <>
      {shapeType === 'circle' && (
        <RecoilCircle config={frameConfig} radius={radius} x={radius} y={radius} fill="white" />
      )}
      {shapeType === 'square' && (
        <RecoilRect config={frameConfig} width={radius * 2} height={radius * 2} fill="white" />
      )}
      <Group
        name="main"
        width={diameter}
        height={diameter}
        clipFunc={(ctx: CanvasRenderingContext2D) => {
          if (shapeType === 'circle') ctx.arc(radius, radius, radius, 0, 360);
          if (shapeType === 'square')
            rectWithBorderRadiusClip(ctx, 0, 0, diameter, diameter, borderRadius);
        }}
      >
        <KonvaImage
          ref={imageRef}
          image={img}
          x={imageDetails.x}
          y={imageDetails.y}
          width={imageDetails.width}
          height={imageDetails.height}
        />
      </Group>
      {shapeType === 'circle' && (
        <RecoilCircle
          config={frameConfig}
          radius={radius}
          x={radius}
          y={radius}
          shadowEnabled={false}
        />
      )}
      {shapeType === 'square' && (
        <RecoilRect
          config={frameConfig}
          width={radius * 2}
          height={radius * 2}
          shadowEnabled={false}
        />
      )}
    </>
  );
};
