import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment';
import classNames from 'classnames';

import Tippy from '@tippyjs/react';
import { Button, Text } from '@eltoro-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'Components';
import { TCommonSubscription, TCommonUser, TCurrentSubscription, TRootState } from 'types';
import { faStopwatch } from '@fortawesome/pro-regular-svg-icons';
import basic_subscription from 'assets/Images/basic_subscription.png';
import premium_subscription from 'assets/Images/premium_subscription.png';

import { ComingSoonFeatures, subscriptionsBulletPoints } from 'Utils/constants';
import { subscription_name } from 'Utils/helpers';

import KeepCurrentSubscription from './components/KeepCurrentSubscription';
import UpdatePlanModal from './components/UpdatePlanModal';

import './SubscriptionCard.scss';

export type SubscriptionCardProps = {
  data: TCommonSubscription[];
  onSubscribe: (body: { subscription_id: TCommonSubscription['id'] }) => () => void;
  onUpdateSubscription: (body: {
    id: TCurrentSubscription['sub_id'];
    plan_id: TCommonSubscription['id'];
  }) => () => void;
  onCancelUpcoming: ({ sub_id }: Pick<TCurrentSubscription, 'sub_id'>) => () => void;
  loading: boolean;
  userData: TCommonUser;
};

export const SubscriptionCard: FC<SubscriptionCardProps> = ({
  data,
  userData,
  onSubscribe,
  onUpdateSubscription,
  onCancelUpcoming,
  loading,
}) => {
  const { spinner } = useSelector((state: TRootState) => state.SpinnerReducer);

  const [updatePlanSubscription, setUpdatePlanSubscription] = useState<TCommonSubscription | null>(
    null
  );
  const [keepCurrentModal, setKeepCurrentModal] = useState<boolean>(false);

  const conditionalText = (name: TCommonSubscription['name']) => {
    if (name.includes('basic')) return 'Activate Basic';
    if (name.includes('premium')) return 'Activate Premium';
    return 'Buy';
  };

  const getSubscriptionButton = (subscription: TCommonSubscription) => {
    const onClickActivate = () => setUpdatePlanSubscription(subscription);
    return (
      <Button type="button" kind="primary" size="l" weight="bold" onClick={onClickActivate}>
        {conditionalText(subscription.name)}
      </Button>
    );
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const onCancelUpdate = () => setUpdatePlanSubscription(null);

  const onUpgradePlan = () =>
    setUpdatePlanSubscription(
      data.find(subscription => subscription.name === 'beewo-subscription-premium') || null
    );

  const onDowngradePlan = () =>
    setUpdatePlanSubscription(
      data.find(subscription => subscription.name === 'beewo-subscription-basic') || null
    );

  const onStayOnCurrent = () => setKeepCurrentModal(true);
  const onCancelKeepCurrentPlan = () => setKeepCurrentModal(false);

  return (
    <div
      className={classNames(
        'subscription_card_container',
        userData?.current_subscription?.next_billing_at &&
          userData?.current_subscription?.scheduled_changes &&
          'has-upcoming'
      )}
    >
      {spinner && <Spinner />}
      {keepCurrentModal && (
        <KeepCurrentSubscription
          current={userData?.current_subscription}
          onKeepPlan={onCancelUpcoming}
          loading={loading}
          onCancel={onCancelKeepCurrentPlan}
        />
      )}
      {updatePlanSubscription && (
        <UpdatePlanModal
          user={userData}
          subscription={updatePlanSubscription as TCommonSubscription}
          onCancel={onCancelUpdate}
          onSubscribe={onSubscribe}
          onUpdateSubscription={onUpdateSubscription}
          loading={loading}
        />
      )}
      {userData?.current_subscription?.next_billing_at &&
        userData?.current_subscription?.scheduled_changes && (
          <div className="current_subscription-upcoming">
            <div className="current_subscription-upcoming-info">
              <span className="icon">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  color="rgba(255, 182, 54, 1)"
                  style={{ fontSize: 31 }}
                />
              </span>
              <div className="current_subscription-upcoming-info-details">
                <div className="current_subscription-upcoming-info-details-title">
                  {userData?.sub_name === 'basic'
                    ? 'Your basic plan will be upgraded to premium tier on'
                    : 'Your premium plan will be downgraded to basic tier on'}{' '}
                  {moment(
                    userData?.current_subscription?.status === 'active'
                      ? userData?.current_subscription?.next_billing_at
                      : userData?.current_subscription?.trial_end
                  ).format('Do MMMM, YYYY')}
                  .
                </div>
                <div className="current_subscription-upcoming-info-details-content">
                  {userData?.sub_name === 'basic'
                    ? 'You will gain access to premium features at the end of the trial period'
                    : 'You will no longer have access to Premium features'}
                </div>
              </div>
            </div>
            <div className="current_subscription-upcoming-action">
              <Button
                kind="text"
                size="l"
                weight="bold"
                UNSAFE_className="stay-on-current"
                onClick={onStayOnCurrent}
              >
                <>
                  Stay on <span style={{ textTransform: 'capitalize' }}>{userData?.sub_name}</span>
                </>
              </Button>
            </div>
          </div>
        )}
      {!userData?.is_active ? (
        data.map((subscription: any, index: number) => (
          <div key={`${subscription.id}${index}`}>
            <div
              data-testid="subscriptionCard"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 46,
              }}
              className="subscription_card"
            >
              <div>
                <div style={{ display: 'flex' }}>
                  {subscription_name(subscription) === 'Basic Subscription' ? (
                    <img src={basic_subscription} alt="basic_subscription" className="sub_logo" />
                  ) : (
                    <img
                      src={premium_subscription}
                      alt="premium_subscription"
                      className="sub_logo"
                    />
                  )}
                  <Text
                    on="white"
                    weight="bold"
                    size="l"
                    textAlign="center"
                    UNSAFE_style={{
                      margin:
                        subscription_name(subscription) === 'Premium Subscription'
                          ? '11px 0px 0px -1px'
                          : '13px 13px 0px 9px',

                      color:
                        subscription_name(subscription) === 'Premium Subscription'
                          ? '#000'
                          : '#fff',
                    }}
                    UNSAFE_className="title"
                  >
                    {subscription?.name === 'beewo-subscription-basic'
                      ? 'Basic Subscription'
                      : 'Premium Subscription'}
                  </Text>
                </div>
                <div className="price_div">
                  <Text
                    UNSAFE_className="price_value"
                    on="white"
                    weight="bold"
                    size="xxxl"
                    textAlign="center"
                  >
                    <span>$</span>
                    {parseInt(subscription?.metadata?.price, 10)}
                  </Text>
                  <div className="price_content">
                    <span className="decimal_value">
                      .{(subscription?.metadata?.price).toString().split('.')[1]}
                    </span>
                    <Text
                      on="grey-500"
                      weight="normal"
                      size="xl"
                      textAlign="center"
                      UNSAFE_className="sub_month"
                    >
                      Per Month
                    </Text>
                  </div>
                </div>

                <ul
                  className={`${
                    subscription?.name === 'beewo-subscription-basic' ? 'basic' : 'premium'
                  } `}
                >
                  {subscriptionsBulletPoints
                    .find(({ id }) => id === subscription.id)
                    ?.bullet_points?.map(({ name, description }) => (
                      <React.Fragment key={`${subscription.id}-${name}`}>
                        <li>
                          <Text
                            on="grey-500"
                            weight="normal"
                            size="m"
                            textAlign="center"
                            UNSAFE_className={`${
                              subscription?.name === 'beewo-subscription-basic'
                                ? 'basic'
                                : 'premium'
                            } `}
                          >
                            <span>
                              {name}
                              {!!description?.length && ':'}
                            </span>
                            {ComingSoonFeatures.includes(name) && (
                              <Tippy placement="top" content="Coming Soon...">
                                <span style={{ marginLeft: '8px' }}>
                                  <FontAwesomeIcon
                                    icon={faStopwatch}
                                    style={{ color: '#FCD917' }}
                                  />
                                </span>
                              </Tippy>
                            )}
                          </Text>
                        </li>
                        {!!description?.length && (
                          <ul>
                            {description?.map((des, i: number) => (
                              <li key={`${des}${i}`}>
                                <span>{des}</span>
                                {ComingSoonFeatures.includes(des) && (
                                  <Tippy placement="top" content="Coming Soon...">
                                    <span style={{ marginLeft: '8px' }}>
                                      <FontAwesomeIcon
                                        icon={faStopwatch}
                                        style={{ color: '#FCD917' }}
                                      />
                                    </span>
                                  </Tippy>
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                      </React.Fragment>
                    ))}
                </ul>
              </div>
              <div>{getSubscriptionButton(subscription)}</div>
            </div>
          </div>
        ))
      ) : (
        <div className="current_subscription_container">
          <div className="current_subscription-details">
            <div className="current_subscription-details-info">
              <span className="current_subscription-details-info-name">
                {userData?.sub_name} plan
                {userData?.current_subscription?.next_billing_at &&
                  userData?.current_subscription?.scheduled_changes && (
                    <div className="current_subscription-details-active">Active</div>
                  )}
              </span>
              <span className="current_subscription-details-info-cost">
                {/* {userData?.current_subscription */}
                {/*  ? formatter.format(userData?.current_subscription.amount / 100) */}
                {/*  : 0}{' '} */}
                {/* per month */}
                Free for Beta
              </span>
            </div>
            {/* {userData?.current_subscription?.next_billing_at && */}
            {/*  !userData?.current_subscription?.scheduled_changes && ( */}
            {/*    <> */}
            {/*      <div className="divider" /> */}
            {/*      <div className="current_subscription-details-next_payment"> */}
            {/*        <span className="current_subscription-details-next_payment-title"> */}
            {/*          Next Payment: */}
            {/*        </span> */}
            {/*        <span className="current_subscription-details-next_payment-date"> */}
            {/*          {moment(userData?.current_subscription.next_billing_at).format('LL')} */}
            {/*        </span> */}
            {/*      </div> */}
            {/*    </> */}
            {/*  )} */}
          </div>
          {userData?.current_subscription?.next_billing_at &&
          !userData?.current_subscription?.scheduled_changes ? (
            <>
              {userData?.current_subscription?.cancelled_at ? null : userData?.sub_name ===
                'basic' ? (
                <Button kind="primary" size="l" weight="bold" onClick={onUpgradePlan}>
                  Upgrade Plan
                </Button>
              ) : (
                <Button
                  kind="text"
                  size="l"
                  weight="bold"
                  UNSAFE_className="refresh-contacts"
                  onClick={onDowngradePlan}
                >
                  Downgrade Plan
                </Button>
              )}
            </>
          ) : null}
        </div>
      )}
    </div>
  );
};
