import { MapRawType, MapLocationType, TLocation, OldMapLocationType, TFeature } from 'types';

export const openStreepMapResolver = (results: MapRawType[]): OldMapLocationType[] =>
  results.map(result => ({
    x: result.lon,
    y: result.lat,
    label: result.display_name,
    bounds: [
      [result.boundingbox[0], result.boundingbox[2]],
      [result.boundingbox[1], result.boundingbox[3]],
    ],
    raw: result,
  }));

export const mapLocationResolver = (location: TLocation): MapLocationType => {
  const coordinates: Pick<MapLocationType, 'x' | 'y'> = { x: '', y: '' };
  if (Array.isArray(location.geometry.coordinates) && location.geometry.type === 'Point') {
    const [lat, lng] = location.geometry.coordinates;
    coordinates.x = lng;
    coordinates.y = lat;
  }
  // [-81.507638, 28.347984, -81.226985, 28.614251];
  // bbox = min Longitude , min Latitude , max Longitude , max Latitude
  // south Latitude, north Latitude, west Longitude, east Longitude

  // const south = parseFloat(location.bounds[0][0]);
  // const north = parseFloat(location.bounds[1][0]);
  // const east = parseFloat(location.bounds[1][1]);
  // const west = parseFloat(location.bounds[0][1]);

  const [south, west, north, east] = location.bounding_box;
  return {
    ...coordinates,
    bounds: [
      [south, west],
      [north, east],
    ],
    label: location.display_value,
    raw: location,
  };
};

export const mapzenLocationResolver = (location: TFeature): TLocation => {
  return {
    display_value: `${location.properties.locality ? `${location.properties.locality}, ` : ''}${
      location.properties.county ? `${location.properties.county}, ` : ''
    }${location.properties.region ? `${location.properties.region}, ` : ''}${
      location.properties.postalcode ? `${location.properties.postalcode}, ` : ''
    }${location.properties.country}`,
    key: location.properties.layer === 'locality' ? 'city' : 'zip',
    value:
      location.properties.layer === 'locality'
        ? location.properties.locality.toLowerCase()
        : (location.properties.postalcode as string),
    bounding_box: location.bbox as TLocation['bounding_box'],
    geometry: location.geometry,
    originalFeature: location,
  };
};
