import { useEffect } from 'react';
import { useAsync } from '@eltoro-ui/hooks';

export const useOnSubmit = (asyncFunc: () => Promise<any>, setterFunc: (val: any) => void) => {
  const { execute, status, value } = useAsync<any>(asyncFunc, false);

  useEffect(() => {
    if (status === 'success') {
      setterFunc(value);
    }
  }, [status, value]);

  return { onSubmit: execute, submitted: status === 'success', status };
};
