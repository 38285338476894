import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import Tooltip from 'rc-tooltip';
import moment from 'moment';

import type { Column, Row } from 'react-table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faWalking } from '@fortawesome/pro-solid-svg-icons';

import { Button, FlexBox } from '@eltoro-ui/components';
import { EmptyStatus, PageHeader, ReactTable, ReactTableSearchBar } from 'Components';
import { Tags } from 'Pages/SavedAudiences/components';

import { deleteAudience, getAudiences } from 'Requests/Request_Methods/audienceMethods';

import type { TAudience, TResPagination, TRootState } from 'types';
import { AudienceStatusEnum } from 'enums';

import './SavedAudiences.scss';

export const SavedAudiences = () => {
  const [audiences, setAudiences] = useState<TAudience[]>([]);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);

  const [page, setPage] = useState<number>(1);
  const [pagination, setPagination] = useState<TResPagination>({ total_count: 0, total_pages: 0 });

  // ========== New Table Utilities
  const [fetchDataIsLoading, setFetchDataIsLoading] = useState(false);

  const { setGlobalFilters } = useSelector((state: TRootState) => state.setGlobalFilters);
  const { globalFilters } = useSelector((state: TRootState) => state.globalFilters);
  const onDelete = async (_items: Array<Row<TAudience>>) => {
    setDeleteIsLoading(true);
    const ids = _items.map(item => item.original.id);
    try {
      const { data } = await deleteAudience({ ids });
      if (data?.msg) toast.success(data.msg);
      setDeleteModalIsOpen(false);
      setDeleteIsLoading(false);

      if (page === 1) _fetchData();
      else await setPage(1);
    } catch (err: any) {
      toast.error(err.detail);
      setDeleteIsLoading(false);
    }
  };

  const _fetchData = async () => {
    setFetchDataIsLoading(true);
    try {
      const res = await getAudiences(page, 50, null, null);
      if (res.data?.audiences?.length) {
        setAudiences(res.data?.audiences);
        const { total_audience, total_pages } = res.data;
        setPagination({ total_count: total_audience, total_pages });
      }
      setFetchDataIsLoading(false);
    } catch (err: any) {
      toast.error(err.detail);
      setFetchDataIsLoading(false);
    }
  };

  useEffect(() => {
    _fetchData();

    return () => {
      setAudiences([]);
    };
  }, [page]);

  const columns: Column<TAudience>[] = [
    {
      Header: ' ',
      columns: [
        {
          Header: 'Audience Name',
          accessor: 'name',
        },
        {
          Header: 'Prospects',
          accessor: 'prospects_counts',
          Cell: ({ row: { original: audience } }) => (
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <FontAwesomeIcon
                icon={faWalking}
                color={audience.status === 'Pending' ? '#B0B0B0' : 'var(--color-secondary-300)'}
                style={{ marginRight: '5px' }}
              />
              {audience.prospects_counts || '-'}
            </span>
          ),
        },
        {
          Header: 'Source',
          accessor: 'source',
        },
        {
          Header: 'Segment',
          accessor: 'segment',
          Cell: ({ row: { original: audience } }) => <span>{audience.segment || '-'}</span>,
        },
        {
          Header: 'Date Uploaded',
          accessor: audience => moment(`${audience.created_at}Z`).format('MM/DD/YYYY hh:mm A'),
        },
        {
          Header: 'Tags',
          accessor: audience =>
            audience.tags?.length ? (
              <span className="SavedAudiences__tagsContainer">
                <Tags data={audience.tags} />
              </span>
            ) : (
              '-'
            ),
          // @ts-ignore
          disableSortBy: true,
        },
        {
          Header: ' ',
          id: 'audience-status',
          accessor: ({ status }) => {
            if (status === AudienceStatusEnum.PENDING)
              return (
                <FlexBox justifyContent="center" alignItems="center">
                  <Tooltip
                    key="save-audience"
                    placement="topRight"
                    trigger="hover"
                    overlayClassName="audience-tooltip audience-status-pending"
                    showArrow
                    align={{
                      offset: [5, -3],
                      targetOffset: [-6, 0],
                    }}
                    overlay="Your audience creation is in process. Please refresh after a few minutes."
                    getTooltipContainer={triggerNode =>
                      triggerNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode
                        ?.parentNode as HTMLElement
                    }
                  >
                    <span style={{ cursor: 'initial' }}>
                      <FontAwesomeIcon
                        icon={faSpinnerThird}
                        color="#FFAB03"
                        style={{ fontSize: 20, animation: 'spinner-border 0.75s linear infinite' }}
                      />
                    </span>
                  </Tooltip>
                </FlexBox>
              );

            if (status === 'Error')
              return (
                <FlexBox justifyContent="center" alignItems="center">
                  <Tooltip
                    key="save-audience"
                    placement="topRight"
                    trigger="hover"
                    overlayClassName="audience-tooltip audience-status-error"
                    showArrow
                    align={{
                      offset: [5, -3],
                      targetOffset: [-6, 0],
                    }}
                    overlay="An error occurred during the audience generation process. This audience is unavailable for use and will be automatically deleted in 48 hours."
                    getTooltipContainer={triggerNode =>
                      triggerNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode
                        ?.parentNode as HTMLElement
                    }
                  >
                    <span style={{ cursor: 'initial' }}>
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        color="#FAAD14"
                        style={{ fontSize: 20 }}
                      />
                    </span>
                  </Tooltip>
                </FlexBox>
              );

            return null;
          },
        },
      ],
    },
  ];

  return (
    <div className="SavedAudiences">
      <PageHeader
        UNSAFE_CLASSNAME="SavedAudiences__PageHeader"
        LeftContent_ClassName="MyListings__header"
        subTitle={
          <span style={{ marginLeft: '5px' }} className="MyListings__totalCounts">
            You have <b>{pagination.total_count} Saved Audience</b>
          </span>
        }
        actions={[
          <ReactTableSearchBar
            key="search-audiences"
            globalFilter={globalFilters}
            setGlobalFilter={setGlobalFilters}
          />,
          <Button
            key="new-campaign"
            kind="primary"
            to="?action=create-campaign"
            replace
            weight="bolder"
            size="l"
          >
            New Campaign
          </Button>,
        ]}
      />
      <ReactTable<TAudience>
        title="audience"
        deleteModalNote="The audiences attached to campaign(s) will not be deleted"
        deleteModalHeader="Are you sure you want to delete the selected audience(s)?"
        loading={fetchDataIsLoading}
        emptyText={
          <EmptyStatus subHeading="You can try by creating new campaign to get better audience." />
        }
        data={audiences}
        onDelete={onDelete}
        rowDisabled={audience => audience.status === 'Pending'}
        deleteModal={deleteModalIsOpen}
        setDeleteModal={setDeleteModalIsOpen}
        deleteIsLoading={deleteIsLoading}
        columns={columns}
        currentPage={page}
        setPage={setPage}
        pageCount={pagination.total_pages}
        totalCount={pagination.total_count}
      />
    </div>
  );
};
