import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

import { FlexBox } from '@eltoro-ui/components';

import { CreativeStatusEnum, CreativeTypeEnum } from 'enums';

import './CreativesFilterTag.scss';

type CreativesFilterTagProps = {
  name: CreativeStatusEnum | CreativeTypeEnum;
  onDelete: (name: CreativeStatusEnum | CreativeTypeEnum) => () => void;
};

const CreativesFilterTag = ({ name, onDelete }: CreativesFilterTagProps) => {
  return (
    <FlexBox
      UNSAFE_className="creative-filter-item-tag"
      alignItems="center"
      justifyContent="center"
      gap="6px"
    >
      <span className="creative-filter-item-tag-content">{name}</span>
      <button
        aria-label="delete-creative-item"
        className="creative-filter-item-tag-delete-btn"
        onClick={onDelete(name)}
      >
        <FontAwesomeIcon icon={faTimes} color="#757575" style={{ fontSize: 12 }} />
      </button>
    </FlexBox>
  );
};

export default CreativesFilterTag;
