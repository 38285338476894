import React, { FC, ReactNode, useState } from 'react';
import { CSSProperties } from 'styled-components';
import './AvatarWrapper.scss';

type AvatarWrapperType = {
  children: ReactNode;
  onClick: () => void;
  hoverOverlay?: JSX.Element;
  UNSAFE_style?: CSSProperties;
};

export const AvatarWrapper: FC<AvatarWrapperType> = ({
  children,
  onClick,
  hoverOverlay,
  UNSAFE_style,
}) => {
  const [showOverlay, setShowOverlay] = useState(false);

  return (
    <>
      <button
        type="button"
        style={UNSAFE_style}
        className={`AvatarWrapper ${showOverlay && hoverOverlay ? 'AvatarWrapper--dim' : ''}`}
        onMouseEnter={() => setShowOverlay(true)}
        onMouseLeave={() => setShowOverlay(false)}
        onFocus={() => setShowOverlay(true)}
        onBlur={() => setShowOverlay(false)}
        onClick={onClick}
      >
        {showOverlay && hoverOverlay && (
          <div className="AvatarWrapper__overlay">{hoverOverlay}</div>
        )}
        {children}
      </button>
    </>
  );
};
