import {
  APIFeatureResponseType,
  APIFeatureType,
  FilterType,
  MapLocationType,
  ResponseType,
  TAreaSearchListining,
  TBuzzData,
  TGetListingHistogramBodyType,
  TMyZip,
  TPostAdvertiseToAll,
  TPutNotesByListingIdBodyType,
  TRealtorResponse,
  TSearchedListingsResponse,
} from 'types';
import requests from '../http';

export const getListings = async (): Promise<ResponseType<APIFeatureResponseType>> => {
  return requests.get('/listings/my_listining');
};

export const searchListingById = async (
  mls_id: string
): Promise<ResponseType<TSearchedListingsResponse>> => {
  return requests.get(`/listings/by-id/${mls_id}`);
};

export const setIntoMyListings = async (
  listing_id: number
): Promise<ResponseType<APIFeatureType>> => {
  return requests.post(`/listings/add-listing/${listing_id}`);
};

export const getMyListings = async (
  page?: number | null,
  size?: number | null,
  search?: string | null,
  sort_key?: string | null,
  order?: string | null
): Promise<ResponseType<APIFeatureResponseType>> => {
  const req = search
    ? `/listings/my_listining/list?page=${page}&size=${size}&search=${search}&${
        sort_key && `sort_key=${sort_key}&order=${order}`
      }`
    : `/listings/my_listining/list?page=${page}&size=${size}&${
        sort_key && `sort_key=${sort_key}&order=${order}`
      }`;
  return requests.get(req);
};

export const getListingById = async (mls_id: string): Promise<ResponseType<APIFeatureType>> => {
  return requests.get(`/listings/my_listining/details/${mls_id}`);
};

export const putNotesByListingId = async (
  mls_id: string,
  body: TPutNotesByListingIdBodyType
): Promise<ResponseType<APIFeatureType>> => {
  return requests.put(`/listings/my_listining/add_note/${mls_id}`, body);
};

export const getListingHistogram = async (
  body: TGetListingHistogramBodyType
): Promise<ResponseType<TBuzzData>> => requests.post(`/listings/buzz_data`, body);

export const getCompListings = async (
  locations: MapLocationType[],
  skip?: number | null,
  limit?: number | null
): Promise<ResponseType<APIFeatureResponseType>> => {
  const constructRequest = (location: MapLocationType) => {
    const south = parseFloat(<string>location.bounds[0][0]);
    const north = parseFloat(<string>location.bounds[1][0]);
    const east = parseFloat(<string>location.bounds[1][1]);
    const west = parseFloat(<string>location.bounds[0][1]);
    const geoParams = `/listings/layer?south=${south}&north=${north}&east=${east}&west=${west}`;
    const paginationParams = `&skip=${skip}&limit=${limit}`;
    return skip && limit ? geoParams + paginationParams : geoParams;
  };
  const resArray = await Promise.all(
    locations.map((location: MapLocationType) => requests.get(constructRequest(location)))
  );
  const listingsRes = resArray.reduce(
    (acc: APIFeatureResponseType, val: { data: APIFeatureResponseType }) => {
      return { ...val.data, listings: acc.listings.concat(val.data.listings) };
    },
    { listings: [], total_pages: 0, total_listing: 0, current_page: 0 }
  );
  return { data: listingsRes };
};

export const getMapListings = async (
  locations: MapLocationType[],
  filters?: { [key: string]: FilterType },
  skip?: number | null,
  limit?: number | null
): Promise<ResponseType<APIFeatureResponseType>> => {
  const constructRequest = (location: MapLocationType) => {
    const south = parseFloat(<string>location.bounds[0][0]);
    const north = parseFloat(<string>location.bounds[1][0]);
    const east = parseFloat(<string>location.bounds[1][1]);
    const west = parseFloat(<string>location.bounds[0][1]);
    const geoParams = `/listings/layer?south=${south}&north=${north}&east=${east}&west=${west}`;
    const paginationParams = `&skip=${skip}&limit=${limit}`;
    return skip && limit ? geoParams + paginationParams : geoParams;
  };
  const resArray = await Promise.all(
    locations.map((location: MapLocationType) => requests.get(constructRequest(location), filters))
  );
  const listingsRes = resArray.reduce(
    (acc: APIFeatureResponseType, val: { data: APIFeatureResponseType }) => {
      return { ...val.data, listings: acc.listings.concat(val.data.listings) };
    },
    { listings: [], total_pages: 0, total_listing: 0, current_page: 0 }
  );
  return { data: listingsRes };
};

export const getMyZipCode = async (): Promise<ResponseType<TMyZip>> => {
  return requests.get(`/listings/my-zip`);
};

export const getRealtorActivity = async (body: {
  bounding_box: [number, number, number, number];
  page: number;
  size: number;
  sort: string;
  order: 'desc' | 'asc';
}): Promise<ResponseType<TRealtorResponse>> => {
  return requests.post(`/listings/realtor-activity`, body);
};

export const postAdvertiseToAll = async (
  body: TPostAdvertiseToAll
): Promise<ResponseType<TAreaSearchListining>> => {
  return requests.post('/listings/advertise-to-all/listining', body);
};
