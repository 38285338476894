import React, { useState } from 'react';
import { Text, Button, Checkbox } from '@eltoro-ui/components';
import { Link } from 'react-router-dom';

import logobefore from '../../assets/Images/honeycomb.svg';
import logoafter from '../../assets/Images/honeycomb_left.svg';

import './PrivacyPolicy.scss';

export const PrivacyPolicy = () => {
  // const [isAgree, setIsAgree] = useState(false)
  return (
    <div className="privacy_policy_container">
      <img src={logobefore} alt="beewo-logo-back" className="img_top" id="logoBefore" />

      <Text on="white" weight="bold" UNSAFE_className="title">
        Scope
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        This privacy statement applies to information collected about you by the Beewo Application
        (&quot;<span className="bold">App</span>&quot;), provided by Beewo LLC (&quot;
        <span className="bold">Company</span>&quot; or <span className="bold">We</span>&quot;).
      </Text>

      <Text on="white" weight="bold" size="xl" UNSAFE_className="title">
        Personal Information
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        We collect personal information directly from you, for instance when you register with us,
        when you synchronize your contacts with us, and through your transactions with us. We may
        obtain additional information about you from other sources. We also use common technologies,
        such as cookies and beacons, in our App to collect information about you.
        <br />
        <br />
        We may collect:
        <ul>
          <li>
            <span>Personal Identifiers</span>, (“PI”) such as first and last name, alias, postal
            address, IP address, mobile ad identifiers, telephone numbers, email, and corporate
            addresses;
          </li>
          <li>
            <span>Demographic information</span>, such as general descriptive information on age,
            occupation, education, gender, etc., associated with an individual or household.
          </li>
          <li>
            <span>Commercial or transactional information</span>, such as records of personal
            property; products or services purchased, obtained, or considered; or other purchasing
            or consuming histories or tendencies;
          </li>
          <li>
            <span>Professional or employment-related information</span>, such as categories of
            occupations (generally inferred) or job titles of potential business prospects;
          </li>
          <li>
            <span>De-identified information related to unique user behavior</span>, such as browsing
            history, or online interests, like the fact that a particular user tends to visit sports
            websites or entertainment websites;
          </li>
          <li>
            <span>Geolocation data </span>
            based on mobile ad identifiers through the devices of our Platform Users and our
            third-party data providers, which enable us to provide our geolocation-based services;
          </li>
          <li>
            <span>Personal data </span>
            provided by our Platform Users within the platform and data through contact list uploads
            or partner integrations, including first and last name, alias, postal address, IP
            address, telephone numbers, and email and corporate addresses, and other information
            connected to those lists;
          </li>
          <li>
            <span>Multiple Listing Services (MLS) Account Number </span>
            provided by our Platform Users to allow us to incorporate the listings of Platform Users
            into our services;
          </li>
          <li>
            <span>Unique individual identifiers </span>
            that we, our Platform Users or other companies (such as ad and data companies) may
            create in order to identify a unique, de-identified user’s device or browser. We may use
            cookies and similar technologies that are linked to these identifiers (see the Section
            titled “We and Others May Use Cookies, Pixels and Similar Technologies”); and
          </li>
          <li>
            <span>Hashed values </span>
            that are derived from PI (such as email addresses) but have been de-identified, through
            various means.
          </li>
        </ul>
      </Text>

      <Text on="grey-500" size="xl" UNSAFE_className="description">
        For more information about the information we collect, including more information about what
        we mean by the terms above,{' '}
        <Link to="/#" className="loginBottomHelp">
          click here
        </Link>
        .
      </Text>

      <Text on="white" weight="bold" size="xl" UNSAFE_className="title">
        Use and Sharing
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        We use your personal information to provide you with the services you request and to provide
        offers and information regarding our products and services, and we may share your
        information with third-parties that we hire to help us provide products and services to you.
        We use the information you provide to us from your contact list uploads or partner
        integrations to enable us to conduct contact enrichment with third-party geolocation data to
        help you better understand your client base. Additionally, when you provide us your MLS
        number, we use it to incorporate your listings and locations of interest into the services
        we provide to you.
        <br />
        <br />
        We may share your information with:
        <ul>
          <li>
            <span>Members of Your Organization</span>: BEEWO may allow other members of your
            Organization or Corporate Account to view and access the information of Platform Users.
          </li>

          <li>
            <span>Partners</span>: We may share Information with business and data partners
            (including Marketing Platforms) to deliver ads, to help provide more tailored
            advertising, and for analytical purposes, such as to measure campaigns, to inform future
            campaigns, or to handle, analyze, or segregate the PI and other data that we handle for
            users. We may also share Information with our service providers, such as providers
            involved in tech or customer support, operations, web or data hosting, billing,
            accounting, security, marketing, data management, validation, enhancement or hygiene, or
            service providers otherwise assisting us to provide, develop, maintain and improve our
            services.
          </li>

          <li>
            <span>Corporate transfers</span>: If BEEWO or its significant assets are purchased by
            another entity, our Information will be transferred to that entity. We also may share
            the Information during the course of any due diligence process or preparation for or
            after a sale, merger, consolidation, change in control, transfer of substantial assets,
            reorganization, or liquidation.
          </li>

          <li>
            <span>Affiliates, Parents and Subsidiaries</span>: BEEWO may share some or all of the
            Information in our possession with affiliated or subsidiary companies.
          </li>
          <li>
            <span>As Required by Law or to Protect Any Party</span>: BEEWO may disclose Information
            if we believe that such disclosure is necessary (a) to comply with relevant laws or
            legal processes, such as to respond to subpoenas or warrants served on us; (b) to
            enforce our Terms of Service, this Privacy Policy, or other contracts with you,
            including investigation of potential violations thereof; (c) to respond to claims that
            any content violates the rights of third parties; and/or (d) to protect or otherwise
            defend the rights, property, or safety of BEEWO, our platform, our users, our agents and
            affiliates, and/or the public. We likewise may provide Information to other companies
            and organizations (including law enforcement) for fraud protection, spam/malware
            prevention, and similar purposes.
          </li>
        </ul>
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        For more information about how we use and share your information, including more information
        about what we mean by the terms above, click here.{' '}
        <Link to="/#" className="loginBottomHelp">
          click here
        </Link>
        .
      </Text>

      <Text on="white" weight="bold" size="xl" UNSAFE_className="title">
        Children Under 16
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        BEEWO does not actively seek to collect the personal Information of children under 16 years
        of age, and BEEWO&apos;s website(s) and services are not intended for use by children. If
        you have concerns about use of your child&apos;s personal Information, you may contact us at{' '}
        <Link to="/#" className="loginBottomHelp">
          privacy@beewo.com
        </Link>
        .
      </Text>
      <Text on="white" weight="bold" size="xl" UNSAFE_className="title">
        Links
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        BEEWO&apos;s website(s) and mobile applications may provide links to other websites that
        BEEWO thinks users will find interesting or useful. BEEWO is not responsible for the privacy
        practices of these other sites.
      </Text>

      <Text on="white" weight="bold" size="xl" UNSAFE_className="title">
        Your Choices
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        <ul>
          <li>
            You may create or change your privacy preferences by editing your profile settings.
          </li>
          <li>
            You may opt out of the BEEWO marketing platform database by accessing our opt out{' '}
            <Link to="/#" className="loginBottomHelp">
              page
            </Link>
            .
          </li>
          <li>
            You may request removal from our database by emailing us at{' '}
            <Link to="/#" className="loginBottomHelp">
              privacy@beewo.com
            </Link>
            .
          </li>
          <li>
            California residents may opt-out of personal information sales{' '}
            <Link to="/#" className="loginBottomHelp">
              here
            </Link>
            .
          </li>
          <li>You may delete and discontinue using this app.</li>
          <li>
            You may opt out of receiving advertising from the online Marketing Platforms that we
            partner with, and learn more about companies that engage in various types of online
            targeted and retargeted advertising, by visiting the opt-out pages offered by the
            Network Advertising Initiative (NAI) (
            <Link to="/#" className="loginBottomHelp">
              here
            </Link>
            ) and the DAA (
            <Link to="/#" className="loginBottomHelp">
              here
            </Link>
            ). Opting out in this manner will not prevent you from seeing ads; it will prevent
            targeted ads customized to what these companies believe are most likely to be relevant
            and of interest to you. In addition, opting out in this way is browser-specific, so if
            you change your browser, update it, or clear your cookie cache, you will need to opt out
            again.
          </li>
        </ul>
      </Text>

      <Text on="grey-500" size="xl" UNSAFE_className="description">
        BEEWO does not actively seek to collect the personal Information of children under 16 years
        of age, and BEEWO&quot;s website(s) and services are not intended for use by children. If
        you have concerns about use of your child&quot;s personal Information, you may contact us at{' '}
        <Link to="/#" className="loginBottomHelp">
          privacy@beewo.com
        </Link>
        .
      </Text>

      <Text on="white" weight="bold" size="xl" UNSAFE_className="title">
        Security and Data Integrity
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        BEEWO takes various steps to protect the Information we license or maintain, and to house
        and transmit that Information in a secure way. For instance, these steps may include various
        types of physical and electronic security, including firewall protections, encryption,
        hashing or truncation of data, and access controls to personal Information. While neither we
        nor any platform can guarantee 100 percent safety from hacks or illegal intrusion, we take
        these steps in an effort to ensure this does not occur.
      </Text>

      <Text on="white" weight="bold" size="xl" UNSAFE_className="title">
        California Residents
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        California residents may have additional personal information rights and choices. Please see
        here for more information.{' '}
      </Text>

      <Text on="white" weight="bold" size="xl" UNSAFE_className="title">
        Binding Agreement; Changes
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        We may revise this Privacy Policy at any time and with an associated effective date. Any
        revised Privacy Policy will be posted on BEEWO’s website(s). You are responsible for
        checking this Privacy Notice for updates when you use any BEEWO website.{' '}
      </Text>

      <Text on="white" weight="bold" size="xl" UNSAFE_className="title">
        How to Contact Us
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        You may email us at{' '}
        <Link to="/#" className="loginBottomHelp">
          privacy@beewo.com
        </Link>{' '}
        or write to us directly at:
        <br />
        ATTN: Privacy Officer
        <br />
        BEEWO LLC
        <br />
        552 E. Market St.
        <br />
        Louisville, KY 40202
      </Text>

      <br />

      <Text on="grey-500" size="xl" UNSAFE_className="description">
        This is a summary of our Privacy Policy. A complete version of our Privacy Policy is
        available at:{' '}
        <Link to="/#" className="loginBottomHelp">
          https://beewo.com/privacy/
        </Link>
        .
      </Text>
      <br />

      <hr className="divider" />
      <br />

      {/* <div className="button_group">
        <Checkbox
          checked={isAgree}
          label="I agree to Terms"
          onChange={() => setIsAgree(!isAgree)}
        />

        <Button
          type="submit"
          kind="primary"
          UNSAFE_className="RealEstateInfo__save_button"
          disabled={!isAgree}
        >
          Accept
        </Button>
      </div> */}

      <img src={logoafter} alt="beewo-logo-back" className="img_bottom" id="logoAfter" />
    </div>
  );
};
