export enum CreativeStatusEnum {
  ACTIVE = 'active',
  CREATING = 'creating',
  UPLOADING = 'uploading',
  ERROR = 'error',
  EXPIRED = 'expired',
}

export enum CampaignCreativeStatusEnum {
  CREATIVE_STATUS_UNSPECIFIED = 'CREATIVE_STATUS_UNSPECIFIED',
  CREATIVE_STATUS_ACCEPTED = 'CREATIVE_STATUS_ACCEPTED',
  CREATIVE_STATUS_ACTIVE = 'CREATIVE_STATUS_ACTIVE',
  CREATIVE_STATUS_CANCELLED = 'CREATIVE_STATUS_CANCELLED',
  CREATIVE_STATUS_DELETED = 'CREATIVE_STATUS_DELETED',
  CREATIVE_STATUS_FAILED = 'CREATIVE_STATUS_FAILED',
  CREATIVE_STATUS_SUCCEEDED = 'CREATIVE_STATUS_SUCCEEDED',
  CREATIVE_STATUS_SUSPENDED = 'CREATIVE_STATUS_SUSPENDED',
  CREATIVE_STATUS_VERIFIED = 'CREATIVE_STATUS_VERIFIED',
  CREATIVE_STATUS_CREATING = 'CREATIVE_STATUS_CREATING',
  CREATIVE_STATUS_DELETING = 'CREATIVE_STATUS_DELETING',
  CREATIVE_STATUS_PENDING = 'CREATIVE_STATUS_PENDING',
  CREATIVE_STATUS_REPAIRING = 'CREATIVE_STATUS_REPAIRING',
  CREATIVE_STATUS_RUNNING = 'CREATIVE_STATUS_RUNNING',
  CREATIVE_STATUS_SUSPENDING = 'CREATIVE_STATUS_SUSPENDING',
  CREATIVE_STATUS_APPROVED = 'CREATIVE_STATUS_APPROVED',
  CREATIVE_STATUS_DISABLED = 'CREATIVE_STATUS_DISABLED',
  CREATIVE_STATUS_STORED = 'CREATIVE_STATUS_STORED',
  CREATIVE_STATUS_INITIALIZING = 'CREATIVE_STATUS_INITIALIZING',
  CREATIVE_STATUS_PROCESSING = 'CREATIVE_STATUS_PROCESSING',
  CREATIVE_STATUS_STORING = 'CREATIVE_STATUS_STORING',
  CREATIVE_STATUS_TRANSCODING = 'CREATIVE_STATUS_TRANSCODING',
  CREATIVE_STATUS_UPLOADING = 'CREATIVE_STATUS_UPLOADING',
  CREATIVE_STATUS_VASTING = 'CREATIVE_STATUS_VASTING',
  CREATIVE_STATUS_APPROVE = 'CREATIVE_STATUS_APPROVE',
  CREATIVE_STATUS_ERROR = 'CREATIVE_STATUS_ERROR',
  CREATIVE_STATUS_PROCESS = 'CREATIVE_STATUS_PROCESS',
  CREATIVE_STATUS_REVIEW = 'CREATIVE_STATUS_REVIEW',
  CREATIVE_STATUS_ARCHIVED = 'CREATIVE_STATUS_ARCHIVED',
  CREATIVE_STATUS_EXPIRED = 'CREATIVE_STATUS_EXPIRED',
  CREATIVE_STATUS_PURGED = 'CREATIVE_STATUS_PURGED',
  CREATIVE_STATUS_UNARCHIVING = 'CREATIVE_STATUS_UNARCHIVING',
}

export enum CampaignCreativeTypeEnum {
  CREATIVE_TYPE_UNSPECIFIED = 'CREATIVE_TYPE_UNSPECIFIED',
  CREATIVE_TYPE_BANNER = 'CREATIVE_TYPE_BANNER',
  CREATIVE_TYPE_NATIVE_BANNER = 'CREATIVE_TYPE_NATIVE_BANNER',
  CREATIVE_TYPE_NATIVE_VIDEO = 'CREATIVE_TYPE_NATIVE_VIDEO',
  CREATIVE_TYPE_HTML5 = 'CREATIVE_TYPE_HTML5',
  CREATIVE_TYPE_VAST_TAG = 'CREATIVE_TYPE_VAST_TAG',
  CREATIVE_TYPE_AD_TAG = 'CREATIVE_TYPE_AD_TAG',
  CREATIVE_TYPE_OTT = 'CREATIVE_TYPE_OTT',
  CREATIVE_TYPE_AUDIO = 'CREATIVE_TYPE_AUDIO',
  CREATIVE_TYPE_VIDEO = 'CREATIVE_TYPE_VIDEO',
  CREATIVE_TYPE_MAIL_PIECE = 'CREATIVE_TYPE_MAIL_PIECE',
}

export enum CreativeTargetTypeEnum {
  AUDIENCE_TYPE_DEVICE = 'AUDIENCE_TYPE_DEVICE',
  AUDIENCE_TYPE_DC = 'AUDIENCE_TYPE_DC',
  AUDIENCE_TYPE_B2C = 'AUDIENCE_TYPE_B2C',
  AUDIENCE_TYPE_IP_FOR_ETHASH = 'AUDIENCE_TYPE_IP_FOR_ETHASH',
}

export enum CreativeName {
  VIDEO = 'video_creative',
  BANNER = 'banner_creative',
}

export enum CampaignOrderlineCreativeTypeEnum {
  BANNER = 'Banner',
  VIDEO = 'Video',
}

export enum CreativeTypeEnum {
  BANNER = 'banner',
  VIDEO = 'video',
}

export enum AudienceStatusEnum {
  ACTIVE = 'Active',
  PENDING = 'Pending',
  ERROR = 'Error',
}

export enum OrderlineStatusEnum {
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  IN_REVIEW = 'In Review',
  ACTIVE = 'Active',
  COMPLETED = 'Completed',
}

export enum CampaignStatusEnum {
  CREATING = 'Creating',
  ERROR = 'Error',
  IN_REVIEW = 'In Review',
  PAYMENT_FAILED = 'Payment Failed',
}

export enum CampaignEltoroStatusEnum {
  CREATING = 'Creating',
  ERROR = 'Error',
  IN_REVIEW = 'In Review',
}

export enum CampaignStep {
  NAME = 1,
  AUDIENCE,
  CREATIVE,
  CHECKOUT,
  DONE,
}

export enum ProspectNameTypeEnums {
  SELL_YOUR_LISTING_FASTER = 'Sell Your Listing Faster',
  TALK_TO_YOUR_SPHERE = 'Talk To Your Sphere',
  GROW_YOUR_SPHERE = 'Grow Your Sphere',
  LIKELY_SELLERS = 'Likely Sellers',
  VIRTUAL_JUST_LISTED_JUST_SOLD = 'Virtual Just Listed/Just Sold',
}

export enum NotificationStatusEnum {
  SUCCESS = 'Success',
  INFORMATION = 'Information',
  ERROR = 'Error',
  WARNING = 'Warning',
}

export enum NotificationSettingType {
  ACCOUNT = 'Account',
  CONTACTS = 'Contacts',
  CAMPAIGN = 'Campaign',
}

export enum UserDeviceEnum {
  WEB = 'web',
  ANDROID = 'android',
  IOS = 'ios',
}

export enum GuideEnum {
  CONTACTS = 'CONTACTS',
  RECOMENDATIONS = 'RECOMENDATIONS',
}

export enum RecomendationContactEnum {
  SELLER = 'SELLER',
  BUYER = 'BUYER',
}

export enum RecomendationTypeEnum {
  AGENT = 'Agent type',
  LISTING = 'Listing',
}

export enum RecomendationListingEnum {
  PROMOTION = 'Promote Your Listing',
  ALERT_NEIGHBORS = 'Alert Neighbors',
}
