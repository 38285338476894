import React, { useState } from 'react';
import './CalendarInput.scss';
import { CalendarInputDay, CalendarInputMonth } from '..';

const makePaddedMonth = (date: Date) => {
  const padding = new Date(date.getFullYear(), date.getMonth(), 1).getDay() - 1;
  return new Array(42)
    .fill(null)
    .map((day, i) => new Date(date.getFullYear(), date.getMonth(), i - padding));
};

const isSameDay = (day1: Date | null | undefined, day2: Date | null | undefined) => {
  return day1?.toDateString() === day2?.toDateString();
};

export const CalendarInput = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  inputTarget,
  onInputTargetChange,
  min,
  max,
  editableDates,
}: {
  startDate?: Date;
  endDate?: Date;
  onStartDateChange?: (newStartDate: any) => any;
  onEndDateChange?: (newEndDate: Date) => void;
  inputTarget: 'start' | 'end';
  onInputTargetChange: (newTarget: 'start' | 'end') => void;
  min?: Date;
  max?: Date;
  editableDates?: boolean;
}) => {
  const [displayMonth, setDisplayMonth] = useState(new Date(new Date().toDateString()));
  const [hoveredDay, setHoveredDay] = useState<Date | null>(null);

  const toggleTarget = () => onInputTargetChange(inputTarget === 'start' ? 'end' : 'start');

  const handleDayClick = (date: Date) => {
    if (date === startDate && date === endDate && onStartDateChange && onEndDateChange) {
      onStartDateChange(date);
      onEndDateChange(date);
      onInputTargetChange('end');
    }
    if (inputTarget === 'start' && onStartDateChange && onEndDateChange && endDate) {
      if (date > endDate) {
        onStartDateChange(date);
        onEndDateChange(date);
        toggleTarget();
      } else {
        onStartDateChange(date);
        toggleTarget();
      }
    }
    if (inputTarget === 'end' && onStartDateChange && onEndDateChange && startDate) {
      if (date < startDate) {
        onStartDateChange(date);
        onEndDateChange(date);
      } else {
        onEndDateChange(date);
        toggleTarget();
      }
    }
  };

  return (
    <div className="CalendarInput">
      <CalendarInputMonth month={displayMonth} onMonthChange={setDisplayMonth} />
      <div className="CalendarInput__days-grid">
        {startDate &&
          endDate &&
          makePaddedMonth(displayMonth).map(day => {
            const isDisabled = (!!min && day < min) || (!!max && day > max);
            const isInRange = day >= startDate && day <= endDate;
            const isStart = isSameDay(day, startDate);
            const isEnd = isSameDay(day, endDate);
            const isHovered = isSameDay(day, hoveredDay);

            const isInHoveredRange = (() => {
              if (isHovered) return true;
              if (hoveredDay) {
                if (inputTarget === 'start' && day >= hoveredDay && day <= endDate) return true;

                if (inputTarget === 'end' && day >= startDate && day <= hoveredDay) return true;
              }
              return false;
            })();

            const isHoveredBeforeStart =
              isHovered &&
              !!hoveredDay &&
              hoveredDay < startDate &&
              !isSameDay(hoveredDay, endDate);

            const isHoveredAfterEnd =
              isHovered &&
              !!hoveredDay &&
              hoveredDay > endDate &&
              !isSameDay(hoveredDay, startDate);

            const isHoveredStart =
              isHoveredBeforeStart ||
              (isHovered && inputTarget === 'start') ||
              (isHovered && isStart && inputTarget === 'end') ||
              (!!hoveredDay && hoveredDay > startDate && isStart);

            const isHoveredEnd =
              isHoveredAfterEnd ||
              (isHovered && inputTarget === 'end') ||
              (isHovered && isEnd && inputTarget === 'start') ||
              (isHoveredBeforeStart && inputTarget === 'end') ||
              (!isHovered && !!hoveredDay && isEnd && inputTarget === 'start');

            return (
              <CalendarInputDay
                key={day.toDateString()}
                day={day}
                isInMonth={displayMonth.getMonth() === day.getMonth()}
                isSubdued={!!hoveredDay && isInRange}
                isDisabled={isDisabled}
                isStart={isStart}
                isEnd={isEnd}
                isInRange={isInRange}
                isHovered={isHovered}
                isHoveredStart={isHoveredStart}
                isHoveredEnd={isHoveredEnd}
                isInHoveredRange={isInHoveredRange}
                onClick={handleDayClick}
                editableDates={editableDates}
                onHoverChange={(hovered, _day) => setHoveredDay(hovered ? _day : null)}
              />
            );
          })}
      </div>
    </div>
  );
};
