import React from 'react';
import type { ReactNode } from 'react';

import { Button, Modal } from '@eltoro-ui/components';
import type { ModalProps } from '@eltoro-ui/components';

import './ConfirmModal.scss';

type ConfirmModalProps = {
  type?: 'confirm' | 'delete';
  title?: string | ReactNode;
  content: string | ReactNode;
  loading?: boolean;
  onOk?: () => void;
  onCancel: () => void;
  okText?: string;
  cancelText?: string;
};

const ConfirmModal = ({
  type = 'confirm',
  title,
  content,
  loading,
  onOk,
  onCancel,
  okText = 'Yes, Confirm',
  cancelText = 'No, Thanks',
}: ConfirmModalProps & ModalProps) => {
  return (
    <Modal className="confirm-modal" offClick={!loading ? onCancel : () => undefined}>
      <div className="confirm-modal-content">
        {title && <span className="confirm-modal-title">{title}</span>}
        {content && <div className="confirm-modal-body">{content}</div>}
      </div>
      <div className="confirm-modal-footer">
        <Button size="l" onClick={!loading ? onCancel : () => undefined} weight="bold">
          {cancelText}
        </Button>
        <Button
          kind={type === 'delete' ? 'danger' : 'primary'}
          size="l"
          loading={loading}
          disabled={loading}
          onClick={onOk}
          weight="bold"
        >
          {okText}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
