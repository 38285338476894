import React, { useState, useEffect } from 'react';
import { votingMethod, getVoting } from 'Requests';
import './VotingModal.scss';
import { Button } from '@eltoro-ui/components';
import toast from 'react-hot-toast';

import cross from '../../assets/Images/cross.png';

type TextInputType = {
  handleModel?: any;
  title?: string | undefined;
  icons?: string;
  subTitle?: string;
};

type initialState = {
  my_vote?: boolean;
  falseValue?: number | undefined;
  trueValue?: number | undefined;
};

export const VotingModal: React.FC<TextInputType> = ({ icons, title, subTitle, handleModel }) => {
  const [yesVote, setyesVote] = useState<string | null>('null');
  const [getVoteData, setGetVoteData] = useState<initialState>({});
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [hideButton, setHideButton] = useState<boolean>(true);

  // --voting poll status -- //
  const totalPerson =
    (getVoteData.trueValue ? getVoteData.trueValue : 0) +
    (getVoteData.falseValue ? getVoteData.falseValue : 0);
  const voteForYes = getVoteData.trueValue ? getVoteData.trueValue : 0;
  const voteForNo = getVoteData.falseValue ? getVoteData.falseValue : 0;
  const YesResult = voteForYes * 100;
  const NoResult = voteForNo * 100;

  const radioChange = (e: React.ChangeEvent<HTMLInputElement | undefined>) => {
    setyesVote(e.target.value);
    setHideButton(false);
  };
  useEffect(() => {
    getVoting(title)
      .then(data => {
        setGetVoteData({
          ...data.data,
          my_vote: data.data?.my_vote,
          trueValue: data.data?.true,
          falseValue: data.data?.false,
        });
        // if (data.data?.my_vote === null) {
        //   setyesVote('true')
        // } else {
        setyesVote(String(data.data?.my_vote));
        // }
      })
      .catch(error => {
        toast.error(error);
      });
  }, []);
  const closedSection = () => {
    handleModel();
  };
  const onSubmit = (event: any) => {
    event?.preventDefault();
    setDisableButton(true);
    votingMethod(yesVote ? JSON.parse(yesVote) : '', title)
      .then(() => {
        handleModel();
        toast.success('Thanks for your feedback');
      })
      .catch(() => {
        toast.error('Send error');
      })
      .finally(() => {
        setDisableButton(false);
      });

    // export const subscribeTopic = async (
    //   device_token: string,
    //   subscribe: number,
    // ): Promise<ResponseType<{ msg: string }>> =>
    //   requests.post(`/notifications/fcm_topic`, { device_token, subscribe })
  };

  return (
    <>
      <form
        onSubmit={e => {
          onSubmit(e);
        }}
      >
        <div className="main_voting">
          <img
            src={cross}
            onClick={closedSection}
            alt="cross"
            className="PersonalInfoForm__cross"
            role="presentation"
          />
          <p className="comming_soon_text">Coming Soon</p>

          <img className="main_voting__icon" src={icons} alt="" />
          <p className="voting_sub_heading">{title}</p>
          {/* <p className="voting_discription">{subTitle}</p> */}
          <Button
            UNSAFE_className="Voting_submit"
            type="submit"
            kind="primary"
            size="s"
            weight="bolder"
            fonts="15px"
            name="sign_in"
            // UNSAFE_style={{ justifyContent: 'center' }}
            UNSAFE_style={
              hideButton
                ? { display: 'none', justifyContent: 'center' }
                : { justifyContent: 'center' }
            }
            disabled={yesVote === 'null' || disableButton}
            loading={disableButton}
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};
