import React, { useRef, useState, useCallback, useEffect } from 'react';
import Tippy from '@tippyjs/react';

import type { TContact } from 'types';

import { isEllipsisActive } from 'Utils/helpers';
import { useResizeEffect } from 'Hooks';

type AddressesAccessorProps = {
  addresses: TContact['addresses'];
};

const AddressesAccessor = ({ addresses }: AddressesAccessorProps) => {
  const ref = useRef<HTMLSpanElement>(null);
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (ref.current) setDisabled(isEllipsisActive(ref.current.parentNode as HTMLElement));
  }, [ref.current]);

  const setDisabledOnResize = useCallback(() => {
    if (!ref.current) return;
    setDisabled(isEllipsisActive(ref.current.parentNode as HTMLElement));
  }, [ref.current]);

  // @ts-ignore
  useResizeEffect(setDisabledOnResize, [ref]);

  const address_zip_code = (separator: string, tooltipContent?: boolean) => {
    if (!addresses.length) return '-';

    return addresses.map(({ street, postal_code }, index, list) =>
      !street && !postal_code ? null : (
        <span key={index}>
          {!!index &&
            !tooltipContent &&
            !!(list[index - 1].street || list[index - 1].postal_code) &&
            separator}
          <span>
            {street}
            {street && postal_code && ', '}
            <b>{postal_code}</b>
          </span>
          {tooltipContent ? (index === list.length - 1 ? null : separator) : null}
        </span>
      )
    );
  };

  if (addresses.length === 1 && !addresses[0].postal_code && !addresses[0].street)
    return <span>-</span>;

  return (
    <Tippy
      placement="bottom-start"
      content={<div className="address-tooltip-content">{address_zip_code(';', true)}</div>}
      disabled={disabled}
    >
      <span ref={ref}>{address_zip_code(', ')}</span>
    </Tippy>
  );
};

export default AddressesAccessor;
