import React from 'react';
import type { FC } from 'react';

import { PieChart } from 'react-minimal-pie-chart';

import { Text } from '@eltoro-ui/components';
import { Section } from 'Components';

import nodata from 'assets/Images/nodata.png';

import './CampaignType.scss';

type CompaignDataType = {
  banner: number;
  video: number;
};

export const CompaignType: FC<{ data: CompaignDataType }> = ({ data }) => {
  const compaignTypeDataSchema = {
    pieData: [
      { title: 'Banner Ad', value: data?.banner || 0, color: '#85E2FF' },
      { title: 'Video Ad', value: data?.video || 0, color: '#FF6D03' },
    ],
    context: [
      { value: data?.video || 0, label: 'Video Ad', backgroundColor: '#FF6D03' },
      {
        value: data?.banner || 0,
        label: 'Banner Ad',
        backgroundColor: '#85E2FF',
      },
    ],
  };
  const CampaignData = Object.values(data).every(value => !value);

  return (
    <Section
      title="Ad Type"
      UNSAFE_className_text="campaignType__title"
      actions="Last 30 days"
      UNSAFE_className_container="campaignType__section"
    >
      <div className="campaignType__campaign-type">
        <div className="campaignType__campaign-type-text">
          {compaignTypeDataSchema.context.map(({ value, label, backgroundColor }) => {
            return (
              <div key={`${label}-${value}`} className="campaignType__context_containers">
                <div
                  className="campaignType__in-progress-campaign-circle"
                  style={{
                    backgroundColor,
                  }}
                />
                <Text on="white" UNSAFE_className="campaignType__label">
                  <span className="campaignType__value">{Math.round(value)}%</span> - {label}
                </Text>
              </div>
            );
          })}
        </div>
        {CampaignData ? (
          <div className="noData-campaign-type">
            <img style={{ width: '100px' }} src={nodata} alt="nodata" />
            <Text on="tertiary-300" UNSAFE_className="noDataText">
              No Data Found
            </Text>
          </div>
        ) : (
          <div className="finished_campaigns_container">
            <PieChart
              className="campaignType__pieChart finished_campaigns_container__pie_className"
              lineWidth={25}
              labelPosition={0}
              rounded
              data={compaignTypeDataSchema.pieData}
            />
          </div>
        )}
      </div>
    </Section>
  );
};
