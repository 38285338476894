import React from 'react';
import { faMap } from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GridBox } from '@eltoro-ui/components';

import { MapLocationType } from 'types';
import './LocationLabel.scss';
import { SpinnerLoader } from 'Components/SpinnerLoader';

export const truncate = (str: string, len = 20) => {
  return str.length >= len ? str.substring(0, len) : str;
};
export const LocationLabel: React.FC<{
  location: any;
  onRemove: (id: string) => void;
  loading?: boolean;
}> = ({ location, onRemove, loading }) => {
  return (
    <div className="LocationLabel">
      <GridBox
        gridTemplateColumns="min-content auto min-content"
        gap="0.2rem"
        padding="0.25rem"
        alignItems="center"
      >
        <div className="LocationLabel__icon-circle">
          <FontAwesomeIcon icon={faSearch} />
        </div>

        <div className="LocationLabel__label">{location.display_value}</div>

        <div className="LocationLabel__icon-circle">
          {loading ? (
            <SpinnerLoader
              style={{
                position: 'unset',
                width: '1.5rem',
                height: '1.5rem',
                borderWidth: '0.23em',
              }}
            />
          ) : (
            <button
              aria-label={`Click to remove location: ${location.value}`}
              className="LocationLabel__delete-button"
              type="button"
              onClick={() => location && onRemove(location.value)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          )}
        </div>
      </GridBox>
    </div>
  );
};
