import React from 'react';

import { Skeleton } from 'primereact/skeleton';

import { FlexBox } from '@eltoro-ui/components';

const CreativeDetailSkeleton = () => {
  return (
    <FlexBox flexDirection="column" gap="16px" padding="16px 20px">
      <Skeleton height="248px" />
      <FlexBox flexDirection="column" gap="18px">
        <FlexBox flexDirection="column" gap="5px">
          <Skeleton width="6rem" height="1.3125rem" />
          <Skeleton width="17rem" height="1.5rem" />
        </FlexBox>
        <FlexBox flexDirection="column" gap="5px">
          <Skeleton width="6rem" height="1.3125rem" />
          <Skeleton width="17rem" height="1.5rem" />
        </FlexBox>
        <FlexBox flexDirection="column" gap="5px">
          <Skeleton width="6rem" height="1.3125rem" />
          <Skeleton width="17rem" height="1.5rem" />
        </FlexBox>
        <FlexBox flexDirection="column" gap="5px">
          <Skeleton width="6rem" height="1.3125rem" />
          <Skeleton width="17rem" height="1.5rem" />
        </FlexBox>
        <FlexBox flexDirection="column" gap="5px">
          <Skeleton width="6rem" height="1.3125rem" />
          <Skeleton width="17rem" height="1.5rem" />
        </FlexBox>
        <FlexBox flexDirection="column" gap="5px">
          <Skeleton width="6rem" height="1.3125rem" />
          <Skeleton width="17rem" height="1.5rem" />
        </FlexBox>
        <FlexBox flexDirection="column" gap="5px">
          <Skeleton width="6rem" height="1.3125rem" />
          <Skeleton width="17rem" height="1.5rem" />
        </FlexBox>
        <FlexBox flexDirection="column" gap="5px">
          <Skeleton width="6rem" height="1.3125rem" />
          <Skeleton width="17rem" height="1.5rem" />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default CreativeDetailSkeleton;
