import { FilterType } from '../Typings';

// default set of filters mapped to Filter tab
export const filters: FilterType[] = [
  {
    id: '01',
    start: 'rgba(255,0,0,1)',
    stop: 'rgba(0,0,255,1)',
    startSpace: 0,
    stopSpace: 1,
    angle: 45,
    opacity: 0.5,
  },
  {
    id: '02',
    start: 'rgba(255, 247, 0, 1)',
    stop: 'rgba(255, 57, 0, 1)',
    startSpace: 0.31,
    stopSpace: 0.85,
    angle: 135,
    opacity: 0.55,
  },
  {
    id: '03',
    start: 'rgba(0, 250, 255, 1)',
    stop: 'rgba(118, 0, 198, 1)',
    startSpace: 0.22,
    stopSpace: 1,
    angle: 255,
    opacity: 0.77,
  },
  {
    id: '04',
    start: 'rgba(255, 152, 0, 1)',
    stop: 'rgba(104, 114, 255, 1)',
    startSpace: 0.09,
    stopSpace: 0.52,
    angle: 150,
    opacity: 0.53,
  },
  {
    id: '05',
    start: 'rgba(237, 246, 255, 1)',
    stop: 'rgba(4, 38, 71, 1)',
    startSpace: 0.23,
    stopSpace: 0.89,
    angle: 295,
    opacity: 0.75,
  },
  {
    id: '06',
    start: 'rgba(0, 255, 231, 0)',
    stop: 'rgba(0, 255, 158, 1)',
    startSpace: 0.37,
    stopSpace: 0.88,
    angle: 38,
    opacity: 1,
  },
  {
    id: '07',
    start: 'rgba(0, 255, 250, 1)',
    stop: 'rgba(184, 16, 178, 1)',
    startSpace: 0.3,
    stopSpace: 0.68,
    angle: 135,
    opacity: 0.76,
  },
  {
    id: '08',
    start: 'rgba(0, 0, 0, 0.53)',
    stop: 'rgba(0, 0, 0, 1)',
    startSpace: 0.26,
    stopSpace: 1,
    angle: 305,
    opacity: 1,
  },
];
