import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { PageNav, PageRoutes, Text } from '@eltoro-ui/components';
import { Layout, Loading } from 'Components';
import { useSelector } from 'react-redux';
import { TabWrapper } from 'Pages/MyProfile/components/TabWrapper';
import { TRootState } from 'types';
import RecomendedForYou from './components/RecomendedForYou';
import Library from './components/Library';
import './styles.scss';

export const RecomendationsPage = () => {
  const [profilePages, setprofilePages] = useState<any>([]);
  const { userData } = useSelector((state: TRootState) => state.userReducer);
  const { userRecomendations } = useSelector((state: TRootState) => state.recomendationsReducer);

  const contactsData = userRecomendations?.recommendations[0];
  const contactFile = userRecomendations?.contact_files;

  const isAnalizing =
    contactFile?.active >= 1 &&
    contactsData?.buyer_count === null &&
    contactsData?.seller_count === null;
  const isImporting = contactFile?.pending >= 1 && contactFile?.active === 0;
  const isEmpty =
    contactFile?.active === 0 && !contactsData?.buyer_count && !contactsData?.seller_count;

  useEffect(() => {
    setprofilePages([
      {
        path: '/recomendations',
        title: 'Recommended for You',
        exactPath: true,
        component: () => (
          <TabWrapper>
            {!userRecomendations ? (
              <Loading />
            ) : (
              <RecomendedForYou
                buyersCount={contactsData?.buyer_count}
                sellersCount={contactsData?.seller_count}
              />
            )}
          </TabWrapper>
        ),
      },
      {
        path: '/recomendations/library',
        title: 'Library',
        exactPath: false,
        component: () => (
          <TabWrapper>
            {!userRecomendations ? (
              <Loading />
            ) : (
              <Library
                buyersCount={contactsData?.buyer_count}
                sellersCount={contactsData?.seller_count}
                isAnalizing={isAnalizing}
                isImporting={isImporting}
                isEmpty={isEmpty}
              />
            )}
          </TabWrapper>
        ),
      },
    ]);
  }, [userRecomendations]);

  return (
    <Layout>
      <div className="RecomendationContainer">
        <BrowserRouter>
          <nav className="RecomendationContainer__nav">
            <Text on="white" weight="bold" UNSAFE_className="title">
              Activity Alerts
            </Text>
            <Text on="white" UNSAFE_className="heading_desc">
              Beewo&#39;s Activity Alerts scan market trends that are relevant to you. Execute
              engagement on the go.
            </Text>
            <PageNav pages={profilePages} userData={userData} />
          </nav>

          <main className="RecomendationContainer__main">
            <PageRoutes pages={profilePages} />
          </main>
        </BrowserRouter>
      </div>
    </Layout>
  );
};
