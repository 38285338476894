import React from 'react';

import { Divider } from 'primereact/divider';

import { Modal } from '@eltoro-ui/components';
import { StepOne } from './StepOne';
import { StepTwo } from './StepTwo';

import './UploadCSVModal.scss';

export const UploadCSVModal = ({ onClose }: { onClose: (e?: boolean) => void }) => {
  return (
    <Modal offClick={onClose} closable={false}>
      <div className="UploadBannerModal uploadCSVModal">
        <StepOne onClose={onClose} />
        <Divider />
        <StepTwo onClose={onClose} />
      </div>
    </Modal>
  );
};
