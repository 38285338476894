import React from 'react';
import { Text } from '@eltoro-ui/components';
import { Link } from 'react-router-dom';

import logobefore from '../../assets/Images/honeycomb.svg';
import logoafter from '../../assets/Images/honeycomb_left.svg';

import './GeneralPrivacyPolicy.scss';

export const GeneralPrivacyPolicy = () => {
  return (
    <div className="general_privacy_policy_container">
      <img src={logobefore} alt="beewo-logo-back" className="img_top" id="logoBefore" />

      <Text on="white" weight="bold" UNSAFE_className="title">
        Required in conjunction with this policy:
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        ●{' '}
        <Link to="/#" className="loginBottomHelp">
          privacy@beewo.com
        </Link>{' '}
        email account
        <br />●{' '}
        <Link to="/#" className="loginBottomHelp">
          https://beewo.com/privacy/
        </Link>{' '}
        landing page (for this policy){' '}
        <Link to="/#" className="loginBottomHelp">
          here
        </Link>
        <br />●{' '}
        <Link to="/#" className="loginBottomHelp">
          https://beewo.com/do-not-sell-my-personal-information/
        </Link>{' '}
        landing page{' '}
        <Link to="/#" className="loginBottomHelp">
          here
        </Link>
        <br />●{' '}
        <Link to="/#" className="loginBottomHelp">
          https://beewo.com/california-consumers-privacy-rights/
        </Link>{' '}
        landing page{' '}
        <Link to="/#" className="loginBottomHelp">
          here
        </Link>
        <br />●{' '}
        <Link to="/#" className="loginBottomHelp">
          https://beewo.com/ca-resident-access-deletion-form/
        </Link>{' '}
        landing page{' '}
        <Link to="/#" className="loginBottomHelp">
          here
        </Link>
      </Text>

      <br />
      <br />

      <Text on="white" weight="bold" UNSAFE_className="heading">
        Privacy Policy
      </Text>

      <Text on="white" weight="bold" size="xl" UNSAFE_className="title">
        BEEWO Data Services and Website Privacy Notice
        <br />
        <br />
        Last Modified: xx
      </Text>

      <Text on="grey-500" size="xl" UNSAFE_className="description">
        BEEWO LLC doing business as BEEWO (“BEEWO,” “We,” or “Our”) helps our Platform Users, who
        are generally real estate marketers, related advertisers and their agencies and providers
        (collectively “Platform Users”) to tailor their advertising to consumers who are most likely
        to consider their ads relevant, based on those consumers’ activities, interests, and
        demographics. We work with Platform Users across various marketing methods and channels,
        including display media, email, programmatic audio, and direct mail, and perform data
        services and analytics, including the services described on our website and mobile
        applications.
        <br />
        <br />
        BEEWO is a strong supporter of individual privacy and a protector of personally identifiable
        information. BEEWO adheres to the Digital Advertising Alliance (“DAA”) Self-Regulatory
        Principles, which is a prominent industry standard setting forth guidance for consumer
        privacy protection.
        <br />
        <br />
        BEEWO also supports and encourages all Platform Users to follow the National Association of
        Realtors Code of Ethics, which is available{' '}
        <Link to="/#" className="loginBottomHelp">
          here
        </Link>
        .
        <br />
        <br />
        The following constitutes BEEWO’s Privacy Policy (“Privacy Policy”) and is intended to
        provide a transparent look into BEEWO’s data practices. You may{' '}
        <Link to="/#" className="loginBottomHelp">
          click here
        </Link>{' '}
        to learn more about how to opt-out of BEEWO’s services. If you are a California resident (as
        defined by the California Consumer Privacy Act of 2018 and/or the Consumer Privacy Rights
        Act of 2020), please see our California Privacy Policy Rights Addendum{' '}
        <Link to="/#" className="loginBottomHelp">
          here
        </Link>
        , which supplements the information in this Privacy Policy for California residents.
        California residents may also opt-out of the “sale” of their personal information by
        clicking{' '}
        <Link to="/#" className="loginBottomHelp">
          here
        </Link>
        , or may request access to or deletion of their personal information by clicking{' '}
        <Link to="/#" className="loginBottomHelp">
          here
        </Link>
        .
      </Text>

      <Text on="white" weight="bold" size="xl" UNSAFE_className="title">
        The Types of Information We Obtain and Use to Provide Our Services
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        BEEWO may receive or collect the following types of information (collectively, the
        “Information”) from a variety of consumer-facing companies and services, data compilers and
        suppliers, publicly available data (such as from government and public sources), and
        marketing platforms (collectively, “Marketing Platforms”) as well as from Platform Users
        when we perform services for those Platform Users:
        <ul>
          <li>
            <span>Personal Identifiers</span> (“PI”), such as first and last name, alias, postal
            address, IP address, mobile ad identifiers, telephone numbers, email, and corporate
            addresses;
          </li>

          <li>
            <span>Demographic information</span>, such as general descriptive information on age,
            occupation, education, gender, etc., associated with an individual or household.
          </li>

          <li>
            <span>Commercial or transactional information</span>, such as records of personal
            property; products or services purchased, obtained, or considered; or other purchasing
            or consuming histories or tendencies;
          </li>

          <li>
            <span>Professional or employment-related information</span>, such as categories of
            occupations (generally inferred) or job titles of potential business prospects;
          </li>
          <li>
            <span>De-identified information related to unique user behavior</span>, such as browsing
            history, or online interests, like the fact that a particular user tends to visit sports
            websites or entertainment websites;
          </li>

          <li>
            <span>Geolocation data</span>
            based on mobile ad identifiers through the devices of our Platform Users and our
            third-party data providers, which enable us to provide our geolocation-based services;
          </li>

          <li>
            <span>Personal data</span>
            provided by our Platform Users within the platform and data through contact list uploads
            or partner integrations, including first and last name, alias, postal address, IP
            address, telephone numbers, and email and corporate addresses, and other information
            connected to those lists;
          </li>

          <li>
            <span>De-identified information related to unique user behavior</span>, such as browsing
            history, or online interests, like the fact that a particular user tends to visit sports
            websites or entertainment websites;
          </li>

          <li>
            <span>Multiple Listing Services (MLS) Account Number</span>
            provided by our Platform Users to allow us to incorporate the listings of Platform Users
            into our services;
          </li>

          <li>
            <span>Unique individual identifiers </span>
            that we, our Platform Users or other companies (such as ad and data companies) may
            create in order to identify a unique, de-identified user’s device or browser. We may use
            cookies and similar technologies that are linked to these identifiers (see the Section
            titled “We and Others May Use Cookies, Pixels and Similar Technologies”); and
          </li>

          <li>
            <span>Hashed values </span>
            that are derived from PI (such as email addresses) but have been de-identified, through
            various means.
          </li>
        </ul>
      </Text>

      <Text on="grey-500" size="xl" UNSAFE_className="description">
        As set forth in this Privacy Policy, we also collect certain Information on our website and
        through our mobile applications, that you may provide to us, or that we may collect
        passively. We may combine, link, or aggregate certain elements of the above Information with
        each other.
        <br />
        <br />
        We also may receive Information from our Platform Users, to whom we act as a service
        provider. That Information may include Platform Users’ customer names, addresses, and other
        Information about those relationships. Information about our Platform User’s data privacy
        and security practices are more fully described in the privacy policies of each of our
        Platform Users. BEEWO operates in accordance with its data privacy and security policies and
        is not responsible for the privacy practices of other companies.
      </Text>

      <Text on="white" weight="bold" size="xl" UNSAFE_className="title">
        How We Use the Information
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        We may (but do not necessarily, or always) use PI and other Information we hold or collect
        to provide a number of marketing-related services, through online display, email, and/or
        direct mail marketing channels. We use the information you provide to us from your contact
        list uploads or partner integrations to enable us to conduct contact enrichment with
        third-party geolocation data to help you better understand your client base. Additionally,
        when you provide us your MLS number, we use it to incorporate your listings into the
        services we provide to you. A non-exhaustive list of our services is as follows:
        <ul>
          <li>
            <span>Targeting of Ads and Content</span>. We provide services that help marketers
            target display media to their own customers or to their likely customers safely and
            effectively, through various media and advertising channels. For instance, we use the
            Information to provide services to enable Platform Users to target “multi-channel” ads
            to their own customers, prospective customers, or consumers that have visited or logged
            in to their websites. In doing so, we often employ various de-identification and data
            segregation techniques to safeguard consumer privacy. We also may use or create
            “Audience Segments” that help us or others (e.g., brands) tailor ads and content based
            on a particular user’s preferences, such as an interest in sports, travel or
            entertainment.
          </li>

          <li>
            <span>Optimization of Ad Campaigns</span>. We help our Platform Users optimize their
            advertising campaigns – for instance, by limiting the frequency or adjusting the
            sequence of advertising shown to customers. We also may use the Information to customize
            ads to a particular geographic region, or to the content of a web page on which they are
            shown.
          </li>

          <li>
            <span>Campaign Measurement</span>. We measure the effectiveness of display media
            campaigns – for instance, to determine which types of ads are most likely to be seen or
            opened, in what circumstances, or by which types of customers, or which types of ads are
            most likely to lead to clicks, purchases, or other behavior.
          </li>

          <li>
            <span>Other Data Services</span>. We sometimes use PI and other Information to provide
            other data services, such as (but not limited to) email and direct mail marketing
            services, as well as analytics services to help marketers optimize marketing to
            prospects.
          </li>

          <li>
            <span>Improving Our Own Services</span>. We may use certain PI and other Information to
            improve, analyze, and develop our own services.
          </li>
        </ul>
        The above descriptions of our services are not exhaustive. We provide them to illustrate in
        a general way the services we offer, and how PI and other Information is used.
      </Text>

      <Text on="white" weight="bold" size="xl" UNSAFE_className="title">
        How We Work and Share Information with Third Parties
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        BEEWO may share Information with Platform Users, Marketing Platforms, and service providers
        that we work with or retain, in order to provide the services described above (or other
        services we may add). More specifically:
        <ul>
          <li>
            <span>Members of Your Organization</span>. BEEWO may allow other members of your
            Organization or Corporate Account to view and access the information of Platform Users.
          </li>

          <li>
            <span>Partners</span>: We may share Information with business and data partners
            (including Marketing Platforms) to deliver ads, to help provide more tailored
            advertising, and for analytical purposes, such as to measure campaigns, to inform future
            campaigns, or to handle, analyze, or segregate the PI and other data that we handle for
            Platform Users. We may also share Information with our service providers, such as
            providers involved in tech or customer support, operations, web or data hosting,
            billing, accounting, security, marketing, data management, validation, enhancement or
            hygiene, or service providers otherwise assisting us to provide, develop, maintain and
            improve our services.
          </li>

          <li>
            <span>Affiliates, Parents and Subsidiaries</span>: BEEWO may share some or all of the
            Information in our possession with affiliated or subsidiary companies.
          </li>

          <li>
            <span>Corporate transfers</span>: If BEEWO or its significant assets are purchased by
            another entity, our Information will be transferred to that entity. We also may share
            the Information during the course of any due diligence process or preparation for or
            after a sale, merger, consolidation, change in control, transfer of substantial assets,
            reorganization, or liquidation.
          </li>

          <li>
            <span>As Required by Law or to Protect Any Party</span>: BEEWO may disclose Information
            if we believe that such disclosure is necessary (a) to comply with relevant laws or
            legal processes, such as to respond to subpoenas or warrants served on us; (b) to
            enforce our Terms of Service, this Privacy Policy, or other contracts with you,
            including investigation of potential violations thereof; (c) to respond to claims that
            any content violates the rights of third parties; and/or (d) to protect or otherwise
            defend the rights, property, or safety of BEEWO, our platform, our Platform Users, our
            agents and affiliates, and/or the public. We likewise may provide Information to other
            companies and organizations (including law enforcement) for fraud protection,
            spam/malware prevention, and similar purposes.
          </li>
        </ul>
      </Text>

      <Text on="white" weight="bold" size="xl" UNSAFE_className="title">
        Your Opt-Out Rights
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        You may opt out of the BEEWO marketing platform database by accessing our opt out{' '}
        <Link to="/#" className="loginBottomHelp">
          page
        </Link>
        . California residents may use this same page to opt out of the “sale” of their personal
        information, pursuant to the California Consumer Privacy Act.
        <br />
        <br />
        You may opt out of receiving advertising from the online Marketing Platforms that we partner
        with, and learn more about companies that engage in various types of online targeted and
        retargeted advertising, by visiting the opt-out pages offered by the Network Advertising
        Initiative (NAI) (
        <Link to="/#" className="loginBottomHelp">
          here
        </Link>
        ) and the DAA (
        <Link to="/#" className="loginBottomHelp">
          here
        </Link>
        ). Opting out in this manner will not prevent you from seeing ads; it will prevent targeted
        ads customized to what these companies believe are most likely to be relevant and of
        interest to you. In addition, opting out in this way is browser-specific, so if you change
        your browser, update it, or clear your cookie cache, you will need to opt out again.
        <ul>
          <li>
            <span>We and Others May Use Cookies, Pixels and Similar Technologies</span>
            <br />
            BEEWO uses industry-standard technologies, including cookies and similarly functional
            technologies. Cookies are small data files made up of a string of characters, such as an
            anonymous unique browser identifier. Cookies are stored on your computer or other device
            and act as unique tags that identify your device. Our (or other companies’) servers send
            your device a cookie when you visit a website. A pixel tag (also known as a web beacon
            or clear GIF) is an invisible 1 x 1 pixel that is placed on certain web pages. When you
            access these web pages, pixel tags generate a generic notice of the visit and permit us
            to set or read our cookies. Pixel tags are used in combination with cookies to
            “remember” the activity on a site by a particular device. When you adjust your browser
            settings to stop accepting cookies, pixel tags simply detect an anonymous website visit.
            We and other Marketing Platforms may use cookies to, among other things, retain the
            history of activity on a site, track user trends, and collect Information about whether
            and how you use our Platform Users’ sites or interact with the advertising we deliver on
            their behalf. We use cookies to help our Platform Users and other Marketing Platforms
            provide relevant content to you and replace non-relevant ads with ads that better match
            your interests.
          </li>
        </ul>
      </Text>

      <Text on="grey-500" size="xl" UNSAFE_className="description">
        While BEEWO cookies expire, we may retain purely aggregated versions of the Information (
        <i>i.e</i>., not tied to a cookie or device) indefinitely. We may work with third parties to
        provide or enhance our services (e.g., for purposes of retargeting and tailoring ads, or
        placing browser cookies). These parties may set and access their own cookies, pixel tags,
        and similar technologies on your device, which may have cookies with varying expiration
        periods. Those parties may, likewise, collect various types of anonymous or de-identified
        information about your browser, device, or browsing activities through use of these cookies.
        <br />
        <br />
        Most web browsers are set up to accept cookies. You may be able to set your browser to warn
        you before accepting certain cookies or to refuse certain cookies. However, if you disable
        the use of cookies in your web browser, some features or services may be difficult to use or
        inoperable. In providing our services, we or other companies we may partner with may (but
        will not necessarily) work with certain third-party companies that use techniques other than
        cookies to recognize your computer or device and/or to collect and record information about
        your web browsing activity, including those integrated with our services. These technologies
        may not respond to browser settings that block conventional cookies.
      </Text>

      <Text on="white" weight="bold" size="xl" UNSAFE_className="title">
        Data Collected Through Our Website and Mobile Applications
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        We also collect data through the website(s) and mobile applications we operate, including
        any website on which this Privacy Policy is posted.
        <br />
        <br />
        The Information we collect from users of our website and mobile applications includes:
        <br />
        <br />
        ● The domain name and IP addresses of users’ web browsers. <br />
        ● A unique identifier (such as in a cookie) that we deploy or read on your browser.
        <br />
        ● Aggregate information on what users access, utilize, or visit.
        <br />
        ● Information, including PI, that you voluntarily provide to us, such as requests for
        newsletters, events, and other information, and submission of contact information (such as
        email address) and (if you decide to provide it) credit card information.
        <br />
        <br />
        BEEWO uses the Information we collect from users of our website and mobile applications to:
        <br />
        <br />
        ● Create and manage your unique user account.
        <br />
        ● Provide services to you.
        <br />
        ● Respond to and communicate with you (including regarding news and updates about our
        services).
        <br />
        ● Send you offers and ads for our services, when you browse the site or other sites on the
        Internet. <br />
        ● Send you offers and ads for products and services of partner brands, or other offers we
        believe may be of interest to you.
        <br />
        ● Perform data analyses (including market research).
        <br />
        ● We may combine the Information with other information we obtain from third parties,
        publicly available sources, and any other service we provide to further improve the
        relevance and effectiveness of products, services, and advertisements offered, including,
        but not limited to, those provided on or through the services.
        <br />
        ● We may use IP addresses to help diagnose problems with our servers and to administer our
        site. We also may use IP addresses to help identify visitors to our site for the duration of
        a session and to gather demographic information about our visitors.
        <br />
        ● We may use clickstream data to determine how much time visitors spend on each web page of
        our site, how visitors navigate through the site, and how we may tailor our site to better
        meet the needs of our visitors.
        <br />
        ● We also use the Information for compliance with our legal obligations, policies and
        procedures, including the enforcement of our Terms of Service.
        <br />
        ● We may sometimes share, sell, or otherwise disclose the Information we collect about you
        through our website, as follows:
        <br />
        <br />
        ▪ We may share the Information with service providers who help us deliver the services you
        request or we provide.
        <br />
        ▪ We may share the Information to communicate with you and market to you, including through
        email, direct mail, or display media.
        <br />▪ We may also disclose the Information (including PI) in accordance with this Privacy
        Policy.
      </Text>
      <br />
      <br />
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        BEEWO’s website(s) uses cookies and similar technologies, both for its internal and
        operational purposes and to market to you (such as to retarget ads to you when you visit
        other sites across the Internet). For more information about cookies and similar
        technologies, please go to the Section titled, “We and Others May Use Cookies, Pixels and
        Similar Technologies” above.
      </Text>

      <Text on="white" weight="bold" size="xl" UNSAFE_className="title">
        Links
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        BEEWO’s website(s) and mobile applications may provide links to other websites that BEEWO
        thinks users will find interesting or useful. BEEWO is not responsible for the privacy
        practices of these other sites.
      </Text>

      <Text on="white" weight="bold" size="xl" UNSAFE_className="title">
        Security and Data Integrity
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        BEEWO takes various steps to protect the Information we license or maintain, and to house
        and transmit that Information in a secure way. For instance, these steps may include various
        types of physical and electronic security, including firewall protections, encryption,
        hashing or truncation of data, and access controls to personal Information. While neither we
        nor any platform can guarantee 100 percent safety from hacks or illegal intrusion, we take
        these steps in an effort to ensure this does not occur.
      </Text>

      <Text on="white" weight="bold" size="xl" UNSAFE_className="title">
        Children Under 16
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        BEEWO does not actively seek to collect the personal Information of children under 16 years
        of age, and BEEWO’s website(s) and services are not intended for use by children. If you
        have concerns about use of your child’s personal Information, you may contact us at{' '}
        <Link to="/#" className="loginBottomHelp">
          privacy@beewo.com
        </Link>
        .
      </Text>

      <Text on="white" weight="bold" size="xl" UNSAFE_className="title">
        Binding Agreement; Changes
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        We may revise this Privacy Policy at any time and with an associated effective date. Any
        revised Privacy Policy will be posted on BEEWO’s website(s). You are responsible for
        checking this Privacy Notice for updates when you use any BEEWO website.
      </Text>

      <Text on="white" weight="bold" size="xl" UNSAFE_className="title">
        Questions or Concerns
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        If you have questions or concerns about BEEWO’s privacy efforts or data practices or
        services, please contact us at{' '}
        <Link to="/#" className="loginBottomHelp">
          privacy@beewo.com
        </Link>{' '}
        or by U.S. Mail to:
        <br />
        <br />
        ATTN: Privacy Officer
        <br />
        BEEWO LLC
        <br />
        552 E. Market St.
        <br />
        Louisville, KY 40202
      </Text>

      <img src={logoafter} alt="beewo-logo-back" className="img_bottom" id="logoAfter" />
    </div>
  );
};
