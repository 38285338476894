import React from 'react';
import { FlexBox, Text } from '@eltoro-ui/components';
import ActivityContent from './components/ActivityContent';

interface Props {
  sellersCount?: number;
  buyersCount?: number;
  isAnalizing?: boolean;
  isImporting?: boolean;
  isEmpty?: boolean;
}

const Activity = ({ sellersCount, buyersCount, isAnalizing, isImporting, isEmpty }: Props) => {
  return (
    <FlexBox
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      UNSAFE_className="card_content"
    >
      <FlexBox
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        UNSAFE_className="heading"
      >
        <Text on="white" weight="bold" UNSAFE_className="title">
          In-market activity of contacts
        </Text>
        <Text on="white" UNSAFE_className="desc">
          We analyze your contacts and provide you with information on the households with the
          highest probability to buy or sell a home in the near future.
        </Text>
      </FlexBox>
      <FlexBox justifyContent="center" alignItems="center" UNSAFE_className="library_card_content">
        <ActivityContent
          isEmpty={isEmpty}
          hasActivity={!!sellersCount || !!buyersCount}
          sellersCount={sellersCount}
          buyersCount={buyersCount}
          isAnalizing={isAnalizing}
          isImporting={isImporting}
        />
      </FlexBox>
    </FlexBox>
  );
};

export default Activity;
