import React, { FC } from 'react';
import { Section } from 'Components';
import { Text } from '@eltoro-ui/components';
import './FinishedCampaigns.scss';
import { PieChart } from 'react-minimal-pie-chart';
import nodata from '../../../../assets/Images/nodata.png';

type FinishedCampaignsDataType = {
  completed_campaigns: number;
  total: number;
};

export const FinishedCampaigns: FC<{ data: FinishedCampaignsDataType }> = ({ data }) => {
  const finishedCampaignsDataSchema = [
    { value: data?.total - data?.completed_campaigns, color: '#CFD8DC' },
    { value: data?.completed_campaigns, color: '#FFCA0E' },
  ];
  const isFinishedCampaignsDataSchema = finishedCampaignsDataSchema.reduce(item => item);

  return (
    <Section
      title="# Of Completed Ads"
      UNSAFE_className_container="finished_campaign_section"
      UNSAFE_className_text="FinishedCampaigns__title"
    >
      <div className="finished_campaign_content">
        {isFinishedCampaignsDataSchema?.value === 0 ? (
          <div className="noData">
            <img style={{ width: '100px' }} src={nodata} alt="nodata" />
            <Text on="tertiary-300" UNSAFE_className="noDataText">
              No Data Found
            </Text>
          </div>
        ) : (
          <div className="finished_campaigns_container">
            <PieChart
              rounded
              data={finishedCampaignsDataSchema}
              lineWidth={25}
              labelPosition={0}
              className="finished_campaigns_container__pie_className"
              label={({ dataIndex, dataEntry }) =>
                dataIndex === 1 ? (
                  <React.Fragment key={dataIndex}>
                    <text
                      dominantBaseline="central"
                      x="50"
                      y="50"
                      dx="0"
                      dy="-15"
                      textAnchor="middle"
                      style={{
                        fontSize: '33px',
                        fill: '#FFCA0E',
                        fontWeight: 700,
                      }}
                    >
                      {dataEntry.value}
                    </text>
                    <text
                      dominantBaseline="central"
                      x="50"
                      y="50"
                      dx="0"
                      dy="13"
                      textAnchor="middle"
                      style={{ fontSize: '8px', fontWeight: 700 }}
                    >
                      COMPLETED
                    </text>
                    <text
                      dominantBaseline="central"
                      x="50"
                      y="50"
                      dx="0"
                      dy="22"
                      textAnchor="middle"
                      style={{ fontSize: '8px', fontWeight: 700 }}
                    >
                      OUT OF {data?.total}
                    </text>
                  </React.Fragment>
                ) : null
              }
            />
          </div>
        )}
      </div>
    </Section>
  );
};
