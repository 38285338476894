import { atomFamily, selectorFamily } from 'recoil';
import { BlockConfigType, BlocksConfigType, ValueWithErrorType } from '../Typings';
import { blockSelectorGetter, blockSelectorSetter, getDefaultTextValue } from '../Helpers';

// each atom in this file has a selector that corresponds to it
// The atom keeps track of the specific piece of the Block's state...
// ...the selector is able to update all atoms of selected blocks at the same time.

export const blockTextColorAtom = atomFamily<string, BlockConfigType>({
  key: 'blockTextColor',
  default: config => getDefaultTextValue(config.kind, config.key, 'color'),
});

export const blockTextColorSelector = selectorFamily<ValueWithErrorType<string>, BlocksConfigType>({
  key: 'blockTextColorSelector',
  get:
    config =>
    ({ get }) =>
      blockSelectorGetter(get, config, blockTextColorAtom),
  set:
    config =>
    ({ set }, newValue) => {
      blockSelectorSetter(set, config, blockTextColorAtom, newValue);
    },
});

export const blockFontSizeAtom = atomFamily<number, BlockConfigType>({
  key: 'blockFontSize',
  default: config => getDefaultTextValue(config.kind, config.key, 'fontSize'),
});

export const blockFontSizeSelector = selectorFamily<ValueWithErrorType<number>, BlocksConfigType>({
  key: 'blockFontSizeSelector',
  get:
    config =>
    ({ get }) =>
      blockSelectorGetter(get, config, blockFontSizeAtom),
  set:
    config =>
    ({ set }, newSize) => {
      blockSelectorSetter(set, config, blockFontSizeAtom, newSize);
    },
});

export const blockTextAlignAtom = atomFamily<
  'left' | 'center' | 'right' | 'justify',
  BlockConfigType
>({
  key: 'blockTextAlign',
  default: config => getDefaultTextValue(config.kind, config.key, 'textAlign'),
});

export const blockTextAlignSelector = selectorFamily<
  ValueWithErrorType<'left' | 'center' | 'right' | 'justify'>,
  BlocksConfigType
>({
  key: 'blockTextAlignSelector',
  get:
    config =>
    ({ get }) =>
      blockSelectorGetter(get, config, blockTextAlignAtom),
  set:
    config =>
    ({ set }, newSize) => {
      blockSelectorSetter(set, config, blockTextAlignAtom, newSize);
    },
});

export const blockFontStyleAtom = atomFamily<'normal' | 'italic' | 'bold', BlockConfigType>({
  key: 'blockFontStyle',
  default: config => getDefaultTextValue(config.kind, config.key, 'fontStyle'),
});

export const blockFontStyleSelector = selectorFamily<
  ValueWithErrorType<'normal' | 'italic' | 'bold'>,
  BlocksConfigType
>({
  key: 'blockFontStyleSelector',
  get:
    config =>
    ({ get }) =>
      blockSelectorGetter(get, config, blockFontStyleAtom),
  set:
    config =>
    ({ set }, newStyle) => {
      blockSelectorSetter(set, config, blockFontStyleAtom, newStyle);
    },
});

export const blockFontFamilyAtom = atomFamily<string, BlockConfigType>({
  key: 'blockFontFamily',
  default: config => getDefaultTextValue(config.kind, config.key, 'fontFamily'),
});

export const blockFontFamilySelector = selectorFamily<ValueWithErrorType<string>, BlocksConfigType>(
  {
    key: 'blockFontFamilySelector',
    get:
      config =>
      ({ get }) =>
        blockSelectorGetter(get, config, blockFontFamilyAtom),
    set:
      config =>
      ({ set }, newFamily) => {
        blockSelectorSetter(set, config, blockFontFamilyAtom, newFamily);
      },
  }
);

export const blockTextDecorationAtom = atomFamily<
  'underline' | 'linethrough' | '',
  BlockConfigType
>({
  key: 'blockTextDecoration',
  default: config => getDefaultTextValue(config.kind, config.key, 'textDecoration'),
});

export const blockTextDecorationSelector = selectorFamily<
  ValueWithErrorType<'underline' | 'linethrough' | ''>,
  BlocksConfigType
>({
  key: 'blockTextDecorationSelector',
  get:
    config =>
    ({ get }) =>
      blockSelectorGetter(get, config, blockTextDecorationAtom),
  set:
    config =>
    ({ set }, newDecoration) => {
      blockSelectorSetter(set, config, blockTextDecorationAtom, newDecoration);
    },
});

export const blockTextValueAtom = atomFamily<string, BlockConfigType>({
  key: 'blockTextValue',
  default: config => getDefaultTextValue(config.kind, config.key, 'value'),
});

export const blockTextValueSelector = selectorFamily<ValueWithErrorType<string>, BlocksConfigType>({
  key: 'blockTextValueSelector',
  get:
    config =>
    ({ get }) =>
      blockSelectorGetter(get, config, blockTextValueAtom),
  set:
    config =>
    ({ set }, newValue) => {
      blockSelectorSetter(set, config, blockTextValueAtom, newValue);
    },
});
