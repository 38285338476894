import React from 'react';
import './CalendarInputMonth.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import arrowrights from '../../../../../assets/Images/arrow-rights.png';
import arrowlefts from '../../../../../assets/Images/arrow-lefts.png';

export const CalendarInputMonth = ({
  month,
  onMonthChange,
}: {
  month: Date;
  onMonthChange: (date: Date) => void;
}) => {
  const incrementMonth = (increment: number) =>
    new Date(month.setMonth(month.getMonth() + increment).valueOf());

  return (
    <div className="CalendarInputMonth">
      <button
        className="CalendarInputMonth__button"
        type="button"
        onClick={() => onMonthChange(incrementMonth(-1))}
      >
        <img src={arrowlefts} alt="arrowlefts" />
      </button>
      <div className="CalendarInput__month-title">
        <p className="CalendarInputMonth__year_heading">
          {' '}
          {`${month.toLocaleString('default', {
            month: 'long',
          })} ${month.toLocaleString('default', { year: 'numeric' })}`}
        </p>
      </div>
      <button
        className="CalendarInputMonth__button"
        type="button"
        onClick={() => onMonthChange(incrementMonth(1))}
      >
        <img src={arrowrights} alt="arrowrights" />
        {/* <FontAwesomeIcon
          className="CalendarInputMonth__icon"
          icon={faChevronRight}
        /> */}
      </button>
    </div>
  );
};
