// @ts-nocheck
// import axios from 'axios'
// import { getSessionOrgUser, getAuthorizationHeader } from './'
// const url = process.env.REACT_APP_API + '/logstash'

// const dataParser = (...data: any) => {
//   return data
//     .map((dat: any) => {
//       if (typeof dat !== 'string') {
//         try {
//           const val = JSON.stringify(dat, null, 2)
//           return val
//         } catch (err) {
//           return 'the data couldn`t be stringified'
//         }
//       }
//       return dat
//     })
//     .toString()
// }

const remoteLog = (name: string, logUrl: string, ...args: any) => {
  console.log(`no external connection. ${name} log not sent `);
  // TBD
  // export const elasticSearch = (...log: any) => {
  //   const index = 'beewo'
  //   const user = getUser()
  //   if (!(user && user._id)) return
  //   return axios
  //     .post(
  //       url,
  //       {
  //         level: log[0] || 'info',
  //         application: index || 'campaign_uprising',
  //         action: log[1].action || 'take action',
  //         message: log[1].message || 'none',
  //         env: process.env.NODE_ENV || 'noop',
  //         fileName: log[1].fileName || 'noop',
  //         functionName: log[1].functionName || 'noop',
  //         user: user,
  //         data: JSON.stringify(log[1].data) || 'noop',
  //         BUILD_BRANCH: process.env.REACT_APP_BUILD_BRANCH || 'noop',
  //         BUILD_SHA: process.env.REACT_APP_BUILD_GITSHA || 'noop',
  //         BUILD_TAG: process.env.REACT_APP_BUILD_TAG || 'noop',
  //         BUILD_TIME: process.env.REACT_APP_BUILD_TIME || 'noop',
  //       },
  //       { headers: getAuthorizationHeader() },
  //     )
  //     .then(res => res)
  //     .catch(err => err)
  // }
};
const logImage = (url: string) => {
  const image = new Image();
  image.onload = function () {
    const style = [
      'font-size: 1px;',
      `line-height: ${this.height % 2}px;`,
      `padding: ${this.height * 0.5}px ${this.width * 0.5}px;`,
      `background-size: ${this.width}px ${this.height}px;`,
      `background: no-repeat url(${url});`,
    ].join(' ');
    console.log('%c ', style);
  };
  image.src = url;
};
const browserLogger = (name: string, ...args: any) => {
  if (name) {
    if (name === 'log') {
      console.log(
        `%c\n Logger - ${name}: \n`,
        'color:green ; font-weight:bold; font-size:14px; font-family: "Open Sans", sans-serif;',
        ...args
      );
    } else if (name === 'info') {
      console.log(
        `%c\n Logger - ${name}: \n`,
        'color:dark yellow ; font-weight:bold; font-size:14px; font-family: "Open Sans", sans-serif;',
        ...args
      );
    } else if (name === 'error') {
      console.log(
        `%c\n Logger - ${name}: \n`,
        'color:red ; font-weight:bold; font-size:14px; font-family: "Open Sans", sans-serif;',
        ...args
      );
    } else if (name === 'fatal') {
      console.log(
        '%cYou has Error! \n',
        'color: red; font-family: sans-serif; font-size: 2.5em; font-weight: bolder; text-shadow: #000 1px 1px;',
        ...args
      );
      logImage('https://media.tenor.com/images/00a1d9b040e03906008518022c34ee28/tenor.gif');
    }
  }
};
const terminalLogger = (name: string, ...args: any) => {
  if (name) {
    if (name === 'log') {
      console.log(`\n Logger - ${name}: \n`, ...args);
    } else if (name === 'info') {
      console.log(
        '\x1b[1m\x1b[32m\x1b[4m%s\x1b[0m',
        `\n Logger - ${name}: \n`,
        '\x1b[32m%s\x1b[0m',
        ...args
      );
    } else if (name === 'error') {
      console.log(
        '\x1b[1m\x1b[31m\x1b[4m%s\x1b[0m',
        `\n Logger - ${name}: \n`,
        '\x1b[31m%s\x1b[0m',
        ...args
      );
    } else if (name === 'boundaryError') {
      console.log(
        '\x1b[1m\x1b[35m\x1b[4m%s\x1b[0m',
        `\n Logger - ${name}: \n`,
        '\x1b[35m%s\x1b[0m',
        ...args
      );
    } else {
      console.log(
        '\x1b[1m\x1b[36m\x1b[4m%s\x1b[0m',
        `\n Logger - ${name}: \n`,
        '\x1b[36m%s\x1b[0m',
        ...args
      );
    }
  }
};

const log =
  (name: string, style: string, env: string, logUrl: string) =>
  (...args: any) => {
    const styleLog = () => {
      if (style === 'browser') {
        browserLogger(name, ...args);
      } else if (style === 'terminal') {
        terminalLogger(name, ...args);
      } else {
        console.log(...args);
      }
    };
    if (env === 'production') {
      styleLog();
      remoteLog(name, logUrl, ...args);
      // TBD setup for external logging service
    } else {
      styleLog();
    }
  };
export const logHelper = (
  env: string = 'development',
  style: string = 'browser',
  logUrl: string = ''
) => {
  return {
    log: log('log', style, env, logUrl),
    info: log('info', style, env, logUrl),
    error: log('error', style, env, logUrl),
    fatal: log('fatal', style, env, logUrl),
  };
};
