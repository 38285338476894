import React from 'react';
import { FlexBox } from '@eltoro-ui/components';
import Activity from './Activity';
import NewListingPromotion from './NewListingPromotion';
import SoldListingPromotion from './SoldListingPromotion';
import './styles.scss';

interface Props {
  sellersCount?: number;
  buyersCount?: number;
  isAnalizing?: boolean;
  isImporting?: boolean;
  isEmpty?: boolean;
}

const Library = ({ sellersCount, buyersCount, isImporting, isAnalizing, isEmpty }: Props) => {
  return (
    <FlexBox flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
      <Activity
        sellersCount={sellersCount}
        buyersCount={buyersCount}
        isAnalizing={isAnalizing}
        isImporting={isImporting}
        isEmpty={isEmpty}
      />
      <NewListingPromotion />
      <SoldListingPromotion />
    </FlexBox>
  );
};

export default Library;
