export const recomendationsReducer: undefined | any = (
  state = { userRecomendations: null },
  action: any
) => {
  switch (action.type) {
    case 'SET_RECOMENDATIONS':
      return { userRecomendations: action.payload };

    default:
      return { ...state };
  }
};
