import Konva from 'konva';
import React, { useEffect, useRef, useState } from 'react';
import { Group, Image as KonvaImage } from 'react-konva';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import user_icon from 'assets/Images/usericon.png';

import useImage from 'use-image';
import { RecoilCircle, RecoilRect } from '../..';
import { placeImage, rectWithBorderRadiusClip } from '../../../../Helpers';
import {
  allBlocksOfKindSelector,
  blockBorderRadiusAtom,
  blockShapeTypeAtom,
  headShotImageSelector,
  mainDimensionsBlockAtom,
} from '../../../../State';
import { BlockConfigType, DimensionsType } from '../../../../Typings';
import { userDetail } from '../../../../../../Requests/Request_Methods/authUserMethods';
import user_img from '../../../../../../assets/Images/user.png';

export const DefaultImageFrame: React.FC<{
  id: string;
  width: number;
  height: number;
  isOnMobile?: boolean;
}> = ({ id, width, height, isOnMobile = false }) => {
  const [imageUrl, setImageUrl] = useState('');
  const setMainDimensions = useSetRecoilState(mainDimensionsBlockAtom(id));
  // this state may not be needed once proper uploader is in place
  const [imageDimensions, setImageDimensions] = useState<DimensionsType>();

  const frameConfig: BlockConfigType = {
    id,
    kind: 'imageFrame',
    key: 'frame',
  };

  useEffect(() => {
    const getUserPic = async () => {
      const { data: user } = await userDetail(localStorage.getItem('beewo_token') as string);
      if (user && user.profile_pic) {
        const response = await fetch(user.profile_pic);
        const blob = await response.blob();
        setImageUrl(URL.createObjectURL(blob));
      } else setImageUrl(user_img);
    };

    getUserPic();
  }, []);

  const borderRadius = useRecoilValue(blockBorderRadiusAtom(frameConfig));
  const shapeType = useRecoilValue(blockShapeTypeAtom(frameConfig));

  const imageRef = useRef<Konva.Image>(null);
  const [img] = useImage(imageUrl);

  // useEffect and contents may be removed once proper uploader is in place
  useEffect(() => {
    const createImageDimensions = () => {
      const newImage = new Image();
      newImage.onload = () => {
        setImageDimensions({
          height: newImage.height,
          width: newImage.width,
        });
      };
      newImage.src = user_icon;
    };
    createImageDimensions();
  }, []);

  const diameter = width < height ? width : height;
  useEffect(() => {
    setMainDimensions({
      width: diameter,
      height: diameter,
    });
  }, [width, height]);

  const radius = diameter / 2;

  const imageDetails = imageDimensions && placeImage(imageRef, imageDimensions);

  if (!imageDetails) return null;

  return (
    <>
      {shapeType === 'circle' && (
        <RecoilCircle config={frameConfig} radius={radius} x={radius} y={radius} fill="white" />
      )}
      {shapeType === 'square' && (
        <RecoilRect config={frameConfig} width={radius * 2} height={radius * 2} fill="white" />
      )}
      <Group
        name="main"
        width={diameter}
        height={diameter}
        clipFunc={(ctx: CanvasRenderingContext2D) => {
          if (shapeType === 'circle') ctx.arc(radius, radius, radius, 0, 360);
          if (shapeType === 'square')
            rectWithBorderRadiusClip(ctx, 0, 0, diameter, diameter, borderRadius);
        }}
      >
        <KonvaImage
          ref={imageRef}
          image={img}
          x={imageDetails.x}
          y={imageDetails.y}
          width={imageDetails.width}
          height={imageDetails.height}
        />
      </Group>
      {shapeType === 'circle' && (
        <RecoilCircle
          config={frameConfig}
          radius={radius}
          x={radius}
          y={radius}
          shadowEnabled={false}
        />
      )}
      {shapeType === 'square' && (
        <RecoilRect
          config={frameConfig}
          width={radius * 2}
          height={radius * 2}
          shadowEnabled={false}
        />
      )}
    </>
  );
};
