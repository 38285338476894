import React, { useEffect, useMemo, useState } from 'react';

import toast from 'react-hot-toast';
import classNames from 'classnames';

import { LabelInputField } from 'Components/LabelInputField';
import type { TextInputType } from 'Components/LabelInputField';

import { Button, Modal } from '@eltoro-ui/components';

import type { CreativesListProps } from 'Pages/CreativesLibrary/components/CreativesList';

import type { TCreative } from 'types';

import { editCreativeName } from 'Requests/Request_Methods/creativesLibraryMethods';

import './RenameCreativeModal.scss';

interface RenameCreativeModalProps
  extends Pick<CreativesListProps, 'updateCreativeAfterEditCreativeName'> {
  creative: TCreative;
  onCancel: () => void;
}

const RenameCreativeModal = ({
  creative,
  onCancel,
  updateCreativeAfterEditCreativeName,
}: RenameCreativeModalProps) => {
  const [creativeName, setCreativeName] = useState<TCreative['name']>(creative.name);
  const [editCreativeNameLoading, setEditCreativeLoading] = useState<boolean>(false);

  const isValidName = useMemo(() => !!creativeName.trim(), [creativeName]);

  const onChangeCreativeName: TextInputType['onChange'] = ({ target: { value } }) =>
    setCreativeName(value);

  const onSaveCreativeName = async () => {
    try {
      setEditCreativeLoading(true);

      const { data } = await editCreativeName(creative.creative_uuid, { name: creativeName });
      if (data) toast.success(data.msg);
      await updateCreativeAfterEditCreativeName({
        creative_uuid: creative.creative_uuid,
        name: creativeName,
      });
      onCancel();
    } catch (e) {
      if ('detail' in e && typeof e.detail === 'string') toast.error(e.detail);
    } finally {
      setEditCreativeLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setEditCreativeLoading(false);
    };
  }, []);

  return (
    <Modal className="confirm-modal rename-creative-modal">
      <div className="confirm-modal-content">
        <span className="confirm-modal-title">Rename creative</span>
        <div className="confirm-modal-body">
          <LabelInputField
            title="Creative Name"
            placeholder="Enter Creative Name"
            value={creativeName}
            type="text"
            style={{ paddingLeft: '22px' }}
            lableStyle={{ left: '14px' }}
            onChange={onChangeCreativeName}
            UNSAFE_ClassName={classNames('creative-name-input')}
          />
        </div>
      </div>
      <div className="confirm-modal-footer">
        <Button
          size="l"
          onClick={!editCreativeNameLoading ? onCancel : () => undefined}
          weight="bold"
        >
          Cancel
        </Button>
        <Button
          kind="primary"
          size="l"
          weight="bold"
          onClick={onSaveCreativeName}
          loading={editCreativeNameLoading}
          disabled={editCreativeNameLoading || !isValidName || creativeName === creative.name}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default RenameCreativeModal;
