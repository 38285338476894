import React, { ReactNode, useRef, useState } from 'react';
import './HeaderDropdown.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp, faBars } from '@fortawesome/free-solid-svg-icons';
import { useOnClickOutside } from '@eltoro-ui/hooks';
import { THeaderDropdown } from 'types';

export const HeaderDropdown = ({
  children,
  name,
  icon,
  position = 'right',
  hasDropdownIcon = true,
  size,
  iconLeft,
  menuIcon = false,
  menuIconOnClick,
  className = '',
  notificationTooltipContent,
  hoverEffect = true,
  clickOnTopLevel = true,
}: THeaderDropdown) => {
  const [open, setOpen] = useState(false);
  const [leftIconOpen, setLeftIconOpen] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    setOpen(false);
    setLeftIconOpen(false);
  });

  return (
    /*eslint-disable */
    <div
      className={`HeaderDropdown HeaderDropdown--position-${position} ${
        open ? ' HeaderDropdown--is-open' : ''
      } ${hoverEffect ? 'hoverEffect' : ''}`}
      ref={ref}
    >
      <div
        className="buttons_container"
        onClick={
          clickOnTopLevel
            ? () => {
                setOpen(!open);
              }
            : () => {}
        }
      >
        {/* <button
          className="HeaderDropdown__button"
          type="button"
          // onClick={() => {
          //   setOpen(!open)
          // }}
        > */}
        {iconLeft && (
          <span
            onClick={
              !clickOnTopLevel
                ? () => {
                    setOpen(false);
                    setLeftIconOpen(!leftIconOpen);
                  }
                : () => {}
            }
            className={`iconLeftClassName ${children && name ? 'HeaderDropdown__iconLeft' : ''}`}
          >
            {iconLeft}
          </span>
        )}

        {!!icon && (
          <span
            // className={`${children && name ? 'HeaderDropdown__iconLeft' : ''}`}
            className={`iconClassName ${children && name ? '' : ''}`}
            style={{ marginRight: '0.3rem' }}
          >
            {icon}
          </span>
        )}
        <span className="userNameClassName">{!!name && name}</span>

        {hasDropdownIcon && open && (
          <FontAwesomeIcon
            onClick={
              !clickOnTopLevel
                ? () => {
                    setOpen(false);
                    setLeftIconOpen(false);
                  }
                : () => {}
            }
            className={`HeaderDropdown__icon ${className} ${
              open ? 'HeaderDropdown__icon--is-open' : ''
            }`}
            icon={faSortUp}
          />
        )}

        {hasDropdownIcon && !open && (
          <FontAwesomeIcon
            onClick={
              !clickOnTopLevel
                ? () => {
                    setOpen(true);
                    setLeftIconOpen(false);
                  }
                : () => {}
            }
            className={`HeaderDropdown__icon ${className}`}
            icon={faSortDown}
          />
        )}
        {/* </button> */}

        {menuIcon && (
          <FontAwesomeIcon onClick={menuIconOnClick} className="burger_menu" icon={faBars} />
        )}
      </div>

      {open && !leftIconOpen && <div className="HeaderDropdown__options">{children}</div>}
      {leftIconOpen && (
        <div className="HeaderDropdown__options" style={{ right: '1rem' }}>
          {notificationTooltipContent}
        </div>
      )}
    </div>
  );
};
