import { DefaultValue, GetRecoilValue, RecoilState, SetRecoilState } from 'recoil';
import {
  AvailableBlocksType,
  BlockConfigType,
  BlocksConfigType,
  BlockShadowType,
  BlockShapeType,
  BlockStrokeType,
  BlockTextType,
} from '../Typings';
import { blocksBase, defaultBlock } from '../Data';
import { selectedBlockIdsAtom } from '../State';

// used in block selector getter to check all values of selected blocks...
// ...returns the first value and an error boolean.
// This can be used in UI to show if multiple values are selected.
// EX: button block is selected, one button is blue, the rest are red...
// ...The color swatch cannot show both red and blue, so it shows a question mark instead
export const blockSelectorGetter = <T>(
  get: GetRecoilValue,
  config: BlocksConfigType,
  atom: (param: BlockConfigType) => RecoilState<T>
) => {
  const { kind, key } = config;
  // by using selectedBlocksIdsAtom below for ids instead of config ids,
  // we create a dynamic dependency that will cause the selector to...
  // ...re-evaluate whenever selectedBlocksIdsAtom updates
  const ids = get(selectedBlockIdsAtom);

  const values = ids.map(id => get(atom({ id, kind, key })));
  const allTheSameValue = values.every(v => v === values[0]);
  const primeConfig = {
    id: ids[0],
    kind,
    key,
  };
  return {
    value: get(atom(primeConfig)),
    error: !allTheSameValue,
  };
};

// used in block selector setter
// sets all atoms of each config passed to whatever newValue is passed in
export const blockSelectorSetter = <T>(
  set: SetRecoilState,
  config: BlocksConfigType,
  atom: (param: BlockConfigType) => RecoilState<T>,
  newValue: { value: T; error: boolean } | DefaultValue
) => {
  const { kind, key, ids } = config;
  ids.forEach(id => {
    const usedValue = newValue instanceof DefaultValue ? newValue : newValue.value;
    set(atom({ id, kind, key }), usedValue);
  });
};

interface GetDefaultTextValueInterface {
  (kind: AvailableBlocksType, key: string, textKey: 'value', defaultValue?: string): string;
  (
    kind: AvailableBlocksType,
    key: string,
    textKey: 'textAlign',
    defaultValue?: 'left' | 'center' | 'right' | 'justify'
  ): 'left' | 'center' | 'right' | 'justify';
  (
    kind: AvailableBlocksType,
    key: string,
    textKey: 'fontStyle',
    defaultValue?: 'normal' | 'italic' | 'bold'
  ): 'normal' | 'italic' | 'bold';
  (
    kind: AvailableBlocksType,
    key: string,
    textKey: 'textDecoration',
    defaultValue?: 'underline' | 'linethrough' | ''
  ): 'underline' | 'linethrough' | '';
  (
    kind: AvailableBlocksType,
    key: string,
    textKey: 'color' | 'fontFamily',
    defaultValue?: string
  ): string;
  (kind: AvailableBlocksType, key: string, textKey: 'fontSize', defaultValue?: number): number;
}

// get default text value from base blocks in Data/blocks.ts
export const getDefaultTextValue: GetDefaultTextValueInterface = (
  kind: AvailableBlocksType,
  key: string,
  textKey: keyof BlockTextType,
  defaultValue?: any
) => {
  const getDefaultValue = () => {
    if (defaultValue) return defaultValue;
    return defaultBlock.textContent?.default[textKey];
  };
  const { textContent } = blocksBase[kind];
  if (!textContent[key]) return getDefaultValue();
  const value = textContent[key][textKey];
  if (!value) return getDefaultValue();
  return value;
};

interface GetDefaultShapeValueInterface {
  (kind: AvailableBlocksType, key: string, shapeKey: 'borderRadius', defaultValue?: number): number;
  (kind: AvailableBlocksType, key: string, shapeKey: 'color', defaultValue?: string): string;
  (
    kind: AvailableBlocksType,
    key: string,
    shapeKey: 'shape',
    defaultValue?: 'circle' | 'square' | ''
  ): 'circle' | 'square' | '';
}

// get default shape value from base blocks in Data/blocks.ts
export const getDefaultShapeValue: GetDefaultShapeValueInterface = (
  kind: AvailableBlocksType,
  key: string,
  shapeKey: keyof BlockShapeType,
  defaultValue?: any
) => {
  const getDefaultValue = () => {
    if (defaultValue) return defaultValue;
    return defaultBlock.shapeContent?.default[shapeKey];
  };
  const { shapeContent } = blocksBase[kind];
  if (!shapeContent) return getDefaultValue();
  const value = shapeContent[key]?.[shapeKey];
  if (!value) return getDefaultValue();
  return value;
};

interface GetDefaultShapeStrokeInterface {
  (kind: AvailableBlocksType, key: string, strokeKey: 'enabled', defaultValue?: boolean): boolean;
  (kind: AvailableBlocksType, key: string, strokeKey: 'color', defaultValue?: string): string;
  (kind: AvailableBlocksType, key: string, strokeKey: 'width', defaultValue?: number): number;
}

// get default stroke value from base blocks in Data/blocks.ts
export const getDefaultShapeStroke: GetDefaultShapeStrokeInterface = (
  kind: AvailableBlocksType,
  key: string,
  strokeKey: keyof BlockStrokeType,
  defaultValue?: any
) => {
  const getDefaultValue = () => {
    if (defaultValue) return defaultValue;
    return defaultBlock.shapeContent?.default.stroke?.[strokeKey];
  };
  const { shapeContent } = blocksBase[kind];
  if (!shapeContent) return getDefaultValue();
  const { stroke } = shapeContent[key];
  if (!stroke) return getDefaultValue();
  const value = stroke[strokeKey];
  if (!value) return getDefaultValue();
  return value;
};

interface GetDefaultShapeShadowInterface {
  (kind: AvailableBlocksType, key: string, shadowKey: 'enabled', defaultValue?: boolean): boolean;
  (kind: AvailableBlocksType, key: string, shadowKey: 'color', defaultValue?: string): string;
  (
    kind: AvailableBlocksType,
    key: string,
    shadowKey: 'blur' | 'opacity' | 'offsetX' | 'offsetY',
    defaultValue?: number
  ): number;
}

// get default shadow value from base blocks in Data/blocks.ts
export const getDefaultShapeShadow: GetDefaultShapeShadowInterface = (
  kind: AvailableBlocksType,
  key: string,
  shadowKey: keyof BlockShadowType,
  defaultValue?: any
) => {
  const getDefaultValue = () => {
    if (defaultValue) return defaultValue;
    return defaultBlock.shapeContent?.default.shadow?.[shadowKey];
  };
  const { shapeContent } = blocksBase[kind];
  if (!shapeContent) return getDefaultValue();
  const { shadow } = shapeContent[key];
  if (!shadow) return getDefaultValue();
  const value = shadow[shadowKey];
  if (!value) return getDefaultValue();
  return value;
};

// returns all block keys of either TextType or ShapeType that are flagged with the `mainColor` boolean
const getColorKeys = (
  keys: string[],
  content: { [key: string]: BlockTextType | BlockShapeType },
  text?: boolean
): { [x: string]: ('shadow' | 'stroke' | 'text' | 'shape')[] } => {
  const mainColorKeys = keys.reduce((acc, key) => {
    const shape = content[key];
    const mainShadow = shape.shadow?.mainColor || false;
    const mainStroke = shape.stroke?.mainColor || false;
    const main = shape.mainColor || false;
    let colorPlaces: string[] = [];
    if (mainShadow) colorPlaces = [...colorPlaces, 'shadow'];
    if (mainStroke) colorPlaces = [...colorPlaces, 'stroke'];
    if (main) colorPlaces = [...colorPlaces, text ? 'text' : 'shape'];
    if (mainShadow || mainStroke || main) {
      return {
        ...acc,
        [key]: colorPlaces,
      };
    }
    return acc;
  }, {});
  return mainColorKeys;
};

// returns atom configs with atom kind
const createAtomConfigs = (
  ids: string[],
  colorKeys: { [x: string]: string[] },
  kind: AvailableBlocksType
) => {
  return ids
    .map(id => {
      const atomKinds = Object.keys(colorKeys).map(colorKey => {
        return colorKeys[colorKey].map(atomKind => {
          return {
            config: {
              id,
              key: colorKey,
              kind,
            },
            atomKind,
          };
        });
      });
      return atomKinds.flat();
    })
    .flat();
};

// looks for all `mainColor` flags within a specified block kind and returns an array of configs...
// ...so they may be used in `blockMainColorSelector`
// `mainColor`s can be updated together on the Content tab below text inputs where it says "Block Color"
export const mainColorHelper = (get: GetRecoilValue, kind: AvailableBlocksType) => {
  const selectedIds = get(selectedBlockIdsAtom);
  const baseBlock = blocksBase[kind];
  const { textContent, shapeContent } = baseBlock;
  const textKeys = Object.keys(textContent);
  const shapeKeys = shapeContent && Object.keys(shapeContent);
  const mainTextColorKeys = getColorKeys(textKeys, textContent, true);
  const mainShapeColorKeys =
    (shapeContent && shapeKeys && getColorKeys(shapeKeys, shapeContent)) || {};

  const textAtomConfigs = createAtomConfigs(selectedIds, mainTextColorKeys, kind);
  const shapeAtomConfigs = createAtomConfigs(selectedIds, mainShapeColorKeys, kind);
  return [...textAtomConfigs, ...shapeAtomConfigs];
};
