import React, { FC, useState } from 'react';
import { Section } from 'Components';
import { createFeedback } from 'Requests';
import toast from 'react-hot-toast';
import {
  Form,
  FormItem,
  Button,
  TextInput,
  // Modal,
  Text,
} from '@eltoro-ui/components';
import './Feedback.scss';
import { useSelector } from 'react-redux';
import { LabelInputField } from 'Components/LabelInputField';
import { LabelRichTextBox } from 'Components/LabelRichTextBox/index';
import { CustomModal } from 'Components/Modal/index';
import tick from '../../../../assets/Images/tick.png';

const initialState = {
  subject: '',
  body: '',
};

export const Feedback: FC = () => {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [state, setState] = useState(initialState);

  const isDisabled = () => {
    const { body, subject } = state;
    return !body || !subject;
  };

  const onSubmit = () => {
    setLoading(true);

    createFeedback(state)
      .then(() => {
        setModal(true);
        setState(initialState);
        setLoading(false);
      })
      .catch(() => {
        toast.error('Send error');
        setLoading(false);
      });
  };

  const onChange = (key: string, value: string) => {
    setState({ ...state, [key]: value });
  };

  return (
    <div className="feedback_section">
      <Form onSubmit={onSubmit}>
        <Section UNSAFE_className_container="feedback_section__container" spacer={false} dark>
          <Text on="white" weight="bold" size="l" UNSAFE_className="heading">
            New Feedback
          </Text>
          {/* <FormItem
            htmlFor="subject"
            label="Subject"
            FontSize="18px"
            Height="1rem"
            required
          >
            <TextInput
              className="feedback_section__input_field"
              data-testid="subject"
              value={state.subject}
              onChange={(e) => onChange('subject', e.target.value)}
              placeholder="Write your subject"
            />
          </FormItem> */}
          <LabelInputField
            title="Subject"
            placeholder="Write your subject"
            style={{ paddingLeft: '20px' }}
            value={state.subject}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              // const newString = e.target.value.replace(/\s+/g, ' ')
              onChange('subject', e.target.value);
            }}
            disabled={loading}
          />
          <br />
          {/* <FormItem
            htmlFor="Message"
            data-testid="message"
            label="Message"
            FontSize="18px"
            Height="1rem"
            required
          >
            <textarea
              value={state.body}
              data-testid="message"
              onChange={(e) => onChange('body', e.target.value)}
              placeholder="Write your feedback"
              className="feedback_section__input_text_area"
            />
          </FormItem> */}

          <LabelRichTextBox
            title="Message"
            placeholder="Write your feedback"
            value={state.body}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              // const newString = e.target.value.replace(/\s+/g, ' ')
              onChange('body', e.target.value);
            }}
            disabled={loading}
          />
          <div className="feedback_section__submit_container">
            <Button
              type="submit"
              kind="primary"
              UNSAFE_className="feedback_section__submit"
              disabled={isDisabled() || loading}
              UNSAFE_style={{ justifyContent: 'center' }}
              loading={loading}
            >
              Send
            </Button>
          </div>
        </Section>
      </Form>
      {modal && (
        <CustomModal
          heading="Your query has been sent. Our team will respond to you via E-mail."
          buttonText="Done"
          image={tick}
          onClose={() => setModal(false)}
        />
        // <Modal className="feedback_section__modal">
        //   <Text UNSAFE_className="feedback_section__modal__title" on="grey-500">
        //     Your query has been sent. Our team will respond to you via E-mail.
        //   </Text>
        //   <Button
        //     type="submit"
        //     kind="primary"
        //     UNSAFE_className="feedback_section__modal__done_btn"
        //     onClick={() => setModal(false)}
        //   >
        //     Done
        //   </Button>
        // </Modal>
      )}
    </div>
  );
};
