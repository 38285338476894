import { Button, Table } from '@eltoro-ui/components';
import { faChevronLeft, faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EmptyStatus, Loading, PageHeader, SearchBar, TableSelectionWrapper } from 'Components';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { uploadContactsSync } from 'Requests/Request_Methods/contactsMethods';
import homeslash from '../../assets/Images/home-slash.png';
import home from '../../assets/Images/home.png';
import AddAddressModal from './components/AddAddress/AddAddressModal';
import GoBackModal from './components/SuccessGoBackModal/GoBackModal';

export const ContactsSync = () => {
  const [selections, setSelections] = useState<any[]>([]);
  const [contacts, setContacts] = useState<any[] | undefined>([]);
  const [unselect, setUnselectAll] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState({ apiCallLoaded: true });
  const [PreSelections, setPreSelections] = useState<any[]>([]);

  const [search, setSearch] = useState('');

  const [addressName, setaddressName] = useState('');
  const [postalCode, setpostalCode] = useState('');
  const [fullAddress, setfullAddress] = useState('');
  const [addAddressModal, setaddAddressModal] = useState(false);
  const [AddressId, setAddressId] = useState<number | undefined>();

  const location: any = useLocation();
  const [tempSearch, settempSearch] = useState<any[]>([]);

  const [goBackModal, setgoBackModal] = useState(false);
  const [updateAddress, setupdateAddress] = useState(false);

  const [doneBtn, setdoneBtn] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setIsLoading({ ...isLoading, apiCallLoaded: true });
    setPreSelections([...selections]);
    if (location?.state?.addressBookContacts) {
      const dataParser = JSON?.parse(location?.state?.addressBookContacts);
      dataParser.forEach((element: any, index: number) => {
        if (element.address.length > 0) {
          element.address[0].formatted = `${
            element.address[0].street ? `${element.address[0].street}, ` : ''
          }${element.address[0].region ? `${element.address[0].region}, ` : ''}${
            element.address[0].country ? `${element.address[0].country}` : ''
          } `;
          if (!Number(element.address[0].postal_code)) {
            element.address[0].postal_code = '';
          }
        }
        if (element.first_name === '') {
          element.first_name = element.__selectedMail__;
        }
        if (element?.address[0]?.city) {
          element.city = `${element.address[0].city || '-'}`;
        }
        element.id = index;
      });
      setContacts([...dataParser]);
    }

    setUnselectAll(false);
    setIsLoading({ ...isLoading, apiCallLoaded: false });
  }, []);

  useEffect(() => {
    if (unselect) {
      setSelections([]);
      setPreSelections([]);
    }
  }, [unselect]);

  const countNotFound = (data?: any[]) => {
    if (data) {
      const counter = data?.reduce((acc, curr) => {
        if (!curr.address[0]) {
          acc += 1;
        }
        return acc;
      }, 0);
      return counter;
    }
    return 0;
  };

  const syncContacts = () => {
    setdoneBtn(true);
    const tempData = [...selections];

    const arrData: {
      first_name: string;
      last_name: string;

      address: string;
      city: string;

      phone: string;
      zip: string;
      email: string;
    }[] = [];
    tempData.forEach((element: any, index: number) => {
      arrData.push({
        first_name: element.first_name,
        last_name: element.last_name,
        address: element.address[0].formatted,
        phone: !element.phone[0]?.number ? '' : element.phone[0]?.number,
        city: element.city,
        zip: !element.address[0].postal_code ? '' : element.address[0].postal_code,
        email: element.email[0].address,
      });
    });

    uploadContactsSync(arrData)
      .then((res: any) => {
        if (res.data.length > 0) {
          setgoBackModal(true);
          setdoneBtn(false);
        } else {
          setdoneBtn(false);
        }
      })
      .catch((err: any) => {
        setdoneBtn(false);
      });
  };
  useEffect(() => {
    if (search.length > 0 && contacts) {
      const filterData = [
        ...contacts?.filter((element: any) => {
          if (element) {
            return (
              element?.address[0]?.formatted.toLowerCase().includes(search.toLowerCase()) ||
              element?.address[0]?.postal_code.toLowerCase().includes(search.toLowerCase()) ||
              element.first_name.toLowerCase().includes(search.toLowerCase()) ||
              element.last_name.toLowerCase().includes(search.toLowerCase())
            );
          }
          return false;
        }),
      ];
      settempSearch([...filterData]);
    }
  }, [search]);

  const getList = () => {
    let component = <></>;
    if (isLoading.apiCallLoaded) {
      component = <Loading />;
    } else if (!contacts?.length) {
      component = <EmptyStatus subHeading="You can try upload contacts to get better audience." />;
    } else {
      component = (
        <TableSelectionWrapper
          selectionType="contact"
          selections={selections}
          actions={[]}
          UNSAFE_CLASSNAME_WRAPPER="sync-table"
        >
          <Table
            rows={search.length > 0 ? tempSearch : contacts}
            columns={[
              {
                path: 'name',
                label: 'Name',
                width: '20%',
                alignment: 'left',
                RowCell: contact => `${contact.first_name} ${contact.last_name}` || '-',
              },
              {
                path: 'Address',
                label: 'Address',
                alignment: 'left',
                RowCell: contact => (
                  <div className="listView">
                    {' '}
                    <span>
                      {contact?.address[0]?.formatted && (
                        <>
                          <img src={home} className="listView__foundIcon" alt="home" />
                          {contact?.address[0]?.formatted}
                          {contact?.address[0]?.postal_code &&
                            contact?.address[0]?.postal_code !== 0 &&
                            `, ${contact?.address[0]?.postal_code}`}
                        </>
                      )}
                    </span>
                    <span className="listView__notFound">
                      {' '}
                      {!contact?.address[0]?.formatted && (
                        <>
                          <img
                            className="listView__notFound__icon"
                            src={homeslash}
                            alt="home-slash"
                          />
                          Address not found
                          <button
                            aria-label="edit"
                            className="CalendarInputMonth__button"
                            type="button"
                            onClick={() => {
                              setaddressName(`${contact.first_name} ${contact.last_name}`);
                              setAddressId(contact.id);
                              setpostalCode(contact?.address[0]?.postal_code);

                              setfullAddress(contact?.address[0]?.formatted);
                              setaddAddressModal(true);
                            }}
                          >
                            <FontAwesomeIcon className="CalendarInputMonth__icon" icon={faPencil} />
                          </button>
                        </>
                      )}
                    </span>
                  </div>
                ),
              },
              {
                path: 'city',
                label: 'City',
                width: '20%',
                alignment: 'left',
                RowCell: contact => `${contact.city || '-'}`,
              },
            ]}
            onSelect={id => {
              if (id.length > 0) {
                setUnselectAll(false);
              }
              setSelections([...id]);
              setPreSelections([...id]);
            }}
            selectedRows={PreSelections}
            unSelectAll={unselect}
            setUnselectAll={setUnselectAll}
          />
        </TableSelectionWrapper>
      );
    }
    return component;
  };

  return (
    <div className="MyContacts">
      {/* Header Section */}
      <PageHeader
        title="Sync Contacts"
        UNSAFE_CLASSNAME="MyContacts__pageHeader"
        icon={
          <Button
            size="l"
            type="button"
            UNSAFE_className={`icon-header icon-header-contacts contacts-btn ${
              countNotFound(contacts) > 0 && 'icon-header-contacts-sync'
            }`}
            UNSAFE_TEXTSTYLE="icon-header-text"
            onClick={() => {
              history.push({
                pathname: '/my-sphere',
                state: { addressBookContacts: [] },
              });
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </Button>
        }
        UNSAFE_CLASSNAME_TITLE="my-contacts-header-title"
        UNSAFE_className_SUBTITLE={`my-contacts-header-subtitle ${
          countNotFound(contacts) > 0 && 'my-contacts-header-subtitle-op'
        }`}
        subTitle={
          <span>
            {countNotFound(contacts) > 0 && <b>{countNotFound(contacts)} Address(es) not found </b>}
          </span>
        }
        actions={[
          <SearchBar
            key="contacts-search"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />,
          <Button
            key="done"
            kind="primary"
            size="l"
            width="140px"
            UNSAFE_className="sync-contacts-button"
            weight="bold"
            disabled={!(selections.length > 0) || countNotFound(selections) > 0 || doneBtn}
            onClick={() => syncContacts()}
          >
            Done
          </Button>,
        ]}
      />

      {getList()}

      {addAddressModal && (
        <AddAddressModal
          setaddressName={setaddressName}
          addressName={addressName}
          setpostalCode={setpostalCode}
          postalCode={postalCode}
          fullAddress={fullAddress}
          setfullAddress={setfullAddress}
          setaddAddressModal={setaddAddressModal}
          AddressId={AddressId}
          updateAddress={updateAddress}
          setupdateAddress={setupdateAddress}
          onSubmit={() => {
            setupdateAddress(true);
            if (contacts && typeof AddressId !== 'undefined' && fullAddress) {
              const data = [...contacts];
              if (data[AddressId].address[0]) {
                data[AddressId].address[0].formatted = fullAddress;
                data[AddressId].address[0].postal_code = postalCode;
              } else {
                const tempData = {
                  formatted: fullAddress,
                  postal_code: postalCode,
                };
                data[AddressId].address[0] = tempData;
              }
              setContacts([...contacts]);
              setupdateAddress(false);
              setaddAddressModal(false);
            }
          }}
        />
      )}
      {goBackModal && (
        <GoBackModal
          setgoBackModal={setgoBackModal}
          sub_text={`${selections.length}/${contacts?.length} Contact(s) imported`}
          text="Contact Successfully Imported"
        />
      )}
    </div>
  );
};
