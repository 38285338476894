/* eslint-disable */
import React from 'react';
import type { MouseEventHandler } from 'react';

import Tippy from '@tippyjs/react';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStopwatch } from '@fortawesome/pro-regular-svg-icons';

import { Text } from '@eltoro-ui/components';

import { subscription_name } from 'Utils/helpers';
import { ComingSoonFeatures, subscriptionsBulletPoints } from 'Utils/constants';

import basic_subscription from 'assets/Images/basic_subscription.png';
import premium_subscription from 'assets/Images/premium_subscription.png';

import type { TCommonSubscription } from 'types';

type SubscriptionCardProps = {
  subscription: TCommonSubscription;
  selectedSubscriptionId?: TCommonSubscription['id'];
  onSelectSubscription?: (subscription: TCommonSubscription) => MouseEventHandler<HTMLDivElement>;
};

const SubscriptionCard = ({
  subscription,
  selectedSubscriptionId,
  onSelectSubscription,
}: SubscriptionCardProps) => {
  return (
    <div
      data-testid="subscriptionCard"
      onClick={onSelectSubscription ? onSelectSubscription(subscription) : () => undefined}
      className={classNames(
        'subscription_card',
        subscription?.name === 'beewo-subscription-basic' ? 'basic' : 'premium',
        subscription.id === selectedSubscriptionId && 'subscription_card_selected'
      )}
      style={{
        // marginRight: index === 1 ? '-4px' : '2rem',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <div style={{ display: 'flex', marginBottom: '2.2rem' }}>
          {subscription_name(subscription) === 'Basic Subscription' ? (
            <img src={basic_subscription} alt="basic_subscription" className="sub_logo" />
          ) : (
            <img src={premium_subscription} alt="premium_subscription" className="sub_logo" />
          )}
          <Text
            on="white"
            weight="bold"
            size="l"
            textAlign="center"
            UNSAFE_style={{
              margin:
                subscription_name(subscription) === 'Premium Subscription'
                  ? '10px 0 0'
                  : '10px 13px 0 0',

              color: subscription_name(subscription) === 'Premium Subscription' ? '#000' : '#fff',
            }}
            UNSAFE_className="title"
          >
            {subscription?.name === 'beewo-subscription-basic'
              ? 'Basic Subscription'
              : 'Premium Subscription'}
          </Text>
        </div>
        {/*<div className="price_div">*/}
        {/*  <Text*/}
        {/*    UNSAFE_className="price_value"*/}
        {/*    on="white"*/}
        {/*    weight="bold"*/}
        {/*    size="xxxl"*/}
        {/*    textAlign="center"*/}
        {/*  >*/}
        {/*    <span>$</span>*/}
        {/*    {parseInt(String(subscription.metadata.price), 10)}*/}
        {/*  </Text>*/}
        {/*  <div className="price_content">*/}
        {/*    <span className="decimal_value">*/}
        {/*      .{String(subscription.metadata.price).split('.')[1]}*/}
        {/*    </span>*/}
        {/*    <Text*/}
        {/*      on="grey-500"*/}
        {/*      weight="normal"*/}
        {/*      size="xl"*/}
        {/*      textAlign="center"*/}
        {/*      UNSAFE_className="sub_month"*/}
        {/*    >*/}
        {/*      Per Month*/}
        {/*    </Text>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <ul
          className={`${subscription?.name === 'beewo-subscription-basic' ? 'basic' : 'premium'} `}
        >
          {subscriptionsBulletPoints
            .find(({ id }) => id === subscription.id)
            ?.bullet_points?.map(({ name, description }) => (
              <React.Fragment key={`${subscription.id}-${name}`}>
                <li>
                  <Text
                    on="grey-500"
                    weight="normal"
                    size="m"
                    textAlign="center"
                    UNSAFE_className={`${
                      subscription?.name === 'beewo-subscription-basic' ? 'basic' : 'premium'
                    } `}
                  >
                    <span>
                      {name}
                      {!!description?.length && ':'}
                    </span>
                    {ComingSoonFeatures.includes(name) && (
                      <Tippy placement="top" content="Coming Soon...">
                        <span style={{ marginLeft: '8px' }}>
                          <FontAwesomeIcon icon={faStopwatch} style={{ color: '#FCD917' }} />
                        </span>
                      </Tippy>
                    )}
                  </Text>
                </li>
                {!!description?.length && (
                  <ul>
                    {description?.map((des: string, i: number) => (
                      <li key={`${des}${i}`}>
                        <span>{des}</span>
                        {ComingSoonFeatures.includes(des) && (
                          <Tippy placement="top" content="Coming Soon...">
                            <span style={{ marginLeft: '8px' }}>
                              <FontAwesomeIcon icon={faStopwatch} style={{ color: '#FCD917' }} />
                            </span>
                          </Tippy>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </React.Fragment>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default SubscriptionCard;
