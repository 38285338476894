import React from 'react';
import { RecomendationContactEnum } from 'enums';
import { FlexBox } from '@eltoro-ui/components';
import EmptyContacts from './EmptyContacts';
import Processing from './Processing';
import NotActive from './NotActive';
import ActivityByKind from './ActivityByKind';

interface Props {
  buyersCount?: number;
  sellersCount?: number;
  isEmpty?: boolean;
  isImporting?: boolean;
  isAnalizing?: boolean;
  hasActivity?: boolean;
}

const ActivityContent = ({
  isEmpty,
  isImporting,
  isAnalizing,
  hasActivity,
  sellersCount,
  buyersCount,
}: Props) => {
  let content;

  if (isEmpty) {
    content = <EmptyContacts />;
  }

  if (isImporting) {
    content = <Processing text="We are importing your contacts!" />;
  }

  if (isAnalizing) {
    content = <Processing text="We are still analyzing in-market activity of your contacts!" />;
  }

  if (!hasActivity && !isEmpty && !isAnalizing && !isImporting) {
    content = <NotActive />;
  }

  if (hasActivity && !isEmpty && !isAnalizing && !isImporting) {
    content = [
      <ActivityByKind
        key={RecomendationContactEnum.BUYER}
        hasActiveContacts={!!buyersCount}
        kind={RecomendationContactEnum.BUYER}
      />,
      <ActivityByKind
        key={RecomendationContactEnum.SELLER}
        hasActiveContacts={!!sellersCount}
        kind={RecomendationContactEnum.SELLER}
      />,
    ];
  }

  return (
    <FlexBox
      alignItems="center"
      justifyContent="center"
      UNSAFE_className="activity_by_kind"
      gap="60px"
    >
      {content}
    </FlexBox>
  );
};

export default ActivityContent;
