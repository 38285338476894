import React from 'react';
import { Button, Spacer, Text } from '@eltoro-ui/components';
import { useSetRecoilState } from 'recoil';
import { MoreInfoContainer } from '../../..';
import { headShotImageSelector } from '../../../../../State';
import { Uploader } from '../../../../../../../Components/Uploader';
import { FileUploadType } from '../../../../../../../types';

export const PhotoEdit: React.FC<{ ids: string[] }> = ({ ids }) => {
  const setImage = useSetRecoilState(headShotImageSelector(ids));

  const onSubmit = (files: FileUploadType) => {
    if (files.acceptedFiles.length) {
      const file = files.acceptedFiles[files.acceptedFiles.length - 1];
      setImage(URL.createObjectURL(file));
    }
  };

  return (
    <div className="PhotoEdit">
      <MoreInfoContainer label="Photo Info">
        <Text on="grey-100" weight="bold" kind="subdued">
          Image Upload
        </Text>
        <Spacer height="0.25rem" />
        <Uploader
          onDropFile={onSubmit}
          type="banner"
          validate={false}
          showThumbs={false}
          multiple={false}
        >
          <div
            className="BackgroundImageTab__temp-uploader"
            style={{ height: 'auto', padding: '0.5rem 1rem' }}
          >
            <span>Future Uploader Here</span>
          </div>
        </Uploader>
      </MoreInfoContainer>
    </div>
  );
};
