import React from 'react';

import moment from 'moment';

import type { Column } from 'react-table';

import { EmptyStatus, ReactTable } from 'Components';

import NameAccessor from 'Pages/CampaignView/components/OrderlinesTable/components/OrderlineNameAccessor';
import StatusAccessor from 'Pages/CampaignView/components/OrderlinesTable/components/OrderlineStatusAccessor';

import { compactNumberConverter } from 'Utils/helpers';

import type { CampaignOrderlineType } from 'types';

import './OrderlinesTable.scss';

type OrderlinesTableProps = {
  orderlines: CampaignOrderlineType[];
};

const OrderlinesTable = ({ orderlines }: OrderlinesTableProps) => {
  const columns: Column<CampaignOrderlineType>[] = [
    {
      Header: ' ',
      columns: [
        {
          Header: 'Name',
          accessor: 'name',
          Cell: ({ row: { original: orderline } }) => <NameAccessor name={orderline.name} />,
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: ({ row: { original: orderline } }) => <StatusAccessor status={orderline.status} />,
          // @ts-ignore
          disableSortBy: true,
        },
        { Header: 'Type', accessor: 'creative_type' },
        {
          Header: 'Start Date',
          accessor: orderline => moment(orderline.start_date).format('MM/DD/YYYY'),
        },
        {
          Header: 'End Date',
          accessor: orderline => moment(orderline.end_date).format('MM/DD/YYYY'),
        },
        {
          Header: 'Total Budget',
          accessor: orderline => compactNumberConverter(orderline.budget, 'currency'),
        },
        {
          Header: 'Budget Spent',
          accessor: orderline => compactNumberConverter(orderline.budget_spent, 'currency'),
        },
        {
          Header: 'Imps',
          accessor: orderline => compactNumberConverter(orderline.impressions, 'number'),
        },
        {
          Header: 'Clicks',
          accessor: orderline => compactNumberConverter(orderline.clicks, 'number'),
        },
        {
          Header: 'CTR',
          accessor: orderline => compactNumberConverter(orderline.ctr, 'percent'),
        },
        { Header: 'ID', accessor: 'eltoro_id' },
      ],
    },
  ];

  const emptyText = <EmptyStatus heading="You do not have any orderline" />;

  return (
    <ReactTable<CampaignOrderlineType>
      title="orderline"
      emptyText={emptyText}
      columns={columns}
      data={orderlines}
      selectFeature={false}
    />
  );
};

export default OrderlinesTable;
