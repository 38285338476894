import { useState } from 'react';
import { TLocation } from 'types';

export const useLocations = () => {
  const [locations, setLocations] = useState<TLocation[]>([]);

  const addLocation = (
    suggestedLocation: TLocation,
    setcallPolygonFilters?: (bool: boolean) => void
  ) => {
    setLocations(prevLocations => {
      return prevLocations.some(location => location.value === suggestedLocation.value)
        ? prevLocations
        : [...prevLocations, suggestedLocation];
    });
    if (setcallPolygonFilters) {
      setcallPolygonFilters(true);
    }
  };

  const removeLocation = (id: string, setremoveLocationBool?: (bool: boolean) => void) => {
    setLocations((prevLocations: any[]) => prevLocations.filter(location => location.value !== id));
    if (setremoveLocationBool) {
      setremoveLocationBool(true);
    }
  };

  const clearLocation = () => setLocations([]);

  const hasLocations = locations?.length > 0;

  return {
    locations,
    addLocation,
    removeLocation,
    clearLocation,
    hasLocations,
  };
};
