import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { Dispatch, SetStateAction, ChangeEventHandler } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons';

import { Button, Checkbox, Modal } from '@eltoro-ui/components';

import ListingToggle from 'Pages/ProspectActivity/lenses/components/LensSidebarHeader/elements/ListingToggle';
import { ListingLabel } from 'Pages/ProspectActivity/components/ListingLabel';

import type { ListingsListProps } from 'Pages/ProspectActivity/components/ListingsList';
import type { APIFeatureType } from 'types';

interface AllListingsModalProps
  extends Omit<
    ListingsListProps,
    'loading' | 'onConfirm' | 'type' | 'onSelectedListingsChange' | 'handleListingLabelOnClick'
  > {
  allChecked: boolean;
  setAllChecked: Dispatch<SetStateAction<boolean>>;
  toggleViewAllListings: () => void;
  handleConfirm: (inactiveListing: boolean, modalSelectedListings: APIFeatureType[]) => () => void;
}

export default function AllListingsModal({
  listings,
  toggle,
  isToggleOpen,
  setAllChecked,
  toggleText,
  allChecked,
  selectedListings,
  toggleViewAllListings,
  handleConfirm,
}: AllListingsModalProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [modalListings, setModalListings] = useState<APIFeatureType[]>(listings);
  const [modalSelectedListings, setModalSelectedListings] =
    useState<APIFeatureType[]>(selectedListings);
  const [inactiveListing, setInactiveListing] = useState<boolean>(isToggleOpen as boolean);

  const [searchValue, setSearchValue] = useState<string>('');

  const onChangeSearch: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
    setSearchValue(value);
  };

  const switchInactive = (value: boolean) => {
    setInactiveListing(value);
  };

  const virtual_listings = useMemo<APIFeatureType[]>(() => {
    if (!inactiveListing) return listings.filter(listing => listing.status === 'Active');

    return modalListings;
  }, [modalListings, inactiveListing]);

  const allListings = useMemo<AllListingsModalProps['listings']>(() => {
    return virtual_listings.filter(listing =>
      listing.street.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [searchValue, virtual_listings]);

  const searchedCheckAll = useMemo<boolean>(() => {
    if (searchValue)
      return (
        allListings.length ===
        modalSelectedListings.filter(modal_selected_listing =>
          allListings.find(listing => listing.listingid === modal_selected_listing.listingid)
        ).length
      );

    return false;
  }, [allListings, searchValue]);

  const handleAllCheckedChange = useCallback(
    (checked: boolean) => {
      if (checked) {
        setModalSelectedListings([...allListings]);
      } else {
        setModalSelectedListings(modalSelectedListings =>
          modalSelectedListings.filter(
            all_listing => !allListings.find(listing => listing.listingid === all_listing.listingid)
          )
        );
      }
    },
    [allListings]
  );

  const onSelectListing = (listing: APIFeatureType) => {
    if (listings.length > 0) {
      const tempList = [...listings];
      let newSelectedListings: APIFeatureType[] = [];

      const matchingListing = modalSelectedListings.find(l => l.listingid === listing.listingid);
      if (matchingListing)
        newSelectedListings = modalSelectedListings.filter(l => l.listingid !== listing.listingid);
      else newSelectedListings = [...modalSelectedListings, listing];

      const newListings = tempList.map(listing =>
        newSelectedListings.some(sublist => sublist.listingid === listing.listingid)
          ? {
              ...listing,
              selected: true,
              unique_observations_total: 0,
            }
          : {
              ...listing,
              selected: false,
              unique_observations_total: 0,
            }
      );
      setModalListings([...newListings]);
      setModalSelectedListings(newSelectedListings);
    }
  };

  useEffect(() => {
    if (allChecked) setModalSelectedListings(allListings);
    else
      setModalSelectedListings(modalSelectedListings =>
        modalSelectedListings.filter(selectedListing =>
          allListings.find(listing => selectedListing.listingid === listing.listingid)
        )
      );
  }, [inactiveListing]);

  useEffect(() => {
    if (
      modalSelectedListings.length &&
      allListings.length &&
      modalSelectedListings.filter(all_listing =>
        allListings.find(listing => listing.listingid === all_listing.listingid)
      ).length === allListings.length
    ) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  }, [allListings, modalSelectedListings]);

  const onClear = () => {
    setSearchValue('');
    inputRef.current?.focus();
  };

  return (
    <Modal className="all-listings-modal">
      <div className="all-listings-modal-content">
        <span className="count">Your Listings ({allListings?.length})</span>
        {toggle && (
          <ListingToggle
            isToggle={(val: boolean) => switchInactive(val)}
            isToggleOpen={inactiveListing}
            toggleText={toggleText}
          />
        )}
        <div className="listing-search">
          <FontAwesomeIcon icon={faSearch} color="#6D6D6D" style={{ fontSize: 22 }} />
          <input
            ref={inputRef}
            onChange={onChangeSearch}
            value={searchValue}
            placeholder="Search by address"
            className="listing-search-input"
            type="text"
          />
          {searchValue && (
            <button aria-label="clear" type="button" className="clear-btn" onClick={onClear}>
              <FontAwesomeIcon color="#FFAB03" icon={faTimes} style={{ fontSize: 15 }} />
            </button>
          )}
        </div>
        <div className="all-listings-wrapper">
          {allListings.length ? (
            <div className="all-listings-selection">
              <Checkbox
                checked={searchValue ? searchedCheckAll : allChecked}
                onChange={handleAllCheckedChange}
                fontOverWrite
                label="Select all"
              />
              {!!modalSelectedListings.length && (
                <span className="selected_listings_count">
                  {modalSelectedListings.length} Selected
                </span>
              )}
            </div>
          ) : (
            <span className="not-matching-found">
              No matching listings found for “{searchValue}”
            </span>
          )}
          <div className="all-listings">
            {allListings.map(listing => (
              <ListingLabel
                key={listing.listingid}
                isSelected={
                  !!modalSelectedListings.find(
                    selected_listing => selected_listing.listingid === listing.listingid
                  )
                }
                listing={listing}
                onClick={onSelectListing}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="all-listings-modal-footer">
        <Button kind="default" size="l" weight="bold" onClick={toggleViewAllListings}>
          Cancel
        </Button>
        <Button
          kind="primary"
          size="l"
          weight="bold"
          onClick={handleConfirm(inactiveListing, modalSelectedListings)}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
}
