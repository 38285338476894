import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { Button } from '@eltoro-ui/components';
import { Loading, PageHeader } from 'Components';

import {
  CampaignPerformance,
  CompaignType,
  FinishedCampaigns,
  ProgressCampaign,
  SavedAudiences,
} from 'Pages/CampaignDashboard/components';

import { getAllOrderlinesStats } from 'Requests/Request_Methods/campaignMethods';

import type { AllOrderlinesStats } from 'types';

import './CampaignDashboard.scss';

export const CampaignDashboard = () => {
  const [statsData, setStatsData] = useState<AllOrderlinesStats | null>(null);

  useEffect(() => {
    getAllOrderlinesStats()
      .then(({ data }) => {
        if (data) setStatsData(data);
      })
      .catch(err => {
        if ('detail' in err) toast.error(err.detail);
      });

    return () => {
      setStatsData(null);
    };
  }, []);

  return (
    <div className="CampaignDashboard">
      <PageHeader
        UNSAFE_CLASSNAME="CampaignDashboard__dashboard_bar"
        subTitle=""
        actions={[
          <Button
            key="new-campaign"
            kind="primary"
            weight="bold"
            to="?action=create-campaign"
            replace
            UNSAFE_style={{
              justifyContent: 'center',
            }}
            size="l"
          >
            New Campaign
          </Button>,
        ]}
      />

      {!statsData ? (
        <Loading />
      ) : (
        <div className="CampaignDashboard__main_div">
          <div className="CampaignDashboard__left_main_col">
            <div className="CampaignDashboard__CampaignPerformance">
              <CampaignPerformance />
            </div>
            <div className="CampaignDashboard__left_col_child_div">
              <div className="CampaignDashboard__graphdata_campaign">
                <CompaignType
                  data={{
                    video: statsData.video_orderlines_percentage,
                    banner: statsData.banner_orderlines_percentage,
                  }}
                />
              </div>
              <div className="CampaignDashboard__graphdata_finshed">
                <FinishedCampaigns
                  data={{
                    completed_campaigns: statsData.Completed,
                    total: statsData.all_orderlines,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="CampaignDashboard__right_main_col">
            <div className="CampaignDashboard__Saved_audiences">
              <SavedAudiences data={statsData.saved_audience} textShow="# Of Saved Audiences" />
            </div>
            <div className="CampaignDashboard__ProgressCampaign">
              <ProgressCampaign
                data={{ 'In Review': statsData['In Review'], Active: statsData.Active }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
