import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ContactDetail } from './components/ContactDetail/ContactDetail';
import { MyContactsPage } from './MyContacts';

export const MyContacts = () => {
  return (
    <Switch>
      <Route exact path="/my-sphere">
        <MyContactsPage />
      </Route>
      <Route exact path="/my-sphere/:contactId/contact-details">
        <ContactDetail />
      </Route>
    </Switch>
  );
};
