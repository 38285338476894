import React, { FC, useState } from 'react';
import { Text, Spacer, Button, FlexBox } from '@eltoro-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import './Notes.scss';
import { faEdit, faPencilAlt } from '@fortawesome/pro-regular-svg-icons';

type NotesType = {
  notes?: string;
  onSaveNotes: (note: string | undefined) => void;
  loading?: boolean;
};

export const Notes: FC<NotesType> = ({ notes, onSaveNotes, loading }) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState<string | undefined>(notes);

  const handleSaveNotes = () => {
    // if (value) {
    onSaveNotes(value);
    setEditing(false);
    // }
  };

  const canBeSaved = editing && notes !== value && ((notes && value === '') || value);

  return (
    <div className="Notes">
      <FlexBox justifyContent="space-between" alignItems="center" paddingRight="0.5rem">
        <Text on="white" size="l" UNSAFE_className="Notes_heading-color">
          Notes
        </Text>
      </FlexBox>
      <Spacer height="0.5rem" />
      <textarea
        className="Notes__textarea"
        name="notes"
        value={value}
        rows={4}
        disabled={!editing}
        placeholder="Write any notes you want to remember here"
        onChange={e => {
          // setEditing(true)
          setValue(e.target.value);
        }}
      >
        {notes}
      </textarea>
      <FlexBox
        justifyContent="flex-start"
        alignItems="center"
        paddingRight="0.5rem"
        paddingTop="0.5rem"
      >
        {!editing && (
          <Button
            onClick={() => setEditing(true)}
            UNSAFE_className="Notes__edit-btn-icon"
            size="l"
            kind="primary"
            disabled={editing}
          >
            <>
              <FontAwesomeIcon size="1x" icon={faEdit} /> Edit Notes
            </>
          </Button>
        )}
        {editing && (
          <>
            <Button
              UNSAFE_className="Notes__edit-btn"
              size="l"
              kind="primary"
              disabled={!canBeSaved}
              onClick={handleSaveNotes}
              loading={loading}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                setEditing(false);
                if (notes) {
                  setValue(notes);
                } else {
                  setValue('');
                }
              }}
              UNSAFE_className="Notes__edit-btn"
              size="l"
              UNSAFE_style={{ marginLeft: '0.7rem' }}
              kind="primary"
              // disabled={!canBeSaved}
            >
              Cancel
            </Button>
          </>
        )}
      </FlexBox>
    </div>
  );
};
