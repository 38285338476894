import { RectConfig } from 'konva/types/shapes/Rect';
import React from 'react';
import { Rect } from 'react-konva';
import { useRecoilValue } from 'recoil';
import {
  blockBorderRadiusAtom,
  blockShadowBlurAtom,
  blockShadowColorAtom,
  blockShadowOffsetXAtom,
  blockShadowOffsetYAtom,
  blockShadowOpacityAtom,
  blockShadowVisibleAtom,
  blockShapeColorAtom,
  blockStrokeColorAtom,
  blockStrokeEnabledAtom,
  blockStrokeWidthAtom,
} from '../../../State';
import { BlockConfigType } from '../../../Typings';

// wraps Konva's Rect with recoil related values to user can supply only a block config if needed
export const RecoilRect: React.FC<{
  config: BlockConfigType;
  // ref?: React.RefObject<RectConfig>;
  name?: string;
  width?: number;
  height?: number;
  x?: number;
  y?: number;
  listening?: boolean;
  fill?: string;
  shadowEnabled?: boolean;
}> = ({ config, name, width, height, x, y, listening = true, fill, shadowEnabled }) => {
  const enableShadow = useRecoilValue(blockShadowVisibleAtom(config));
  const shadowColor = useRecoilValue(blockShadowColorAtom(config));
  const shadowBlur = useRecoilValue(blockShadowBlurAtom(config));
  const shadowOpacity = useRecoilValue(blockShadowOpacityAtom(config));
  const shadowOffsetX = useRecoilValue(blockShadowOffsetXAtom(config));
  const shadowOffsetY = useRecoilValue(blockShadowOffsetYAtom(config));

  const strokeEnabled = useRecoilValue(blockStrokeEnabledAtom(config));
  const strokeColor = useRecoilValue(blockStrokeColorAtom(config));
  const strokeWidth = useRecoilValue(blockStrokeWidthAtom(config));

  const borderRadius = useRecoilValue(blockBorderRadiusAtom(config));
  const color = useRecoilValue(blockShapeColorAtom(config));

  const enabledShadow = shadowEnabled !== undefined ? shadowEnabled : enableShadow;
  return (
    <Rect
      name={name}
      width={width}
      height={height}
      x={x}
      y={y}
      fill={fill || color}
      cornerRadius={borderRadius}
      shadowEnabled={enabledShadow}
      shadowColor={shadowColor}
      shadowBlur={shadowBlur}
      shadowOpacity={shadowOpacity}
      shadowOffsetX={shadowOffsetX}
      shadowOffsetY={shadowOffsetY}
      strokeEnabled={strokeEnabled}
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      listening={listening}
    />
  );
};
