import React, { useEffect, useRef } from 'react';
import { Image } from 'react-konva';
import useImage from 'use-image';
import Konva from 'konva';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useDebounce } from '@eltoro-ui/hooks';
import type { UploadedBackgroundImageType } from 'types';
import { getImageDetailsCover, handleCoverDrag } from '../../../Helpers';
import { ImageType } from '../../../Typings';
import {
  backgroundPhotoDimensionsAtom,
  backgroundPhotoPositionAtom,
  creativeTypeAtom,
  directMailSizeAtom,
  loadingBackgroundPhotoAtom,
} from '../../../State';

// covers background of artboard...
// ...can be dragged, but dragBoundFunc prevents it from ever leaving frame
export const BackgroundImage: React.FC<{
  image: ImageType | UploadedBackgroundImageType;
  artboardName: string;
}> = ({ image, artboardName }) => {
  // console.log(image, '<================ image');
  const creativeType = useRecoilValue(creativeTypeAtom);
  const directMailSize = useRecoilValue(directMailSizeAtom);
  const imageUrl =
    'urls' in image ? (creativeType === 'banner' ? image.urls.regular : image.urls.raw) : image.url;
  const imageRef = useRef<Konva.Image>(null);
  const [img] = useImage(imageUrl, 'anonymous');
  const debouncedImg = useDebounce(img, 200);
  const setImgLoading = useSetRecoilState(loadingBackgroundPhotoAtom);
  const [photoPos, setPhotoPos] = useRecoilState(
    backgroundPhotoPositionAtom({ image: imageUrl, artboardName })
  );
  const [photoDims, setPhotoDims] = useRecoilState(
    backgroundPhotoDimensionsAtom({ image: imageUrl, artboardName })
  );

  useEffect(() => {
    // checks for img to finish rending inside useImage hook...
    // ...use debounced img to cut down on flashes of loading bar
    if (!debouncedImg) setImgLoading(true);
    else setImgLoading(false);
    if (creativeType === 'directMail' && !directMailSize) setImgLoading(false);
  }, [debouncedImg, creativeType, directMailSize]);

  useEffect(() => {
    if (!imageRef.current) return;
    const imageDetails = getImageDetailsCover(imageRef.current, {
      width: image.width,
      height: image.height,
    });
    if (!photoDims) {
      // only set dims if they are currently undefined for this atom...
      // ...this allows dims to persist if tabs (creative type) or images change
      setPhotoDims({
        width: imageDetails.width,
        height: imageDetails.height,
      });
    }
    if (!photoPos) {
      // only set position if they are currently undefined for this atom...
      // ...this allows pos to persist if tabs (creative type) or images change
      setPhotoPos({
        x: imageDetails.x,
        y: imageDetails.y,
      });
    }
  }, [image.id]);

  return (
    <Image
      ref={imageRef}
      image={img}
      onClick={e => (e.cancelBubble = true)}
      x={photoPos?.x}
      y={photoPos?.y}
      width={photoDims?.width}
      height={photoDims?.height}
      onDragStart={e => (e.cancelBubble = true)}
      draggable
      onDragEnd={e => {
        setPhotoPos({
          x: e.currentTarget.x(),
          y: e.currentTarget.y(),
        });
      }}
      dragBoundFunc={pos => handleCoverDrag(pos, imageRef.current)}
    />
  );
};
