/*
  This mock api uses the miragejs mock api library.
  For an overview and Docmentation, check https://miragejs.com/docs/getting-started/overview/.
  This is also using localStorage to persist the mock db after browser reloads
*/
import { createHash } from 'crypto';
import { createServer, Model, Response } from 'miragejs';
import { v4 as uuid } from 'uuid';
import fixtures from 'Requests/Fixtures';
import { AudienceType, CampaignType, CreativeType, NotificationType } from 'types';
import keycloak from '../../keycloakConf';

const root =
  process.env.NODE_ENV === 'development' ? process.env.REACT_APP_PROXY : process.env.REACT_APP_API;

const baseUrl = `${root}/api/v1`;

const findAll = (collection: any) => collection.all().models.map((model: any) => model.attrs);

const hasher = (data: string) => {
  const hash = createHash('sha256').update(data);
  return hash.digest('hex');
};

const modelIndexer = (fixtureSet: object) => {
  // creates model list for the mirage server with the list of fixtures to be seeded
  return Object.keys(fixtureSet).reduce((modelIndex: object, key: string) => {
    return { ...modelIndex, [key]: Model };
  }, {});
};

const getValidUser = (token: string, schema: any) => {
  const tkn = token.replace('Bearer ', '');
  const validUser = schema.users.findBy({ current_token: tkn });
  if (validUser) {
    return validUser;
  }
  if (keycloak?.token === tkn) {
    const genericUser = schema.users.find(1);
    genericUser.update('current_token', uuid());
    return genericUser;
  }
  if (localStorage.getItem('beewo_token') === tkn) {
    const storedUser = JSON.parse(localStorage.getItem('beewo_user') as string);
    return storedUser;
  }
  return { error: 'invalid token' };
};

const isPointWithin = (
  point: { x: number; y: number },
  bb: { north: number; south: number; east: number; west: number }
) => {
  // this could be more precise, but it works with the current mock data set
  if (bb.north >= point.x && point.x >= bb.south) return true;
  return false;
};

const createPersist = (
  collection: {
    camelizedModelName: string;
    create: (arg0: { [key: string]: any }) => void;
  },
  newData: { [key: string]: any }
) => {
  const collectionName = collection.camelizedModelName;
  const localDb = JSON.parse(localStorage.getItem('mirageDB') as string);
  collection.create(newData);
  localStorage.setItem(
    'mirageDB',
    JSON.stringify({
      ...localDb,
      [collectionName]: localDb[collectionName].concat(newData),
    })
  );
};

const updatePersist = (
  collection: { camelizedModelName: string },
  record: {
    _id: string;
    update: (arg0: { [key: string]: any }) => void;
  },
  newData: { [key: string]: any }
) => {
  const collectionName = collection.camelizedModelName;
  const localDb = JSON.parse(localStorage.getItem('mirageDB') as string);
  record.update(newData);
  localStorage.setItem(
    'mirageDB',
    JSON.stringify({
      ...localDb,
      [collectionName]: localDb[collectionName].map((obj: any) => {
        if (obj._id === record._id) {
          return {
            ...obj,
            ...newData,
          };
        }
        return obj;
      }),
    })
  );
};

export const mockApi = (environment = 'development') => {
  const mockServer = createServer({
    environment,
    models: modelIndexer(fixtures),
    seeds(server) {
      const localDb = localStorage.getItem('mirageDB');
      if (localDb) {
        server.db.loadData({ ...JSON.parse(localDb) });
      } else {
        localStorage.setItem('mirageDB', JSON.stringify(fixtures));
        server.db.loadData({ ...fixtures });
      }
    },
    routes() {
      // allows requests to openstreetmap and local keycloak to not be intercepted
      this.passthrough('http://35.80.134.236/api/***');
      this.passthrough('http://35.80.134.236:8080/auth/***');
      this.passthrough(`${process.env.REACT_APP_API}/***`);
      this.passthrough('https://api.unsplash.com/***');
      this.passthrough('https://nominatim.openstreetmap.org/***');
      this.passthrough('https://map.dev.beewo.com/v1/***');
      this.passthrough('https://search.osmnames.org/***');
      this.passthrough('http://polygons.openstreetmap.fr/***');
      this.passthrough('https://s3.amazonaws.com/***');
      this.passthrough('https://api.cloudsponge.com/***');
      this.passthrough('https://collect.cloudsponge.com/***');
      // projects/dev-wit/registrations
      this.passthrough('https://fcmregistrations.googleapis.com/v1/***');
      this.passthrough('https://firebaseinstallations.googleapis.com/v1/***');

      // this.passthrough('https://nominatim.openstreetmap.org/***')
      // this.passthrough('http://localhost:8080/auth/*')

      // Auth endpoint

      this.post(`${baseUrl}/token`, (schema: any, request) => {
        const token = uuid();
        const body = JSON.parse(request.requestBody) as any;
        const user = schema.users.findBy({ email: body.email });

        const verified =
          user && body?.password && hasher(body.password) === user.attrs.password_hash;
        if (verified) {
          updatePersist(schema.users, user, { current_token: token });
          return { _id: user.attrs._id, token };
        }
        return new Response(403, { some: 'header' }, { error: ['incorrect email or password'] });
      });

      // User endpoints

      this.get(`${baseUrl}/user`, (schema: any, request) => {
        // the user _id is being returned in the response object for the mock API,
        // but will most likely never be returned in the actual API, since the user
        // will be derived from the token in the request header, and exposing the user _id
        // in the client could be a security risk

        const validUser = getValidUser(request.requestHeaders.Authorization, schema);
        if (validUser && !validUser.error) return validUser.attrs;
        return new Response(401, { some: 'header' }, { error: [`${validUser.error}`] });
      });
      this.post(`${baseUrl}/user`, (schema: any, request) => {
        // should id for user be generated here?
        const validUser = getValidUser(request.requestHeaders.Authorization, schema);
        if (validUser && !validUser.error) {
          const body = JSON.parse(request.requestBody);
          createPersist(schema.users, body);
          return body;
        }
        return new Response(401, { some: 'header' }, { error: [`${validUser.error}`] });
      });
      this.patch(`${baseUrl}/user`, (schema: any, request) => {
        const body = JSON.parse(request.requestBody);
        const validUser = getValidUser(request.requestHeaders.Authorization, schema);
        if (validUser && !validUser.error) {
          updatePersist(schema.users, validUser, body);
          return schema.users.findBy({ _id: validUser.attrs._id }).attrs;
        }
        return new Response(401, { some: 'header' }, { error: [`${validUser.error}`] });
      });

      // Listings endpoints

      this.post(`${baseUrl}/listings`, (schema: any, request) => {
        // Its is currently unknown how this will be implemented
        // const body = JSON.parse(request.requestBody) as any
        return new Response(
          400,
          { some: 'header' },
          { error: ['sorry, this endpoint does not work in the mock api yet'] }
        );
      });
      this.get(`${baseUrl}/listings`, (schema: any, request) => {
        const rawListings = findAll(schema.mapListings);
        const { skip, limit } = request.queryParams;
        if (limit && skip) {
          const skipInt = parseInt(skip as string, 10);
          const limitInt = parseInt(limit as string, 10);
          return {
            listings: rawListings.listings.slice(skipInt, limitInt + skipInt),
            total: limit || rawListings.length,
            limit,
            skip,
          };
        }
        return {
          listings: rawListings,
          total: limit || rawListings.length,
          limit,
          skip,
        };
      });
      this.get(`${baseUrl}/listings/by-id/:mls_id`, (schema: any, request) => {
        const { mls_id } = request.params;
        const matchedListing = schema.mapListings.findBy({ mls_id });
        if (matchedListing) return matchedListing.attrs;
        return new Response(
          404,
          { some: 'header' },
          { error: ['sorry, no listing was found with the provided mls id'] }
        );
      });
      this.get(`${baseUrl}/listings/layer`, (schema: any, request) => {
        // const body = JSON.parse(request.requestBody) as any
        const { north, south, east, west, skip, limit } = request.queryParams;
        const listings = findAll(schema.mapListings);
        const bb = {
          north: parseFloat(north as string),
          south: parseFloat(south as string),
          east: parseFloat(east as string),
          west: parseFloat(west as string),
        };
        const matchedListings = listings.filter((listing: any) =>
          isPointWithin({ x: listing.latitude, y: listing.longitude }, bb)
        );
        if (matchedListings.length)
          return {
            listings: matchedListings,
            total: limit || matchedListings.length,
            skip,
            limit,
          };
        return new Response(
          404,
          { some: 'header' },
          { error: ['no data on the queried location'] }
        );
      });
      this.get(`${baseUrl}/listings/comps`, (schema: any, request) => {
        // const body = JSON.parse(request.requestBody)
        return new Response(
          400,
          { some: 'header' },
          { error: ['sorry, this endpoint does not work in the mock api yet'] }
        );
      });

      // Prospects endpoints

      this.get(`${baseUrl}/prospects/canvas`, (schema: any, request) => {
        // return prospect count
        // const body = JSON.parse(request.requestBody)
        return new Response(
          400,
          { some: 'header' },
          { error: ['sorry, this endpoint does not work in the mock api yet'] }
        );
      });
      this.get(`${baseUrl}/prospects/movers`, (schema: any, request) => {
        // const body = JSON.parse(request.requestBody)
        return new Response(
          400,
          { some: 'header' },
          { error: ['sorry, this endpoint does not work in the mock api yet'] }
        );
      });

      // Audience endpoints

      this.post(`${baseUrl}/audience`, (schema: any, request) => {
        const validUser = getValidUser(request.requestHeaders.Authorization, schema);
        if (validUser && !validUser.error) {
          const body = JSON.parse(request.requestBody);
          const date = new Date().toISOString();
          // make location a field on audience
          const audience = {
            ...body,
            _id: uuid(),
            user_id: validUser._id,
            date_captured: date,
            last_updated: date,
          };
          createPersist(schema.audiences, audience);
          return audience;
        }
        return new Response(401, { some: 'header' }, { error: [`${validUser.error}`] });
      });
      this.get(`${baseUrl}/audiences`, (schema: any, request) => {
        // gets audiences by user with provided token
        const { skip, limit } = request.queryParams;
        const validUser = getValidUser(request.requestHeaders.Authorization, schema);
        if (validUser && !validUser.error) {
          const audienceCollection = schema.audiences.where(
            (audience: AudienceType) => audience.id === validUser._id
          );
          const audienceArray = audienceCollection.models.map((child: any) => child.attrs);
          if (limit && skip) {
            const skipInt = parseInt(skip as string, 10);
            const limitInt = parseInt(limit as string, 10);
            return {
              audiences: audienceArray.slice(skipInt, limitInt + skipInt),
              total: limit || audienceArray.length,
              limit,
              skip,
            };
          }
          return {
            audiences: audienceArray,
            total: limit || audienceArray.length,
            limit,
            skip,
          };
        }
        // unsure about error handling
        return new Response(401, { some: 'header' }, { error: [`${validUser.error}`] });
      });
      this.get(`${baseUrl}/audience/:_id`, (schema: any, request) => {
        // gets audience by audience id
        const { _id } = request.params;
        const audience = schema.audiences.findBy({ _id }).attrs;
        if (audience) return audience;
        return new Response(
          404,
          { some: 'header' },
          { error: [`no audience found with the given id`] }
        );
      });
      this.patch(`${baseUrl}/audience/:_id`, (schema: any, request) => {
        const { _id } = request.params;
        const body = JSON.parse(request.requestBody);
        const audience = schema.audiences.findBy({ _id });
        if (audience) {
          updatePersist(schema.audiences, audience, body);
          return audience.attrs;
        }
        return new Response(
          404,
          { some: 'header' },
          { error: [`no audience found with the given id`] }
        );
      });

      // Campaign endpoints

      this.post(`${baseUrl}/campaign`, (schema: any, request) => {
        const validUser = getValidUser(request.requestHeaders.Authorization, schema);
        if (validUser && !validUser.error) {
          const body = JSON.parse(request.requestBody);
          const date = new Date().toISOString();
          const campaign = {
            ...body,
            _id: uuid(),
            user_id: validUser._id,
            created: date,
          };
          createPersist(schema.campaigns, campaign);
          return campaign;
        }
        return new Response(401, { some: 'header' }, { error: [`${validUser.error}`] });
      });
      this.get(`${baseUrl}/campaigns`, (schema: any, request) => {
        // gets campaigns by user with provided token
        const { skip, limit } = request.queryParams;
        const validUser = getValidUser(request.requestHeaders.Authorization, schema);
        if (validUser && !validUser.error) {
          const campaignCollection = schema.campaigns.where(
            (campaign: CampaignType) => campaign.id === validUser._id
          );
          const campaignArray = campaignCollection.models.map((child: any) => child.attrs);
          if (limit && skip) {
            const skipInt = parseInt(skip as string, 10);
            const limitInt = parseInt(limit as string, 10);
            return {
              campaigns: campaignArray.slice(skipInt, limitInt + skipInt),
              total: limit || campaignArray.length,
              limit,
              skip,
            };
          }
          return {
            campaigns: campaignArray,
            total: limit || campaignArray.length,
            limit,
            skip,
          };
        }
        return new Response(401, { some: 'header' }, { error: [`${validUser.error}`] });
      });
      this.get(`${baseUrl}/campaign/:_id`, (schema: any, request) => {
        // gets campaign by campaign id
        const { _id } = request.params;
        return schema.campaigns.findBy({ _id }).attrs;
      });
      this.patch(`${baseUrl}/campaign/:_id`, (schema: any, request) => {
        const { _id } = request.params;
        const body = JSON.parse(request.requestBody);
        const campaign = schema.campaigns.findBy({ _id });
        if (campaign) {
          updatePersist(schema.campaigns, campaign, body);
          return campaign;
        }
        return new Response(
          404,
          { some: 'header' },
          { error: [`no campaign found with the given id`] }
        );
      });
      this.patch(`${baseUrl}/campaign/attach-audience/:campaign_id`, (schema: any, request) => {
        const { campaign_id } = request.params;
        const body = JSON.parse(request.requestBody);
        const campaign = schema.campaigns.findBy({ _id: campaign_id });
        if (campaign) {
          const updatedAudiences = [...campaign.audiences, ...body];
          updatePersist(schema.campaigns, campaign, {
            audiences: updatedAudiences,
          });
          return campaign;
        }
        return new Response(
          404,
          { some: 'header' },
          { error: [`no campaign found with the given id`] }
        );
      });
      this.patch(`${baseUrl}/campaign/detach-audience/:campaign_id`, (schema: any, request) => {
        const { campaign_id } = request.params;
        const body = JSON.parse(request.requestBody);
        const campaign = schema.campaigns.findBy({ _id: campaign_id });
        if (campaign) {
          const updatedAudiences = campaign.audiences.filter((audience: AudienceType) => {
            return !body.some((bodyAudience: AudienceType) => bodyAudience.id === audience.id);
          });
          updatePersist(schema.campaigns, campaign, {
            audiences: updatedAudiences,
          });
          return campaign;
        }
        return new Response(
          404,
          { some: 'header' },
          { error: [`no campaign found with the given id`] }
        );
      });
      this.patch(`${baseUrl}/campaign/attach-creative/:campaign_id`, (schema: any, request) => {
        const { campaign_id } = request.params;
        const body = JSON.parse(request.requestBody);
        const campaign = schema.campaigns.findBy({ _id: campaign_id });
        if (campaign) {
          const updatedCreatives = campaign.attrs.creatives
            ? [...campaign.attrs.creatives, ...body]
            : [...body];
          updatePersist(schema.campaigns, campaign, {
            creatives: updatedCreatives,
          });
          return campaign;
        }
        return new Response(
          404,
          { some: 'header' },
          { error: [`no campaign found with the given id`] }
        );
      });
      this.patch(`${baseUrl}/campaign/detach-creative/:campaign_id`, (schema: any, request) => {
        const { campaign_id } = request.params;
        const body = JSON.parse(request.requestBody);
        const campaign = schema.campaigns.findBy({ _id: campaign_id });
        if (campaign) {
          const updatedCreatives = campaign.attrs.creatives.filter((creative: CreativeType) => {
            return !body.some((reqCreative: CreativeType) => reqCreative.id === creative.id);
          });
          updatePersist(schema.campaigns, campaign, {
            creatives: updatedCreatives,
          });
          return campaign;
        }
        return new Response(
          404,
          { some: 'header' },
          { error: [`no campaign found with the given id`] }
        );
      });

      // Creative endpoints

      this.post(`${baseUrl}/creative`, (schema: any, request) => {
        const validUser = getValidUser(request.requestHeaders.Authorization, schema);
        if (validUser && !validUser.error) {
          const body = JSON.parse(request.requestBody);
          const creative = {
            ...body,
            id: uuid(),
            user_id: validUser._id,
          };
          createPersist(schema.creatives, body);
          return creative;
        }
        return new Response(401, { some: 'header' }, { error: [`${validUser.error}`] });
      });
      this.get(`${baseUrl}/creatives`, (schema: any, request) => {
        // gets creatives by user with provided token
        const validUser = getValidUser(request.requestHeaders.Authorization, schema);
        if (validUser && !validUser.error) {
          const creativeCollection = schema.creatives.where(
            (creative: CreativeType) => creative?.id === '580fuej9320n1ji2552d'
          );
          return creativeCollection.models.map((child: any) => child.attrs);
        }
        // unsure about error handling
        return new Response(401, { some: 'header' }, { error: [`${validUser.error}`] });
      });
      this.get(`${baseUrl}/creative/:_id`, (schema: any, request) => {
        // gets creative by creative id
        // error handling
        const { _id } = request.params;
        return schema.creatives.findBy({ _id }).attrs;
      });
      this.patch(`${baseUrl}/creative/:_id`, (schema: any, request) => {
        const { _id } = request.params;
        const body = JSON.parse(request.requestBody);
        const creative = schema.creatives.findBy({ _id });
        if (creative) {
          createPersist(schema.creatives, body);
          return creative;
        }
        return new Response(
          404,
          { some: 'header' },
          { error: [`no creative found with the given id`] }
        );
      });

      // Misc  endpoints

      this.get(`${baseUrl}/filters`, (schema: any, request) => {
        // const filterSet = findAll(schema.filters)[0]
        return { filters: { ...findAll(schema.filters)[0], id: undefined } };
      });
      this.get(`${baseUrl}/notifications`, (schema: any, request) => {
        const validUser = getValidUser(request.requestHeaders.Authorization, schema);
        if (validUser && !validUser.error) {
          const notificationCollection = schema.notifications.where(
            (notification: NotificationType) => notification.user_id === validUser._id
          );
          return {
            notifications: notificationCollection.models.map((child: any) => child.attrs),
          };
        }
        return new Response(401, { some: 'header' }, { error: [`${validUser.error}`] });
      });
    },
  });
  return mockServer;
};
