import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useResizeObserver from '@react-hook/resize-observer';

import toast from 'react-hot-toast';

import { Button, FlexBox, MaxHeightContainer, Spacer } from '@eltoro-ui/components';

import { BuzzScoreFilters, Map, MapToolTip, MapPopup } from 'Components';
import type { BuzzFilterType } from 'Components';

import { LinearLoader } from 'Components/LinearLoader';
import {
  LensFilterContainer,
  LensSidebar,
  LensSidebarFooter,
  LensSidebarHeader,
} from 'Pages/ProspectActivity/lenses/components';
import { useSelectedListingMarker } from 'Pages/ProspectActivity/lenses/components/LensSelectedMarker/LensSelectedMarker';
import {
  AudienceResultsCard,
  ListingsList,
  MapMarker,
  SaveAudienceWizard,
} from 'Pages/ProspectActivity/components';
import { ListingSearch } from 'Pages/ProspectActivity/lenses/VirtualJustListedJustSold/elements/ListingsSearch';

import { setFilteredBuzz, setRefreshList, setSelectedListing } from 'Redux/actions';
import { getListings } from 'Requests/Request_Methods/listingMethods';

import { buzzFilters } from 'Pages/ProspectActivity/configs';
import { getIconBuzzColor } from 'Helpers';
import { browserLog } from 'Tools';

import type { APIFeatureType, TRootState } from 'types';

import './VirtualJustListedJustSold.scss';
import { Link } from 'react-router-dom';

type TListing = {
  unique_observations_total: number;
  unique_visitor_count: number;
  selected: boolean;
};

type ListingType = APIFeatureType & TListing;

export type TListingData = {
  listings: ListingType[];
  total_listing: number;
  total_pages: number;
};

export const VirtualJustListedJustSold = () => {
  const dispatch = useDispatch();
  const { refreshList } = useSelector((state: TRootState) => state.RefreshListingReducer);

  const [activeBuzzFilters, setActiveBuzzFilters] = useState<BuzzFilterType[]>(buzzFilters);
  const [inactiveListing, setInactiveListing] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [searchClicked, setSearchClicked] = useState<boolean>(false);
  const [listingData, setListingData] = useState<TListingData>({
    listings: [],
    total_listing: 0,
    total_pages: 0,
  });
  const [mapFeatures, setMapFeatures] = useState<APIFeatureType[]>([]);
  const [mapFeaturesSelectedListings, setMapFeaturesSelectedListings] = useState<APIFeatureType[]>(
    []
  );

  const [VJLJSOffsetHeight, setVJLJSOffsetHeight] = useState<number>(0);

  const [listings, setListings] = useState(refreshList);
  const [radiusListing, setRadiusListing] = useState<number[]>([]);

  const [selectedListings, setSelectedListings] = useState<APIFeatureType[]>([]);
  const [activeToggleFeature, setActiveToggleFeature] = useState<string>('buzz_filter');
  const [totalProspects, setTotalProspects] = useState(0);

  const AllBuzz: string[] = [];

  useEffect(() => {
    setListings(refreshList);
  }, [refreshList]);

  useEffect(() => {
    getMlsListings();
  }, []);

  const getMlsListings = () => {
    setLoading(true);
    setSelectedListings([]);
    getListings()
      .then(res => {
        setSearchClicked(false);
        if (res.data) {
          const listings: ListingType[] = res.data.listings
            .filter(listing => listing.latitude && listing.longitude)
            .map(listing => ({
              ...listing,
              radius: {
                label: '1,000 meters',
              },
              selected: false,
              unique_observations_total: 0,
            }));
          setListingData({ ...res.data, listings });
          setMapFeatures(listings);
          dispatch(setRefreshList(listings));
        }
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        toast.error(err.detail);
      });
  };

  const onAdd = (selected_listing: APIFeatureType) => {
    const listing = {
      ...selected_listing,
      radius: {
        label: '1,000 meters',
      },
      selected: selected_listing.status === 'Active' ? true : inactiveListing,
      unique_observations_total: 0,
    };

    const listings = [listing, ...listingData.listings];

    const selectedListing =
      selected_listing.status === 'Active' || inactiveListing ? [listing] : [];

    setListingData({
      ...listingData,
      listings,
    });
    setSelectedListings(listings => [...selectedListing, ...listings]);
    dispatch(setRefreshList(listings));
  };

  useEffect(() => {
    activeBuzzFilters.forEach((item: { label: string }) => AllBuzz.push(item.label));
    const Buzz = ['High buzz', 'Warm buzz', 'Medium buzz', 'Low buzz'];
    const data = Buzz.filter((v: string) => {
      return !AllBuzz.includes(v);
    });
    dispatch(setFilteredBuzz(data));
  }, [activeBuzzFilters]);

  useEffect(() => {
    dispatch(setSelectedListing(selectedListings));

    if (selectedListings.length < 1 || searchClicked) {
      setActiveToggleFeature('buzz_filter');
    }
  }, [selectedListings]);

  useEffect(() => {
    const totalProspectsCount = listingData.listings.reduce(
      (total: number, listing: TListing) => totalN(listing, total),
      0
    );
    setTotalProspects(totalProspectsCount);
  }, [listingData, listings]);

  const totalN = (listing: TListing, total: number) => {
    if (listing?.selected) {
      if (listing?.unique_observations_total > 0) {
        return listing?.unique_observations_total + total;
      }

      return listing?.unique_visitor_count + total;
    }
    return total;
  };

  const checkSwitchToggle = (value: boolean) => {
    setInactiveListing(value);
  };

  useEffect(() => {
    const newListings = selectedListings.filter(selectedListing =>
      virtual_listings.find(listing => selectedListing.listingid === listing.listingid)
    );

    setListingData({
      ...listingData,
      listings: listingData.listings.map(listing =>
        newListings.some(sublist => sublist.listingid === listing.listingid)
          ? {
              ...listing,
              selected: true,
              unique_observations_total: 0,
            }
          : {
              ...listing,
              selected: false,
              unique_observations_total: 0,
            }
      ),
    });
    setSelectedListings(newListings);
  }, [inactiveListing]);

  const virtual_listings = useMemo(() => {
    const listingsData = listingData.listings.filter(listing =>
      activeBuzzFilters.find(buzz => buzz.test(listing.buzz_score))
    );
    if (!inactiveListing) return listingsData.filter(listing => listing.status === 'Active');

    return listingsData;
  }, [listingData.listings, inactiveListing, activeBuzzFilters]);

  useEffect(() => {
    setMapFeatures([...virtual_listings]);
  }, [inactiveListing, activeBuzzFilters]);

  const getIconMap = () => {
    setSearchLoading(true);
    setTimeout(() => {
      setSearchClicked(true);
      setListings([...virtual_listings]);
      setMapFeatures([...virtual_listings]);
      setMapFeaturesSelectedListings(virtual_listings.filter(listing => listing.selected));
      setSearchLoading(false);
    }, 1000);
  };

  const lensRef = useRef<HTMLDivElement>(null);

  useResizeObserver(lensRef, entry => {
    return setVJLJSOffsetHeight(entry.target.getBoundingClientRect().height);
  });

  return (
    <div className="VirtualJustListedJustSold">
      <LensSidebar
        UNSAFE_className="VirtualJustListedJustSold_sidebar"
        root_UNSAFE_className="root_content"
        ref={lensRef}
      >
        <MaxHeightContainer
          fullHeight
          header={
            <LensSidebarHeader
              heading="Virtual Just Listed/Just Sold"
              subHeading="Identify targets that reside around a specific area."
              toggle={!loading && !!refreshList.length}
              isToggleOpen={inactiveListing}
              isToggle={(val: boolean) => checkSwitchToggle(val)}
            />
          }
          footer={
            !loading ? (
              refreshList.length ? (
                <FlexBox flexDirection="column" gap="8px">
                  <SaveAudienceWizard
                    type="Virtual Just Listed/Just Sold"
                    onSaveAndClose={audienceDetails => browserLog.info(audienceDetails)}
                    totalProspects={totalProspects}
                    listing={selectedListings}
                    radiusListing={radiusListing}
                    disabled={!selectedListings.length || loading || searchLoading}
                  />
                </FlexBox>
              ) : (
                <Button
                  UNSAFE_className="MyListingsLens__FooterBtn"
                  kind="primary"
                  weight="bold"
                  size="l"
                  width="100%"
                  to="/profile"
                >
                  Go to Profile
                </Button>
              )
            ) : null
          }
        >
          <div className="MyListingsLens__sidebar-content VirtualJustListedJustSold__sidebar-content">
            <ListingsList
              type="Virtual Just Listed/Just Sold"
              loading={loading}
              listings={virtual_listings}
              selectedListings={selectedListings}
              toggle={!loading && !!refreshList.length}
              isToggleOpen={inactiveListing}
              isToggle={(val: boolean) => checkSwitchToggle(val)}
              onSelectedListingsChange={newSelectedListings => {
                if (listingData.listings.length > 0) {
                  const tempList = [...listingData.listings];

                  const newA = tempList.map(listing =>
                    newSelectedListings.some(sublist => sublist.listingid === listing.listingid)
                      ? {
                          ...listing,
                          selected: true,
                          unique_observations_total: 0,
                        }
                      : {
                          ...listing,
                          selected: false,
                          unique_observations_total: 0,
                        }
                  );
                  setListingData({ ...listingData, listings: [...newA] });
                  setSelectedListings(newSelectedListings);
                }
              }}
            />
          </div>
        </MaxHeightContainer>
      </LensSidebar>

      <LensSidebar
        UNSAFE_style={{ top: VJLJSOffsetHeight + 16, zIndex: VJLJSOffsetHeight ? 999 : 998 }}
      >
        <MaxHeightContainer fullHeight>
          <div className="MyListingsLens__sidebar-content VirtualJustListedJustSold__sidebar-content VirtualJustListedJustSold__listing-search">
            <span className="title">Could not find your listing above?</span>
            <ListingSearch onAdd={onAdd} />
          </div>
        </MaxHeightContainer>
      </LensSidebar>

      {mapFeaturesSelectedListings.length && searchClicked ? (
        <LensSidebarFooter>
          <AudienceResultsCard>
            <div className="MyListingsLens__score virtual-just-listed-just-sold">
              <div className="MyListingsLens__scoreLeft" style={{ margin: 0 }}>
                <span className="prospects_count">
                  {searchClicked && mapFeaturesSelectedListings.length
                    ? `${mapFeaturesSelectedListings.length * 40}-${
                        mapFeaturesSelectedListings.length * 60
                      }`
                    : null}
                </span>
              </div>
              <div className="MyListingsLens__scoreRight">
                <div className="neighbors-found">
                  <span className="prospects_count_text">Neighbors Found</span>
                  <span className="around-listing-score">
                    around
                    <b>
                      {' '}
                      {mapFeaturesSelectedListings.length} Listing
                      {mapFeaturesSelectedListings.length > 1 ? 's' : null}
                    </b>
                  </span>
                </div>
              </div>
            </div>
            <Spacer />
          </AudienceResultsCard>
        </LensSidebarFooter>
      ) : null}

      <div className="MyListingsLens__map VirtualJustListedJustSold__map">
        <div className="GrowYourSphere__rightTop" style={{ display: 'flex', width: 'auto' }}>
          <Button
            UNSAFE_className="GrowYourSphere__rightTopButton"
            UNSAFE_style={{
              background: activeToggleFeature === 'buzz_filter' ? '#FFB708' : '',
              color: activeToggleFeature === 'buzz_filter' ? 'white' : '',
            }}
            onClick={() => {
              setActiveToggleFeature(activeToggle => (activeToggle ? '' : 'buzz_filter'));
            }}
          >
            Buzz Filter
          </Button>
        </div>

        <div className="MyListingsLens__filter" style={{ display: 'flex' }}>
          {activeToggleFeature === 'buzz_filter' && (
            <LensFilterContainer>
              <BuzzScoreFilters
                filters={buzzFilters}
                activeFilters={activeBuzzFilters}
                onChange={setActiveBuzzFilters}
              />
            </LensFilterContainer>
          )}
        </div>

        <div style={{ height: '100%' }}>
          {loading && <LinearLoader />}
          <Map
            features={mapFeatures}
            type="Virtual Just Listed/Just Sold"
            locations={[]}
            setListingData={setListingData}
            listingData={listingData}
            setListings={setListings}
            setRadiusListing={setRadiusListing}
            marker={f => (
              <MapMarker
                searchClicked={searchClicked}
                color={getIconBuzzColor(f)}
                isSelected={useSelectedListingMarker}
                f={f}
              />
            )}
            popup={f => <MapPopup feature={f} />}
            tooltip={f => <MapToolTip feature={f} />}
          />
        </div>
      </div>
    </div>
  );
};
