export const centerLensReducer: undefined | any = (
  state = { isCenter: false, openSideBar: true },
  action: any
) => {
  switch (action.type) {
    case 'SET_CENTER_LENS':
      return { ...state, isCenter: action.payload };

    case 'SET_LENS_SIDEBAR':
      return { ...state, openSideBar: action.payload };
    default:
      return { ...state };
  }
};

export const selectedListingsReducer: undefined | any = (
  state = { selectedList: [] },
  action: any
) => {
  switch (action.type) {
    case 'SET_SELECTED-LIST':
      return { ...state, selectedList: action.payload };
    default:
      return { ...state };
  }
};

export const openTagModalReducer: undefined | any = (
  state = { tagModal: false, tags: [] },
  action: any
) => {
  switch (action.type) {
    case 'SET_TAG_MODAL':
      return { tagModal: action.payload };
    default:
      return { ...state };
  }
};

export const selectedMarkerReducer: undefined | any = (
  state = { selectedMarker: null },
  action: any
) => {
  switch (action.type) {
    case 'SET_SELECTED_MARKER':
      return { ...state, selectedMarker: action.payload };

    default:
      return { ...state };
  }
};

export const FilteredBuzzReducer: undefined | any = (state = { filteredBuzz: [] }, action: any) => {
  switch (action.type) {
    case 'SET_FILTERED_BUZZ':
      return { filteredBuzz: action.payload };
    default:
      return { ...state };
  }
};
