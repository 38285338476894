import React, { useState } from 'react';
import type { ReactNode } from 'react';

import toast from 'react-hot-toast';

import { Button, GridBox, Text } from '@eltoro-ui/components';
import { Uploader } from 'Components/Uploader';

import {
  UploadAreaLayout,
  UploadTypes,
} from 'Pages/CampaignCreation/components/CampaignCreatives/_components/UploadAreaLayout';

import creativePlaceholders from 'Pages/CampaignCreation/components/CampaignCreatives/_components/UploadBannerModal/CreativePlaceholders.json';

import { CreativeTypeEnum } from 'enums';
import type { FileUploadType } from 'types';

import cross from 'assets/Images/cross.png';

import './UploadBannerModal.scss';

type UploadBannerModalProps = {
  onClickOutside: () => void;
  onSubmit: (files: FileUploadType, type?: CreativeTypeEnum) => void;
  typeModal: CreativeTypeEnum.BANNER;
  loading?: boolean;
  okText?: string | ReactNode;
};

export const UploadBannerModal = ({
  onClickOutside,
  onSubmit,
  typeModal,
  loading,
  okText = 'Confirm',
}: UploadBannerModalProps) => {
  const [fileSubmited, setfileSubmited] = useState<any>();
  return (
    <div className="UploadBannerModal">
      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <img
          src={cross}
          onClick={onClickOutside}
          alt="cross"
          className="PersonalInfoForm__cross"
          role="presentation"
        />
      </div>
      <GridBox justifyItems="flex-start" gap="0.5rem">
        <Text tag="div" on="white" size="xxl" UNSAFE_className="UploadMailerModal__step-subheading">
          Upload Your Banner
        </Text>
      </GridBox>
      <div className="UploadBannerModal__hl" />
      <GridBox gap="0.5rem">
        <Text
          tag="div"
          on="white"
          size="xxl"
          UNSAFE_style={{ marginBottom: '20px' }}
          UNSAFE_className="UploadMailerModal__step-subheading"
        >
          Consider Using Multiple Banner Sizes
        </Text>
        <GridBox UNSAFE_className="UploadMailerModal__grid_box">
          {creativePlaceholders.map(creative => (
            <GridBox
              key={creative.id}
              gridTemplateColumns="min-content auto"
              alignItems="center"
              gap="1rem"
              UNSAFE_className="gridSizing"
            >
              <div
                className="UploadBannerModal__creative-placeholder"
                style={{ height: creative.height, width: creative.width }}
              />
              <Text on="white">
                <b>{creative.actualDimensions}</b>
                {creative.text}
                {!!creative.textSecondary && <br />}
                {!!creative.textSecondary && creative.textSecondary}
              </Text>
            </GridBox>
          ))}
        </GridBox>
      </GridBox>
      <div className="UploadBannerModal__hl" />
      <GridBox justifyItems="flex-start" gap="0.5rem">
        <Text
          tag="div"
          on="white"
          size="xxl"
          UNSAFE_style={{ marginBottom: '20px' }}
          UNSAFE_className="UploadMailerModal__step-subheading"
        >
          Upload Your Banner Creatives
        </Text>
      </GridBox>
      <div className="UploadBannerModal__upload_area_layout">
        <Uploader
          type="banner"
          fileSubmited={fileSubmited}
          onDropFile={(files: FileUploadType) => setfileSubmited(files)}
        >
          <UploadAreaLayout uploadType={UploadTypes.banner} />
        </Uploader>
      </div>
      <div className="UploadBannerModal__footer">
        <Button kind="default" size="l" weight="bold" onClick={onClickOutside}>
          Cancel
        </Button>
        <Button
          kind="primary"
          size="l"
          weight="bold"
          disabled={loading || !fileSubmited?.acceptedFiles?.length}
          loading={loading}
          onClick={async () => {
            if (fileSubmited?.acceptedFiles?.length > 0) {
              await onSubmit(fileSubmited, typeModal);
              await onClickOutside();
            } else {
              toast.error('Please add a banner creatives');
            }
          }}
        >
          {okText}
        </Button>
      </div>
    </div>
  );
};
