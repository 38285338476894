import React from 'react';
import { FlexBox, Text } from '@eltoro-ui/components';
import { SpinnerLoader } from 'Components/SpinnerLoader';

interface Props {
  text: string;
}

const Processing = ({ text }: Props) => {
  return (
    <FlexBox justifyContent="space-around" alignItems="center" UNSAFE_className="processing">
      <SpinnerLoader
        style={{
          position: 'unset',
          width: '1.5rem',
          height: '1.5rem',
          borderWidth: '0.23em',
          color: '#007B94',
          marginRight: '10px',
        }}
      />
      <Text on="white">{text}</Text>
    </FlexBox>
  );
};

export default Processing;
