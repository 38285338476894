import React from 'react';
import { useRecoilState } from 'recoil';
import { Spacer } from '@eltoro-ui/components';
import {
  CollapseSection,
  ColorSelect,
  HorizontalLabel,
  MoreInfoContainer,
  TypographySettings,
} from '../../..';
import { getDefaultTextValue } from '../../../../../Helpers';
import {
  blockFontFamilySelector,
  blockFontSizeSelector,
  blockFontStyleSelector,
  blockTextAlignSelector,
  blockTextColorSelector,
  blockTextDecorationSelector,
} from '../../../../../State';
import { BlocksConfigType } from '../../../../../Typings';
import { ResetSettings } from '../ResetSettings';

export const EditTextContents: React.FC<{
  label: string;
  config: BlocksConfigType;
}> = ({ label, config }) => {
  const { kind, key } = config;
  const [textColor, setTextColor] = useRecoilState(blockTextColorSelector(config));

  const [fontSize, setFontSize] = useRecoilState(blockFontSizeSelector(config));
  const [align, setAlign] = useRecoilState(blockTextAlignSelector(config));
  const [fontStyle, setFontStyle] = useRecoilState(blockFontStyleSelector(config));
  const [fontFamily, setFontFamily] = useRecoilState(blockFontFamilySelector(config));
  const [textDecoration, setTextDecoration] = useRecoilState(blockTextDecorationSelector(config));
  const defaultFontSize = getDefaultTextValue(kind, key, 'fontSize');
  const defaultAlign = getDefaultTextValue(kind, key, 'textAlign');
  const defaultFontStyle = getDefaultTextValue(kind, key, 'fontStyle');
  const defaultFontFamily = getDefaultTextValue(kind, key, 'fontFamily');
  const defaultTextDecoration = getDefaultTextValue(kind, key, 'textDecoration', '');
  const defaultColor = getDefaultTextValue(kind, key, 'color');

  const showTypographyReset = !(
    fontSize.value === defaultFontSize &&
    align.value === defaultAlign &&
    fontStyle.value === defaultFontStyle &&
    fontFamily.value === defaultFontFamily &&
    textDecoration.value === defaultTextDecoration
  );

  const handleTypographyReset = () => {
    setFontSize({ value: defaultFontSize, error: false });
    setAlign({ value: defaultAlign, error: false });
    setFontStyle({ value: defaultFontStyle, error: false });
    setFontFamily({ value: defaultFontFamily, error: false });
    setTextDecoration({ value: defaultTextDecoration, error: false });
  };

  const showColorReset = textColor.value !== defaultColor;

  const handleColorReset = () => {
    setTextColor({ value: defaultColor, error: false });
  };

  return (
    <>
      <CollapseSection label={label}>
        <MoreInfoContainer
          label="Typography"
          showReset={showTypographyReset}
          onReset={handleTypographyReset}
        >
          <TypographySettings config={config} />
        </MoreInfoContainer>
        <Spacer height=".5rem" />
        <HorizontalLabel label="Text Color">
          <ResetSettings showReset={showColorReset || textColor.error} onReset={handleColorReset}>
            <ColorSelect
              color={textColor.value}
              onChange={newColor => setTextColor({ ...textColor, value: newColor.hex })}
              error={textColor.error}
            />
          </ResetSettings>
        </HorizontalLabel>
      </CollapseSection>
      <Spacer />
    </>
  );
};
