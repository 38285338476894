import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import { blocksBase, defaultArtboards } from '../Data';
import { createBlockPosDims } from '../Helpers';
import { ArtboardBlocksType, AvailableBlocksType, DimensionsType, PositionType } from '../Typings';
import { creativeTypeAtom, directMailSizeAtom } from './state';

// all blocks on all artboards
export const blocksAtom = atom<ArtboardBlocksType[]>({
  key: 'blocks',
  default: [],
});

// filters blocks by artboard
export const artboardBlocksSelector = selectorFamily({
  key: 'artboardBlocks',
  get:
    id =>
    ({ get }) => {
      const blocks = get(blocksAtom);
      return blocks.filter(block => block.artboardName === id);
    },
});

// removes selected blocks of kind
export const removeBlocksOfKindSelector = selector({
  key: 'removeBlocksOfKind',
  get: ({ get }) => {
    return get(blocksAtom);
  },
  set: ({ get, set }) => {
    const blocks = get(blocksAtom);
    const selectedBlockIds = get(selectedBlockIdsAtom);
    const filteredBlocks = blocks.filter(block => !selectedBlockIds.includes(block.id));
    set(blocksAtom, filteredBlocks);
  },
});

// get all blocks of the current creative type (banner | directMail)
export const allBlocksOfCreativeTypeSelector = selector({
  key: 'allBlocksOfCreativeType',
  get: ({ get }) => {
    const blocks = get(blocksAtom);
    const creativeType = get(creativeTypeAtom);
    const artboardsOfCreativeType = defaultArtboards.filter(
      artboard => artboard.type === creativeType
    );
    return blocks.reduce((acc: ArtboardBlocksType[], block) => {
      const blockExistsOnCreativeTypeArtboard = artboardsOfCreativeType.some(
        artboard => artboard.id === block.artboardName
      );
      if (blockExistsOnCreativeTypeArtboard) {
        return [...acc, block];
      }
      return acc;
    }, []);
  },
});

// lists block kinds that are on an artboard
export const appliedBlockKindsSelector = selector({
  key: 'appliedBlockKinds',
  get: ({ get }) => {
    const blocks = get(allBlocksOfCreativeTypeSelector);
    return blocks.reduce((acc: { kind: AvailableBlocksType; label: string }[], block) => {
      if (acc.some(a => a.kind === block.kind)) return acc;
      const kindAndLabel = {
        kind: block.kind,
        label: blocksBase[block.kind].label,
      };
      return [...acc, kindAndLabel];
    }, []);
  },
});

// a list of all blocks currently selected for editing
export const selectedBlockIdsAtom = atom<string[]>({
  key: 'selectedBlockIds',
  default: [],
});

// the kind of blocks selected
export const selectedKindAtom = atom<AvailableBlocksType | undefined>({
  key: 'selectedKind',
  default: undefined,
});

export const visibleBlockAtom = atomFamily({
  key: 'visibleBlock',
  default: true,
});

export const lockedBlockAtom = atomFamily({
  key: 'lockedBlock',
  default: false,
});

// tracks the position and size of a block
export const positionAndDimensionsBlockAtom = atomFamily<
  PositionType & DimensionsType,
  { kind: AvailableBlocksType; artboardName: string }
>({
  key: 'posAndDimsBlock',
  default: config => {
    const [currentArtboard] = defaultArtboards.filter(
      artboard => artboard.id === config.artboardName
    );
    const { width, height } = currentArtboard;
    return createBlockPosDims(
      { width, height },
      blocksBase[config.kind].minMaxWidth,
      blocksBase[config.kind].minMaxHeight
    );
  },
});

// get all blocks of kind belonging to a creativeType (banner | directMail)
export const allBlocksOfKindSelector = selector({
  key: 'allBlocksOfKind',
  get: ({ get }) => {
    const selectedKind = get(selectedKindAtom);
    const creativeType = get(creativeTypeAtom);
    const directMailSize = get(directMailSizeAtom);
    const creativeTypeBlocks = get(allBlocksOfCreativeTypeSelector);
    const blocksOfKind =
      selectedKind && creativeTypeBlocks.filter(block => block.kind === selectedKind);
    if (creativeType === 'directMail') {
      return blocksOfKind?.filter(
        block => directMailSize && block.artboardName.includes(directMailSize)
      );
    }
    return blocksOfKind;
  },
});

// get block types used on all artboards of specific creativeType (banner | directMail)
export const artboardBlockTypesSelector = selector({
  key: 'artboardBlockTypes',
  get: ({ get }) => {
    const creativeTypeBlocks = get(allBlocksOfCreativeTypeSelector);
    const blockKinds = creativeTypeBlocks.map(block => block.kind);
    return Array.from(new Set(blockKinds));
  },
});

// the mainDimension can be set from within a Block if it needs specific dimensions beyond...
// ...what is supplied by positionAndDimensionsBlockAtom
// See ActionButton for an example
export const mainDimensionsBlockAtom = atomFamily({
  key: 'mainDimensionsBlock',
  default: {
    width: 0,
    height: 0,
  },
});
