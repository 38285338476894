import { selector, selectorFamily } from 'recoil';
import { defaultArtboards, directMailArtboardGap, directMailInches } from '../Data';
import { capitalize } from '../Helpers';
import { creativeTypeAtom, directMailSizeAtom } from './state';

// returns filtered artboards by type and size (if direct mail)
export const selectedArtboardsSelector = selector({
  key: 'selectArtboardsSelector',
  get: ({ get }) => {
    const creativeType = get(creativeTypeAtom);
    const directMailSize = get(directMailSizeAtom);
    if (creativeType === 'banner') {
      return defaultArtboards.filter(artboard => artboard.type === 'banner');
    }
    const directMailSizeArtboards = defaultArtboards
      .filter(artboard => artboard.type === 'directMail')
      .filter(artboard => artboard.size === directMailSize);
    return directMailSizeArtboards;
  },
});

// returns appropriate group dims based on creative type and directmail size selected
export const artboardGroupDimensionsSelector = selector({
  key: 'artboardGroupDimensions',
  get: ({ get }) => {
    const creativeType = get(creativeTypeAtom);
    if (creativeType === 'banner')
      // this return has some magic numbers. It would be better if this was computed somehow..
      // ...to allow different banner sizes to be added/removed
      return {
        width: 840,
        height: 770,
      };
    const [selectedDirectMail] = get(selectedArtboardsSelector);
    if (!selectedDirectMail) {
      return {
        width: 0,
        height: 0,
      };
    }
    return {
      width: selectedDirectMail.width * 2 + directMailArtboardGap,
      height: selectedDirectMail.height,
    };
  },
});

// returns an artboard label
// if directmail, label includes Inch dims, size (small, medium, etc), and side (front/back)
export const artboardLabelSelector = selectorFamily({
  key: 'artboardLabel',
  get:
    artboardName =>
    ({ get }) => {
      const creativeType = get(creativeTypeAtom);
      const directMailSize = get(directMailSizeAtom);
      const [currentArtboard] = defaultArtboards.filter(artboard => artboard.id === artboardName);
      const { width, height, side } = currentArtboard;
      const label = () => {
        if (creativeType === 'banner') {
          return `${width}x${height}`;
        }
        if (creativeType === 'directMail') {
          return directMailSize
            ? `${directMailInches[directMailSize].width}" x ${
                directMailInches[directMailSize].height
              }" (${capitalize(directMailSize)}) - ${side && capitalize(side)}`
            : '';
        }
        return '';
      };
      return label();
    },
});
