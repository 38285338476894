import { CollapsibleContainer, FlexBox, Spacer } from '@eltoro-ui/components';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import './CollapseSection.scss';

export const CollapseSection: React.FC<{ label: string }> = ({ label, children }) => {
  const [open, setOpen] = useState(true);
  return (
    <div className="CollapseSection">
      <button onClick={() => setOpen(!open)} className="CollapseSection__trigger" type="button">
        <FlexBox justifyContent="space-between">
          {label}
          <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
        </FlexBox>
      </button>
      <CollapsibleContainer isOpen={open}>
        <div className="CollapseSection__body">
          <Spacer height=".5rem" />
          {children}
        </div>
      </CollapsibleContainer>
    </div>
  );
};
