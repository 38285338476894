import React, { forwardRef } from 'react';
import type { ReactNode, ForwardedRef, CSSProperties } from 'react';

import classNames from 'classnames';

import './MaxHeightContainer.scss';

type MaxHeightContainerType = {
  header?: ReactNode;
  footer?: ReactNode | null;
  children: ReactNode;
  fullHeight?: boolean;
  overFlow?: string;
  bottom?: Boolean;
  headerAbsolute?: Boolean;
  UNSAFE_CLASSNAME?: string;
  UNSAFE_style?: CSSProperties;
};

export const MaxHeightContainer = forwardRef(
  (
    {
      header,
      footer,
      fullHeight = false,
      children,
      overFlow,
      bottom,
      UNSAFE_CLASSNAME,
      UNSAFE_style,
      headerAbsolute,
    }: MaxHeightContainerType,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div
        ref={ref}
        className={classNames(
          'MaxHeightContainer',
          fullHeight && 'MaxHeightContainer--full-height'
        )}
        style={UNSAFE_style}
      >
        {header && (
          <div
            className="MaxHeightContainer__header"
            style={{ zIndex: headerAbsolute ? 0 : 'inherit' }}
          >
            {header}
          </div>
        )}
        <div className={classNames('MaxHeightContainer__content', UNSAFE_CLASSNAME)}>
          {children}
        </div>
        {footer && <div className="MaxHeightContainer__footer">{footer}</div>}
      </div>
    );
  }
);
