/* eslint-disable */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import toast from 'react-hot-toast';
import { PhoneNumberUtil } from 'google-libphonenumber';

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useForm } from '@eltoro-ui/hooks';

import { Button, Form, Text } from '@eltoro-ui/components';
import { ErrorMsg, Tooltip, Spinner } from 'Components';
import { LabelInputField } from 'Components/LabelInputField';
import { LabelInputSelect } from 'Components/LabelInputSelect';

import { createChargebeeUser, userUpdate } from 'Requests';
import { setSpinner } from 'Redux/actions';

import type { TRootState, UserInfoType, ErrorResponse, FieldErrors } from 'types';

import Warning from 'Images/warning.png';
import cross from 'assets/Images/cross.png';

import USStates from 'Utils/us_states.json';

import './PersonalInfoForm.scss';

export const PersonalInfoForm = ({
  onSubmit,
  user,
}: {
  onSubmit: (value: UserInfoType['personalInfo']) => void;
  user: UserInfoType;
}) => {
  const dispatch = useDispatch();
  const phoneUtil = PhoneNumberUtil.getInstance();

  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [isValidPrimaryZipCode, setIsValidPrimaryZipCode] = useState(true);
  const [isValidZipCode, setIsValidZipCode] = useState(true);

  const required = [
    'first_name',
    'last_name',
    'mls_id',
    'mls_registered_name',
    'phone_number',
    'brokerage',
    'email',
    'primary_zip_code',
    'state',
    'street',
    'city',
    'billing_zip_code',
  ];
  const [isSubmit, setIsSubmit] = useState(false);

  // --------- Personal Information ---------
  const [firstName, setFistName] = useState(
    user.personalInfo.first_name ? user.personalInfo.first_name : ''
  );
  const [lastName, setLastName] = useState(
    user.personalInfo.last_name ? user.personalInfo.last_name : ''
  );
  const [mlsRegisteredName, setMlsRegisteredName] = useState<string>(
    user.personalInfo.mls_registered_name || ''
  );
  const [mlsAgentID, setMlsAgentID] = useState<string>(user.personalInfo.mls_id || '');
  const [email] = useState<string>(
    user.personalInfo.email || localStorage.getItem('userMail') || ''
  );
  const [primaryZipCode, setPrimaryZipCode] = useState<string>(
    user.personalInfo.primary_zip_code || ''
  );
  const [brokerage, setBrokerage] = useState<string>(user.personalInfo.brokerage || '');
  const [phoneNumber, setPhoneNumber] = useState<string>(user.personalInfo.phone_number || '');

  // ------------ Billing Information -------------

  const [street, setStreet] = useState(user.personalInfo ? user.personalInfo.street : '');
  const [city, setCity] = useState(user.personalInfo.last_name ? user.personalInfo.city : '');

  const [usState, setUsState] = useState(user.personalInfo.state ? user.personalInfo.state : '');
  const [zipCode, setZipCode] = useState(
    user.personalInfo.billing_zip_code ? user.personalInfo.billing_zip_code : ''
  );

  const [errors, setErrors] = useState<FieldErrors<UserInfoType['personalInfo']>>({});

  const { formData, touched, isValidForm } = useForm<UserInfoType['personalInfo']>({
    brokerage,
    first_name: firstName,
    last_name: lastName,
    mls_registered_name: mlsRegisteredName,
    mls_id: mlsAgentID,
    phone_number: phoneNumber,
    email: email,
    primary_zip_code: primaryZipCode,
    street,
    city,
    state: usState,
    billing_zip_code: zipCode,
  });
  const { validForm, missingFields } = isValidForm([...required]);
  const validateZipCode = (zipCode: string) => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode);

  const [resErrorPhoneNumber, setResErrorPhoneNumber] = useState('');

  const { spinner } = useSelector((state: TRootState) => state.SpinnerReducer);

  const verifySubmit = async (data: UserInfoType['personalInfo']) => {
    const number = phoneUtil.parseAndKeepRawInput(
      phoneNumber.length >= 1
        ? phoneNumber
        : user.personalInfo.phone_number.length >= 1
        ? user.personalInfo.phone_number
        : '',
      'US'
    );

    const postalCode =
      primaryZipCode.length >= 1
        ? primaryZipCode
        : user.personalInfo.primary_zip_code.length >= 1
        ? user.personalInfo.primary_zip_code
        : '';

    const billingZipCode =
      zipCode.length >= 1
        ? zipCode
        : user.personalInfo.billing_zip_code.length >= 1
        ? user.personalInfo.billing_zip_code
        : '';

    if (
      (firstName.length >= 1 || user.personalInfo.first_name.length >= 1) &&
      (lastName.length >= 1 || user.personalInfo.last_name.length >= 1) &&
      (mlsRegisteredName.length >= 1 || user.personalInfo.mls_registered_name.length >= 1) &&
      (mlsAgentID.length >= 1 || user.personalInfo.mls_id.length >= 1) &&
      (brokerage.length >= 1 || user.personalInfo.brokerage.length >= 1) &&
      (street.length >= 1 || user.personalInfo.street.length >= 1) &&
      (usState.length >= 1 || user.personalInfo.state.length >= 1) &&
      (city.length >= 1 || user.personalInfo.city.length >= 1) &&
      validateZipCode(postalCode) &&
      validateZipCode(billingZipCode) &&
      phoneUtil.isValidNumberForRegion(number, 'US') &&
      !Object.keys(errors).length
    ) {
      dispatch(setSpinner(true));
      userUpdate(data)
        .then(res => {
          const schema = {
            id: res.data?.id,
            first_name: res.data?.first_name,
            last_name: res.data?.last_name,
            mls_registered_name: res.data?.mls_registered_name,
            email: res.data?.email,
          };

          createChargebeeUser(schema).catch(err => {
            toast.error(err.detail);
          });
          onSubmit(data);
          dispatch(setSpinner(false));
        })
        .catch((err: ErrorResponse<UserInfoType['personalInfo']>) => {
          dispatch(setSpinner(false));
          if (Array.isArray(err.detail))
            err.detail.map(error =>
              setErrors(errors => ({
                ...errors,
                [error.loc[1]]: {
                  type: error.type,
                  message: error.msg,
                },
              }))
            );
          else toast.error(err.detail);
        });
    } else {
      setIsSubmit(true);
    }
  };

  const closedSection = () => {
    window.localStorage.clear();
    location.reload();
  };
  const ErrorToolbar = () => {
    return (
      <div style={{ position: 'relative' }}>
        <Tooltip
          text="Please fill the input"
          topAbsolute="-80px"
          rightAbsolute="-43px"
          padding="5px 5px"
        />
      </div>
    );
  };

  return (
    <div className="PersonalInfoForm" style={{ position: 'relative', overflow: 'hidden' }}>
      <img src={cross} onClick={closedSection} alt="cross" className="PersonalInfoForm__cross" />
      <>
        {spinner && <Spinner />}
        <div className="PersonalInfoForm__description">
          <div className="PersonalInfoForm__header">
            <Text on="white" UNSAFE_className="heading">
              General Info
            </Text>
            <Text on="grey-500" UNSAFE_className="sub_heading">
              We’re here to get you the most tailored experience. All we need from you is to answer
              a couple of simple questions.
            </Text>
          </div>
        </div>
        <div className="PersonalInfoForm__inputs">
          <Form
            onSubmit={() => {
              verifySubmit(formData);
            }}
            required={required}
            valid={validForm && !Object.keys(errors).length}
            missingFields={missingFields}
            touched={touched}
          >
            <div className="form-section">
              <span className="form-section-title">Personal Information</span>
              <div className="form-section-content">
                <div className="inputs-wrapper">
                  <div>
                    {/* First Name  */}
                    <div className="input-wrapper">
                      <LabelInputField
                        style={{ paddingLeft: '22px' }}
                        lableStyle={{ left: '14px' }}
                        requireTage="*"
                        value={firstName}
                        title="First Name"
                        placeholder="Enter your first name"
                        isError={isSubmit && firstName.length <= 1}
                        type="text"
                        iconRight={
                          isSubmit && firstName.length <= 1 ? (
                            <img
                              alt="icon"
                              className="start_icon"
                              style={{ marginTop: '7px', width: '24px' }}
                              src={Warning}
                            />
                          ) : null
                        }
                        onChange={e => {
                          if (errors?.first_name)
                            setErrors(({ first_name, ...errors }) => ({ ...errors }));
                          setFistName(e.target.value);
                        }}
                      />
                      {isSubmit && firstName.length === 0 ? <ErrorToolbar /> : ''}
                      {errors?.first_name?.message ? (
                        <ErrorMsg
                          title={errors?.first_name?.message ?? 'Please enter your first name'}
                          icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                        />
                      ) : null}
                    </div>

                    {/* MLS Registered Name  */}
                    <div className="input-wrapper">
                      <LabelInputField
                        style={{ paddingLeft: '22px' }}
                        lableStyle={{ left: '14px' }}
                        requireTage="*"
                        value={mlsRegisteredName}
                        title="Your MLS Registered Name"
                        placeholder="Enter your MLS registered name"
                        isError={isSubmit && mlsRegisteredName.length <= 1}
                        type="text"
                        iconRight={
                          isSubmit && mlsRegisteredName.length <= 1 ? (
                            <img
                              alt="icon"
                              className="start_icon"
                              style={{ marginTop: '7px', width: '24px' }}
                              src={Warning}
                            />
                          ) : null
                        }
                        onChange={e => {
                          if (errors?.mls_registered_name)
                            setErrors(({ mls_registered_name, ...errors }) => ({ ...errors }));
                          setMlsRegisteredName(e.target.value);
                        }}
                      />
                      {isSubmit && mlsRegisteredName.length === 0 ? <ErrorToolbar /> : ''}
                      {errors?.mls_registered_name?.message ? (
                        <ErrorMsg
                          title={
                            errors?.mls_registered_name?.message ??
                            'Please enter right your MLS registered name'
                          }
                          icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                        />
                      ) : null}
                    </div>

                    {/* MLS Agent ID  */}
                    <div className="input-wrapper">
                      <LabelInputField
                        requireTage="*"
                        style={{ paddingLeft: '22px' }}
                        lableStyle={{ left: '14px' }}
                        value={mlsAgentID}
                        title="Your MLS Agent ID"
                        placeholder="e.g. 1223456789"
                        isError={isSubmit && !mlsAgentID}
                        type="text"
                        iconRight={
                          isSubmit && !mlsAgentID ? (
                            <img
                              alt="icon"
                              className="start_icon"
                              style={{ marginTop: '7px', width: '24px' }}
                              src={Warning}
                            />
                          ) : null
                        }
                        onChange={e => {
                          if (errors?.mls_id) setErrors(({ mls_id, ...errors }) => ({ ...errors }));
                          setMlsAgentID(e.target.value);
                        }}
                      />
                      {isSubmit && mlsAgentID.length < 1 ? <ErrorToolbar /> : ''}
                      {errors?.mls_id?.message ? (
                        <ErrorMsg
                          title={errors?.mls_id?.message ?? 'Please enter right your MLS agent ID'}
                          icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                        />
                      ) : null}
                    </div>

                    {/* Work Email  */}
                    <div className="input-wrapper">
                      <LabelInputField
                        requireTage="*"
                        style={{ paddingLeft: '22px' }}
                        lableStyle={{ left: '14px' }}
                        value={user.personalInfo.email ? user.personalInfo.email : email}
                        title="Work Email"
                        placeholder="Work Email"
                        isError={isSubmit && email.length < 1}
                        disabled={true}
                        type="text"
                      />
                      {isSubmit && email.length < 1 ? <ErrorToolbar /> : ''}
                    </div>
                  </div>

                  <div>
                    {/* Last Name  */}
                    <div className="input-wrapper">
                      <LabelInputField
                        style={{ paddingLeft: '22px' }}
                        lableStyle={{ left: '14px' }}
                        requireTage="*"
                        value={lastName}
                        title="Last Name"
                        placeholder="Enter your last name"
                        isError={isSubmit && lastName.length <= 1}
                        type="text"
                        iconRight={
                          isSubmit && lastName.length <= 1 ? (
                            <img
                              alt="icon"
                              className="start_icon"
                              style={{ marginTop: '7px', width: '24px' }}
                              src={Warning}
                            />
                          ) : null
                        }
                        onChange={e => {
                          if (errors?.last_name)
                            setErrors(({ last_name, ...errors }) => ({ ...errors }));
                          setLastName(e.target.value);
                        }}
                      />
                      {isSubmit && lastName.length === 0 ? <ErrorToolbar /> : ''}
                      {errors?.last_name?.message ? (
                        <ErrorMsg
                          title={errors?.mls_id?.message ?? 'Please enter your last name'}
                          icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                        />
                      ) : null}
                    </div>
                    {/* Zip Code  */}
                    <div className="input-wrapper">
                      <LabelInputField
                        requireTage="*"
                        style={{ paddingLeft: '22px' }}
                        lableStyle={{ left: '14px' }}
                        value={primaryZipCode}
                        title="Primary Zip Code"
                        placeholder="e.g. 12345"
                        isError={isSubmit && !primaryZipCode}
                        type="text"
                        iconRight={
                          isSubmit && !primaryZipCode ? (
                            <img
                              alt="icon"
                              className="start_icon"
                              style={{ marginTop: '7px', width: '24px' }}
                              src={Warning}
                            />
                          ) : null
                        }
                        onChange={e => {
                          if (errors?.primary_zip_code)
                            setErrors(({ primary_zip_code, ...errors }) => ({ ...errors }));
                          setPrimaryZipCode(e.target.value);
                          setIsValidPrimaryZipCode(validateZipCode(e.target.value));
                        }}
                      />
                      {isSubmit && primaryZipCode.length < 1 ? <ErrorToolbar /> : ''}
                      {errors?.primary_zip_code?.message ||
                      (!isValidPrimaryZipCode && primaryZipCode !== '') ? (
                        <ErrorMsg
                          title={errors?.primary_zip_code?.message ?? 'Invalid zip code!'}
                          icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                        />
                      ) : null}
                    </div>

                    {/* Brokerage  */}
                    <div className="input-wrapper">
                      <LabelInputField
                        requireTage="*"
                        style={{ paddingLeft: '22px' }}
                        lableStyle={{ left: '14px' }}
                        value={brokerage}
                        title="Your Brokerage"
                        placeholder="Enter your brokerage"
                        isError={isSubmit && !brokerage}
                        type="text"
                        iconRight={
                          isSubmit && !brokerage ? (
                            <img
                              alt="icon"
                              className="start_icon"
                              style={{ marginTop: '7px', width: '24px' }}
                              src={Warning}
                            />
                          ) : null
                        }
                        onChange={e => {
                          if (errors?.brokerage)
                            setErrors(({ brokerage, ...errors }) => ({ ...errors }));
                          setBrokerage(e.target.value);
                        }}
                      />
                      {isSubmit && brokerage.length < 1 ? <ErrorToolbar /> : ''}
                      {errors?.brokerage?.message ? (
                        <ErrorMsg
                          title={errors?.brokerage?.message ?? 'Enter your brokerage'}
                          icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                        />
                      ) : null}
                    </div>

                    {/* Phone Number  */}
                    <div className="input-wrapper">
                      <LabelInputField
                        style={{ paddingLeft: '22px' }}
                        lableStyle={{ left: '14px' }}
                        requireTage="*"
                        value={phoneNumber}
                        title="Phone Number"
                        placeholder="e.g. 234567890987"
                        isError={isSubmit && phoneNumber.length < 1}
                        type="text"
                        iconRight={
                          isSubmit && phoneNumber.length < 1 ? (
                            <img
                              alt="icon"
                              className="start_icon"
                              style={{ marginTop: '7px', width: '24px' }}
                              src={Warning}
                            />
                          ) : null
                        }
                        onChange={e => {
                          if (errors?.phone_number)
                            setErrors(({ phone_number, ...errors }) => ({ ...errors }));
                          resErrorPhoneNumber && setResErrorPhoneNumber('');
                          const value = e.target.value.replace(/[^0-9() -]+/g, '');
                          setPhoneNumber(value);

                          if (value) {
                            try {
                              const number = phoneUtil.parseAndKeepRawInput(value, 'US');
                              setIsValidPhoneNumber(phoneUtil.isValidNumberForRegion(number, 'US'));
                            } catch (e) {
                              setIsValidPhoneNumber(false);
                            }
                          }
                        }}
                      />
                      {isSubmit && phoneNumber.length < 1 ? <ErrorToolbar /> : ''}
                      {errors?.phone_number?.message ||
                      (!isValidPhoneNumber && phoneNumber !== '') ? (
                        <ErrorMsg
                          title={errors?.phone_number?.message ?? 'Invalid phone number!'}
                          icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-section">
              <span className="form-section-title">Address</span>
              <div className="form-section-content">
                <div className="inputs-wrapper">
                  <div>
                    {/* Street */}
                    <div className="input-wrapper">
                      <LabelInputField
                        style={{ paddingLeft: '22px' }}
                        lableStyle={{ left: '14px' }}
                        requireTage="*"
                        value={street}
                        title="Street"
                        placeholder="Enter your street name"
                        isError={isSubmit && street.length <= 1}
                        type="text"
                        iconRight={
                          isSubmit && street.length <= 1 ? (
                            <img
                              alt="icon"
                              className="start_icon"
                              style={{ marginTop: '7px', width: '24px' }}
                              src={Warning}
                            />
                          ) : null
                        }
                        onChange={e => {
                          if (errors?.street) setErrors(({ street, ...errors }) => ({ ...errors }));
                          setStreet(e.target.value);
                        }}
                      />
                      {isSubmit && street.length === 0 ? <ErrorToolbar /> : ''}
                      {errors?.street?.message ? (
                        <ErrorMsg
                          title={errors?.street?.message ?? 'Please enter your street name'}
                          icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                        />
                      ) : null}
                    </div>

                    {/* State  */}
                    <div className="input-wrapper">
                      <LabelInputSelect
                        requireTage="*"
                        value={usState}
                        title="Choose State"
                        placeholder="Choose State"
                        onChange={event => setUsState(event.target.value)}
                        Options={
                          <>
                            {USStates.map(state => (
                              <React.Fragment key={state}>
                                <option value={state}>{state}</option>
                              </React.Fragment>
                            ))}
                          </>
                        }
                      />
                      {errors?.state?.message ? (
                        <ErrorMsg
                          title={errors?.state?.message ?? 'Please choose your state'}
                          icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                        />
                      ) : null}
                    </div>
                  </div>

                  <div>
                    {/* City  */}
                    <div className="input-wrapper">
                      <LabelInputField
                        style={{ paddingLeft: '22px' }}
                        lableStyle={{ left: '14px' }}
                        requireTage="*"
                        value={city}
                        title="City"
                        placeholder="Enter your city name"
                        isError={isSubmit && city.length <= 1}
                        type="text"
                        iconRight={
                          isSubmit && city.length <= 1 ? (
                            <img
                              alt="icon"
                              className="start_icon"
                              style={{ marginTop: '7px', width: '24px' }}
                              src={Warning}
                            />
                          ) : null
                        }
                        onChange={e => {
                          if (errors?.city) setErrors(({ city, ...errors }) => ({ ...errors }));
                          setCity(e.target.value);
                        }}
                      />
                      {isSubmit && city.length === 0 ? <ErrorToolbar /> : ''}
                      {errors?.city?.message ? (
                        <ErrorMsg
                          title={errors?.city?.message ?? 'Please enter your city name'}
                          icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                        />
                      ) : null}
                    </div>
                    {/* Zip Code  */}
                    <div className="input-wrapper">
                      <LabelInputField
                        requireTage="*"
                        style={{ paddingLeft: '22px' }}
                        lableStyle={{ left: '14px' }}
                        value={zipCode}
                        title="Zip Code"
                        placeholder="e.g. 45678"
                        isError={isSubmit && !zipCode}
                        type="text"
                        iconRight={
                          isSubmit && !zipCode ? (
                            <img
                              alt="icon"
                              className="start_icon"
                              style={{ marginTop: '7px', width: '24px' }}
                              src={Warning}
                            />
                          ) : null
                        }
                        onChange={e => {
                          if (errors?.billing_zip_code)
                            setErrors(({ billing_zip_code, ...errors }) => ({ ...errors }));
                          setZipCode(e.target.value);
                          setIsValidZipCode(validateZipCode(e.target.value));
                        }}
                      />
                      {isSubmit && zipCode.length < 1 ? <ErrorToolbar /> : ''}
                      {errors?.billing_zip_code?.message || (!isValidZipCode && zipCode !== '') ? (
                        <ErrorMsg
                          title={errors?.billing_zip_code?.message ?? 'Invalid zip code!'}
                          icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ paddingTop: '2rem' }}>
              <div className="PersonalInfoForm__submit">
                <Text on="white" UNSAFE_className="required-text">
                  <span style={{ color: '#BD0000' }}>*</span>required
                </Text>
                <Button
                  type="submit"
                  kind="primary"
                  size="l"
                  width="115px"
                  fonts="16px"
                  weight="bolder"
                  disabled={
                    !validForm ||
                    !isValidZipCode ||
                    !isValidPrimaryZipCode ||
                    !isValidPhoneNumber ||
                    Object.keys(errors).length
                  }
                >
                  Confirm
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </>
    </div>
  );
};
