import React, { forwardRef, useEffect, useMemo } from 'react';
import type { CSSProperties, ForwardedRef, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-solid-svg-icons';

import type { TRootState } from 'types';

import { setCenterLens, setSidebarLens } from 'Redux/actions';

import './LensSidebar.scss';

type LensSidebarProps = {
  children: ReactNode;
  root_UNSAFE_className?: string;
  UNSAFE_className?: string;
  UNSAFE_style?: CSSProperties;
};

export const LensSidebar = forwardRef(
  (
    { children, UNSAFE_className, UNSAFE_style, root_UNSAFE_className }: LensSidebarProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const Location = useLocation();

    const prospect = useMemo(() => Location?.pathname === '/prospects', [Location]);

    const { isCenter, openSideBar } = useSelector((state: TRootState) => state.centerLensReducer);
    const dispatch = useDispatch();
    const toggleSidebar = () => {
      dispatch(setSidebarLens(!openSideBar));
    };

    useEffect(() => {
      return () => {
        dispatch(setCenterLens(false));
      };
    }, []);

    return (
      <div
        ref={ref}
        className={classNames(
          prospect ? 'LensSidebar' : 'ProspectLensGridBar',
          isCenter && 'ProspectLensGridBarCenter',
          UNSAFE_className
        )}
        style={{
          boxShadow: openSideBar ? '' : 'none',
          background: openSideBar ? '' : 'none',
          ...UNSAFE_style,
        }}
      >
        <div
          className={classNames(root_UNSAFE_className, {
            'opened-side-bar': !openSideBar,
          })}
        >
          {children}
        </div>
        {!openSideBar && <FontAwesomeIcon icon={faBars} onClick={toggleSidebar} />}
      </div>
    );
  }
);
