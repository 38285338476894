/* eslint-disable react/self-closing-comp */
import { GridBox, Text } from '@eltoro-ui/components';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAbbreviatedNumber, getBuzz, getIconBuzzColor, hexToRgb, luminance } from 'Helpers';
import React, { useEffect, useState } from 'react';
import { getListingHistogram } from 'Requests';
import './CustomMapPopup.scss';

// @ts-ignore
const MarkerWrap = React.createRef(null);

export const CustomMapPopup: React.FC<{
  feature?: any;
  prospect?: boolean;
  type?: string;
}> = ({ feature, prospect = true, type }) => {
  const getTextHeaderColor = () => {
    // trim 'var(' & ')' from returned string
    const trimmedColor = getIconBuzzColor(feature).slice(4, -1);
    // get css var property and remove '#' from beginning
    const hexColor = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue(trimmedColor)
      .replace(/[#]/g, '');
    // convert hex to rgb and get luminance
    // useEffect(() => {
    // })

    const lumins = luminance(hexToRgb(hexColor));
    if (lumins > 0.6) {
      return 'black';
    }
    return 'white';
  };
  const Hist = [0.4, 0.2, 0, 0, 0, 0, 0];
  const max = feature?.buzz_history && Math.max(...feature.buzz_history);

  const [day, setDay] = useState('');
  const histogram = localStorage.getItem('Histogram');
  // @ts-ignore
  const [selectedVal, setSelectedVal] = useState(false);
  // @ts-ignore

  function markerHis() {
    const mls = localStorage.getItem('Seleceted_Marker');
    if (feature?.mls_id) {
      if (mls === feature?.mls_id) {
        setSelectedVal(true);
      }
    }
  }
  setInterval(markerHis, 500);
  const D = new Date();
  const currentDay: number = D.getDay();
  function Histogram() {
    getListingHistogram({ listing_id: feature.mls_id }).then((res: any) => {
      localStorage.setItem('Histogram', res.data);
    });
  }

  function days(index: number) {
    switch (index) {
      case 0:
        return 'S';
      case 1:
        return 'M';

      case 2:
        return 'T';

      case 3:
        return 'W';

      case 4:
        return 'T';

      case 5:
        return 'F';

      case 6:
        return 'S';

      default:
        return '';
    }
  }
  const color = getIconBuzzColor(feature);
  const buzz = getBuzz(feature);
  return (
    <div className="MapCustomPopup">
      <div
        className="MapCustomPopup__header"
        style={{
          // padding: prospect ? '1rem' : '0.8rem 0.8rem 0rem 0.8rem',
          // width: prospect ? '206px' : '',

          padding: '0.8rem 0.8rem 0.8rem 0.8rem',
          width: 'max-content',
        }}
      >
        {/* <b> {prospect && 'Weekly Buzz'}</b> */}
        <Text size="m" on="white" weight="bold">
          {' '}
          {!prospect && feature?.street}
        </Text>
        <br />
        {/* {prospect && <Text on="white">{feature?.street}</Text>} */}
        {!prospect && type !== 'Advertise To All In Market' && type !== undefined && (
          <Text UNSAFE_style={{ color: '#515A5C', marginLeft: '13px' }} size="s" on="grey-050">
            average score
          </Text>
        )}
      </div>

      <div style={{ display: 'flex' }}>
        <GridBox
          UNSAFE_style={
            {
              // padding: prospect ? '1rem' : '0.4rem 1rem 1rem 1rem',
              // padding: 0px,
            }
          }
          gridTemplateColumns="max-content"
          gap=".5rem"
        >
          {/* {prospect && (
            <>
              <Text on="white">
                <span>
                  <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />
                </span>
                Beds : {feature?.beds}
              </Text>
              <Text on="white">
                <span>
                  {' '}
                  <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />
                </span>
                Baths : {feature?.baths}
              </Text>
              <Text on="white">
                <span>
                  {' '}
                  <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />
                </span>
                Listing Price: $
                {feature?.listing_price &&
                  getAbbreviatedNumber(feature?.listing_price.toString())}
              </Text>
            </>
          )} */}

          {/* <div id={feature.mls_id}>It loads</div>
        {selectedVal ? '<div>' : 'Not Showing'} */}
          {feature.activity_score && (
            <div className="MapCustomPopup__buzzStatus" style={{ backgroundColor: color }}>
              {buzz}
            </div>
          )}
          {/* <Text on="white">
          Listed on <b>{new Date(feature.created).toLocaleDateString()}</b>
        </Text> */}
        </GridBox>
        {/* {prospect && (
          <div
            style={{
              display: 'flex',
              height: 'fit-content',
              position: 'relative',
            }}
          >
            {feature?.buzz_history?.map((item: number, index: number) => {
              // days(index)
              return (
                <div style={{ position: 'relative' }}>
                  <div
                    style={{
                      width: '20px',
                      margin: '0 10px',
                      backgroundColor:
                        currentDay === index ? '#F66233' : 'grey',
                      height: `${(item / max) * 10}rem`,
                      // position: 'absolute',
                      bottom: '0',
                      zIndex: 999,
                      marginTop: `${6 - (item / max) * 10}rem`,
                    }}
                  >
                    <p
                      style={{
                        position: 'absolute',
                        bottom: '-36px',
                        left: '15px',
                        color: currentDay === index ? '#F66233' : '',
                      }}
                    >
                      {' '}
                      {days(index)}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        )} */}
      </div>
    </div>
  );
};
