import { Button, Text } from '@eltoro-ui/components';
import { THomeTypeFilter } from 'types';
import React, { useEffect, useState } from 'react';
import './FindYourProspectFilters.scss';

export const HomeTypeFilter = ({
  modalOpen,
  setIsModalOpen,
  setHome,
  currentFilters,
  setIsClicked,
  isClicked,
}: THomeTypeFilter) => {
  const [homeTypeLocal, setHomeTypeLocal] = useState<{
    homeType: string | null;
  }>({ homeType: null });

  const onClickClearOrCancel = (type: string) => {
    setIsModalOpen({ ...modalOpen, homeType: false });
    if (type === 'Clear') {
      setHome({ ...currentFilters, homeType: null });
      setHomeTypeLocal({ ...homeTypeLocal, homeType: null });

      if (currentFilters?.homeType) {
        setIsClicked({ ...isClicked, isLoaded: false });
      }
    }
  };

  const clickOnSave = () => {
    setHome({ ...currentFilters, ...homeTypeLocal });
    // setHomeTypeLocal({ ...homeTypeLocal, ...homeTypeLocal })
    setIsModalOpen({ ...modalOpen, homeType: false });
    setIsClicked({ ...isClicked, isLoaded: false });
  };

  useEffect(() => {
    if (currentFilters.homeType) {
      setHomeTypeLocal({ homeType: currentFilters.homeType });
    }
  }, []);

  return (
    <div className="FindYourProspectFilters">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Text size="m" tag="div" on="grey-500" UNSAFE_className="title">
          Home Type
        </Text>
        <div className="title-divider" />
      </div>
      <br />
      <div className="FindYourProspectFilters__homeType">
        <div className="SellYourListingFaster__modalOptions">
          <Button
            UNSAFE_style={{
              fontSize: '12px',
              fontWeight: 600,
              fontFamily: 'Open Sans',
            }}
            UNSAFE_className={`FindYourProspectFilters__modalOption ${
              homeTypeLocal.homeType === 'Any' ? 'FindYourProspectFiltersActive' : ''
            }`}
            onClick={() => {
              setHomeTypeLocal({ ...homeTypeLocal, homeType: 'Any' });
            }}
          >
            Any
          </Button>
          <Button
            UNSAFE_style={{
              fontSize: '12px',
              fontWeight: 600,
              fontFamily: 'Open Sans',
            }}
            UNSAFE_className={`FindYourProspectFilters__modalOption ${
              homeTypeLocal.homeType === 'Single Family Residence'
                ? 'FindYourProspectFiltersActive'
                : ''
            }`}
            onClick={() => {
              setHomeTypeLocal({
                ...homeTypeLocal,
                homeType: 'Single Family Residence',
              });
            }}
          >
            Single Family Home
          </Button>
          <Button
            UNSAFE_style={{
              fontSize: '12px',
              fontWeight: 600,
              fontFamily: 'Open Sans',
            }}
            UNSAFE_className={`FindYourProspectFilters__modalOption ${
              homeTypeLocal.homeType === 'Condominium' ? 'FindYourProspectFiltersActive' : ''
            }`}
            onClick={() => {
              setHomeTypeLocal({ ...homeTypeLocal, homeType: 'Condominium' });
            }}
          >
            Condominium
          </Button>
          <Button
            UNSAFE_style={{
              fontSize: '12px',
              fontWeight: 600,
              fontFamily: 'Open Sans',
            }}
            UNSAFE_className={`FindYourProspectFilters__modalOption ${
              homeTypeLocal.homeType === 'Apartment' ? 'FindYourProspectFiltersActive' : ''
            }`}
            onClick={() => {
              setHomeTypeLocal({ ...homeTypeLocal, homeType: 'Apartment' });
            }}
          >
            Apartment
          </Button>
          <Button
            UNSAFE_style={{
              fontSize: '12px',
              fontWeight: 600,
              fontFamily: 'Open Sans',
              borderRadius: '0 10px 10px 0',
            }}
            UNSAFE_className={`FindYourProspectFilters__modalOption ${
              homeTypeLocal.homeType === '1/2 Duplex' ? 'FindYourProspectFiltersActive' : ''
            }`}
            onClick={() => {
              setHomeTypeLocal({ ...homeTypeLocal, homeType: '1/2 Duplex' });
            }}
          >
            Duplex
          </Button>
        </div>
      </div>
      <br />
      <hr />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button
          kind="default"
          size="l"
          UNSAFE_className="SellYourListingFaster__modalCancel"
          weight="bold"
          onClick={() => onClickClearOrCancel('Cancel')}
        >
          Cancel
        </Button>
        <Button
          kind="default"
          UNSAFE_className="SellYourListingFaster__modalSave"
          size="l"
          weight="bold"
          disabled={!homeTypeLocal?.homeType}
          onClick={clickOnSave}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
};
