import React from 'react';

import classNames from 'classnames';

import type { CampaignOrderlineType } from 'types';
import { OrderlineStatusEnum } from 'enums';

interface OrderlineStatusAccessorProps extends Pick<CampaignOrderlineType, 'status'> {}

const OrderlineStatusAccessor = ({ status }: OrderlineStatusAccessorProps) => {
  return (
    <span
      className={classNames('orderline-status', {
        review: status === OrderlineStatusEnum.IN_REVIEW,
        active: status === OrderlineStatusEnum.ACTIVE,
        approved: status === OrderlineStatusEnum.APPROVED,
        completed: status === OrderlineStatusEnum.COMPLETED,
        rejected: status === OrderlineStatusEnum.REJECTED,
      })}
    >
      {status}
    </span>
  );
};

export default OrderlineStatusAccessor;
