import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Collapsible from 'react-collapsible';

import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSortDown,
  faSortUp,
  faPlus,
  faUserCircle,
  faSearch,
  faColumns,
  faChartArea,
  faImages,
  faImage,
  faUsers,
  faCog,
  faLightbulb,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { faReceipt } from '@fortawesome/pro-solid-svg-icons';

import { HeaderItem, Avatar, HeaderLogo, Modal } from '@eltoro-ui/components';
import { ConfirmationModal } from 'Components';
import { VotingModal } from 'Components/VotingModal';
import { createBrowserHistory } from 'history';

import CreativesLibraryIcon from 'assets/icons/CreativeLibraryIcon';

import { useSidebarContext } from 'contexts/SidebarContext';

import type { TRootState } from 'types';

import logo from 'assets/Images/logo-update.svg';
import Charticons from 'assets/Images/charticons.png';
import { GuideEnum } from '../../enums';
import GuidePopoverSelector from '../GuidePopoverSelector';

import './SideNavigationBarForDesktop.scss';

export const SideNavigationBarForDesktop: FC<{
  onLogOut: () => void;
  isAdmin: boolean;
}> = ({ onLogOut, isAdmin }) => {
  const { campaignsDropdown, settingsDropdown, toggleDropdown } = useSidebarContext();
  const [dropdownIsActive, setDropdownIsActive] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [collapseToggle, setCollapseToggle] = useState(false);
  const [chartModal, setChartModal] = useState(false);
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [activeGuideType, setActiveGuideType] = useState<GuideEnum | null>(null);
  const { userData } = useSelector((state: TRootState) => state.userReducer);
  const quideBot = sessionStorage.getItem('guideBot');
  const { userRecomendations } = useSelector((state: TRootState) => state.recomendationsReducer);

  const history = createBrowserHistory();
  const Location = useLocation();
  const childItemRoutes = [
    '/campaign-dashboard',
    '/campaigns',
    '/saved-audiences',
    '/create-campaign',
    '/creatives-library',
    '/profile',
  ];
  const isActiveRoute = (route: string) => {
    let className = '';
    if (childItemRoutes.includes(route) && Location.pathname.includes(route)) {
      className = 'active_sidebar_icon_color';
    } else if (Location.pathname.includes(route)) {
      className = 'active_sidebar_icon';
    }
    return className;
  };

  const handleCloseRecomendations = () => {
    sessionStorage.setItem(
      'guideBot',
      JSON.stringify({
        isActive: false,
        type: activeGuideType,
      })
    );
    setActiveGuideType(null);
  };

  useEffect(() => {
    if (quideBot) {
      const guideBotData = JSON.parse(quideBot);
      setActiveGuideType(guideBotData.type);
    }
  }, [quideBot]);

  return (
    <div className="side_nav_bar_container_for_desktop">
      {collapseToggle && (
        <div
          className={classNames('shadow_container', {
            'sidebar-collapsed': collapseToggle,
          })}
          onClick={() => {
            setCollapseToggle(false);
          }}
          onKeyDown={() => {
            setCollapseToggle(false);
          }}
          role="none"
        />
      )}
      <div
        className={classNames('side_nav_bar_nested_container', {
          set_width: collapseToggle,
        })}
      >
        <div className="icon_container">
          <HeaderLogo
            onClick={() => {
              setCollapseToggle(!collapseToggle);
            }}
            logo={logo}
            to="#"
          />
        </div>

        <div>
          <ul className="unorder_list">
            <>
              <li className="list">
                <abbr title="Dashboard">
                  <HeaderItem
                    childrenClassName="bar_text"
                    iconClassname={
                      Location.pathname === '/'
                        ? 'active_sidebar_icon'
                        : isActiveRoute('/dashboard')
                    }
                    disabled={!userData?.is_active}
                    to={userData?.is_active ? '/dashboard' : null}
                    icon={<FontAwesomeIcon className="sidebar_icon" icon={faColumns} />}
                    onClick={() => {
                      toggleDropdown();
                    }}
                  >
                    Dashboard
                  </HeaderItem>
                </abbr>
              </li>

              <li className="list">
                <abbr title="My Sphere">
                  <HeaderItem
                    childrenClassName="bar_text"
                    iconClassname={isActiveRoute('/my-sphere')}
                    icon={<FontAwesomeIcon className="sidebar_icon" icon={faUserCircle} />}
                    disabled={!userData?.is_active}
                    to={userData?.is_active ? '/my-sphere' : null}
                    hasRecommendations={
                      activeGuideType === GuideEnum.CONTACTS ||
                      (userRecomendations?.contact_files.active === 0 &&
                        userRecomendations?.contact_files.pending === 0)
                    }
                    onClick={() => {
                      toggleDropdown();
                    }}
                  >
                    My Sphere
                  </HeaderItem>
                </abbr>
                <GuidePopoverSelector
                  isActive={
                    activeGuideType === GuideEnum.CONTACTS &&
                    quideBot &&
                    JSON.parse(quideBot).isActive
                  }
                  collapseToggle={collapseToggle}
                  type={GuideEnum.CONTACTS}
                  handleClose={handleCloseRecomendations}
                />
              </li>

              <li className="list">
                <abbr title="Prospects">
                  <HeaderItem
                    childrenClassName="bar_text"
                    iconClassname={isActiveRoute('/prospects')}
                    icon={<FontAwesomeIcon className="sidebar_icon" icon={faSearch} />}
                    to={userData?.is_active ? '/prospects' : null}
                    disabled={!userData?.is_active}
                    onClick={(): boolean => {
                      if (!userData?.is_active) return false;
                      toggleDropdown();
                      return true;
                    }}
                  >
                    Prospects
                  </HeaderItem>
                </abbr>
              </li>

              <li className="list">
                <abbr title="Advanced Reporting">
                  <HeaderItem
                    childrenClassName="bar_text"
                    iconClassname={isActiveRoute('#')}
                    icon={
                      <FontAwesomeIcon
                        className="sidebar_icon"
                        color="lightgray"
                        icon={faChartArea}
                      />
                    }
                    disabled={!userData?.is_active}
                    to={userData?.is_active ? '#' : null}
                    onClick={() => {
                      if (userData?.is_active) {
                        toggleDropdown();
                        setChartModal(true);
                      }
                    }}
                  >
                    Advanced Reporting
                  </HeaderItem>
                </abbr>
              </li>
              <li className="list">
                <abbr title="Recomendations">
                  <HeaderItem
                    childrenClassName="bar_text"
                    iconClassname={isActiveRoute('/recomendations')}
                    icon={<FontAwesomeIcon className="sidebar_icon" icon={faLightbulb} />}
                    disabled={!userData?.is_active}
                    to={userData?.is_active ? '/recomendations' : null}
                    hasRecommendations={
                      activeGuideType === GuideEnum.RECOMENDATIONS &&
                      !!quideBot &&
                      !JSON.parse(quideBot).isUpToDate &&
                      !history.location.pathname.includes('/recomendations')
                    }
                    onClick={() => toggleDropdown()}
                  >
                    Activity Alerts
                  </HeaderItem>
                </abbr>
                <GuidePopoverSelector
                  isActive={
                    activeGuideType === GuideEnum.RECOMENDATIONS &&
                    quideBot &&
                    !history.location.pathname.includes('/recomendations') &&
                    JSON.parse(quideBot).isActive
                  }
                  handleClose={handleCloseRecomendations}
                  collapseToggle={collapseToggle}
                  type={GuideEnum.RECOMENDATIONS}
                  recomendationType={quideBot ? JSON.parse(quideBot).msgType : undefined}
                  recomendationsCount={quideBot ? JSON.parse(quideBot).count : 0}
                  recomendationListingName={userRecomendations?.recommendations[0]?.name}
                  sellersCount={userRecomendations?.recommendations[0]?.seller_count}
                  buyersCount={userRecomendations?.recommendations[0]?.buyer_count}
                  locationData={{
                    state: userRecomendations?.recommendations[0]?.state,
                    city: userRecomendations?.recommendations[0]?.city,
                    street: userRecomendations?.recommendations[0]?.street,
                    zip: userRecomendations?.recommendations[0]?.zip,
                  }}
                />
              </li>
              {chartModal && (
                <Modal offClick={() => setChartModal(false)}>
                  <VotingModal
                    handleModel={() => setChartModal(false)}
                    icons={Charticons}
                    title="Advanced Reporting"
                    subTitle="View geographic statistics based on physical foot traffic and broker-level competition."
                  />
                </Modal>
              )}
              <div>
                <Collapsible
                  className={classNames(!collapseToggle && 'collapsed')}
                  openedClassName={classNames(
                    !collapseToggle && 'collapsed',
                    !collapseToggle && campaignsDropdown && 'active-dropdown'
                  )}
                  open={campaignsDropdown}
                  triggerDisabled={!userData?.is_active}
                  onTriggerOpening={() => {
                    setDropdownIsActive(!dropdownIsActive);
                    toggleDropdown('campaigns');
                  }}
                  onTriggerClosing={() => {
                    setDropdownIsActive(!dropdownIsActive);
                    toggleDropdown();
                  }}
                  trigger={
                    <li className="list">
                      <abbr title="My Campaigns">
                        <HeaderItem
                          childrenClassName="bar_text"
                          // UNSAFE_STYLE={!collapseToggle ? { marginLeft: '-11px' } : {}}
                          iconClassname="icon_group"
                          icon={
                            <>
                              <FontAwesomeIcon className="sidebar_icon" icon={faImages} />
                              <FontAwesomeIcon
                                className="sidebar_icon"
                                icon={campaignsDropdown ? faSortUp : faSortDown}
                              />
                            </>
                          }
                          to={null}
                          disabled={!userData?.is_active}
                        >
                          My Campaigns
                        </HeaderItem>
                      </abbr>
                    </li>
                  }
                >
                  <ul className={collapseToggle ? 'unorder_list_withOutPadding' : 'unorder_list'}>
                    <li className="list sub_item">
                      <abbr title="Campaign Dashboard">
                        <HeaderItem
                          childrenClassName="bar_text"
                          iconClassname={`${isActiveRoute(
                            '/campaign-dashboard'
                          )} active_sidebar_img box_shadow_none`}
                          icon={<FontAwesomeIcon className="sidebar_icon" icon={faReceipt} />}
                          to="/campaign-dashboard"
                        >
                          Campaign Dashboard
                        </HeaderItem>
                      </abbr>
                    </li>
                    <li className="list sub_item">
                      <abbr title="Campaigns">
                        <HeaderItem
                          childrenClassName="bar_text"
                          iconClassname={`${isActiveRoute('/campaigns')} box_shadow_none`}
                          icon={<FontAwesomeIcon className="sidebar_icon" icon={faImage} />}
                          to="/campaigns"
                        >
                          Campaigns
                        </HeaderItem>
                      </abbr>
                    </li>
                    <li className="list sub_item">
                      <abbr title="Saved Audience">
                        <HeaderItem
                          childrenClassName="bar_text"
                          iconClassname={`${isActiveRoute('/saved-audiences')} box_shadow_none`}
                          icon={<FontAwesomeIcon className="sidebar_icon" icon={faUsers} />}
                          to="/saved-audiences"
                        >
                          Saved Audience
                        </HeaderItem>
                      </abbr>
                    </li>
                    <li className="list sub_item">
                      <abbr title="Creatives Library">
                        <HeaderItem
                          childrenClassName="bar_text"
                          iconClassname={`${isActiveRoute('/creatives-library')} box_shadow_none`}
                          icon={<CreativesLibraryIcon className="sidebar_icon" />}
                          to="/creatives-library"
                        >
                          Creatives Library
                        </HeaderItem>
                      </abbr>
                    </li>
                    <li className="list sub_item">
                      <abbr title="New Campaign">
                        <HeaderItem
                          childrenClassName="bar_text"
                          iconClassname={`${isActiveRoute(
                            '/create-campaign/setup'
                          )} box_shadow_none`}
                          icon={<FontAwesomeIcon icon={faPlus} />}
                          to="?action=create-campaign"
                          replace
                        >
                          New Campaign
                        </HeaderItem>
                      </abbr>
                    </li>
                  </ul>
                </Collapsible>
              </div>
              <Collapsible
                className={classNames(!collapseToggle && 'collapsed')}
                openedClassName={classNames(
                  !collapseToggle && 'collapsed',
                  !collapseToggle && settingsDropdown && 'active-dropdown'
                )}
                open={settingsDropdown}
                onTriggerOpening={() => {
                  setDropdownIsActive(!dropdownIsActive);
                  toggleDropdown('settings');
                }}
                onTriggerClosing={() => {
                  setDropdownIsActive(!dropdownIsActive);
                  toggleDropdown();
                }}
                trigger={
                  <li className="list">
                    <abbr title="Settings">
                      <HeaderItem
                        childrenClassName="bar_text"
                        iconClassname="icon_group"
                        icon={
                          <>
                            <FontAwesomeIcon className="sidebar_icon" icon={faCog} />
                            <FontAwesomeIcon
                              className="sidebar_icon"
                              icon={settingsDropdown ? faSortUp : faSortDown}
                            />
                          </>
                        }
                        to={null}
                      >
                        Settings
                      </HeaderItem>
                    </abbr>
                  </li>
                }
              >
                <ul className={collapseToggle ? 'unorder_list_withOutPadding' : 'unorder_list'}>
                  <li className="list sub_item">
                    <abbr title="Profile">
                      <HeaderItem
                        childrenClassName="bar_text"
                        iconClassname={`${isActiveRoute('/profile')} box_shadow_none`}
                        disabled={!userData?.is_active}
                        to={userData?.is_active ? '/profile' : null}
                        icon={
                          userData?.profile_pic ? (
                            <Avatar
                              name={`${userData?.first_name} ${userData?.last_name}`}
                              rounded
                              size="s"
                              src={userData?.profile_pic}
                              UNSAFE_className="avatar"
                            />
                          ) : (
                            <FontAwesomeIcon className="sidebar_icon" icon={faUserCircle} />
                          )
                        }
                      >
                        Profile
                      </HeaderItem>
                    </abbr>
                  </li>

                  <li className="list sub_item">
                    <abbr title="Logout">
                      <HeaderItem
                        childrenClassName="bar_text"
                        iconClassname="box_shadow_none"
                        UNSAFE_CLASSNAME="box_shadow_none"
                        icon={<FontAwesomeIcon className="sidebar_icon" icon={faSignOutAlt} />}
                        onClick={() => setLogoutModal(true)}
                      >
                        Logout
                      </HeaderItem>
                    </abbr>
                  </li>
                </ul>
              </Collapsible>
              {logoutModal && (
                <ConfirmationModal
                  heading="Are you sure you want to logout?"
                  onSubmit={() => {
                    onLogOut();
                    setIsLoading(true);
                  }}
                  onCancel={() => setLogoutModal(false)}
                  loading={Boolean(isLoading)}
                />
              )}
            </>

            {/* {!isAdmin && ( */}
            {/*  <ul className="unorder_list"> */}
            {/*    <li className="list sub_item"> */}
            {/*      <abbr title="Logout"> */}
            {/*        <HeaderItem */}
            {/*          childrenClassName="bar_text" */}
            {/*          iconClassname="box_shadow_none" */}
            {/*          UNSAFE_CLASSNAME="box_shadow_none" */}
            {/*          icon={<FontAwesomeIcon className="sidebar_icon" icon={faSignOutAlt} />} */}
            {/*          onClick={onLogOut} */}
            {/*        > */}
            {/*          Logout */}
            {/*        </HeaderItem> */}
            {/*      </abbr> */}
            {/*    </li> */}
            {/*  </ul> */}
            {/* )} */}
          </ul>
        </div>
      </div>
    </div>
  );
};
