import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import Tooltip from 'rc-tooltip';

import classNames from 'classnames';
import toast from 'react-hot-toast';

import { Button, FlexBox, MaxHeightContainer, Modal, Table, Text } from '@eltoro-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye } from '@fortawesome/pro-regular-svg-icons';
import { faWalking } from '@fortawesome/pro-solid-svg-icons';

import CustomIcons from 'assets/icons';

import { Layout, Loading, PageHeader, Pagination, ProgressStepper, SummaryCart } from 'Components';
import { VotingModal } from 'Components/VotingModal';
import { CampaignCreationFooter, CampaignCreationWrapper } from 'Pages/CampaignCreation/components';
import { CreativeCampaignModal } from 'Pages/CampaignCreation/components/CampaignCreatives/_components';
import CampaignErrorInfo from 'Pages/CampaignCreation/components/CampaignErrorInfo';

import { getFileName } from 'Helpers';

import { setCampaignId, setIsLaunchCampaign, setLaunchProspect, setSpinner } from 'Redux/actions';

import {
  getCampaignAudience,
  getCampaignById,
  saveCampaignTarget,
} from 'Requests/Request_Methods/campaignMethods';

import { CampaignStatusEnum, CampaignStep } from 'enums';
import type { CampaignType, TRootState, TTarget, TTargets } from 'types';

import uploadCSV from 'assets/Images/Group286.png';
import mySphereIcon from 'assets/Images/Group87212.png';
import target from 'assets/Images/target.png';
import user from 'assets/Images/user.png';

import './CampaignSetup.scss';

export type CampaignSetupParams = { campaignId: string };

export const CampaignSetup = (props: RouteComponentProps<CampaignSetupParams>) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { campaignId: campaignIdParams } = props.match.params;

  const campaignId = useSelector((state: TRootState) => state.campaignIdReducer);
  const isLaunchCampaign = useSelector((state: TRootState) => state.isCampaignLaunchReducer);

  const [campaignName, setCampaignName] = useState<string>('');
  const [currentCampaign, setCurrentCampaign] = useState<CampaignType | null>(null);
  const [audiences, setAudiences] = useState<TTargets>([]);
  const [selectedAudiences, setSelectedAudiences] = useState<TTargets>([]);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const sum = useMemo(() => {
    return selectedAudiences.reduce((prevValue, item) => prevValue + item.prospects_counts, 0);
  }, [selectedAudiences]);

  const [campaign, setCampaign] = useState<null | {
    prospects: number;
    audiences: number;
  }>(null);

  const [audiencePaginate, setAudiencePaginate] = useState<{
    total: number;
    current_page: number;
    total_page: number;
  }>({
    total: 1,
    current_page: 1,
    total_page: 0,
  });

  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const campaignAudienceAPI = (id: string) => {
    setIsloading(true);
    getCampaignAudience(id, audiencePaginate?.current_page, 10, isShowContact).then(res => {
      if (res.data) {
        setAudiences(res.data.targets);

        setAudiencePaginate({
          current_page: res.data.current_page,
          total: res.data.total,
          total_page: res.data.total_pages,
        });
      }
      setIsloading(false);
    });
  };

  useEffect(() => {
    if (
      currentCampaign?.id === +campaignIdParams &&
      firstLoad &&
      audiences.length &&
      currentCampaign?.audience
    ) {
      callIsSelected();
    }
  }, [audiences, currentCampaign?.audience, currentCampaign?.id, firstLoad]);

  const callIsSelected = () => {
    if (audiences.length && currentCampaign?.audience) {
      const createdAudience = audiences.find(
        audience =>
          audience.status === 'Active' && audience.id === history.location.state?.audience_id
      );

      if (!currentCampaign?.audience.length) {
        const selectableAudiences =
          createdAudience &&
          history.location.state?.isSelected &&
          history.location.state?.audience_id
            ? [createdAudience]
            : [];

        setSelectedAudiences([...selectableAudiences]);
        setCampaign({
          prospects: reduceProspects([...selectableAudiences]),
          audiences: selectableAudiences.length,
        });
        setFirstLoad(false);
      }
      const selectableAudiences =
        createdAudience && history.location.state?.isSelected && history.location.state?.audience_id
          ? [...currentCampaign?.audience, createdAudience]
          : [...currentCampaign?.audience];

      setSelectedAudiences(selected_audiences => [...selected_audiences, ...selectableAudiences]);
      setCampaign({
        prospects: reduceProspects([...selectableAudiences]),
        audiences: selectableAudiences.length,
      });
      setFirstLoad(false);
    }
  };

  useEffect(() => {
    campaignAudienceAPI(campaignIdParams);
  }, [audiencePaginate.current_page, campaignIdParams]);

  const uploadCSVFile = () => {
    setUploadModalOpen(true);
  };
  const handleNextClick = () => {
    const targets = selectedAudiences.map(({ type, id, campaign_id, prospects_counts }) => ({
      type,
      id,
      campaign_id,
      prospects_counts,
    }));
    dispatch(setSpinner(true));

    saveCampaignTarget({ targets }, campaignId.campaignId?.id).then(res => {
      if (res.data) {
        const item = {
          audiences: res.data.audience,
          prospects: res.data.prospects_count,
        };
        history.push({
          pathname: `/create-campaign/${campaignId.campaignId?.id}/creatives`,
          state: item,
        });
      }

      dispatch(setSpinner(false));
    });
  };

  const reduceProspects = (p: { name: string; prospects_counts: number }[]) => {
    return p.reduce((acc, cur) => acc + cur.prospects_counts, 0);
  };

  useEffect(() => {
    if (campaignIdParams) {
      getCampaignDetails(campaignIdParams);
    }
  }, [campaignIdParams]);

  const getCampaignDetails = (id: any) => {
    const getCampaign = async () => {
      try {
        setFirstLoad(true);
        setIsloading(true);
        const { data } = await getCampaignById(id);
        if (data) {
          if (data.status === CampaignStatusEnum.IN_REVIEW)
            history.replace(`/campaigns/${data.id}`);

          if (data.step === CampaignStep.DONE)
            if (
              data.status === CampaignStatusEnum.ERROR ||
              data.status === CampaignStatusEnum.CREATING
            )
              history.replace('/campaigns');

          setCampaignName(data?.name || '');
          dispatch(setCampaignId({ id: id || campaignIdParams }));
          setCurrentCampaign(data);
          setCampaign({
            prospects: reduceProspects(data?.audience),
            audiences: data?.audience.length,
          });
        }
        setIsloading(false);
      } catch (e) {
        toast.error(e);
      } finally {
        setIsloading(false);
      }
    };
    getCampaign();
  };

  const inputRef = useRef<HTMLInputElement>(null);
  const handleCSV = async () => {
    console.log('csv');
    // need to set something
  };
  const [isLoading, setIsloading] = useState(true);
  const [isShowContact, setIsShowContact] = useState(false);

  useEffect(() => {
    if (!uploadModalOpen && campaignName) {
      getCampaignDetails(campaignId?.campaignId?.id || campaignIdParams);
      if (audiencePaginate.current_page === 1) campaignAudienceAPI(campaignIdParams);
      else
        setAudiencePaginate({
          total: 1,
          current_page: 1,
          total_page: 0,
        });
    }
  }, [uploadModalOpen]);

  const audienceSelection = () => {
    const [mySphereModal, setMySphereModal] = useState(false);
    if (isLoading) {
      return <Loading />;
    }
    return (
      <div className="CampaignSetup__empty" style={{ boxSizing: 'content-box' }}>
        {uploadModalOpen && (
          <CreativeCampaignModal
            campaignId={campaignId.campaignId || 1}
            onSubmit={() => {}}
            okText="Upload"
            onClickOutside={() => {
              setUploadModalOpen(false);
            }}
            type="csv"
          />
        )}

        <div className="CampaignSetup__box">
          <div className="CampaignSetup__box__content-inner">
            <div className="CampaignSetup__box__icon_container">
              <img src={target} alt="target" className="CampaignSetup__box__icon" />
            </div>
            <div className="CampaignSetup__boxText">
              <Text
                on="grey-500"
                size="s"
                textAlign="center"
                UNSAFE_className="CampaignSetup__boxtext_size"
              >
                Use the Prospect Finder to apply lenses and find audiences most relevant to your
                market.
              </Text>
            </div>
          </div>

          <Button
            type="button"
            kind="primary"
            width="auto"
            size="l"
            UNSAFE_className="CampaignSetup__boxButtonSize"
            UNSAFE_TEXTSTYLE="CampaignSetup__boxTextButton"
            onClick={() => {
              dispatch(setIsLaunchCampaign(true));
              dispatch(setLaunchProspect(true));
              history.push('/prospects');
            }}
          >
            Launch Prospect Finder
          </Button>
        </div>
        <div className="CampaignSetup__box">
          <div className="CampaignSetup__box__content-inner">
            <div className="CampaignSetup__box__icon_container">
              <img src={uploadCSV} alt="upload" className="CampaignSetup__box__icon" />
            </div>
            <div className="CampaignSetup__boxText">
              <Text
                on="grey-500"
                size="s"
                textAlign="center"
                UNSAFE_className="CampaignSetup__boxtext_size"
              >
                Upload a list of your prospects and send them advertisements.
              </Text>
            </div>
          </div>
          <Button
            type="file"
            kind="primary"
            width="auto"
            size="l"
            UNSAFE_className="CampaignSetup__boxButtonSize"
            UNSAFE_TEXTSTYLE="CampaignSetup__boxTextButton"
            onClick={uploadCSVFile}
          >
            Upload CSV
          </Button>
        </div>
        <div className="CampaignSetup__box">
          <div className="CampaignSetup__box__content-inner">
            <div className="CampaignSetup__box__icon_container">
              <img src={user} alt="user" className="CampaignSetup__box__icon" />
            </div>
            <div className="CampaignSetup__boxText">
              <Text
                on="grey-500"
                size="s"
                textAlign="center"
                UNSAFE_className="CampaignSetup__boxtext_size"
              >
                Send advertisements to everyone in your contacts.
              </Text>
            </div>
          </div>

          <Button
            type="button"
            kind="primary"
            width="auto"
            size="l"
            UNSAFE_className="CampaignSetup__boxButtonSize"
            UNSAFE_TEXTSTYLE="CampaignSetup__boxTextButton"
            disabled={isShowContact}
            onClick={() => {
              dispatch(setIsLaunchCampaign(true));
              dispatch(setLaunchProspect(true));
              history.push('/prospects/talk-to-your-sphere');
            }}
          >
            Use My Sphere
          </Button>
          {mySphereModal && (
            <Modal offClick={() => setMySphereModal(false)}>
              <VotingModal
                handleModel={() => setMySphereModal(false)}
                icons={mySphereIcon}
                title="Use My Sphere"
                subTitle="Send advertisements to everyone in your contacts."
              />
            </Modal>
          )}
          <input
            type="file"
            style={{ display: 'none' }}
            ref={inputRef}
            onInput={() => {
              handleCSV();
            }}
          />
        </div>
      </div>
    );
  };
  function audienceIcon(audience: any) {
    if (audience.type === 'prospects') {
      return (
        <CustomIcons
          name="upload"
          color={audience.status === 'Pending' ? '#D1D1D1' : 'var(--color-secondary-300)'}
          className="table-audience-icon"
        />
      );
    }
    if (audience.type === 'audience') {
      return (
        <FontAwesomeIcon
          icon={faBullseye}
          color={audience.status === 'Pending' ? '#D1D1D1' : 'var(--color-secondary-300)'}
          className="table-audience-icon"
        />
      );
    }
    return user;
  }

  const renderAudiences = () => {
    if (isLoading) {
      return <Loading />;
    }
    if (audiences?.length) {
      return (
        <>
          <Table<TTarget>
            rows={audiences}
            rowKey="eltoro_audience_id"
            rowDisabled={audience => audience.status === 'Pending'}
            tableRowClass="CampaignSetup__tableRow"
            checkBorder="1px solid black"
            checkBoxActive="CampaignSetup__tableCheckbox"
            selectedRows={selectedAudiences}
            tableHeaderNoCheckbox
            fixedCellSize
            onSelect={audienceList => {
              setSelectedAudiences([...audienceList]);
              setCampaign({
                prospects: reduceProspects(audienceList),
                audiences: audienceList.length,
              });
            }}
            columns={[
              {
                path: 'source',
                label: 'Source',
                RowCell: audience => (
                  <FlexBox justifyContent="center" alignItems="center">
                    {audienceIcon(audience)}
                  </FlexBox>
                ),
                width: '103px',
              },
              {
                path: 'name',
                label: 'Name',
                RowCell: audience => (
                  <Text
                    on="white"
                    size="l"
                    weight="bold"
                    tag="div"
                    UNSAFE_className="table-audience-name"
                  >
                    {audience.type === 'prospects' ? getFileName(audience.name) : audience.name}
                    <br />
                    <p
                      style={{
                        fontWeight: 300,
                        margin: '0',
                        textTransform: 'capitalize',
                      }}
                    >
                      {audience.source}
                    </p>
                  </Text>
                ),
              },
              {
                path: 'prospects_counts',
                label: 'Prospects',
                width: '118px',
                RowCell: audience => (
                  <Text on="white" size="l" weight="bold" UNSAFE_className="prospects_count">
                    <FontAwesomeIcon
                      icon={faWalking}
                      color={
                        audience.status === 'Pending' ? '#D1D1D1' : 'var(--color-secondary-300)'
                      }
                    />{' '}
                    {audience.prospects_counts || '-'}
                  </Text>
                ),
              },
            ]}
          />
          <Pagination
            title="audiences"
            currentPage={audiencePaginate?.current_page}
            loading={isLoading}
            totalItems={audiencePaginate?.total}
            totalPages={audiencePaginate?.total_page}
            rangeOfItems={10}
            onClickPrev={() =>
              setAudiencePaginate({
                ...audiencePaginate,
                current_page: audiencePaginate?.current_page - 1,
              })
            }
            onClickNext={() =>
              setAudiencePaginate({
                ...audiencePaginate,
                current_page: audiencePaginate?.current_page + 1,
              })
            }
          />
        </>
      );
    }
    return (
      <div className="CampaignSetup__NoAudience">
        <Text
          on="grey-050"
          weight="normal"
          tag="div"
          size="xl"
          textAlign="center"
          UNSAFE_className="CampaignSetup__NoAudience_titles"
        >
          You don&apos;t have a saved audience.
          <p
            style={{
              fontSize: '16px',
              fontWeight: 400,
              color: '#6D6D6D',
              marginTop: '-6px',
            }}
          >
            Use the prospect activity tool or upload your own audience to get started
          </p>
        </Text>
      </div>
    );
  };

  return (
    <div className="CampaignSetup">
      <MaxHeightContainer
        fullHeight
        header={
          <PageHeader
            UNSAFE_CLASSNAME_GRID="CampaignSetup__titleSpace"
            UNSAFE_CLASSNAME="CampaignSetup__header"
            UNSAFE_CLASSNAME_TITLE="CampaignCheckout__title "
            actionsStyle="CampaignSetup__progress"
            title={campaignName ?? (isLaunchCampaign?.campaignId?.name || '')}
            actions={[
              <ProgressStepper
                key="progress-stepper"
                tagNames={['Audience', 'Creative', 'Checkout', 'Submit']}
                activeStep={1}
              />,
            ]}
          />
        }
        footer={
          <CampaignCreationFooter>
            <div className="CampaignSetup__bottom_details">
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className="prospects-selected">
                  <FontAwesomeIcon style={{ color: '#FFAB03' }} icon={faWalking} />{' '}
                  <Text UNSAFE_className="" on="primary-lighter" size="l">
                    <b>{sum}</b> <span>Prospect Selected</span>
                  </Text>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Text
                    on="primary-lighter"
                    size="xs"
                    UNSAFE_className="CampaignSetup__targeted_audience"
                  >
                    from {selectedAudiences.length} Targeted Audience
                  </Text>
                </div>
              </div>
            </div>
            <div className="CampaignSetup__next-button">
              <Tooltip
                placement="top"
                trigger="hover"
                overlayClassName={classNames('audience-tooltip', 'use-audience-prospects')}
                showArrow
                overlay="Please select additional audiences to reach a total of more than 50 prospects."
                {...(sum < 50 ? {} : { visible: false })}
                getTooltipContainer={() => document.body}
              >
                <Button
                  size="l"
                  UNSAFE_className="CampaignSetup__campaign_use_total_prospects"
                  onClick={handleNextClick}
                  disabled={!campaignId.campaignId || !selectedAudiences.length || sum < 50}
                >
                  <span>
                    Use <b>{sum} Prospects</b>
                  </span>
                </Button>
              </Tooltip>
            </div>
          </CampaignCreationFooter>
        }
      >
        <Layout>
          {currentCampaign?.status === CampaignStatusEnum.ERROR &&
            currentCampaign?.step === CampaignStep.AUDIENCE && (
              <CampaignErrorInfo
                title="There were some issues with the attached audience(s), which have been automatically removed."
                subTitle="Please consider selecting different audiences."
              />
            )}
          <CampaignCreationWrapper>
            <div>
              <div className="CampaignSetup__audiences">
                <div>
                  <Text on="grey-500" size="xxl" weight="bold" UNSAFE_className="heading">
                    Create a new Target Audience or pick one from the list below
                  </Text>
                </div>
                <div style={{ marginTop: '7px', marginBottom: '0.5rem' }}>
                  <Text on="white" size="s" UNSAFE_className="CampaignSetup__subheading">
                    Your campaign needs an audience, choose one or more audience libraries
                  </Text>
                </div>

                <div className="CampaignSetup__audience-list">
                  {audienceSelection()}
                  {renderAudiences()}
                </div>
              </div>
            </div>
            {campaign && <SummaryCart campaign={campaign} />}
          </CampaignCreationWrapper>
        </Layout>
      </MaxHeightContainer>
    </div>
  );
};
