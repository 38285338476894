import React from 'react';
import type { FC, ChangeEventHandler, ReactNode } from 'react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';

import classNames from 'classnames';

import './InputBox.scss';

type InputTypes = {
  type?: string;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  isError?: boolean;
  icon?: IconProp | ReactNode;
  validIcon?: ReactNode;
  placeHolder?: string | undefined;
  value?: string | number | readonly string[] | undefined;
  wdt?: string | number | undefined;
  border?: string | number | undefined;
  FontSize?: string | number | undefined;
  Height?: string | number | undefined;
  paddingLeft?: string | number | undefined;
  paddingTop?: string | number | undefined;
  EyePassIcon?: ReactNode;
  reference?: any;
  UNSAFE_CLASS_text?: string | number | undefined;
  UNSAFE_CLASS_icon?: string | number | undefined;
  UNSAFE_CLASS_Boolean?: boolean;
  UNSAFE_CLASS_RIGHT_ICON?: boolean;
  UNSAFE_CLASS_INPUT_BOX?: string;
  disabled?: boolean;
  Input__ClassName?: string;
  data_testid?: string;
  prefix?: String;
};
export const InputBox: FC<InputTypes> = ({
  type,
  onChange,
  isError,
  disabled = false,
  icon,
  validIcon,
  placeHolder,
  value,
  wdt,
  border,
  FontSize,
  Height,
  paddingLeft,
  paddingTop,
  EyePassIcon,
  UNSAFE_CLASS_icon,
  UNSAFE_CLASS_text,
  UNSAFE_CLASS_Boolean = true,
  UNSAFE_CLASS_RIGHT_ICON,
  UNSAFE_CLASS_INPUT_BOX,
  Input__ClassName,
  prefix,
  data_testid,
  reference,
}) => {
  return (
    <>
      <div
        style={{
          width: 'fit-content',
          position: UNSAFE_CLASS_icon && UNSAFE_CLASS_Boolean ? 'absolute' : 'relative',
        }}
        className={UNSAFE_CLASS_INPUT_BOX}
      >
        <div
          className={classNames(
            UNSAFE_CLASS_icon,
            isError ? 'inputLeftIconError' : 'inputLeftIcon'
          )}
        >
          {icon}
          <span style={{ color: 'black' }} className="inputPrefix">
            {prefix}
          </span>
        </div>
        <input
          ref={reference}
          style={{
            width: wdt,
            height: Height,
            border: isError ? '1px solid #bd0000' : border,
            fontSize: FontSize,
            paddingLeft,
            paddingTop,
          }}
          className={classNames(
            UNSAFE_CLASS_text,
            Input__ClassName,
            isError ? 'setPasswordInputError' : 'setPasswordInput'
          )}
          disabled={disabled}
          type={type}
          onChange={onChange}
          placeholder={placeHolder}
          value={value}
          data-testid={data_testid}
        />
        <div className={classNames(isError ? 'inputRightIcon campaignInputRightIcon' : 'hidden')}>
          {validIcon}
        </div>
      </div>
      <div className="inputRightIcon">{EyePassIcon}</div>
    </>
  );
};
