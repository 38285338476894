import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { Table, Text } from '@eltoro-ui/components';
import { getChargebeeTransactions } from 'Requests';
import { TableSelectionWrapper, Loading } from 'Components';
import './Transaction.scss';
import { TTransaction } from '../../../../types';

export const Transaction = () => {
  const [transactions, setTransactions] = useState<TTransaction[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getChargebeeTransactions(1, 5)
      .then(res => {
        if (res?.data?.transactions) setTransactions(res.data.transactions);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });

    return () => {
      setTransactions([]);
    };
  }, []);

  const getLoading = () => {
    let component;
    if (loading) {
      component = <Loading />;
    } else if (!transactions.length) {
      component = (
        <div
          style={{
            minHeight: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h4>No Transactions</h4>
        </div>
      );
    } else {
      component = (
        <Table
          UNSAFE_className="transactions-table"
          noCheckbox
          striped
          rows={transactions || []}
          columns={[
            {
              path: '',
              width: '18%',
              alignment: 'left',
              RowCell: (transaction: TTransaction) => (
                <span>
                  <Text on="white" weight="bold" size="l" UNSAFE_className="transaction_name">
                    {transaction.name}
                  </Text>
                  <br />
                  <Text
                    on="white"
                    weight="light"
                    UNSAFE_className="transaction_date"
                    UNSAFE_style={{ fontSize: '16px', color: '#6D6D6D' }}
                  >
                    {moment(transaction.created_at).format('MM/DD/YYYY')} -{' '}
                    <span style={{ textTransform: 'capitalize' }}>{transaction.type}</span>
                  </Text>
                </span>
              ),
            },
            {
              path: '',
              alignment: 'right',
              RowCell: (transaction: TTransaction) => {
                const formatter = new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                });
                return (
                  <span>
                    <Text on="white" weight="bold" size="l" UNSAFE_className="transaction_cost">
                      {formatter.format(transaction.budget / 100)}
                    </Text>
                  </span>
                );
              },
            },
          ]}
        />
      );
    }
    return component;
  };

  return (
    <div className="Transactions__container">
      <TableSelectionWrapper
        selectionType="listing"
        selections={[]}
        actions={[]}
        UNSAFE_CLASSNAME_WRAPPER="Transactions__table-wrapper"
      >
        <Text on="white" weight="normal" size="l" UNSAFE_className="heading">
          Transactions
        </Text>
        {getLoading()}
      </TableSelectionWrapper>
    </div>
  );
};
