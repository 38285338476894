import React, { ButtonHTMLAttributes, CSSProperties, useState } from 'react';
import './TextInput.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

type TextInputType = {
  value: string;
  FontSize?: string | number | undefined;
  placeholder?: string;
  UNSAFE_CLASS_icon?: string;
  UNSAFE_CLASS_text?: string;
  onBlur?: any;
  icon?: JSX.Element;
  password?: boolean;
  id?: string;
  color?: string;
  error?: boolean;
  Background?: CSSProperties['background'];
  margin?: string | number | undefined;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  size?: 's' | 'm' | 'l' | 'xl';
  onChange?: (e: any) => void;
  onClick?: () => void;
  className?: string;
  textFieldClassName?: string;
  inputRef?: any;
  iconStyle?: { [key: string]: any };
  iconButtonClassName?: string;
  data_testid?: string;
} & (
  | { valid: boolean; validityIcon?: undefined | JSX.Element }
  | { valid?: undefined; validityIcon: React.ReactNode }
  | { valid?: undefined; validityIcon?: undefined }
);

export const TextInput: React.FC<TextInputType> = ({
  value,
  FontSize,
  onChange,
  color,
  placeholder = '',
  icon,
  valid,
  validityIcon,
  margin,
  password,
  id,
  onKeyDown,
  onBlur,
  error,
  Background,
  size = 'm',
  UNSAFE_CLASS_icon,
  UNSAFE_CLASS_text,
  className,
  textFieldClassName,
  inputRef,
  iconStyle,
  iconButtonClassName = 'defaultIconClass',
  onClick,
  data_testid,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const sizeClass = ` TextInput--${size}-size`;
  const sizeClass2 = `TextInputError--${size}-size`;
  const modifierClasses = classNames(' ', sizeClass, {
    'TextInput--is-focused': isFocused,
  });
  const modifierClasses2 = classNames(' ', sizeClass2, {
    'TextInputError--is-focused': isFocused,
  });

  return (
    <div
      style={{ margin }}
      className={classNames(
        error
          ? `TextInputError${modifierClasses2} ${UNSAFE_CLASS_text}`
          : `TextInput${modifierClasses} ${UNSAFE_CLASS_text}`,
        className
      )}
    >
      <input
        ref={inputRef}
        data-testid={data_testid}
        onKeyDown={onKeyDown}
        id={id}
        className={`${error ? 'TextInputError__input' : 'TextInput__input'} ${textFieldClassName}`}
        type={password ? 'password' : 'text'}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false);
          if (onBlur) {
            onBlur();
          }
        }}
        style={{
          borderColor: color,
          color,
          fontSize: FontSize,
          background: Background,
        }}
      />
      {!!icon && <span className="icon">{icon}</span>}
      {!!validityIcon && (
        <div className={error ? 'TextInputError__iconRight' : 'TextInput__iconRight'}>
          {validityIcon}
        </div>
      )}
      {!!valid && (
        <div className={error ? 'TextInputError__iconRight' : 'TextInput__iconRight'}>
          <FontAwesomeIcon icon={valid ? faExclamationCircle : faTimes} />
        </div>
      )}
    </div>
  );
};
TextInput.defaultProps = {
  FontSize: '18px',
  Background: '#fff',
};
