import { withinMinMax } from '.';
import { DimensionsType } from '../Typings';

const padding = 10;

// creates intial block position and dimensions
export const createBlockPosDims = (
  bounds: DimensionsType,
  minMaxWidth: [number, number],
  minMaxHeight: [number, number]
) => {
  const isMobile = bounds.width <= 320 && bounds.height <= 250;
  // division is used as a ratio to define a max width or height
  const division = isMobile ? 2.125 : 3;
  const isVertical = bounds.height > bounds.width;
  const initialWidth = isVertical ? bounds.width - padding : bounds.width / division;
  const initialHeight = !isVertical ? bounds.height - padding : bounds.height / division;
  // final dimensions are set after making sure the initial is within the minMax length supplied
  const width = withinMinMax(initialWidth, minMaxWidth);
  const height = withinMinMax(initialHeight, minMaxHeight);

  // centers block pos to its bounds (artboard)
  const x = bounds.width / 2 - width / 2;
  const y = bounds.height / 2 - height / 2;

  return { width, height, x, y };
};
