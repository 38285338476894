import React from 'react';

import { Button, Modal } from '@eltoro-ui/components';

import { TCurrentSubscription } from 'types';

import premium_honey from 'assets/Images/honey-premium.png';
import basic_honey from 'assets/Images/honey-basic.png';

import './KeepCurrentSubscription.scss';

type KeepCurrentSubscriptionProps = {
  current: TCurrentSubscription | null;
  onKeepPlan: ({ sub_id }: Pick<TCurrentSubscription, 'sub_id'>) => () => void;
  onCancel: () => void;
  loading?: boolean;
};

const KeepCurrentSubscription = ({
  current,
  onKeepPlan,
  onCancel,
  loading,
}: KeepCurrentSubscriptionProps) => {
  if (!current) return null;

  return (
    <Modal className="keep-current-subscription" offClick={!loading ? onCancel : () => undefined}>
      <div className="cancel-subscription-modal-container">
        <div className="cancel-subscription-modal-content">
          <div className="icon-container">
            <img src={current.item_price_id === 'basic' ? basic_honey : premium_honey} alt="icon" />
          </div>
          <div className="cancel-subscription-modal-body">
            <span className="cancel-subscription-modal-body-title">
              {current.item_price_id === 'basic' ? 'Stay on Basic' : 'Keep Enjoying Premium'}
            </span>
            <div className="cancel-subscription-modal-body-description">
              <span>
                {current.item_price_id === 'basic'
                  ? 'You will retain access to the features and functionalities of the basic plan, but please note that the premium plan features will not be accessible.'
                  : 'You are on track to continuing your premium journey with us! Continue to enjoy the exclusive benefits of our premium service and the unrestricted access to our full suite of features and tools.'}
              </span>
            </div>
          </div>
        </div>
        <div className="cancel-subscription-modal-footer">
          <Button
            weight="bold"
            size="l"
            disabled={loading}
            onClick={!loading ? onCancel : () => undefined}
          >
            No, Thanks
          </Button>
          <Button
            kind="primary"
            weight="bold"
            size="l"
            disabled={loading}
            loading={loading}
            onClick={onKeepPlan({ sub_id: current.sub_id })}
          >
            Keep Current Plan
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default KeepCurrentSubscription;
