import React, { FC, ReactElement, PropsWithChildren, ReactNode } from 'react';
import { Text } from '@eltoro-ui/components';
import folderImg from '../../assets/Images/folder.png';
import './EmptyStatus.scss';

export const EmptyStatus: FC<
  {
    heading?: string;
    subHeading?: string;
    icon?: ReactElement | false;
  } & PropsWithChildren<ReactNode>
> = ({ heading = 'No Records Are Found', subHeading, icon, children }) => {
  return (
    <div className="empty_status_container">
      <div className="empty_status_container__nested_container">
        {icon || <img src={folderImg} alt="folder" />}
        <div className="empty_status_container__headings_container">
          <Text UNSAFE_className="empty_status_container__heading" on="white" size="xxxl">
            {heading}
          </Text>
          <br />
          <Text UNSAFE_className="empty_status_container__subHeading" on="white">
            {subHeading}
          </Text>
        </div>
        <>{children}</>
      </div>
    </div>
  );
};
