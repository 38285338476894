/* eslint-disable */
import React, { useEffect, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';

import classNames from 'classnames';
import CustomIcons from 'assets/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faLink } from '@fortawesome/pro-regular-svg-icons';

import { GridBox, Text } from '@eltoro-ui/components';
import { ConfirmationModal, ErrorMsg, InputBox } from 'Components';

import closeIcon from 'assets/Images/close1.png';
import ImageIcon from 'assets/Images/image (1).png';

import type { CreativeUrls } from 'Pages/CampaignCreation/components/CampaignCreatives';

import type { CampaignType, CreativeType } from 'types';
import { CreativeStatusEnum, CreativeTypeEnum } from 'enums';

import { validateURL } from 'Helpers';

import './ImageWithLink.scss';

type ImageWithLinkProps = {
  creatives: CreativeType[];
  setCampaign: (value: CampaignType) => void;
  setGeneratedBanners: Dispatch<SetStateAction<CreativeType[]>>;
  setCreativeUUIDs: Dispatch<SetStateAction<string[]>>;
  creativeUUIDs: string[];
  setFiles: Dispatch<SetStateAction<File[]>>;
  campaign: CampaignType;
  isSubmit: boolean;
  type: string;
  setnextClicked: (p: boolean) => void;
  setUrls?: Dispatch<SetStateAction<CreativeUrls>>;
  urls?: CreativeUrls;
};

export const ImageWithLink = ({
  creatives,
  setCampaign,
  campaign,
  isSubmit,
  type,
  setnextClicked,
  setFiles,
  setGeneratedBanners,
  setCreativeUUIDs,
  creativeUUIDs,
  setUrls,
  urls,
}: ImageWithLinkProps) => {
  const [deleteModal, setdeleteModal] = useState(false);
  const [isError, setIsError] = useState(false);
  const [deleteModalCreative, setdeleteModalCreative] = useState<any>();
  const [clickUrl, setClickUrl] = useState<string>('');

  useEffect(() => {
    const creative = creatives.find(creative => creative.content_type === type);
    if (creative && creative.click_url) {
      setClickUrl(creative.click_url);
    }
  }, [creatives]);

  const checkName = (val: string) => {
    if (val === 'banner') {
      return `Digital Banner Clickthrough URL`;
    }
    return 'Video Clickthrough URL';
  };

  const creativeClickUrl = (str: any) => {
    return str?.replace('https://', '');
  };

  const onChangeUrl = (e: any) => {
    if (e.target.value.length <= 4 || !validateURL(e.target.value)) {
      setIsError(true);
      setnextClicked(true);
      if (type === 'banner') {
        setUrls?.(urls => ({ ...urls, banner_url: false }));
      }
      if (type === 'video') {
        setUrls?.(urls => ({ ...urls, video_url: false }));
      }
    } else {
      setIsError(false);
      setnextClicked(false);
      if (type === 'banner') {
        setUrls?.(urls => ({ ...urls, banner_url: true }));
      }
      if (type === 'video') {
        setUrls?.(urls => ({ ...urls, video_url: true }));
      }
    }

    const CampCreatives = campaign.creatives?.map(creative => {
      if (creative.content_type === type) {
        creative.click_url = e.target.value;
      }

      return creative;
    });

    setClickUrl(e.target.value);

    setCampaign({
      ...campaign,
      creatives: [...CampCreatives],
    });
  };

  return (
    <GridBox gap="15px" gridTemplateColumns="2.6fr">
      {deleteModal && (
        <ConfirmationModal
          deletion
          heading="Are you sure you want to delete this creative?"
          onCancel={() => setdeleteModal(false)}
          onSubmit={() => {
            if (deleteModalCreative) {
              const newCreatives = campaign.creatives?.filter(creative => {
                if (creative.text) {
                  if (creativeUUIDs.includes(creative.id))
                    setCreativeUUIDs(creative_uuids =>
                      creative_uuids.filter(uuid => deleteModalCreative.id !== uuid)
                    );
                  else
                    setFiles(prevFiles =>
                      prevFiles.filter(file => file.name !== deleteModalCreative.text)
                    );
                } else
                  setGeneratedBanners(banners =>
                    banners.filter(banner => banner.thumb !== deleteModalCreative.thumb)
                  );

                return creative.id !== deleteModalCreative.id;
              });

              setUrls?.({
                banner_url: newCreatives.some(
                  creative => creative.content_type === CreativeTypeEnum.BANNER
                ),
                video_url: newCreatives.some(
                  creative => creative.content_type === CreativeTypeEnum.VIDEO
                ),
              });

              setCampaign({
                ...campaign,
                creatives: newCreatives,
              });
            }
            setdeleteModal(false);
          }}
        />
      )}

      <div>
        <Text
          UNSAFE_className="digital_banner_title"
          tag="div"
          on="white"
          size="l"
          UNSAFE_style={{ color: '#2B1F0A' }}
        >
          {checkName(type)}
          <span style={{ color: 'red' }}>*</span>
        </Text>
        {type !== 'directMail' && (
          <>
            <InputBox
              wdt="100%"
              Input__ClassName="link_input_field"
              value={creativeClickUrl(clickUrl)}
              prefix="https://"
              placeHolder="www.example.com"
              onChange={onChangeUrl}
              UNSAFE_CLASS_icon="link_input_field_prefix"
              UNSAFE_CLASS_text="link_input_field_err"
              UNSAFE_CLASS_Boolean={false}
              icon={<FontAwesomeIcon icon={faLink} className="AttachedCreatives__icon" />}
              isError={
                (isSubmit &&
                  creatives
                    .filter(creative => creative.content_type === type)
                    .some(creative => !creative.click_url)) ||
                isError
              }
              validIcon={
                <abbr title="https://www.example.com">
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    style={{
                      fontSize: '23px',
                    }}
                  />
                </abbr>
              }
              UNSAFE_CLASS_RIGHT_ICON
            />
            {isSubmit &&
              creatives
                .filter(creative => creative.content_type === type)
                .some(creative => !creative.click_url) && (
                <ErrorMsg
                  UNSAFE_CLASS_icon="creative-error"
                  title="Please enter url"
                  icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                />
              )}
          </>
        )}
      </div>

      <div className="ImageWithLink">
        {creatives
          .filter(({ content_type }) => content_type === type)
          .map(creative => {
            return (
              <GridBox key={creative.id} gap="15px" gridTemplateColumns="2.6fr">
                <div className="center-image-box">
                  <div
                    className={classNames('image-box', {
                      'creative-error': creative.status === CreativeStatusEnum.ERROR,
                    })}
                  >
                    {creative.status === CreativeStatusEnum.ERROR && (
                      <span className="creative-error-filter-container">
                        <span className="creative-error-icon">
                          <FontAwesomeIcon
                            icon={faExclamationCircle}
                            color="#FF0000"
                            style={{ fontSize: 24 }}
                          />
                        </span>
                      </span>
                    )}
                    <img
                      src={closeIcon}
                      alt="new"
                      className="image-box__img-1"
                      onClick={() => {
                        setdeleteModalCreative(creative);
                        setdeleteModal(true);
                      }}
                    />
                    {creative.content_type === CreativeTypeEnum.BANNER ||
                    (creative.content_type === CreativeTypeEnum.VIDEO &&
                      creative.thumb_presigned_url) ? (
                      <div className="creative-content">
                        {creative.content_type === CreativeTypeEnum.VIDEO && (
                          <CustomIcons
                            className="creative-video-icon"
                            name="video_play"
                            color="#FFAB03"
                            fontSize={20}
                          />
                        )}
                        <img
                          src={
                            creative.thumb ||
                            creative.thumb_presigned_url ||
                            creative.presigned_url ||
                            ImageIcon
                          }
                          style={{
                            color: 'transparent',
                          }}
                          alt="banner"
                          className="image-box__img-2"
                        />
                      </div>
                    ) : (
                      <div className="creative-content">
                        {creative.content_type === CreativeTypeEnum.VIDEO && (
                          <CustomIcons
                            className="creative-video-icon"
                            name="video_play"
                            color="#FFAB03"
                            fontSize={20}
                          />
                        )}
                        <video
                          src={creative.presigned_url || creative.thumb || ImageIcon}
                          className="image-box__img-2"
                        >
                          <track kind="captions" />
                        </video>
                      </div>
                    )}
                  </div>
                  <button
                    type="button"
                    className="center-image-box__text"
                    onClick={() => {
                      if (creative.thumb || creative.presigned_url) {
                        const anchor = document.createElement('a');

                        anchor.href = creative.presigned_url || creative.thumb;

                        anchor.rel = 'noreferrer';
                        anchor.download = creative.text ? creative?.text : 'download';
                        anchor.target = '_blank';

                        anchor.click();
                      }
                    }}
                  >
                    View
                  </button>
                </div>
              </GridBox>
            );
          })}
      </div>
    </GridBox>
  );
};
