import React, { Suspense } from 'react';
import { KonvaEventObject } from 'konva/types/Node';
import { Group, Rect, Text } from 'react-konva';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AvailableBlocksType } from '../../../../Typings';
import { handleBlockDragMove } from '../../../../Helpers';
import {
  positionAndDimensionsBlockAtom,
  visibleBlockAtom,
  lockedBlockAtom,
  selectedKindAtom,
  selectedBlockIdsAtom,
  mainDimensionsBlockAtom,
  allBlocksOfKindSelector,
} from '../../../../State';
import { ActionButton, BasicParagraph, SquareLogo, PoliticalHeader } from '..';
import { ImageFrame } from '../ImageFrame';

// acts as a wrapper for a specific block type
export const Block: React.FC<{
  blockConfig: {
    id: string;
    kind: AvailableBlocksType;
    artboardName: string;
  };
  isEditing: boolean;
  onSelect: (select: boolean) => void;
  isOnMobile: boolean;
}> = ({ blockConfig, isEditing, onSelect, isOnMobile }) => {
  const [blockPosAndDims, setBlockPosAndDims] = useRecoilState(
    positionAndDimensionsBlockAtom(blockConfig)
  );
  const mainDimensions = useRecoilValue(mainDimensionsBlockAtom(blockConfig.id));
  const visible = useRecoilValue(visibleBlockAtom(blockConfig.id));
  const locked = useRecoilValue(lockedBlockAtom(blockConfig.id));
  const [selectedKind, setSelectedKind] = useRecoilState(selectedKindAtom);
  const allBlocksOfKind = useRecoilValue(allBlocksOfKindSelector);
  const [selectedIds, setSelectedIds] = useRecoilState(selectedBlockIdsAtom);
  const { x, y, width, height } = blockPosAndDims;

  const handleDragEnd = (e: KonvaEventObject<DragEvent>) => {
    setBlockPosAndDims({
      ...blockPosAndDims,
      x: e.target.x(),
      y: e.target.y(),
    });
    const layer = e.target.getLayer();
    if (!layer) return;
    // removes any guides
    layer.find('.guide-line').destroy();
  };

  const isSelected =
    isEditing && blockConfig.kind === selectedKind && selectedIds.some(id => id === blockConfig.id);

  const handleClick = (e: KonvaEventObject<MouseEvent>) => {
    e.cancelBubble = true;
    // if there is no Block selected
    if (!selectedKind) {
      onSelect(true);
      setSelectedKind(blockConfig.kind);
      return;
    }
    // if there is a block selected and user clicks a block of the same type
    if (blockConfig.kind === selectedKind) {
      const matchingBlocks = allBlocksOfKind
        ?.map(blockOfKind => blockOfKind.id)
        .reduce((acc: boolean[], blockId) => {
          const match = selectedIds.some(selectedId => selectedId === blockId);
          return [...acc, match];
        }, []);
      // if all blocks of a kind are selected, only select the individual block that is clicked
      if (matchingBlocks?.every(matchingBlock => matchingBlock)) {
        setSelectedIds([blockConfig.id]);
        return;
      }
      // if next click will result in 0 selectedIds, close edit menu
      if (isSelected) {
        setSelectedIds(selectedIds.filter(id => id !== blockConfig.id));
        if (selectedIds.length === 1) {
          setSelectedKind(undefined);
          onSelect(false);
        }
      } else {
        // otherwise, add block to selected array
        setSelectedIds([blockConfig.id, ...selectedIds]);
      }
    } else {
      // select new kind
      setSelectedKind(blockConfig.kind);
    }
  };

  const renderPiece = () => {
    switch (blockConfig.kind) {
      case 'actionButton':
        return (
          <ActionButton isOnMobile={isOnMobile} id={blockConfig.id} width={width} height={height} />
        );
      case 'basicParagraph':
        return (
          <BasicParagraph
            isOnMobile={isOnMobile}
            id={blockConfig.id}
            width={width}
            height={height}
          />
        );
      case 'squareLogo':
        return (
          <SquareLogo isOnMobile={isOnMobile} id={blockConfig.id} width={width} height={height} />
        );
      case 'politicalHeader':
        return (
          <PoliticalHeader
            isOnMobile={isOnMobile}
            id={blockConfig.id}
            width={width}
            height={height}
          />
        );
      case 'imageFrame':
        return (
          <Suspense fallback={<Text text="Loading..." />}>
            <ImageFrame isOnMobile={isOnMobile} id={blockConfig.id} width={width} height={height} />
          </Suspense>
        );
      default:
        return <Rect />;
    }
  };

  // a clumsy way to get the width and height of a block if it differs from the original recoil state
  const actualWidth = () => {
    if (isOnMobile && mainDimensions.width >= width) {
      return width;
    }
    return mainDimensions.width > 0 ? mainDimensions.width : width;
  };

  const actualHeight = mainDimensions.height > 0 ? mainDimensions.height : height;

  return (
    <Group
      id={blockConfig.id}
      x={x}
      y={y}
      draggable={!locked}
      onDragMove={e => handleBlockDragMove(e, actualWidth(), actualHeight)}
      onDragEnd={handleDragEnd}
      onClick={handleClick}
      onMouseEnter={e => {
        if (locked) return;
        const stage = e.currentTarget.getStage();
        if (!stage) return;
        stage.container().style.cursor = 'pointer';
      }}
      onMouseLeave={e => {
        const stage = e.currentTarget.getStage();
        if (!stage) return;
        stage.container().style.cursor = 'default';
      }}
      opacity={visible ? 1 : 0}
      listening={visible}
    >
      {/* isSelected rect, should be updated to look better and be more accessable */}
      <Rect
        stroke={locked ? 'darkgrey' : 'yellow'}
        strokeWidth={5}
        width={actualWidth()}
        height={actualHeight}
        cornerRadius={3}
        opacity={isSelected ? 1 : 0}
        shadowColor={locked ? 'grey' : 'gold'}
        shadowBlur={5}
        shadowOpacity={1}
      />
      {renderPiece()}
    </Group>
  );
};
