import { Button, Modal, Text } from '@eltoro-ui/components';
import { fal } from '@fortawesome/pro-light-svg-icons';
import React, { useEffect, useState } from 'react';

export const BedroomNBathFilter = ({
  modalOpen,
  setIsModalOpen,
  setBath,
  setBed,
  currentFilters,
  postedFilters,
  setPostedFilter,
  setIsClicked,
  isClicked,
}: {
  modalOpen: any;
  setIsModalOpen: any;
  setBath: any;
  setBed: any;
  currentFilters: any;
  postedFilters: any;
  setPostedFilter: any;
  setIsClicked?: any;
  isClicked?: any;
}) => {
  const [localBedAndBath, setLocalBedAndBath] = useState<{
    bed: null | string;
    bath: null | string;
  }>({
    bed: null,
    bath: null,
  });
  const [activeBed, setActiveBed] = useState({
    any: false,
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
    six: false,
  });
  const [initialActiveBed, setInitialActiveBed] = useState({
    any: false,
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
    six: false,
  });
  const [initialActiveBath, setInitialActiveBath] = useState({
    any: false,
    one: false,
    onePlus: false,
    two: false,
    twoPlus: false,
    three: false,
    threePlus: false,
    four: false,
  });
  const [activeBath, setActiveBath] = useState({
    any: false,
    one: false,
    onePlus: false,
    two: false,
    twoPlus: false,
    three: false,
    threePlus: false,
    four: false,
  });

  const [clearBedAndBath, setClearBedAndBath] = useState<{
    bed: null | string;
    bath: null | string;
  }>({
    bed: null,
    bath: null,
  });

  const onSaveChange = () => {
    setIsModalOpen({ ...modalOpen, bedNBath: false });
    setBed({
      ...currentFilters,
      bed: localBedAndBath?.bed || currentFilters.bed,
      bath: localBedAndBath?.bath || currentFilters?.bath,
    });

    // setBath({ ...currentFilters, ...localBedAndBath })
    setIsClicked({ ...isClicked, isLoaded: false });
  };

  const clickCancelOrClear = (type: string) => {
    setIsModalOpen({ ...modalOpen, bedNBath: false });
    if (type === 'Clear') {
      setBath({ ...currentFilters, bath: null, bed: null });
      setPostedFilter({ ...postedFilters, bath: null, bed: null });
      setActiveBath({ ...initialActiveBath });
      setActiveBed({ ...initialActiveBed });
      if (currentFilters?.bed || currentFilters?.bath) {
        setIsClicked({ ...isClicked, isLoaded: false });
      }
    }
    // show prospects box on cancel
    // else {
    //   setIsClicked({ ...isClicked, isLoaded: true })
    // }
  };

  function areFalse(Obj: any) {
    return Object.values(Obj).every(value => value === false);
  }

  return (
    <div>
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text size="m" tag="div" on="grey-500" UNSAFE_className="title">
            Bedrooms
          </Text>
          <div className="title-divider" />
        </div>
        <br />
        <div className="SellYourListingFaster__modalOptions">
          <Button
            UNSAFE_className={`SellYourListingFaster__modalOption ${
              activeBed.any || (currentFilters.bed === 'ANY' && areFalse(activeBed))
                ? 'FindYourProspectFiltersActive'
                : ''
            }`}
            onClick={() => {
              // setBed({ ...currentFilters, bed: 'ANY' })
              setLocalBedAndBath({ ...localBedAndBath, bed: 'ANY' });
              setPostedFilter({ ...postedFilters, bed: '0' });
              setActiveBed({ ...initialActiveBed, any: true });
            }}
          >
            ANY
          </Button>
          <Button
            UNSAFE_className={`SellYourListingFaster__modalOption ${
              activeBed.one || (currentFilters.bed === '1+' && areFalse(activeBed))
                ? 'FindYourProspectFiltersActive'
                : ''
            }`}
            onClick={() => {
              // setBed({ ...currentFilters, bed: '1+' })
              setLocalBedAndBath({ ...localBedAndBath, bed: '1+' });
              setPostedFilter({ ...postedFilters, bed: '1' });
              setActiveBed({ ...initialActiveBed, one: true });
            }}
          >
            1+
          </Button>
          <Button
            UNSAFE_className={`SellYourListingFaster__modalOption ${
              activeBed.two || (currentFilters.bed === '2+' && areFalse(activeBed))
                ? 'FindYourProspectFiltersActive'
                : ''
            }`}
            onClick={() => {
              // setBed({ ...currentFilters, bed: '2+' })
              setLocalBedAndBath({ ...localBedAndBath, bed: '2+' });
              setPostedFilter({ ...postedFilters, bed: '2' });
              setActiveBed({ ...initialActiveBed, two: true });
            }}
          >
            2+
          </Button>
          <Button
            UNSAFE_className={`SellYourListingFaster__modalOption ${
              activeBed.three || (currentFilters.bed === '3+' && areFalse(activeBed))
                ? 'FindYourProspectFiltersActive'
                : ''
            }`}
            onClick={() => {
              // setBed({ ...currentFilters, bed: '3+' })
              setLocalBedAndBath({ ...localBedAndBath, bed: '3+' });
              setPostedFilter({ ...postedFilters, bed: '3' });
              setActiveBed({ ...initialActiveBed, three: true });
            }}
          >
            3+
          </Button>
          <Button
            UNSAFE_className={`SellYourListingFaster__modalOption ${
              activeBed.four || (currentFilters.bed === '4+' && areFalse(activeBed))
                ? 'FindYourProspectFiltersActive'
                : ''
            }`}
            onClick={() => {
              // setBed({ ...currentFilters, bed: '4+' })
              setLocalBedAndBath({ ...localBedAndBath, bed: '4+' });
              setPostedFilter({ ...postedFilters, bed: '4' });
              setActiveBed({ ...initialActiveBed, four: true });
            }}
          >
            4+
          </Button>
          <Button
            UNSAFE_className={`SellYourListingFaster__modalOption ${
              activeBed.five || (currentFilters.bed === '5+' && areFalse(activeBed))
                ? 'FindYourProspectFiltersActive'
                : ''
            }`}
            onClick={() => {
              // setBed({ ...currentFilters, bed: '5+' })
              setLocalBedAndBath({ ...localBedAndBath, bed: '5+' });
              setPostedFilter({ ...postedFilters, bed: '5' });
              setActiveBed({ ...initialActiveBed, five: true });
            }}
          >
            5+
          </Button>
          <Button
            UNSAFE_style={{ borderRadius: '0 10px 10px 0' }}
            UNSAFE_className={`SellYourListingFaster__modalOption ${
              activeBed.six || (currentFilters.bed === '6+' && areFalse(activeBed))
                ? 'FindYourProspectFiltersActive'
                : ''
            }`}
            onClick={() => {
              // setBed({ ...currentFilters, bed: '6+' })
              setLocalBedAndBath({ ...localBedAndBath, bed: '6+' });
              setPostedFilter({ ...postedFilters, bed: '6' });
              setActiveBed({ ...initialActiveBed, six: true });
            }}
          >
            6+
          </Button>
        </div>
        <br />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text size="m" tag="div" on="grey-500" UNSAFE_className="title">
            Bathrooms
          </Text>
          <div className="title-divider" />
        </div>
        <br />
        <div className="SellYourListingFaster__modalOptions">
          <Button
            UNSAFE_className={`SellYourListingFaster__modalOption ${
              activeBath.any || (currentFilters.bath === 'ANY' && areFalse(activeBath))
                ? 'FindYourProspectFiltersActive'
                : ''
            }`}
            onClick={() => {
              // setBath({ ...currentFilters, bath: 'ANY' })
              setLocalBedAndBath({ ...localBedAndBath, bath: 'ANY' });
              setPostedFilter({ ...postedFilters, bath: '0' });
              setActiveBath({ ...initialActiveBath, any: true });
            }}
          >
            ANY
          </Button>
          <Button
            UNSAFE_className={`SellYourListingFaster__modalOption ${
              activeBath.one || (currentFilters.bath === '1+' && areFalse(activeBath))
                ? 'FindYourProspectFiltersActive'
                : ''
            }`}
            onClick={() => {
              // setBath({ ...currentFilters, bath: '1+' })
              setLocalBedAndBath({ ...localBedAndBath, bath: '1+' });
              setPostedFilter({ ...postedFilters, bath: '1' });
              setActiveBath({ ...initialActiveBath, one: true });
            }}
          >
            1+
          </Button>
          <Button
            UNSAFE_className={`SellYourListingFaster__modalOption ${
              activeBath.onePlus || (currentFilters.bath === '1.5+' && areFalse(activeBath))
                ? 'FindYourProspectFiltersActive'
                : ''
            }`}
            onClick={() => {
              // setBath({ ...currentFilters, bath: '1.5+' })
              setLocalBedAndBath({ ...localBedAndBath, bath: '1.5+' });
              setPostedFilter({ ...postedFilters, bath: '1.5' });
              setActiveBath({ ...initialActiveBath, onePlus: true });
            }}
          >
            1.5+
          </Button>
          <Button
            UNSAFE_className={`SellYourListingFaster__modalOption ${
              activeBath.two || (currentFilters.bath === '2+' && areFalse(activeBath))
                ? 'FindYourProspectFiltersActive'
                : ''
            }`}
            onClick={() => {
              // setBath({ ...currentFilters, bath: '2+' })
              setLocalBedAndBath({ ...localBedAndBath, bath: '2+' });
              setPostedFilter({ ...postedFilters, bath: '2' });
              setActiveBath({ ...initialActiveBath, two: true });
            }}
          >
            2+
          </Button>
          <Button
            UNSAFE_className={`SellYourListingFaster__modalOption ${
              activeBath.twoPlus || (currentFilters.bath === '2.5+' && areFalse(activeBath))
                ? 'FindYourProspectFiltersActive'
                : ''
            }`}
            onClick={() => {
              // setBath({ ...currentFilters, bath: '2.5+' })
              setLocalBedAndBath({ ...localBedAndBath, bath: '2.5+' });
              setPostedFilter({ ...postedFilters, bath: '2.5' });
              setActiveBath({ ...initialActiveBath, twoPlus: true });
            }}
          >
            2.5+
          </Button>
          <Button
            UNSAFE_className={`SellYourListingFaster__modalOption ${
              activeBath.three || (currentFilters.bath === '3+' && areFalse(activeBath))
                ? 'FindYourProspectFiltersActive'
                : ''
            }`}
            onClick={() => {
              // setBath({ ...currentFilters, bath: '3+' })
              setLocalBedAndBath({ ...localBedAndBath, bath: '3+' });
              setPostedFilter({ ...postedFilters, bath: '3' });
              setActiveBath({ ...initialActiveBath, three: true });
            }}
          >
            3+
          </Button>
          <Button
            UNSAFE_className={`SellYourListingFaster__modalOption ${
              activeBath.threePlus || (currentFilters.bath === '3.5+' && areFalse(activeBath))
                ? 'FindYourProspectFiltersActive'
                : ''
            }`}
            onClick={() => {
              // setBath({ ...currentFilters, bath: '3.5+' })
              setLocalBedAndBath({ ...localBedAndBath, bath: '3.5+' });
              setPostedFilter({ ...postedFilters, bath: '3.5' });
              setActiveBath({ ...initialActiveBath, threePlus: true });
            }}
          >
            3.5+
          </Button>
          <Button
            UNSAFE_style={{ borderRadius: '0 10px 10px 0' }}
            UNSAFE_className={`SellYourListingFaster__modalOption ${
              activeBath.four || (currentFilters.bath === '4+' && areFalse(activeBath))
                ? 'FindYourProspectFiltersActive'
                : ''
            }`}
            onClick={() => {
              // setBath({ ...currentFilters, bath: '4+' })
              setLocalBedAndBath({ ...localBedAndBath, bath: '4+' });
              setPostedFilter({ ...postedFilters, bath: '4' });
              setActiveBath({ ...initialActiveBath, four: true });
            }}
          >
            4+
          </Button>
        </div>
      </div>
      <br />
      <hr />

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button
          kind="default"
          size="l"
          UNSAFE_className="SellYourListingFaster__modalCancel"
          weight="bold"
          onClick={() => clickCancelOrClear('Cancel')}
        >
          Cancel
        </Button>
        <Button
          kind="default"
          size="l"
          UNSAFE_className="SellYourListingFaster__modalSave"
          disabled={
            !(localBedAndBath.bath || currentFilters.bath) ||
            !(localBedAndBath.bed || currentFilters.bed)
          }
          weight="bold"
          onClick={onSaveChange}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
};
