import React from 'react';
import './DateRangeSelector.scss';
import { DateRangePicker, Spacer, Text } from '@eltoro-ui/components';

export const DateRangeSelector = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  message,
}: {
  startDate: Date;
  endDate: Date;
  onStartDateChange: (newStartDate: Date) => void;
  onEndDateChange: (newEndDate: Date) => void;
  // message?: string | ((durationInDays: number) => string)
  message?: any;
}) => {
  const duration = Math.ceil((endDate.valueOf() - startDate.valueOf()) / (60 * 60 * 24) / 1000) + 1;
  const sameMonth =
    startDate.getMonth() === endDate.getMonth() &&
    startDate.getFullYear() === endDate.getFullYear();

  const sameYear = startDate.getFullYear() === endDate.getFullYear();

  const options = {
    month: 'long',
    day: 'numeric',
  } as const;

  const optionsWithYear = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  } as const;

  return (
    <div className="DateRangeSelector">
      <div className="DateRangeSelector__header">
        <Text
          on="white"
          size="xxl"
          weight="bold"
          tag="div"
          UNSAFE_className="DateRangeSelector__pick_timeframe"
        >
          Pick Timeframe
        </Text>
        <Text
          on="white"
          kind="subdued"
          size="s"
          tag="div"
          UNSAFE_className="DateRangeSelector__pick_timeframe_subheading"
        >
          Your campaign and direct mail will go out within this timeframe
        </Text>
        <Spacer />
      </div>
      <div className="DateRangeSelector__body">
        <div className="DateRangeSelector__date-selection">
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            min={new Date()}
            onStartDateChange={onStartDateChange}
            onEndDateChange={onEndDateChange}
          />
        </div>
        <div className="DateRangeSelector__date-display">
          <p className="DateRangeSelector__count_days">
            {`${duration} ${duration <= 1 ? 'DAY' : 'DAYS'}`}
          </p>
          <Spacer />
          <p className="DateRangeSelector__start_end_date">
            {sameMonth
              ? `${startDate.toLocaleString('default', {
                  month: 'long',
                })} ${startDate.getDate()} - ${endDate.getDate()}`
              : `${startDate.toLocaleDateString(
                  'default',
                  sameYear ? options : optionsWithYear
                )} - ${endDate.toLocaleDateString(
                  'default',
                  sameYear ? options : optionsWithYear
                )}`}
          </p>
          <Spacer />
          <Text on="white" size="s" tag="div">
            {typeof message === 'function' ? message(duration) : message}
          </Text>
        </div>
      </div>
    </div>
  );
};
