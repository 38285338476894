import React, { ReactNode, Suspense } from 'react';
import {
  useRecoilBridgeAcrossReactRoots_UNSTABLE,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { Group, Layer, Rect, Stage, Text as KonvaText } from 'react-konva';
import { v4 as uuid } from 'uuid';
import { MaxHeightContainer, Spacer, Text } from '@eltoro-ui/components';
import {
  ActionButton,
  BasicParagraph,
  SquareLogo,
  PoliticalHeader,
  DefaultImageFrame,
} from '../../Canvas';
import { createBlockPosDims } from '../../../Helpers';
import { blocksBase, defaultArtboards } from '../../../Data';
import {
  ArtboardBlocksType,
  AvailableBlocksType,
  DimensionsType,
  PositionType,
} from '../../../Typings';
import './BlockSelection.scss';
import { artboardBlockTypesSelector, blocksAtom, creativeTypeAtom } from '../../../State';

// A button to select Blocks to add to artboards
// Each button contains a little konva stage to display the Block
const SelectionButton: React.FC<{
  label: string;
  onClick: () => void;
  block: ReactNode;
  pos: PositionType;
  dimensions: DimensionsType;
  disabled: boolean;
}> = ({ label, onClick, block, pos, dimensions, disabled }) => {
  const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE();

  return (
    <button
      onClick={onClick}
      className={`BlockSelection__selection-button ${
        disabled ? 'BlockSelection__selection-button--is-disabled' : ''
      }`}
      type="button"
      disabled={disabled}
    >
      <div className="BlockSelection__selection-button__canvas">
        <Stage width={dimensions.width} height={dimensions.height}>
          <RecoilBridge>
            <Layer>
              <Group x={pos.x} y={pos.y}>
                {block}
              </Group>
            </Layer>
          </RecoilBridge>
        </Stage>
      </div>
      <Spacer height=".5rem" />
      <Text on="white" kind="subdued">
        {label}
      </Text>
    </button>
  );
};

const blockDim = {
  width: 250,
  height: 150,
};

export const BlockSelection: React.FC<{}> = () => {
  const creativeType = useRecoilValue(creativeTypeAtom);
  const setBlocks = useSetRecoilState(blocksAtom);
  const currentArtboardBlocks = useRecoilValue(artboardBlockTypesSelector);
  const blockKeys = Object.keys(blocksBase) as Array<AvailableBlocksType>;

  const dimensions = (kind: AvailableBlocksType) =>
    createBlockPosDims(blockDim, blocksBase[kind].minMaxWidth, blocksBase[kind].minMaxHeight);
  const getBlock = (kind: AvailableBlocksType) => {
    switch (kind) {
      case 'actionButton':
        return (
          <ActionButton
            id={uuid()}
            width={dimensions(kind).width}
            height={dimensions(kind).height}
          />
        );
      case 'basicParagraph':
        return (
          <BasicParagraph
            id={uuid()}
            width={dimensions(kind).width}
            height={dimensions(kind).height}
          />
        );
      case 'squareLogo':
        return (
          <SquareLogo id={uuid()} width={dimensions(kind).width} height={dimensions(kind).height} />
        );
      case 'politicalHeader':
        return (
          <PoliticalHeader
            id={uuid()}
            width={dimensions(kind).width}
            height={dimensions(kind).height}
          />
        );
      case 'imageFrame':
        return (
          <Suspense fallback={<KonvaText text="Loading..." />}>
            <DefaultImageFrame
              id={uuid()}
              width={dimensions(kind).width}
              height={dimensions(kind).height}
            />
          </Suspense>
        );
      default:
        return <Rect />;
    }
  };

  const handleClick = (kind: AvailableBlocksType) => {
    // get current artboard names
    const artboardNames = defaultArtboards
      .filter(artboard => artboard.type === creativeType)
      .map(artboard => artboard.id);

    // apply blocks to current artboards
    // This is where a blocks config is created.
    // Each block must have a unique id for recoil...
    // ...to be able to tell the difference.
    setBlocks(prevBlocks => {
      const newBlocks = artboardNames.reduce((acc: ArtboardBlocksType[], artboardName) => {
        return [...acc, { id: uuid(), kind, artboardName }];
      }, []);
      return [...prevBlocks, ...newBlocks];
    });
  };

  return (
    <div className="BlockSelection">
      <MaxHeightContainer
        fullHeight
        UNSAFE_CLASSNAME="BlockSelection__overflow"
        header={
          <div className="BlockSelection__header">
            <Text on="white" weight="bold">
              Select a block
            </Text>
          </div>
        }
      >
        <div className="BlockSelection__body">
          {blockKeys.map((kind: AvailableBlocksType, i) => (
            <React.Fragment key={blocksBase[kind].kind}>
              <SelectionButton
                label={blocksBase[kind].label}
                block={getBlock(kind)}
                pos={{
                  x: blockDim.width / 2 - dimensions(kind).width / 2,
                  y: blockDim.height / 2 - dimensions(kind).height / 2,
                }}
                dimensions={blockDim}
                onClick={() => handleClick(kind)}
                disabled={currentArtboardBlocks.some(blockKind => blockKind === kind)}
              />
              <Spacer />
            </React.Fragment>
          ))}
        </div>
      </MaxHeightContainer>
    </div>
  );
};
