/* eslint-disable */

import React, { useRef, useState } from 'react';
import type { ChangeEventHandler } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import toast from 'react-hot-toast';

import { Button, FlexBox, Text } from '@eltoro-ui/components';
import { Spinner } from 'Components/Spinner';
import { ErrorMsg } from 'Components/ErrorMsg';
import { Tooltip } from 'Components/Tooltip';
import { LabelInputField } from 'Components/LabelInputField';
import SubscriptionCard from 'Components/SubscriptionPlanChoose/components/SubscriptionCard';

import {
  CardComponent,
  CardCVV,
  CardExpiry,
  CardNumber,
} from '@chargebee/chargebee-js-react-wrapper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { faLongArrowLeft } from '@fortawesome/pro-light-svg-icons/faLongArrowLeft';

import cross from 'assets/Images/cross.png';
import Warning from 'Images/warning.png';

import { setSpinner } from 'Redux/actions';
import { chargebeeSubscription } from 'Requests/Request_Methods/chargebeeMethods';

import type { TCommonSubscription, TRootState } from 'types';

import './ConfirmPayment.scss';
import classNames from 'classnames';

type ConfirmPaymentProps = {
  subscription: TCommonSubscription | null;
  onPreviousStep: () => void;
  setModalStep: (step: number) => void;
};

type TConfirmPaymentFormState = {
  isSubmit: boolean;
  firstName: string;
  lastName: string;
  isLoading: boolean;
  isComplete: boolean;
  field: string;
  number: boolean;
  monthExpiry: string;
  expiry: boolean;
  detailsMessage: string;
  statusCode: string;
  statusCodeCard: string | boolean;
  statusCodeExpiry: string | boolean;
  statusCodeCvv: string | boolean;
  cvv: boolean;
  invalidCard: string;
  invalidExpiry: string;
  invalidCvv: string;
  errors: any;
  allErr: boolean;
};

const initialFormState = {
  isSubmit: false,
  firstName: '',
  lastName: '',
  isLoading: false,
  isComplete: false,
  field: '',
  number: false,
  monthExpiry: '',
  expiry: false,
  detailsMessage: '',
  statusCode: '',
  statusCodeCard: '',
  statusCodeExpiry: '',
  statusCodeCvv: '',
  cvv: false,
  invalidCard: '',
  invalidExpiry: '',
  invalidCvv: '',
  errors: {},
  allErr: false,
};

const classes = {
  focus: 'focus',
  invalid: 'invalid',
  empty: 'empty',
  complete: 'complete',
};
// Google Fonts and other whitelisted fonts
const fonts = ['https://fonts.googleapis.com/css?family=Open+Sans'];
// Style customizations
const styles = {
  base: {
    color: '#6d6d6d',
    fontWeight: 400,
    paddingTop: '4px',

    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    lineHeight: '17px',

    // height: '1.4rem',
    ':focus': {
      // color: '#6d6d6d',
    },

    '::placeholder': {
      color: '#6D6D6D',
    },

    ':focus::placeholder': {
      color: '#6D6D6D',
      border: '1px solid #bd0000',
    },
  },
  invalid: {
    border: '1px solid #bd0000 !important',
    color: '#BD0000',
    ':focus': {
      color: '#BD0000',
    },
    '::placeholder': {
      color: '#BD0000',
    },
  },
};

const placeholder = {
  number: '4111 1111 1111 1111',
  expiry: 'MM / YY',
  cvv: 'CVC',
};

const ConfirmPayment = ({ subscription, onPreviousStep, setModalStep }: ConfirmPaymentProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { spinner } = useSelector((state: TRootState) => state.SpinnerReducer);

  const cardRef = useRef<any>(null);

  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [formState, setFormState] = useState<TConfirmPaymentFormState>(initialFormState);

  const disabled =
    !formState.firstName ||
    !formState.lastName ||
    formState.statusCodeCard ||
    formState.statusCodeCvv ||
    formState.statusCodeExpiry ||
    !formState.number ||
    !formState.expiry ||
    !formState.cvv ||
    formState.isLoading;

  const onBack = () => {
    setFormState(initialFormState);
    onPreviousStep();
  };

  const closedSection = () => {
    window.localStorage.clear();
    history.go(0);
  };

  const onReady = (el: any) => {
    el.focus();
  };

  const onChange = (status: any) => {
    setFormState(prevState => ({
      ...prevState,
      isSubmit: false,
      statusCodeCard: typeof status.error === 'object',
      number: status.complete && status.field === 'number',
      invalidCard: status?.error?.message,
    }));
  };

  const onChangeExpiry = (status: any) => {
    setFormState(prevState => ({
      ...prevState,
      isSubmit: false,
      statusCodeExpiry: typeof status.error === 'object',
      invalidExpiry: status?.error?.message,
      expiry: status.complete && status.field === 'expiry',
    }));
    formState.invalidExpiry === 'Expired card'
      ? setFormState(prevState => ({ ...prevState, allErr: true }))
      : false;
  };

  const onChangeCvv = (status: any) => {
    setFormState(prevState => ({
      ...prevState,
      isSubmit: false,
      statusCodeCvv: typeof status.error === 'object',
      invalidCvv: status?.error?.message,
      cvv: status.complete && status.field === 'cvv',
    }));
  };

  const onChangeFirstName: ChangeEventHandler<HTMLInputElement> = e => {
    setFormState(prevState => ({
      ...prevState,
      isSubmit: false,
      firstName: e.target.value,
    }));
  };

  const onChangeLastName: ChangeEventHandler<HTMLInputElement> = e => {
    setFormState(prevState => ({
      ...prevState,
      isSubmit: false,
      lastName: e.target.value,
    }));
  };

  const ErrorToolbar = () => {
    return (
      <div style={{ position: 'relative' }}>
        <Tooltip
          text="Please fill the input"
          topAbsolute="-110px"
          rightAbsolute="-43px"
          padding="5px 5px"
        />
      </div>
    );
  };

  const onConfirmPayment = async (): Promise<void> => {
    try {
      dispatch(setSpinner(true));
      if (cardRef && cardRef.current && formState.firstName && formState.lastName) {
        const tokenData = await cardRef.current.tokenize({
          firstName: formState.firstName,
          lastName: formState.lastName,
        });
        await chargebeeSubscription({
          subscription_id: subscription?.id,
          token_id: tokenData.token,
        });
        setModalStep(4);
      }
    } catch (e) {
      if (e?.message?.toLowerCase().includes('Invalid card number'.toLowerCase()))
        toast.error('Invalid card number');
      else toast.error('An error occurred while processing your card.');
    } finally {
      dispatch(setSpinner(false));
    }
  };

  return (
    <div className="ConfirmPayment" style={{ position: 'relative', overflow: 'hidden' }}>
      <img src={cross} onClick={closedSection} alt="cross" className="ConfirmPayment__cross" />
      {spinner && <Spinner />}
      <div className="ConfirmPayment__description">
        <div className="ConfirmPayment__back" onClick={onBack}>
          <FontAwesomeIcon icon={faLongArrowLeft} style={{ fontSize: 44, color: '#ffab03' }} />
        </div>
        <div className="ConfirmPayment__header">
          <Text on="white" UNSAFE_className="heading">
            Confirm Payment
          </Text>
          <Text on="grey-500" UNSAFE_className="sub_heading">
            Please enter your card information below.
          </Text>
        </div>
      </div>
      <div className="ConfirmPayment__content">
        <SubscriptionCard subscription={subscription as TCommonSubscription} />
        <div className="ConfirmPayment__form">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div className="ex1-field" style={{ width: '48%' }}>
              <LabelInputField
                style={{ paddingLeft: '22px', margin: '0rem  0' }}
                lableStyle={{ left: '14px' }}
                title="First Name"
                placeholder="First Name"
                requireTage="*"
                type="text"
                isError={formState.isSubmit && !formState.firstName}
                onChange={onChangeFirstName}
                value={formState.firstName}
                iconRight={
                  formState.isSubmit && !formState.firstName ? (
                    <img
                      className="start_icon"
                      style={{ marginTop: '7px', width: '24px' }}
                      src={Warning}
                      // alt="icon"
                    />
                  ) : null
                }
              />
              {formState.isSubmit && !formState.firstName ? <ErrorToolbar /> : ''}
            </div>
            <div className="ex1-field" style={{ width: '48%' }}>
              <LabelInputField
                style={{ paddingLeft: '22px', margin: '0rem  0' }}
                lableStyle={{ left: '14px' }}
                title="Last Name"
                placeholder="Last Name"
                requireTage="*"
                type="text"
                isError={formState.isSubmit && !formState.lastName}
                onChange={onChangeLastName}
                value={formState.lastName}
                iconRight={
                  formState.isSubmit && !formState.lastName ? (
                    <img
                      className="start_icon"
                      style={{ marginTop: '7px', width: '24px' }}
                      src={Warning}
                      alt="icon"
                    />
                  ) : null
                }
              />
              {formState.isSubmit && !formState.lastName ? <ErrorToolbar /> : ''}
            </div>
          </div>
          <div className="fieldset">
            <CardComponent
              className="field"
              fonts={fonts}
              classes={classes}
              styles={styles}
              ref={cardRef}
              onReady={onReady}
              placeholder={placeholder}
            >
              <div className="ex1-field">
                {/* Card number component */}
                <CardNumber onChange={onChange} onReady={onReady} className="ex1-input" />
                {!formState.invalidCard ? (
                  ''
                ) : (
                  <ErrorMsg
                    title={formState.invalidCard}
                    icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                  />
                )}
                <label className="ex1-label">
                  Card Number<span style={{ color: '#BD0000' }}>*</span>
                </label>
                {/* <i className="ex1-bar"></i> */}
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <div className="ex1-field" style={{ width: '48%' }}>
                  {/* Card expiry component */}
                  <CardExpiry className="ex1-input" onChange={onChangeExpiry} />
                  {!formState.invalidExpiry ? (
                    ''
                  ) : (
                    <ErrorMsg
                      title={formState.invalidExpiry}
                      icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                    />
                  )}
                  <label className="ex1-label">
                    Expiration<span style={{ color: '#BD0000' }}>*</span>
                  </label>
                </div>

                <div className="ex1-field" style={{ width: '48%' }}>
                  {/* Card cvv component */}
                  <CardCVV onChange={onChangeCvv} className="ex1-input" />
                  {!formState.invalidCvv ? (
                    ''
                  ) : (
                    <ErrorMsg
                      title={formState.invalidCvv}
                      icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                    />
                  )}
                  <label className="ex1-label">
                    CVC<span style={{ color: '#BD0000' }}>*</span>
                  </label>
                </div>
              </div>
            </CardComponent>
          </div>
          <FlexBox
            gap="24px"
            UNSAFE_style={{
              position: 'absolute',
              bottom: 25,
              right: 80,
              maxWidth: 'calc(100% - 337px - 88px - 160px)',
            }}
          >
            <span style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
              <div
                className={classNames('authorize-checkbox', isChecked && 'checked')}
                onClick={() => setIsChecked(checked => !checked)}
              >
                {isChecked && <FontAwesomeIcon icon={faCheck} />}
              </div>
              <span className="authorize-text">
                I authorize Beewo to charge the above credit card for prepaid advertising campaigns.
              </span>
            </span>
            <Button
              kind="primary"
              size="l"
              weight="bold"
              disabled={disabled || !isChecked}
              loading={formState.isLoading}
              onClick={onConfirmPayment}
            >
              Confirm
            </Button>
          </FlexBox>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPayment;
