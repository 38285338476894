import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOff, faToggleOn } from '@fortawesome/pro-regular-svg-icons';

import type { LensSidebarHeaderProps } from 'Pages/ProspectActivity/lenses/components/LensSidebarHeader';

type ListingToggleProps = Pick<LensSidebarHeaderProps, 'isToggle' | 'toggleText' | 'isToggleOpen'>;

export default function ListingToggle({ isToggle, isToggleOpen, toggleText }: ListingToggleProps) {
  return (
    <div className="LensSidebarHeader__Inactive">
      <FontAwesomeIcon
        style={{
          fontSize: '32px',
          cursor: 'pointer',
        }}
        size="1x"
        color="#FFB708"
        icon={isToggleOpen ? faToggleOn : faToggleOff}
        onClick={() => {
          if (isToggle) isToggle(!isToggleOpen);
        }}
      />
      <span>{toggleText}</span>
    </div>
  );
}
