// These helpers recieve ISO strings, ex: "2021-03-26T18:24:53.650Z"

export const getLocalTime = (timeString: string) => {
  if (!Date.parse(timeString)) return '';
  const date = new Date(`${timeString.split('T').join(' ')} UTC`);
  const hour = (date.getHours() + 24) % 12 || 12;
  const minute = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
  const ampm = date.getHours() >= 12 ? ' PM' : ' AM';

  if (date && hour && minute && ampm)
    return `${hour < 10 ? '\u00A0\u00A0' : '\u00A0'}${hour}:${minute}${ampm}`;
  return '';
};

export const getLocalDate = (timeString: string, fullDate?: boolean) => {
  if (!Date.parse(timeString)) return '';
  const isToday = (comparedDate: Date) => {
    const today = new Date();
    return (
      comparedDate.getDate() === today.getDate() &&
      comparedDate.getMonth() === today.getMonth() &&
      comparedDate.getFullYear() === today.getFullYear()
    );
  };

  const date = new Date(`${timeString.split('T').join(' ')} UTC`);

  let dateString: string;

  if (!fullDate) {
    dateString = isToday(date)
      ? 'Today'
      : `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().substr(-2)}`;
  } else {
    dateString = `${date.getMonth() + 1}/${date.getDate()}/${date
      .getFullYear()
      .toString()
      .substr(-2)}`;
  }
  if (date && dateString) return dateString;
  return '';
};

export const getLocalDateTime = (timeString: string, fullDate?: boolean) => {
  if (!Date.parse(timeString)) return '';
  return `${getLocalDate(timeString, fullDate)} ${getLocalTime(timeString)}`;
};

export const getTimePassed = (timeString: string) => {
  if (!Date.parse(timeString)) return '';
  const date = new Date(`${timeString.split('T').join(' ')} UTC`);
  const today = new Date();
  const diff = today.getTime() - date.getTime();

  if (diff < 0) return '';

  const startYear = date.getFullYear();
  const feb = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
  const daysInMonth = [31, feb, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  const minutes = Math.floor(diff / 1000 / 60);
  if (minutes < 60) return `${minutes} minute${minutes <= 1 ? '' : 's'} ago`;

  const hours = Math.floor(diff / 1000 / 60 / 60);
  if (hours < 24) return `${hours} hour${hours <= 1 ? '' : 's'} ago`;

  let years = today.getFullYear() - startYear;

  let months = today.getMonth() - date.getMonth();
  if (months < 0) {
    years -= 1;
    months += 12;
  }

  let days = today.getDate() - date.getDate();
  if (days < 0) {
    if (months > 0) {
      months -= 1;
    } else {
      years -= 1;
      months = 11;
    }
    days += daysInMonth[date.getMonth()];
  }

  if (years > 0) return `${years} year${years <= 1 ? '' : 's'} ago`;

  if (months > 0) return `${months} month${months <= 1 ? '' : 's'} ago`;

  return `${days} day${days <= 1 ? '' : 's'} ago`;
};

// Expiry date in terms of credit card- expires after month has passed
export const isValidExpiryDate = (expiry: string) => {
  const maxYear = new Date().getFullYear() + 5;
  const maxYearValue = Number(maxYear.toString().slice(-2));
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = Number(new Date().getFullYear().toString().substr(-2));
  const expirySplit = expiry.split('/');
  const month = Number(expirySplit[0]);
  const year = Number(expirySplit[1]);
  const result =
    month > 0 &&
    month < 13 &&
    year < maxYearValue &&
    !(year < currentYear) &&
    (year > currentYear || (year === currentYear && month >= currentMonth));

  return result;
};
