import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { TRootState } from '../../../types';

import './PageRoutes.scss';

export type PagesType = {
  path: string;
  exactPath?: boolean;
  title: string;
  component: () => ReactElement;
};

type PageRoutesPropsType = {
  pages: PagesType[];
};

export const PageRoutes = ({ pages }: PageRoutesPropsType) => {
  const history = useHistory();
  const location = useLocation();

  const { userData } = useSelector((state: TRootState) => state.userReducer);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    });
  }, [location.pathname, location.search]);

  useEffect(() => {
    if (
      userData?.is_active === 0 &&
      userData?.is_compelete === 1 &&
      userData?.is_comfirmed === 1 &&
      location.pathname !== '/profile/billing'
    )
      history.replace('/profile/billing');
  }, [location.pathname]);

  return (
    <div className="PageRoutes">
      <Switch>
        {pages.map((page, index) => (
          <Route key={index} exact={page.exactPath} path={page.path} component={page.component} />
        ))}
      </Switch>
    </div>
  );
};
