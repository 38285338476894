import React from 'react';
import { Text, Spacer, Button } from '@eltoro-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CreditBundleType, TRootState } from 'types';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { addChargebeeBundle, userDetail } from 'Requests';
import { setUser } from 'Redux/actions';
import { userRole } from 'Helpers';
import { CreditBundleCard } from '..';
import star from '../../assets/Images/Star-2.png';
import './SelectBundle.scss';

export const SelectBundle: React.FC<{
  bundles: CreditBundleType[] | any;
  pointsNeeded?: number;
  selectedBundle?: CreditBundleType | null;
  offClick?: any;
  setIsCreditBuy?: any;

  onChange: (bundle: CreditBundleType) => void;
}> = ({ bundles, pointsNeeded, onChange, selectedBundle, offClick, setIsCreditBuy }) => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state: TRootState) => state.userReducer);
  const buyCredits = () => {
    const body = {
      user_id: userData?.id,
      metadata: selectedBundle?.metadata,
      bundle_id: selectedBundle?.id,
    };

    addChargebeeBundle(body).then(() => {
      const tkn = localStorage.getItem('beewo_token');
      // @ts-ignore
      userDetail(tkn).then(res => {
        if (res?.data?.email) {
          const user = {
            user_role: userRole(res.data.is_super),
            ...res.data,
          };

          dispatch(setUser(user));
          offClick();
          setIsCreditBuy(true);
        }
      });
    });
  };
  return (
    <div className="SelectBundle">
      <>
        <div className="SelectBundle__top">
          <div className="SelectBundle__topItem">
            <Text size="s" on="grey-500">
              Current Balance <FontAwesomeIcon icon={faStar} className="SelectBundle__star" />{' '}
              <Text on="white" weight="bold">
                {userData?.addons_credits.toLocaleString()}
              </Text>
            </Text>
          </div>
          {pointsNeeded !== 0 ? (
            <div className="SelectBundle__topItem">
              <Text size="s" on="grey-500">
                You need <FontAwesomeIcon icon={faStar} className="SelectBundle__star" />{' '}
                <Text on="white" weight="bold">
                  {pointsNeeded ? pointsNeeded * -1 : ''}
                </Text>
              </Text>
            </div>
          ) : (
            ''
          )}
        </div>
        {pointsNeeded !== 0 ? (
          <Text on="white" kind="warning" tag="div" textAlign="center">
            <span style={{ color: '#FF0000' }}>
              You don&apos;t have enough points to buy this, set your own price or buy more points.
            </span>
          </Text>
        ) : (
          ''
        )}
        <Spacer />
        {/* <div className="SelectBundle__points-display">
            You need{' '}
            <FontAwesomeIcon icon={faStar} className="SelectBundle__star" />{' '}
            <Text on="white" weight="bold">
              {pointsNeeded.toLocaleString()}
            </Text>
          </div> */}
      </>

      <Spacer />
      <div className="SelectBundle__bundle-list">
        {bundles.map((bundle: CreditBundleType) => (
          <CreditBundleCard
            key={bundle.name}
            bundle={bundle}
            pointsNeeded={pointsNeeded}
            selectedBundle={selectedBundle}
            onClick={() => onChange(bundle)}
          />
        ))}
      </div>
      <Spacer />
      {/* {pointsNeeded && <Button kind="text">Set Your Price</Button>} */}
      <div className="SelectBundle__bottom">
        {/* <Text on="grey-500">{selectedBundle?.name}</Text> */}

        <Button kind="primary" weight="bold" disabled={!selectedBundle} onClick={buyCredits}>
          Buy Credits
        </Button>
      </div>
    </div>
  );
};
