import { ResponseType, FeedbackType, getVotingType } from 'types';
import requests from '../http';

export const votingMethod = async (
  status?: boolean,
  feature?: string
): Promise<ResponseType<FeedbackType>> => requests.post(`/voting`, { feature, status });

export const getVoting = async (feature?: string): Promise<ResponseType<getVotingType>> =>
  requests.get(`/voting`, { feature });
